import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ChevronLeft, Save, AlertTriangle, Loader2 } from 'lucide-react';
import { supabase } from '../../lib/supabase/client';
import ProjectTypeSelector from '../components/common/ProjectTypeSelector';
import WebProjectForm from '../forms/WebProjectForm';
import VideoProjectForm from '../forms/VideoProjectForm';
import SocialProjectForm from '../forms/SocialProjectForm';
import { Button } from '../../components/ui/Button';

const AdminProjectEdit: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [initialData, setInitialData] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedProjectType, setSelectedProjectType] = useState<string | null>(null);
  
  // Pobierz dane projektu jeśli edytujemy istniejący
  useEffect(() => {
    if (id) {
      fetchProjectData();
    }
  }, [id]);
  
  // Ustaw typ projektu jeśli edytujemy istniejący
  useEffect(() => {
    if (initialData?.projectType) {
      setSelectedProjectType(initialData.projectType);
    }
  }, [initialData]);
  
  const fetchProjectData = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // Pobierz główne dane projektu
      const { data: projectData, error: projectError } = await supabase
        .from('projects')
        .select('*')
        .eq('id', id)
        .single();
      
      if (projectError) throw projectError;
      
      // Pobierz tagi projektu
      const { data: projectTags, error: tagsError } = await supabase
        .from('project_tags')
        .select('tag_id')
        .eq('project_id', id);
      
      if (tagsError) throw tagsError;
      
      // Pobierz technologie projektu
      const { data: projectTechnologies, error: techError } = await supabase
        .from('project_technologies')
        .select('technology_id')
        .eq('project_id', id);
      
      if (techError) throw techError;
      
      // Pobierz tłumaczenia
      const { data: translationsData, error: translationsError } = await supabase
        .from('project_translations')
        .select('*')
        .eq('project_id', id);
      
      if (translationsError) throw translationsError;
      
      // Pobierz funkcjonalności
      const { data: featuresData, error: featuresError } = await supabase
        .from('project_features')
        .select('*')
        .eq('project_id', id)
        .order('sort_order');
      
      if (featuresError) throw featuresError;
      
      // Pobierz galerię
      const { data: galleryData, error: galleryError } = await supabase
        .from('project_gallery')
        .select('*')
        .eq('project_id', id)
        .order('sort_order');
      
      if (galleryError) throw galleryError;
      
      // Usunięto próbę pobrania danych z nieistniejącej tabeli project_achievements
      // Projekty social używają tabeli project_features tak samo jak projekty web
      
      // Pobierz opinie
      const { data: testimonialsData, error: testimonialsError } = await supabase
        .from('project_testimonials')
        .select('*')
        .eq('project_id', id);
      
      if (testimonialsError) throw testimonialsError;
      
      // Przygotuj dane dla formularza
      const plTranslation = translationsData?.find(t => t.language === 'pl');
      const enTranslation = translationsData?.find(t => t.language === 'en');
      
      const formData = {
        title: plTranslation?.title || '',
        title_en: enTranslation?.title || '',
        slug: projectData.slug,
        pl_slug: projectData.pl_slug,
        en_slug: projectData.en_slug,
        client: projectData.client,
        timeFrame: projectData.time_frame,
        projectUrl: projectData.project_url,
        videoUrl: projectData.video_url,
        thumbnailUrl: projectData.thumbnail_url,
        thumbnailAltPl: projectData.thumbnail_alt_pl,
        thumbnailAltEn: projectData.thumbnail_alt_en,
        published: projectData.published,
        projectType: projectData.project_type,
        tags: projectTags?.map(tag => tag.tag_id) || [],
        technologies: projectTechnologies?.map(tech => tech.technology_id) || [],
        
        // Tłumaczenia
        descriptionPl: plTranslation?.description || '',
        descriptionEn: enTranslation?.description || '',
        overviewPl: plTranslation?.overview || '',
        overviewEn: enTranslation?.overview || '',
        challengePl: plTranslation?.challenge || '',
        challengeEn: enTranslation?.challenge || '',
        approachPl: plTranslation?.approach || '',
        approachEn: enTranslation?.approach || '',
        resultsPl: plTranslation?.results || '',
        resultsEn: enTranslation?.results || '',
        
        // Funkcjonalności
        features: featuresData?.map(feature => ({
          titlePl: feature.pl_title,
          titleEn: feature.en_title,
          descriptionPl: feature.pl_description,
          descriptionEn: feature.en_description
        })) || [],
        
        // Galeria
        gallery: galleryData?.map(image => ({
          imageUrl: image.image_url,
          altPl: image.pl_alt,
          altEn: image.en_alt
        })) || [],
        
        // Opinie
        testimonials: testimonialsData?.map(testimonial => ({
          companyName: testimonial.company_name,
          authorName: testimonial.author_name,
          contentPl: testimonial.pl_content,
          contentEn: testimonial.en_content,
          logoUrl: testimonial.logo_url,
          originalLanguage: testimonial.original_language || 'pl'
        })) || [],
        
        // Osiągnięcia
        achievements: [] // Usunięto dane z nieistniejącej tabeli project_achievements
      };
      
      setInitialData(formData);
      
    } catch (error: any) {
      console.error('Błąd pobierania danych projektu:', error);
      setError('Wystąpił błąd podczas pobierania danych projektu. Spróbuj ponownie.');
    } finally {
      setLoading(false);
    }
  };
  
  // Funkcja pomocnicza do przygotowania danych klienta z testimonials
  const prepareClientData = (testimonials: any[]) => {
    if (!testimonials || testimonials.length === 0) {
      return '';
    }
    // Używamy nazwy firmy z pierwszego testimonial jako wartości dla pola client
    return testimonials[0]?.companyName || '';
  };

  // Obsługa zapisu formularza
  const handleSubmit = async (data: any) => {
    setIsSubmitting(true);
    setError(null);
    
    try {
      // Generuj ID projektu jeśli to nowy projekt
      const projectId = id || crypto.randomUUID();
      
      // Ustaw domyślne wartości dla szkicu
      if (!data.published) {
        const defaultDraftValue = 'Wersja robocza';
        const defaultDraftValueEn = 'Draft version';
        data.descriptionPl = data.descriptionPl || defaultDraftValue;
        data.descriptionEn = data.descriptionEn || defaultDraftValueEn;
        data.overviewPl = data.overviewPl || defaultDraftValue;
        data.overviewEn = data.overviewEn || defaultDraftValueEn;
        
        // Wartości specyficzne dla projektu web
        if (selectedProjectType === 'web' || initialData?.projectType === 'web') {
          data.challengePl = data.challengePl || defaultDraftValue;
          data.challengeEn = data.challengeEn || defaultDraftValueEn;
          data.approachPl = data.approachPl || defaultDraftValue;
          data.approachEn = data.approachEn || defaultDraftValueEn;
          data.resultsPl = data.resultsPl || defaultDraftValue;
          data.resultsEn = data.resultsEn || defaultDraftValueEn;
        }
        
        data.title = data.title || defaultDraftValue;
        data.title_en = data.title_en || defaultDraftValueEn;
      }
      
      // Generuj domyślne slugi jeśli nie zostały podane
      data.pl_slug = data.pl_slug || `szkic-${projectId.slice(0, 8)}`;
      data.en_slug = data.en_slug || `draft-${projectId.slice(0, 8)}`;
      
      // Przygotuj dane główne projektu
      const projectType = selectedProjectType || initialData?.projectType || 'web';
      
      const projectData: any = {
        id: projectId,
        project_type: projectType,
        client: prepareClientData(data.testimonials),
        thumbnail_url: data.thumbnailUrl,
        thumbnail_alt_pl: data.thumbnailAltPl,
        thumbnail_alt_en: data.thumbnailAltEn,
        published: data.published,
        pl_slug: data.pl_slug,
        en_slug: data.en_slug
      };
      
      // Dodaj pola specyficzne dla typu projektu
      if (projectType === 'web') {
        projectData.time_frame = data.timeFrame;
        projectData.project_url = data.projectUrl;
      } else if (projectType === 'video') {
        projectData.video_url = data.videoUrl;
      }
      
      if (id) {
        // Aktualizacja istniejącego projektu
        const { error: projectError } = await supabase
          .from('projects')
          .update(projectData)
          .eq('id', id);
        
        if (projectError) throw projectError;
      } else {
        // Dodawanie nowego projektu
        const { error: projectError } = await supabase
          .from('projects')
          .insert([projectData]);
        
        if (projectError) throw projectError;
      }
      
      // Przygotuj dane tłumaczeń w zależności od typu projektu
      const plTranslation: any = {
        project_id: projectId,
        language: 'pl',
        title: data.title,
        description: data.descriptionPl,
        overview: data.overviewPl
      };
      
      const enTranslation: any = {
        project_id: projectId,
        language: 'en',
        title: data.title_en,
        description: data.descriptionEn,
        overview: data.overviewEn
      };
      
      // Dodaj pola specyficzne dla typu projektu
      if (projectType === 'web') {
        plTranslation.challenge = data.challengePl;
        plTranslation.approach = data.approachPl;
        plTranslation.results = data.resultsPl;
        
        enTranslation.challenge = data.challengeEn;
        enTranslation.approach = data.approachEn;
        enTranslation.results = data.resultsEn;
      } else if (projectType === 'social') {
        plTranslation.results = data.resultsPl;
        enTranslation.results = data.resultsEn;
      }
      
      const translations = [plTranslation, enTranslation];
      
      if (id) {
        // Usuń stare tłumaczenia
        await supabase
          .from('project_translations')
          .delete()
          .eq('project_id', id);
      }
      
      // Dodaj nowe tłumaczenia
      const { error: translationsError } = await supabase
        .from('project_translations')
        .insert(translations);
      
      if (translationsError) throw translationsError;
      
      // Zapisz funkcjonalności dla projektów web i social
      if ((projectType === 'web' || projectType === 'social') && data.features) {
        const features = data.features.map((feature: any, index: number) => ({
          project_id: projectId,
          pl_title: feature.titlePl,
          en_title: feature.titleEn,
          pl_description: feature.descriptionPl,
          en_description: feature.descriptionEn,
          sort_order: index
        }));
        
        if (id) {
          // Usuń stare funkcjonalności
          await supabase
            .from('project_features')
            .delete()
            .eq('project_id', id);
        }
        
        // Dodaj nowe funkcjonalności jeśli istnieją
        if (features.length > 0) {
          const { error: featuresError } = await supabase
            .from('project_features')
            .insert(features);
          
          if (featuresError) throw featuresError;
        }
      }
      
      // Zapisz galerię dla projektów web i social media
      if ((projectType === 'web' || projectType === 'social') && data.gallery) {
        const gallery = data.gallery.map((image: any, index: number) => ({
          project_id: projectId,
          image_url: image.imageUrl,
          pl_alt: image.altPl,
          en_alt: image.altEn,
          sort_order: index
        }));
        
        if (id) {
          // Usuń stare zdjęcia
          await supabase
            .from('project_gallery')
            .delete()
            .eq('project_id', id);
        }
        
        // Dodaj nowe zdjęcia jeśli istnieją
        if (gallery.length > 0) {
          const { error: galleryError } = await supabase
            .from('project_gallery')
            .insert(gallery);
          
          if (galleryError) throw galleryError;
        }
      }
      
      // Zapisz opinie
      if (data.testimonials) {
        const testimonials = data.testimonials.map((testimonial: any) => ({
          project_id: projectId,
          company_name: testimonial.companyName,
          author_name: testimonial.authorName,
          pl_content: testimonial.contentPl,
          en_content: testimonial.contentEn,
          logo_url: testimonial.logoUrl,
          original_language: testimonial.originalLanguage || 'pl'
        }));
        
        if (id) {
          // Usuń stare opinie
          await supabase
            .from('project_testimonials')
            .delete()
            .eq('project_id', id);
        }
        
        // Dodaj nowe opinie jeśli istnieją
        if (testimonials.length > 0) {
          const { error: testimonialsError } = await supabase
            .from('project_testimonials')
            .insert(testimonials);
          
          if (testimonialsError) throw testimonialsError;
        }
      }
      
      // Zapisz powiązania z tagami
      if (id) {
        // Usuń stare powiązania
        await supabase
          .from('project_tags')
          .delete()
          .eq('project_id', id);
      }
      
      // Dodaj nowe powiązania z tagami (nawet jeśli lista jest pusta)
      if (data.tags?.length > 0) {
        const { error: tagsError } = await supabase
          .from('project_tags')
          .insert(data.tags.map((tagId: string) => ({
            project_id: projectId,
            tag_id: tagId
          })));
        
        if (tagsError) throw tagsError;
      }
      
      // Zapisz powiązania z technologiami tylko dla projektów web
      if (projectType === 'web' && data.technologies) {
        if (id) {
          // Usuń stare powiązania
          await supabase
            .from('project_technologies')
            .delete()
            .eq('project_id', id);
        }
        
        // Dodaj nowe powiązania z technologiami jeśli istnieją
        if (data.technologies?.length > 0) {
          const { error: techError } = await supabase
            .from('project_technologies')
            .insert(data.technologies.map((techId: string) => ({
              project_id: projectId,
              technology_id: techId
            })));
          
          if (techError) throw techError;
        }
      }
      
      // Usunięto kod zapisujący osiągnięcia dla projektów social media
      
      // Przekieruj do listy projektów
      navigate('/admin/projects');
      
    } catch (error: any) {
      console.error('Błąd zapisywania projektu:', error);
      setError('Wystąpił błąd podczas zapisywania projektu. Spróbuj ponownie.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Ustawienie odpowiedniego formularza na podstawie typu projektu
  const renderProjectForm = () => {
    if (id && initialData) {
      // Dla istniejącego projektu
      switch (initialData.projectType) {
        case 'web':
          return (
            <WebProjectForm
              onSubmit={(data) => handleSubmit(data)}
              initialData={initialData}
              isLoading={isSubmitting}
            />
          );
        case 'video':
          return (
            <VideoProjectForm
              onSubmit={(data) => handleSubmit(data)}
              initialData={initialData}
              isLoading={isSubmitting}
            />
          );
        case 'social':
          return (
            <SocialProjectForm
              onSubmit={(data) => handleSubmit(data)}
              initialData={initialData}
              isLoading={isSubmitting}
            />
          );
        default:
          return (
            <WebProjectForm
              onSubmit={(data) => handleSubmit(data)}
              initialData={initialData}
              isLoading={isSubmitting}
            />
          );
      }
    } else if (!id && selectedProjectType) {
      // Dla nowego projektu
      switch (selectedProjectType) {
        case 'web':
          return (
            <WebProjectForm
              onSubmit={(data) => handleSubmit(data)}
              initialData={initialData}
              isLoading={isSubmitting}
            />
          );
        case 'video':
          return (
            <VideoProjectForm
              onSubmit={(data) => handleSubmit(data)}
              initialData={initialData}
              isLoading={isSubmitting}
            />
          );
        case 'social':
          return (
            <SocialProjectForm
              onSubmit={(data) => handleSubmit(data)}
              initialData={initialData}
              isLoading={isSubmitting}
            />
          );
        default:
          return null;
      }
    }
    return null;
  };

  return (
    <div className="flex flex-col h-full min-h-screen">
      {/* Nagłówek */}
      <div className="flex justify-between items-center mb-8">
        <div>
          <Button
            variant="dark"
            icon={<ChevronLeft size={16} />}
            onClick={() => navigate('/admin/projects')}
          >
            Powrót do listy
          </Button>
          <h1 className="text-3xl font-bold text-white mt-4">
            {id ? 'Edytuj projekt' : 'Nowy projekt'}
          </h1>
        </div>
      </div>
      
      {/* Komunikat błędu */}
      {error && (
        <motion.div
          className="mb-6 p-4 bg-red-950/30 border border-red-800/50 rounded-lg text-red-200 flex items-center"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
        >
          <AlertTriangle size={18} className="mr-2 flex-shrink-0" />
          <span>{error}</span>
        </motion.div>
      )}
      
      {/* Formularz */}
      {loading ? (
        <div className="flex items-center justify-center py-12 flex-1">
          <div className="animate-spin rounded-full h-8 w-8 border-2 border-brand-primary border-t-transparent" />
        </div>
      ) : id ? (
        // Jeśli edytujemy istniejący projekt, pokaż odpowiedni formularz
        <div className="bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl p-6 flex-1">
          {renderProjectForm()}
        </div>
      ) : (
        // Jeśli dodajemy nowy projekt, najpierw pokaż selektor typu
        <div className="flex flex-col flex-1">
          <div className="mb-8">
            <h2 className="text-xl font-semibold text-white mb-4">
              Wybierz typ projektu
            </h2>
            <ProjectTypeSelector
              onSelect={setSelectedProjectType}
              selectedType={selectedProjectType}
            />
          </div>
          
          {selectedProjectType && (
            <div className="bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl p-6 flex-1">
              {renderProjectForm()}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminProjectEdit;