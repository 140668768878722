import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Komponent, który agresywnie resetuje pozycję przewijania
 * przy każdej zmianie lokalizacji (routy)
 */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Zapobiegaj domyślnemu zachowaniu przewijania przeglądarki
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }
    
    // Funkcja do przewijania
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    // Natychmiast przewiń do góry
    scrollToTop();
    
    // Użyj requestAnimationFrame, aby przewinąć po renderowaniu DOM
    requestAnimationFrame(() => {
      scrollToTop();
    });
    
    // Ustaw kilka przewinięć z opóźnieniem na wypadek, gdyby skrypt nawigacyjny
    // nadpisał nasze przewijanie
    const timeoutIds = [
      setTimeout(scrollToTop, 50),
      setTimeout(scrollToTop, 100),
      setTimeout(scrollToTop, 250),
      setTimeout(scrollToTop, 500)
    ];
    
    return () => {
      timeoutIds.forEach(clearTimeout);
      if ('scrollRestoration' in history) {
        history.scrollRestoration = 'auto';
      }
    };
  }, [pathname]);

  return null; // Ten komponent nie renderuje nic widocznego
};

export default ScrollToTop;
