import React from 'react';
import { motion } from 'framer-motion';
import { cn } from '../../lib/utils';

interface IconContainerProps {
  children: React.ReactNode;
  color: string;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
  animate?: boolean;
}

export const IconContainer: React.FC<IconContainerProps> = ({
  children,
  color,
  size = 'md',
  className,
  animate = false
}) => {
  const sizeClasses = {
    sm: 'w-10 h-10',
    md: 'w-12 h-12',
    lg: 'w-14 h-14'
  };
  
  // Funkcja do generowania przezroczystości koloru w formacie rgba
  const withOpacity = (color: string, opacity: number) => {
    if (color.startsWith('rgba')) {
      // Jeśli już jest w formacie rgba
      return color.replace(/rgba\(([^,]+),([^,]+),([^,]+),[^)]+\)/, `rgba($1,$2,$3,${opacity})`);
    } else if (color.startsWith('rgb')) {
      // Jeśli jest w formacie rgb
      return color.replace('rgb', 'rgba').replace(')', `, ${opacity})`);
    } else {
      // Zwróć oryginalny kolor, jeśli nie jest w formacie rgb/rgba
      return color;
    }
  };
  
  // Obliczenie kolorów
  const backgroundColor = withOpacity(color, 0.15);
  const hoverGradientColor = withOpacity(color, 0.3);
  const shadowColor = withOpacity(color, 0.2);
  
  const container = (
    <div
      className={cn(
        'group rounded-full flex items-center justify-center relative overflow-hidden border border-white/10',
        sizeClasses[size],
        className
      )}
      style={{
        background: `linear-gradient(135deg, ${backgroundColor}, transparent)`,
        boxShadow: `0 0 20px ${shadowColor}`
      }}
    >
      {/* Warstwa gradientu widoczna po najechaniu */}
      <div 
        className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
        style={{ 
          background: `radial-gradient(circle at center, ${hoverGradientColor} 0%, transparent 70%)`
        }}
      />
      
      {/* Ikona */}
      <div className="relative z-10 text-white group-hover:text-white transition-all duration-300">
        {children}
      </div>
    </div>
  );
  
  if (animate) {
    return (
      <motion.div
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.3 }}
      >
        {container}
      </motion.div>
    );
  }
  
  return container;
};