import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { supabase } from '../../lib/supabase/client';
import { cn } from '../../lib/utils';
import { Button } from '../../components/ui/Button';
import { Save, Loader2, AlertTriangle, X, Plus, Trash, Upload, Image as ImageIcon } from 'lucide-react';
import { RadioGroup } from '../../components/ui/RadioGroup';
import ReactPlayer from 'react-player';

// Interfejsy dla tagów
interface Tag {
  id: string;
  pl_name: string;
  en_name: string;
}

// Interfejs dla danych formularza
interface VideoProjectFormData {
  // Podstawowe informacje
  title: string;
  title_en: string;
  pl_slug: string;
  en_slug: string;
  client: string;
  videoUrl: string;
  thumbnailUrl: string;
  thumbnailAltPl: string;
  thumbnailAltEn: string;
  published: boolean;

  // Tagi
  tags: string[];

  // Treść
  descriptionPl: string;
  descriptionEn: string;
  overviewPl: string;
  overviewEn: string;

  // Opinie
  testimonials: Array<{
    companyName: string;
    authorName: string;
    contentPl: string;
    contentEn: string;
    logoUrl?: string;
    originalLanguage: string;
  }>;
}

interface VideoProjectFormProps {
  onSubmit: (data: VideoProjectFormData) => void;
  initialData?: Partial<VideoProjectFormData>;
  isLoading?: boolean;
}

const VideoProjectForm: React.FC<VideoProjectFormProps> = ({
  onSubmit,
  initialData,
  isLoading = false
}) => {
  const [activeTab, setActiveTab] = useState('basic');
  const [error, setError] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isDraft, setIsDraft] = useState(!initialData?.published);

  const { register, handleSubmit, control, setValue, formState: { errors }, watch, trigger } = useForm<VideoProjectFormData>({
    defaultValues: {
      ...initialData,
      tags: initialData?.tags || [],
      published: initialData?.published || false,
      testimonials: initialData?.testimonials || [],
      thumbnailUrl: initialData?.thumbnailUrl || '',
      title_en: initialData?.title_en || '',
      videoUrl: initialData?.videoUrl || '',
    },
    mode: 'onSubmit'
  });

  useEffect(() => {
    // Inicjalizacja testimonials z domyślną wartością originalLanguage
    const testimonials = watch('testimonials');
    if (testimonials && testimonials.length > 0) {
      const updatedTestimonials = testimonials.map(testimonial => ({
        ...testimonial,
        originalLanguage: testimonial.originalLanguage || 'pl' // Dodajemy domyślną wartość, jeśli nie jest ustawiona
      }));
      setValue('testimonials', updatedTestimonials);
    } else if (!testimonials || testimonials.length === 0) {
      // Jeśli nie ma żadnych testimonials, dodajemy pusty z domyślnymi wartościami
      // Użyj wartości client jeśli została przekazana jako nazwa firmy
      const clientName = watch('client') || '';
      setValue('testimonials', [{
        companyName: clientName,
        authorName: '',
        contentPl: '',
        contentEn: '',
        logoUrl: '',
        originalLanguage: 'pl'
      }]);
    }
  }, [setValue, watch]);

  // Własny handler do obsługi submit
  const handleFormSubmit = (data: any) => {
    // Pobierz nazwę firmy klienta z testimonials, jeśli istnieje
    const clientName = data.testimonials && data.testimonials.length > 0 
      ? data.testimonials[0].companyName 
      : '';
    
    // Łączymy dane z formularza
    const completeData = {
      ...data,
      client: clientName, // Ustaw wartość client na podstawie testimonials.companyName
    };
    
    // Przekazujemy kompletne dane do nadrzędnego handlera onSubmit
    onSubmit(completeData);
  };

  // Stan dla tagów
  const [tags, setTags] = useState<Tag[]>([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loadError, setLoadError] = useState<string | null>(null);
  const [selectedTags, setSelectedTags] = useState<string[]>(initialData?.tags || []);

  // Pobierz tagi
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Pobierz tagi
        const { data: tagsData, error: tagsError } = await supabase
          .from('tags')
          .select('*')
          .order('pl_name');
        
        if (tagsError) throw tagsError;
        
        setTags(tagsData || []);
        setLoadingData(false);
      } catch (error: any) {
        console.error('Błąd pobierania danych:', error);
        setLoadError('Wystąpił błąd podczas pobierania danych. Spróbuj ponownie.');
        setLoadingData(false);
      }
    };
    
    fetchData();
  }, []);

  // Obsługa zmiany tagów
  const handleTagChange = (tagId: string) => {
    if (selectedTags.includes(tagId)) {
      setSelectedTags(selectedTags.filter(id => id !== tagId));
      setValue('tags', selectedTags.filter(id => id !== tagId));
    } else {
      setSelectedTags([...selectedTags, tagId]);
      setValue('tags', [...selectedTags, tagId]);
    }
  };

  // Obsługa uploadowania obrazu
  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;
    
    const file = files[0];
    
    try {
      setError(null);
      setIsUploading(true);
      
      // Sprawdź rozmiar pliku (limit 5MB)
      if (file.size > 5 * 1024 * 1024) {
        throw new Error('Plik jest za duży. Maksymalny rozmiar to 5MB.');
      }
      
      // Sprawdź typ pliku
      if (!file.type.startsWith('image/')) {
        throw new Error('Nieprawidłowy format pliku. Akceptowane są tylko obrazy.');
      }
      
      // Generuj unikalną nazwę pliku
      const fileExt = file.name.split('.').pop();
      const fileName = `${Date.now()}-${Math.floor(Math.random() * 10000)}.${fileExt}`;
      const filePath = `uploads/${fileName}`;
      
      // Upload pliku do storage
      const { error: uploadError } = await supabase.storage
        .from('projects')
        .upload(filePath, file);
      
      if (uploadError) throw uploadError;
      
      // Pobierz publiczny URL
      const { data: publicData } = supabase.storage
        .from('projects')
        .getPublicUrl(filePath);
      
      if (publicData && publicData.publicUrl) {
        // Aktualizuj stan formularza
        setValue(field as any, publicData.publicUrl);
      }
    } catch (error: any) {
      console.error('Błąd podczas wgrywania pliku:', error);
      setError(`Wystąpił błąd podczas wgrywania pliku: ${error.message || error}`);
    } finally {
      setIsUploading(false);
    }
  };

  // Lista zakładek
  const tabs = [
    { id: 'basic', label: 'Podstawowe informacje' },
    { id: 'content', label: 'Treść' },
    { id: 'testimonials', label: 'Klient' }
  ];

  // Renderowanie aktywnej zakładki
  const renderActiveTab = () => {
    switch (activeTab) {
      case 'basic':
        return (
          <div className="space-y-6">
            {/* Tytuł PL */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Tytuł projektu (PL)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <input
                type="text"
                {...register('title', { required: 'Tytuł w języku polskim jest wymagany' })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                placeholder="Nazwa projektu po polsku"
              />
              {errors.title && (
                <p className="mt-2 text-sm text-red-400">{errors.title.message}</p>
              )}
            </div>

            {/* Tytuł EN */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Tytuł projektu (EN)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <input
                type="text"
                {...register('title_en', { required: 'Tytuł w języku angielskim jest wymagany' })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                placeholder="Nazwa projektu po angielsku"
              />
              {errors.title_en && (
                <p className="mt-2 text-sm text-red-400">{errors.title_en.message}</p>
              )}
            </div>

            {/* Slug PL */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Slug (URL PL)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <input
                type="text"
                {...register('pl_slug', { 
                  required: 'Polski slug jest wymagany',
                  pattern: {
                    value: /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
                    message: 'Slug może zawierać tylko małe litery, cyfry i myślniki'
                  }
                })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                placeholder="tytul-projektu-pl"
              />
              {errors.pl_slug && (
                <p className="mt-2 text-sm text-red-400">{errors.pl_slug.message}</p>
              )}
            </div>

            {/* Slug EN */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Slug (URL EN)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <input
                type="text"
                {...register('en_slug', { 
                  required: 'Angielski slug jest wymagany',
                  pattern: {
                    value: /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
                    message: 'Slug może zawierać tylko małe litery, cyfry i myślniki'
                  }
                })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                placeholder="project-title-en"
              />
              {errors.en_slug && (
                <p className="mt-2 text-sm text-red-400">{errors.en_slug.message}</p>
              )}
            </div>
            
            {/* URL Wideo */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Link do wideo
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <input
                type="text"
                {...register('videoUrl', { required: 'Link do wideo jest wymagany' })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                placeholder="https://www.youtube.com/watch?v=..."
              />
              {errors.videoUrl && (
                <p className="mt-2 text-sm text-red-400">{errors.videoUrl.message}</p>
              )}
              
              {/* Podgląd wideo */}
              {watch('videoUrl') && (
                <div className="mt-4 border border-white/10 rounded-lg overflow-hidden aspect-video">
                  <ReactPlayer
                    url={watch('videoUrl')}
                    width="100%"
                    height="100%"
                    controls={true}
                    light={true}
                  />
                </div>
              )}
            </div>

            {/* Miniatura */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Miniatura projektu
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <div className="border border-white/10 rounded-lg p-4 bg-white/5">
                <div className="flex items-center justify-center mb-4">
                  {watch('thumbnailUrl') ? (
                    <div className="relative w-full">
                      <img 
                        src={watch('thumbnailUrl')} 
                        alt="Miniatura projektu" 
                        className="w-full h-auto rounded-lg"
                      />
                      <button
                        type="button"
                        onClick={() => setValue('thumbnailUrl', '')}
                        className="absolute top-2 right-2 bg-black/60 text-white p-1 rounded-full hover:bg-black/80"
                      >
                        <X size={16} />
                      </button>
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center border border-dashed border-white/20 rounded-lg p-6 w-full">
                      <ImageIcon size={48} className="text-white/40 mb-2" />
                      <p className="text-white/60 text-sm text-center mb-4">
                        Wybierz miniaturę projektu (JPG, PNG, WebP)<br />
                        Zalecany rozmiar: 1200 x 630 px
                      </p>
                      
                      <div className="relative">
                        <Button
                          type="button"
                          variant="dark"
                          disabled={isUploading}
                          icon={isUploading ? <Loader2 size={16} className="animate-spin" /> : <Upload size={16} />}
                        >
                          {isUploading ? 'Wgrywanie...' : 'Wybierz plik'}
                        </Button>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => handleImageUpload(e, 'thumbnailUrl')}
                          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                        />
                      </div>
                    </div>
                  )}
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block mb-2 text-sm font-medium text-white/80">
                      Tekst alternatywny (PL)
                      <span className="text-brand-primary ml-1">*</span>
                    </label>
                    <input
                      type="text"
                      {...register('thumbnailAltPl', { required: 'Tekst alternatywny (PL) jest wymagany' })}
                      className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                      placeholder="Opisowy tekst alternatywny po polsku"
                    />
                    {errors.thumbnailAltPl && (
                      <p className="mt-2 text-sm text-red-400">{errors.thumbnailAltPl.message}</p>
                    )}
                  </div>
                  
                  <div>
                    <label className="block mb-2 text-sm font-medium text-white/80">
                      Tekst alternatywny (EN)
                      <span className="text-brand-primary ml-1">*</span>
                    </label>
                    <input
                      type="text"
                      {...register('thumbnailAltEn', { required: 'Tekst alternatywny (EN) jest wymagany' })}
                      className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                      placeholder="Descriptive alt text in English"
                    />
                    {errors.thumbnailAltEn && (
                      <p className="mt-2 text-sm text-red-400">{errors.thumbnailAltEn.message}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Tagi */}
            <div>
              <label className="block mb-4 text-sm font-medium text-white/80">
                Tagi projektu
              </label>
              {loadingData ? (
                <div className="flex items-center justify-center py-12">
                  <Loader2 size={24} className="animate-spin text-brand-primary" />
                </div>
              ) : loadError ? (
                <div className="p-4 bg-red-950/30 border border-red-800/50 rounded-lg text-red-200">
                  <AlertTriangle size={18} className="mb-2" />
                  <p>{loadError}</p>
                </div>
              ) : (
                <div className="flex flex-wrap gap-2">
                  {tags.map((tag) => (
                    <button
                      key={tag.id}
                      type="button"
                      onClick={() => handleTagChange(tag.id)}
                      className={cn(
                        "px-3 py-1.5 rounded-full text-sm transition-all duration-300 flex items-center gap-2",
                        selectedTags.includes(tag.id)
                          ? "bg-brand-primary/20 text-brand-primary border border-brand-primary/30"
                          : "bg-white/5 text-white/70 border border-white/10 hover:bg-white/10"
                      )}
                    >
                      {tag.pl_name}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        );
      
      case 'content':
        return (
          <div className="space-y-6">
            {/* Opis PL */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Opis projektu (PL)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <textarea
                {...register('descriptionPl', { required: 'Opis projektu (PL) jest wymagany' })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all min-h-[150px]"
                placeholder="Krótki opis projektu po polsku (maksymalnie 2-3 zdania)"
              />
              {errors.descriptionPl && (
                <p className="mt-2 text-sm text-red-400">{errors.descriptionPl.message}</p>
              )}
            </div>
            
            {/* Opis EN */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Opis projektu (EN)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <textarea
                {...register('descriptionEn', { required: 'Opis projektu (EN) jest wymagany' })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all min-h-[150px]"
                placeholder="Krótki opis projektu po angielsku (maksymalnie 2-3 zdania)"
              />
              {errors.descriptionEn && (
                <p className="mt-2 text-sm text-red-400">{errors.descriptionEn.message}</p>
              )}
            </div>
            
            {/* Przegląd PL */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Przegląd projektu (PL)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <textarea
                {...register('overviewPl', { required: 'Przegląd projektu (PL) jest wymagany' })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all min-h-[250px]"
                placeholder="Szczegółowy opis projektu po polsku"
              />
              {errors.overviewPl && (
                <p className="mt-2 text-sm text-red-400">{errors.overviewPl.message}</p>
              )}
            </div>
            
            {/* Przegląd EN */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Przegląd projektu (EN)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <textarea
                {...register('overviewEn', { required: 'Przegląd projektu (EN) jest wymagany' })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all min-h-[250px]"
                placeholder="Szczegółowy opis projektu po angielsku"
              />
              {errors.overviewEn && (
                <p className="mt-2 text-sm text-red-400">{errors.overviewEn.message}</p>
              )}
            </div>
          </div>
        );
      
      case 'testimonials':
        return (
          <div className="space-y-6">
            <Controller
              name="testimonials"
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <div>
                  <div className="flex justify-between items-center mb-4">
                    <label className="text-sm font-medium text-white/80">
                      Dane klienta i opinia
                    </label>
                  </div>
                  
                  <div className="space-y-6">
                    {field.value.slice(0, 1).map((testimonial, index) => (
                      <div 
                        key={index}
                        className="p-4 bg-white/5 border border-white/10 rounded-lg relative"
                      >
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                          {/* Nazwa firmy */}
                          <div>
                            <label className="block mb-2 text-sm font-medium text-white/80">
                              Nazwa firmy klienta
                              <span className="text-brand-primary ml-1">*</span>
                            </label>
                            <input
                              type="text"
                              value={testimonial.companyName}
                              onChange={(e) => {
                                const newTestimonials = [...field.value];
                                newTestimonials[index].companyName = e.target.value;
                                field.onChange(newTestimonials);
                              }}
                              className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                              placeholder="Nazwa firmy klienta"
                            />
                          </div>
                        </div>

                        <div className="mt-6 mb-3">
                          <label className="block mb-2 text-lg font-semibold text-white/80">
                            Dane opinii (opcjonalne)
                          </label>
                        </div>
                        
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                          {/* Autor */}
                          <div>
                            <label className="block mb-2 text-sm font-medium text-white/80">
                              Autor opinii
                            </label>
                            <input
                              type="text"
                              value={testimonial.authorName}
                              onChange={(e) => {
                                const newTestimonials = [...field.value];
                                newTestimonials[index].authorName = e.target.value;
                                field.onChange(newTestimonials);
                              }}
                              className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                              placeholder="Imię i nazwisko osoby udzielającej opinii"
                            />
                          </div>
                          
                          {/* Logo URL */}
                          <div>
                            <label className="block mb-2 text-sm font-medium text-white/80">
                              URL logo klienta
                            </label>
                            <input
                              type="text"
                              value={testimonial.logoUrl || ''}
                              onChange={(e) => {
                                const newTestimonials = [...field.value];
                                newTestimonials[index].logoUrl = e.target.value;
                                field.onChange(newTestimonials);
                              }}
                              className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                              placeholder="https://przyklad.pl/logo.png"
                            />
                          </div>
                        </div>
                        
                        <div className="grid grid-cols-1 gap-4 mb-4">
                          {/* Treść opinii PL */}
                          <div>
                            <label className="block mb-2 text-sm font-medium text-white/80">
                              Treść opinii (PL)
                            </label>
                            <textarea
                              value={testimonial.contentPl || ''}
                              onChange={(e) => {
                                const newTestimonials = [...field.value];
                                newTestimonials[index].contentPl = e.target.value;
                                field.onChange(newTestimonials);
                              }}
                              className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all min-h-[100px]"
                              placeholder="Treść opinii klienta w języku polskim"
                            />
                          </div>
                          
                          {/* Treść opinii EN */}
                          <div>
                            <label className="block mb-2 text-sm font-medium text-white/80">
                              Treść opinii (EN)
                            </label>
                            <textarea
                              value={testimonial.contentEn || ''}
                              onChange={(e) => {
                                const newTestimonials = [...field.value];
                                newTestimonials[index].contentEn = e.target.value;
                                field.onChange(newTestimonials);
                              }}
                              className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all min-h-[100px]"
                              placeholder="Treść opinii klienta w języku angielskim"
                            />
                          </div>
                        </div>
                        
                        {/* Oryginalny język opinii */}
                        <div className="mt-4">
                          <label className="block mb-2 text-sm font-medium text-white/80">
                            Oryginalny język opinii
                          </label>
                          <RadioGroup
                            name={`originalLanguage-${index}`}
                            options={[
                              { value: 'pl', label: 'Polski' },
                              { value: 'en', label: 'Angielski' }
                            ]}
                            value={testimonial.originalLanguage || 'pl'}
                            onChange={(value) => {
                              const newTestimonials = [...field.value];
                              newTestimonials[index].originalLanguage = value;
                              field.onChange(newTestimonials);
                            }}
                            direction="horizontal"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            />
          </div>
        );
        
      default:
        return null;
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="h-full flex flex-col">
      {/* Nawigacja zakładek */}
      <div className="flex gap-4 mb-6 border-b border-white/10 overflow-x-auto pb-px custom-scrollbar">
        {tabs.map(tab => (
          <button
            key={tab.id}
            type="button"
            onClick={() => setActiveTab(tab.id)}
            className={cn(
              "px-4 py-2 text-sm font-medium transition-colors relative whitespace-nowrap",
              activeTab === tab.id 
                ? "text-brand-primary" 
                : "text-white/70 hover:text-white"
            )}
          >
            {tab.label}
            {activeTab === tab.id && (
              <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-brand-primary" />
            )}
          </button>
        ))}
      </div>

      {/* Komunikat błędu */}
      {error && (
        <div className="p-4 mb-6 bg-red-950/30 border border-red-800/50 rounded-lg text-red-200 flex items-center">
          <AlertTriangle size={18} className="mr-2 flex-shrink-0" />
          <span>{error}</span>
        </div>
      )}

      {/* Zawartość aktywnej zakładki */}
      <div className="flex-1 overflow-y-auto mb-8">
        {renderActiveTab()}
      </div>
      
      {/* Przyciski akcji */}
      <div className="border-t border-white/10 py-4 flex justify-end gap-4 mt-auto">
        <Button
          type="button"
          variant="dark"
          disabled={isLoading}
          icon={isLoading ? <Loader2 className="animate-spin" /> : <Save />}
          onClick={async () => {
            setIsDraft(true);
            // Pomiń walidację i wyślij dane
            const data = watch();
            handleFormSubmit({ ...data, published: false });
          }}
        >
          {isLoading ? 'Zapisywanie...' : 'Zapisz jako szkic'}
        </Button>
        <Button
          type="button"
          variant="default"
          disabled={isLoading} 
          icon={isLoading ? <Loader2 className="animate-spin" /> : <Save />}
          onClick={async () => {
            setIsDraft(false);
            // Wykonaj pełną walidację przed publikacją
            const isValid = await trigger();
            if (isValid) {
              const data = watch();
              handleFormSubmit({ ...data, published: true });
            }
          }}
        >
          {isLoading ? 'Publikowanie...' : 'Opublikuj projekt'}
        </Button>
      </div>
    </form>
  );
};

export default VideoProjectForm; 