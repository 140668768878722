import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FileText, FolderIcon, Tag, Code, Plus, Clock, Eye, PenTool } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

const AdminDashboard: React.FC = () => {
  const { user } = useAuth();
  
  // Animacja
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };
  
  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.6, ease: [0.22, 1, 0.36, 1] }
    }
  };
  
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className="mb-8"
        variants={itemVariants}
      >
        <h1 className="text-3xl font-bold text-white mb-2">Panel Administracyjny</h1>
        <p className="text-white/70">
          Witaj, <span className="text-brand-primary">{user?.email}</span>. Zarządzaj zawartością swojej strony.
        </p>
      </motion.div>
      
      {/* Karty z linkami do różnych sekcji */}
      <motion.div 
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mb-12"
        variants={itemVariants}
      >
        {/* Projekty */}
        <Link to="/admin/projects" className="group">
          <div className="bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl p-6 h-full hover:bg-white/8 hover:border-brand-primary/30 transition-all duration-300 hover:shadow-lg hover:shadow-brand-primary/5">
            <div className="flex justify-between items-start mb-4">
              <div className="p-3 rounded-lg bg-blue-500/10 text-blue-400">
                <FileText size={24} />
              </div>
              <span className="text-xs text-white/40 group-hover:text-white/60 transition-colors">
                Zarządzaj
              </span>
            </div>
            <h3 className="text-xl font-semibold text-white mb-1 group-hover:text-brand-primary transition-colors">
              Projekty
            </h3>
            <p className="text-white/60 text-sm">
              Dodawaj i edytuj projekty wyświetlane na stronie
            </p>
          </div>
        </Link>
        
        {/* Kategorie */}
        <Link to="/admin/categories" className="group">
          <div className="bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl p-6 h-full hover:bg-white/8 hover:border-brand-primary/30 transition-all duration-300 hover:shadow-lg hover:shadow-brand-primary/5">
            <div className="flex justify-between items-start mb-4">
              <div className="p-3 rounded-lg bg-purple-500/10 text-purple-400">
                <FolderIcon size={24} />
              </div>
              <span className="text-xs text-white/40 group-hover:text-white/60 transition-colors">
                Zarządzaj
              </span>
            </div>
            <h3 className="text-xl font-semibold text-white mb-1 group-hover:text-brand-primary transition-colors">
              Kategorie
            </h3>
            <p className="text-white/60 text-sm">
              Zarządzaj kategoriami projektów
            </p>
          </div>
        </Link>
        
        {/* Tagi */}
        <Link to="/admin/tags" className="group">
          <div className="bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl p-6 h-full hover:bg-white/8 hover:border-brand-primary/30 transition-all duration-300 hover:shadow-lg hover:shadow-brand-primary/5">
            <div className="flex justify-between items-start mb-4">
              <div className="p-3 rounded-lg bg-green-500/10 text-green-400">
                <Tag size={24} />
              </div>
              <span className="text-xs text-white/40 group-hover:text-white/60 transition-colors">
                Zarządzaj
              </span>
            </div>
            <h3 className="text-xl font-semibold text-white mb-1 group-hover:text-brand-primary transition-colors">
              Tagi
            </h3>
            <p className="text-white/60 text-sm">
              Dodawaj i edytuj tagi dla projektów
            </p>
          </div>
        </Link>
        
        {/* Technologie */}
        <Link to="/admin/technologies" className="group">
          <div className="bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl p-6 h-full hover:bg-white/8 hover:border-brand-primary/30 transition-all duration-300 hover:shadow-lg hover:shadow-brand-primary/5">
            <div className="flex justify-between items-start mb-4">
              <div className="p-3 rounded-lg bg-amber-500/10 text-amber-400">
                <Code size={24} />
              </div>
              <span className="text-xs text-white/40 group-hover:text-white/60 transition-colors">
                Zarządzaj
              </span>
            </div>
            <h3 className="text-xl font-semibold text-white mb-1 group-hover:text-brand-primary transition-colors">
              Technologie
            </h3>
            <p className="text-white/60 text-sm">
              Zarządzaj technologiami używanymi w projektach
            </p>
          </div>
        </Link>
      </motion.div>
      
      {/* Szybkie akcje */}
      <motion.div 
        className="mb-12"
        variants={itemVariants}
      >
        <h2 className="text-xl font-semibold text-white mb-4">Szybkie akcje</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          <Link 
            to="/admin/projects/new" 
            className="flex items-center gap-3 p-4 bg-white/5 backdrop-blur-sm border border-white/10 rounded-lg hover:bg-brand-primary/10 hover:border-brand-primary/30 transition-colors group"
          >
            <div className="p-2 rounded-full bg-brand-primary/20 text-brand-primary">
              <Plus size={16} />
            </div>
            <span className="text-white group-hover:text-brand-primary transition-colors">Nowy projekt</span>
          </Link>
          
          <Link 
            to="/admin/projects" 
            className="flex items-center gap-3 p-4 bg-white/5 backdrop-blur-sm border border-white/10 rounded-lg hover:bg-blue-500/10 hover:border-blue-500/30 transition-colors group"
          >
            <div className="p-2 rounded-full bg-blue-500/20 text-blue-400">
              <Eye size={16} />
            </div>
            <span className="text-white group-hover:text-blue-400 transition-colors">Przeglądaj projekty</span>
          </Link>
          
          <Link 
            to="/admin/categories" 
            className="flex items-center gap-3 p-4 bg-white/5 backdrop-blur-sm border border-white/10 rounded-lg hover:bg-purple-500/10 hover:border-purple-500/30 transition-colors group"
          >
            <div className="p-2 rounded-full bg-purple-500/20 text-purple-400">
              <PenTool size={16} />
            </div>
            <span className="text-white group-hover:text-purple-400 transition-colors">Zarządzaj kategoriami</span>
          </Link>
          
          <Link 
            to="/admin/tags" 
            className="flex items-center gap-3 p-4 bg-white/5 backdrop-blur-sm border border-white/10 rounded-lg hover:bg-green-500/10 hover:border-green-500/30 transition-colors group"
          >
            <div className="p-2 rounded-full bg-green-500/20 text-green-400">
              <Tag size={16} />
            </div>
            <span className="text-white group-hover:text-green-400 transition-colors">Zarządzaj tagami</span>
          </Link>
        </div>
      </motion.div>
      
      {/* Ostatnie aktualizacje - placeholder */}
      <motion.div 
        variants={itemVariants}
      >
        <h2 className="text-xl font-semibold text-white mb-4">Ostatnie aktualizacje</h2>
        <div className="bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl p-6">
          <div className="flex items-center gap-4 mb-4">
            <div className="p-3 rounded-full bg-white/10 text-white/70">
              <Clock size={20} />
            </div>
            <div>
              <h3 className="text-white font-medium">Twoje ostatnie działania</h3>
              <p className="text-white/60 text-sm">Historia zmian będzie wyświetlana tutaj</p>
            </div>
          </div>
          
          <div className="border-t border-white/10 pt-4 mt-4">
            <div className="text-center text-white/40 text-sm py-8">
              Brak danych do wyświetlenia. Zacznij dodawać projekty!
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default AdminDashboard;