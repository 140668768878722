import React from 'react';

interface LogoProps {
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  variant?: 'full' | 'icon';
}

const Logo: React.FC<LogoProps> = ({ 
  className = '',
  size = 'md',
  variant = 'full' 
}) => {
  // Ustalenie wysokości logo w zależności od rozmiaru
  const dimensions = {
    sm: { height: 30, width: variant === 'full' ? 120 : 30 },
    md: { height: 40, width: variant === 'full' ? 160 : 40 },
    lg: { height: 50, width: variant === 'full' ? 200 : 50 },
  };

  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 803.3 620.05"
        height={dimensions[size].height}
        width={variant === 'full' ? dimensions[size].width : dimensions[size].height}
      >
        <defs>
          <style>
            {`
              .cls-1 {
                fill: #fff;
              }
              .cls-2 {
                fill: #e95a1a;
              }
            `}
          </style>
        </defs>
        <g id="Warstwa_2-2" data-name="Warstwa 2">
          <g>
            <rect className="cls-1" x="703.94" width="99.36" height="99.36"/>
            <rect className="cls-2" x="637.7" y="99.36" width="66.14" height="66.14"/>
            <g>
              <path className="cls-1" d="M417.57,454.98l-.12-66.05s3.06-.09,6.05-.09h40.35c19.93,0,38.18-5,54.79-15.01,16.6-10,29.8-23.48,39.6-40.45,9.79-16.96,14.7-35.61,14.7-55.96s-4.9-39-14.7-55.96c-9.8-16.96-23-30.44-39.6-40.45-16.61-10-34.87-15.01-54.79-15.01h-40.35c-1.67,0-6,.1-6,.1,0,0,.02,4.31.02,6v31.04c0,3.05-.26,10.55-.26,10.55,0,0-7.71.13-10.7.13h-43.34c-2.66,0-10.46-.25-10.46-.25v-113.71h111.08c31.55,0,60.6,7.97,87.17,23.91,26.57,15.95,47.65,37.48,63.26,64.61,15.6,27.14,23.41,56.81,23.41,89.03s-7.81,61.9-23.41,89.03c-15.61,27.14-36.7,48.67-63.26,64.61-26.57,15.95-55.63,23.91-87.17,23.91h-46.28Z"/>
              <path className="cls-2" d="M155.75,437.79c-27.73-16.1-49.65-38.02-65.75-65.75-16.11-27.72-24.16-58.03-24.16-90.91s8.05-63.18,24.16-90.91c16.1-27.72,38.02-49.64,65.75-65.75,27.73-16.1,58.03-24.16,90.91-24.16,7.64,0,33.91-1,41.22,0,0,18.37-.47,49.8,0,63.57,0,0-30.75-.22-41.22.69-20.84,1.81-40.35,5.23-58.28,15.69-17.93,10.46-32.13,24.66-42.59,42.59-10.46,17.93-15.69,37.36-15.69,58.28s5.23,40.35,15.69,58.28c10.46,17.93,24.66,32.13,42.59,42.59,17.93,10.46,37.36,15.69,58.28,15.69,24.24,0,45.49-6.89,63.76-20.67,18.26-13.78,31.71-32.13,40.35-55.04h-64.26c.43-11.62.11-37.9,0-56.29h106.1c7.97,0,24.91.31,24.91.31v15.13c0,33.21-7.31,63.6-21.92,91.16-14.62,27.57-34.96,49.4-61.02,65.5-26.08,16.11-55.38,24.16-87.92,24.16s-63.18-8.05-90.91-24.16Z"/>
            </g>
            <g>
              <path className="cls-1" d="M58.68,586.94c0,19.34-13.39,32.64-34.22,32.64H0v-65.29h24.46c20.83,0,34.22,13.21,34.22,32.64ZM23.44,605.82c12,0,19.07-6.88,19.07-18.88s-7.07-19.07-19.07-19.07h-7.53v37.95h7.53Z"/>
              <path className="cls-1" d="M70.03,554.3h15.9v65.29h-15.9v-65.29Z"/>
              <path className="cls-1" d="M161.17,574.94h-17.58c-2.42-4.37-6.88-6.7-12.83-6.7-10.23,0-17.02,7.25-17.02,18.6,0,12.09,6.88,19.16,18.32,19.16,7.91,0,13.39-4.19,15.81-11.53h-20.18v-11.72h34.6v14.79c-3.35,11.25-14.23,22.51-31.43,22.51-19.53,0-33.29-13.67-33.29-33.2s13.67-33.29,33.2-33.29c15.62,0,26.97,8,30.41,21.39Z"/>
              <path className="cls-1" d="M172.98,554.3h15.9v65.29h-15.9v-65.29Z"/>
              <path className="cls-1" d="M200.6,554.3h50.5v12.74h-17.3v52.55h-15.9v-52.55h-17.3v-12.74Z"/>
              <path className="cls-1" d="M294.62,608.05h-24.37l-3.91,11.53h-16.65l23.62-65.29h18.42l23.62,65.29h-16.83l-3.91-11.53ZM282.44,571.87l-8,23.9h16.09l-8.09-23.9Z"/>
              <path className="cls-1" d="M342.61,554.3v53.01h20.83v12.28h-36.74v-65.29h15.9Z"/>
              <path className="cls-2" d="M401.76,554.3h18.79l18.32,45.2,18.14-45.2h18.69v65.29h-15.9v-39.15l-14.6,39.15h-12.83l-14.69-39.25v39.25h-15.9v-65.29Z"/>
              <path className="cls-2" d="M529.91,567.04h-24.09v13.21h21.3v12.28h-21.3v14.32h24.09v12.74h-39.99v-65.29h39.99v12.74Z"/>
              <path className="cls-2" d="M601.71,586.94c0,19.34-13.39,32.64-34.22,32.64h-24.46v-65.29h24.46c20.83,0,34.22,13.21,34.22,32.64ZM566.46,605.82c12,0,19.07-6.88,19.07-18.88s-7.07-19.07-19.07-19.07h-7.53v37.95h7.53Z"/>
              <path className="cls-2" d="M611.56,554.3h15.9v65.29h-15.9v-65.29Z"/>
              <path className="cls-2" d="M681.5,608.05h-24.37l-3.91,11.53h-16.65l23.62-65.29h18.42l23.62,65.29h-16.83l-3.91-11.53ZM669.31,571.87l-8,23.9h16.09l-8.09-23.9Z"/>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Logo;