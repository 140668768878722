import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Lightbulb, Award, Users, TrendingUp, Heart } from 'lucide-react';
import SectionPreheader from '../common/SectionPreheader';
import aboutUsImage from '../../assets/image/home-about-us.webp';
import { IconContainer } from '../ui/IconContainer';

// Komponent karty wartości - zaktualizowany dla spójności z innymi kartami
const ValueCard = ({ 
  title, 
  description, 
  index 
}: { 
  title: string; 
  description: string; 
  index: number 
}) => {
  // Animacja dla karty wartości
  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.1 * i,
        duration: 0.4,
        ease: "easeOut"
      }
    })
  };

  // Ikony dla różnych wartości
  const getValueIcon = () => {
    const size = 22;
    const className = "text-brand-primary";
    
    switch (index) {
      case 0: return <Lightbulb size={size} className={className} />;
      case 1: return <Award size={size} className={className} />;
      case 2: return <Users size={size} className={className} />;
      case 3: return <TrendingUp size={size} className={className} />;
      default: return <Heart size={size} className={className} />;
    }
  };

  // Kolory dla różnych kart
  const colors = [
    'rgba(255, 90, 31, 1)',    // Brand primary
    'rgba(255, 125, 58, 1)',   // Brand tertiary
    'rgba(255, 159, 69, 1)',   // Brand accent
    'rgba(232, 72, 13, 1)'     // Brand secondary
  ];

  return (
    <motion.div
      className="group h-full p-6 rounded-xl backdrop-blur-sm bg-white/[0.05] border border-white/10 hover:bg-white/[0.08] hover:shadow-[0_8px_30px_rgba(0,0,0,0.12)] hover:-translate-y-1 transition-all duration-300"
      variants={cardVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      custom={index}
    >
      {/* Efekt tła z kropkami - pojawia się po najechaniu */}
      <div
        className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-xl overflow-hidden"
      >
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(255,255,255,0.03)_1px,transparent_1px)] bg-[length:4px_4px]" />
      </div>

      {/* Delikatny gradient na obramowaniu - enhanced glass effect */}
      <div
        className="absolute inset-0 -z-10 rounded-xl p-px bg-gradient-to-br from-transparent via-white/15 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"
      />

      <div className="flex items-start gap-4 relative z-10 h-full">
        {/* Ikona z kontenerem */}
        <IconContainer color={colors[index % colors.length]} size="sm">
          {getValueIcon()}
        </IconContainer>
        
        {/* Treść */}
        <div className="flex-1">
          <h4 className="text-lg font-medium text-white mb-2 group-hover:text-white/100 transition-colors duration-300">{title}</h4>
          <p className="text-sm text-white/70 leading-relaxed group-hover:text-white/80 transition-colors duration-300">{description}</p>
        </div>
      </div>
    </motion.div>
  );
};

// Komponent sekcji "O nas"
const AboutUs = () => {
  const { t } = useTranslation(['about']);
  
  // Pobieranie danych o wartościach z tłumaczeń
  const values = t('values.items', { returnObjects: true }) as Array<{ title: string; description: string }>;
  
  // Animacje dla sekcji
  const sectionVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3
      }
    }
  };
  
  const textBlockVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: [0.22, 1, 0.36, 1]
      }
    }
  };

  return (
    <section id="about" className="w-full relative py-24 sm:py-28 overflow-hidden">
      {/* Efekt gradientu po prawej stronie */}
      <div className="absolute right-0 top-0 bottom-0 pointer-events-none">
        <div 
          className="absolute right-0 top-1/2 transform -translate-y-1/2 w-[60vw] h-[70vh]"
          style={{
            background: `
              radial-gradient(
                ellipse at 100% 50%, 
                rgba(255, 90, 31, 0.55) 0%,
                rgba(255, 90, 31, 0.45) 10%,
                rgba(255, 125, 58, 0.3) 30%, 
                rgba(255, 159, 69, 0.15) 50%,
                rgba(255, 210, 100, 0.08) 70%,
                transparent 90%
              )
            `,
            filter: 'blur(70px)',
          }}
        />
      </div>

      <div className="container mx-auto px-6 sm:px-8 md:px-10 lg:px-12 relative z-10">
        <div className="max-w-3xl mx-auto text-center mb-16">
          {/* Preheader */}
          <SectionPreheader 
            text={t('preheader')} 
            variant="primary" 
            className="mb-8" 
          />
          
          <h2 className="text-3xl md:text-4xl font-bold mb-6 text-white">
            {t('heading')}
          </h2>
          
          <p className="text-gray-300 text-lg">
            {t('subheading')}
          </p>
        </div>
        
        <motion.div
          className="grid grid-cols-1 lg:grid-cols-2 gap-16"
          variants={sectionVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.1 }}
        >
          {/* Lewa kolumna - Zdjęcie zespołu (przeniesione z prawej) */}
          <div className="flex flex-col h-full">
            {/* Zdjęcie biura/zespołu */}
            <motion.div 
              className="relative rounded-xl overflow-hidden w-full h-full"
              variants={textBlockVariants}
            >
              <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent z-10"></div>
              <img 
                src={aboutUsImage} 
                alt="DG Digital Media Team" 
                className="w-full h-full object-contain"
              />
              <div className="absolute bottom-0 left-0 right-0 p-4 z-20">
                <h3 className="text-2xl font-semibold text-white mb-2">
                  {t('team.title')}
                </h3>
                <p className="text-white/80 text-sm">
                  {t('team.description')}
                </p>
              </div>
            </motion.div>
          </div>
          
          {/* Prawa kolumna - Wartości, historia i misja (przeniesione z lewej) */}
          <div className="space-y-12">
            {/* Wartości */}
            <motion.div variants={textBlockVariants}>
              <h3 className="text-2xl font-semibold text-white mb-4">
                {t('values.title')}
              </h3>
              
              <div className="grid grid-cols-1 gap-4">
                {values.map((value, index) => (
                  <ValueCard
                    key={index}
                    title={value.title}
                    description={value.description}
                    index={index}
                  />
                ))}
              </div>
            </motion.div>

            {/* Historia */}
            <motion.div variants={textBlockVariants}>
              <h3 className="text-2xl font-semibold text-white mb-4">
                {t('story.title')}
              </h3>
              <div className="relative">
                <div className="absolute -left-3 top-0 bottom-0 w-[2px] bg-gradient-to-b from-brand-primary via-brand-tertiary to-transparent rounded-full"></div>
                <p className="text-white/80 leading-relaxed pl-6">
                  {t('story.content')}
                </p>
              </div>
            </motion.div>

            {/* Misja */}
            <motion.div variants={textBlockVariants}>
              <h3 className="text-2xl font-semibold text-white mb-4">
                {t('mission.title')}
              </h3>
              <div className="relative">
                <div className="absolute -left-3 top-0 bottom-0 w-[2px] bg-gradient-to-b from-brand-tertiary via-brand-accent to-transparent rounded-full"></div>
                <p className="text-white/80 leading-relaxed pl-6">
                  {t('mission.content')}
                </p>
              </div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default AboutUs;