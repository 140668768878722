import React, { useState } from 'react';
import { Outlet, NavLink, useNavigate } from 'react-router-dom';
import { 
  Home, 
  FileText, 
  Tag, 
  FolderIcon, 
  Code, 
  Settings, 
  LogOut, 
  Menu,
  X,
  ChevronDown
} from 'lucide-react';
import Logo from '../../components/common/Logo';
import { useAuth } from '../../contexts/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';

const AdminLayout: React.FC = () => {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  // Jeśli nie ma zalogowanego użytkownika, przekieruj na stronę logowania
  if (!user) {
    navigate('/admin/login', { replace: true });
    return null;
  }

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/admin/login');
    } catch (error) {
      console.error('Błąd podczas wylogowywania:', error);
    }
  };

  // Style dla linków nawigacyjnych
  const linkClasses = ({ isActive }: { isActive: boolean }) => 
    isActive 
      ? "flex items-center space-x-2 px-4 py-2.5 rounded-lg bg-brand-primary/20 text-brand-primary hover:bg-brand-primary/30 transition-colors font-medium"
      : "flex items-center space-x-2 px-4 py-2.5 rounded-lg text-gray-300 hover:bg-white/5 hover:text-white transition-colors";

  // Główna zawartość dla desktop i mobile
  const navigationContent = (
    <>
      <div className="px-4 py-2 flex items-center">
        <Logo className="h-7" />
        <span className="text-lg font-semibold text-white ml-2">Panel</span>
      </div>
      
      <nav className="mt-8 px-2 space-y-2">
        <NavLink to="/admin" end className={linkClasses}>
          <Home size={18} />
          <span>Dashboard</span>
        </NavLink>
        <NavLink to="/admin/projects" className={linkClasses}>
          <FileText size={18} />
          <span>Projekty</span>
        </NavLink>
        <NavLink to="/admin/categories" className={linkClasses}>
          <FolderIcon size={18} />
          <span>Kategorie</span>
        </NavLink>
        <NavLink to="/admin/tags" className={linkClasses}>
          <Tag size={18} />
          <span>Tagi</span>
        </NavLink>
        <NavLink to="/admin/technologies" className={linkClasses}>
          <Code size={18} />
          <span>Technologie</span>
        </NavLink>
      </nav>
      
      <div className="mt-auto px-2">
        <button 
          onClick={() => setIsSettingsOpen(!isSettingsOpen)} 
          className="w-full flex items-center justify-between px-4 py-2.5 rounded-lg text-gray-300 hover:bg-white/5 hover:text-white transition-colors mb-1"
        >
          <div className="flex items-center space-x-2">
            <Settings size={18} />
            <span>Ustawienia</span>
          </div>
          <ChevronDown 
            size={16} 
            className={`transition-transform duration-300 ${isSettingsOpen ? 'rotate-180' : ''}`} 
          />
        </button>
        
        <AnimatePresence>
          {isSettingsOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.2 }}
              className="overflow-hidden"
            >
              <div className="pl-10 pr-2 pb-2 space-y-1">
                <button 
                  onClick={handleSignOut}
                  className="w-full flex items-center space-x-2 px-4 py-2 rounded-lg text-red-400 hover:bg-red-950/30 hover:text-red-300 transition-colors"
                >
                  <LogOut size={18} />
                  <span>Wyloguj</span>
                </button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );

  return (
    <div className="flex h-screen bg-surface-dark text-white">
      {/* Sidebar - wersja desktop */}
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 z-50">
        <div className="flex flex-col h-full min-h-screen bg-surface-dark border-r border-white/10 overflow-y-auto">
          {navigationContent}
        </div>
      </div>

      {/* Overlay dla wersji mobilnej */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 bg-black/70 z-40 md:hidden"
            onClick={() => setIsMobileMenuOpen(false)}
          />
        )}
      </AnimatePresence>

      {/* Sidebar - wersja mobilna */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            initial={{ x: '-100%' }}
            animate={{ x: 0 }}
            exit={{ x: '-100%' }}
            transition={{ type: 'spring', damping: 20 }}
            className="fixed inset-y-0 left-0 z-50 w-64 md:hidden"
          >
            <div className="flex flex-col h-full min-h-screen bg-surface-dark border-r border-white/10 overflow-y-auto relative">
              <button
                onClick={() => setIsMobileMenuOpen(false)}
                className="absolute top-4 right-4 p-1 rounded-full bg-white/10 text-white/80 hover:bg-white/20 transition-colors"
              >
                <X size={18} />
              </button>
              {navigationContent}
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Główna treść */}
      <div className="flex flex-col flex-1 md:pl-64 min-h-screen">
        {/* Header mobilny */}
        <div className="md:hidden sticky top-0 z-10 flex items-center px-4 py-2 bg-surface-dark border-b border-white/10">
          <button
            onClick={() => setIsMobileMenuOpen(true)}
            className="p-2 rounded-md bg-white/5 text-white/80 hover:bg-white/10 transition-colors"
          >
            <Menu size={20} />
          </button>
          <div className="ml-4">
            <Logo className="h-7" />
          </div>
        </div>

        {/* Treść strony */}
        <main className="flex-1 py-6 px-4 md:px-6 max-w-screen-2xl mx-auto w-full bg-surface-dark">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default AdminLayout; 