import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface AnimatedHeadlineProps {
  prefix: string;
  phrases: string[];
  interval?: number;
  className?: string;
}

const AnimatedHeadline: React.FC<AnimatedHeadlineProps> = ({
  prefix,
  phrases,
  interval = 3000,
  className = '',
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  
  useEffect(() => {
    if (phrases.length <= 1) return;
    
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % phrases.length);
    }, interval);
    
    return () => clearInterval(timer);
  }, [phrases, interval]);
  
  return (
    <h1 className={`text-4xl md:text-5xl lg:text-6xl font-bold leading-tight w-full ${className}`}>
      <span className="block mb-1 sm:mb-2 md:mb-3 text-text-white">{prefix}</span>
      <div className="w-full flex justify-center">
        <div className="relative h-[3rem] sm:h-[3.5rem] md:h-[4rem] lg:h-[4.5rem] w-full max-w-4xl">
          <AnimatePresence mode="wait">
            <motion.span
              key={currentIndex}
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -20, opacity: 0 }}
              transition={{ 
                type: "spring", 
                stiffness: 300, 
                damping: 25 
              }}
              className="absolute inset-0 text-center text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-text-white"
              style={{
                textShadow: '0 2px 4px rgba(0, 0, 0, 0.3)'
              }}
            >
              {phrases[currentIndex]}
            </motion.span>
          </AnimatePresence>
        </div>
      </div>
    </h1>
  );
};

export default AnimatedHeadline;