import React from 'react';
import { motion } from 'framer-motion';

const UiWireframes: React.FC = () => {
  // Każdy z wireframe'ów ma różne parametry animacji i różną pozycję
  const wireframes = [
    // Komponent przypominający okno aplikacji
    {
      width: '160px',
      height: '130px',
      left: '10%',
      top: '25%',
      rotate: -10,
      animationDuration: 12,
      animationDelay: 0,
      yOffset: 12,
    },
    // Komponent przypominający listę lub sidebar
    {
      width: '90px',
      height: '180px',
      right: '15%',
      top: '15%',
      rotate: 8,
      animationDuration: 20,
      animationDelay: 4,
      yOffset: 15,
    },
    // Mały komponent przypominający button lub element formularza
    {
      width: '70px',
      height: '40px',
      left: '25%',
      bottom: '25%',
      rotate: -5,
      animationDuration: 8,
      animationDelay: 2,
      yOffset: 6,
    },
    // Komponent przypominający kartę z sekcjami
    {
      width: '190px',
      height: '110px',
      right: '10%',
      bottom: '22%',
      rotate: 12,
      animationDuration: 15,
      animationDelay: 3,
      yOffset: 10,
    },
    // Komponent przypominający panel boczny z elementami
    {
      width: '70px',
      height: '230px',
      left: '15%',
      top: '40%',
      rotate: 6,
      animationDuration: 18,
      animationDelay: 1,
      yOffset: 14,
    },
    // Komponent przypominający panel z nagłówkiem
    {
      width: '150px',
      height: '90px',
      right: '25%',
      top: '45%',
      rotate: -8,
      animationDuration: 14,
      animationDelay: 5,
      yOffset: 8,
    },
  ];

  return (
    <>
      {wireframes.map((wireframe, index) => (
        <motion.div
          key={index}
          className="absolute"
          style={{
            left: wireframe.left,
            right: wireframe.right,
            top: wireframe.top,
            bottom: wireframe.bottom,
            zIndex: 1,
            perspective: '800px',
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.6 }}
          transition={{ duration: 1.5, delay: index * 0.2 }}
        >
          <motion.div
            className="relative backdrop-blur-[1px] bg-white/[0.04] border border-white/[0.08] rounded-lg shadow-md overflow-hidden"
            style={{
              width: wireframe.width,
              height: wireframe.height,
              transformStyle: 'preserve-3d',
            }}
            animate={{
              y: [0, wireframe.yOffset, 0],
              rotateX: [0, 1, 0],
              rotateY: [0, 1, 0],
            }}
            transition={{
              duration: wireframe.animationDuration,
              repeat: Infinity,
              repeatType: 'reverse',
              ease: 'easeInOut',
              delay: wireframe.animationDelay,
            }}
            whileInView={{ opacity: 1 }}
          >
            {/* Wewnętrzne elementy wireframe'a */}
            <div className="absolute top-0 left-0 right-0 h-[20%] border-b border-white/[0.05]">
              {/* Nagłówek lub pasek tytułu */}
              <div className="absolute left-3 top-1/2 -translate-y-1/2 w-[20%] h-2 bg-white/[0.1] rounded-full"></div>
            </div>
            
            {/* Elementy wewnątrz różnych rodzajów wireframe'ów */}
            {index === 0 && (
              // Okno aplikacji z siatką
              <>
                <div className="absolute top-[30%] left-[10%] right-[10%] bottom-[10%] grid grid-cols-2 gap-2">
                  <div className="bg-white/[0.05] rounded"></div>
                  <div className="bg-white/[0.05] rounded"></div>
                  <div className="bg-white/[0.05] rounded"></div>
                  <div className="bg-white/[0.05] rounded"></div>
                </div>
              </>
            )}
            
            {index === 1 && (
              // Lista lub sidebar
              <>
                <div className="absolute top-[30%] left-[15%] right-[15%] bottom-[15%] flex flex-col space-y-3">
                  <div className="h-3 bg-white/[0.05] rounded-full"></div>
                  <div className="h-3 bg-white/[0.05] rounded-full"></div>
                  <div className="h-3 bg-white/[0.05] rounded-full"></div>
                  <div className="h-3 bg-white/[0.05] rounded-full"></div>
                  <div className="h-3 bg-white/[0.05] rounded-full"></div>
                </div>
              </>
            )}
            
            {index === 2 && (
              // Button
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[70%] h-[50%] bg-white/[0.06] rounded-md"></div>
            )}
            
            {index === 3 && (
              // Karta z sekcjami
              <>
                <div className="absolute top-[30%] left-[10%] right-[10%] bottom-[10%] flex flex-col space-y-2">
                  <div className="h-6 bg-white/[0.06] rounded-sm"></div>
                  <div className="flex-1 bg-white/[0.04] rounded-sm"></div>
                  <div className="h-4 bg-white/[0.05] rounded-sm"></div>
                </div>
              </>
            )}
            
            {index === 4 && (
              // Panel boczny z elementami
              <>
                <div className="absolute top-[25%] left-[15%] right-[15%] bottom-[10%] flex flex-col space-y-4">
                  <div className="h-4 bg-white/[0.05] rounded-sm"></div>
                  <div className="h-4 bg-white/[0.05] rounded-sm"></div>
                  <div className="h-4 bg-white/[0.05] rounded-sm"></div>
                  <div className="h-16 bg-white/[0.04] rounded-sm"></div>
                  <div className="h-4 bg-white/[0.05] rounded-sm"></div>
                </div>
              </>
            )}
            
            {index === 5 && (
              // Panel z nagłówkiem
              <>
                <div className="absolute top-[30%] left-[10%] right-[10%] bottom-[15%] flex flex-col space-y-3">
                  <div className="h-3 w-[60%] bg-white/[0.07] rounded-full"></div>
                  <div className="h-8 bg-white/[0.04] rounded-sm"></div>
                  <div className="h-3 w-[40%] bg-white/[0.05] rounded-full"></div>
                </div>
              </>
            )}
          </motion.div>
        </motion.div>
      ))}
    </>
  );
};

export default UiWireframes;