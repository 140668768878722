import { useEffect } from 'react';

/**
 * Hook do blokowania scrollowania strony
 * @param isLocked - czy scrollowanie ma być zablokowane
 */
export const useScrollLock = (isLocked: boolean): void => {
  useEffect(() => {
    // Oryginalna implementacja mogła nie w pełni działać na wszystkich przeglądarkach
    // Zoptymalizowana wersja, która działa niezawodnie na większości przeglądarek
    
    if (isLocked) {
      // Zapisz obecną pozycję scrollowania
      const scrollY = window.scrollY;
      
      // Dodaj style do body, które zablokują przewijanie
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}px`;
      document.body.style.left = '0';
      document.body.style.right = '0';
      document.body.style.bottom = '0';
      document.body.style.overflow = 'hidden';
      document.body.style.width = '100%';
      
      // Zapisz pozycję scrolla jako atrybut dla przywrócenia później
      document.body.setAttribute('data-scroll-position', scrollY.toString());
    } else {
      // Odblokuj przewijanie i przywróć pozycję scrolla
      const scrollY = parseInt(document.body.getAttribute('data-scroll-position') || '0', 10);
      
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.left = '';
      document.body.style.right = '';
      document.body.style.bottom = '';
      document.body.style.overflow = '';
      document.body.style.width = '';
      
      // Przywróć pozycję scrolla
      window.scrollTo(0, scrollY);
      
      // Usuń atrybut
      document.body.removeAttribute('data-scroll-position');
    }
  }, [isLocked]);
};