import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import plików tłumaczeń dla języka polskiego
import plCommon from './locales/pl/common.json';
import plNavbar from './locales/pl/navbar.json';
import plHero from './locales/pl/hero.json';
import plLanguage from './locales/pl/language.json';
import plMeta from './locales/pl/meta.json';
import plServices from './locales/pl/services.json';
import plWhyUs from './locales/pl/whyus.json';
import plAbout from './locales/pl/about.json';
import plPortfolio from './locales/pl/portfolio.json';
import plTestimonials from './locales/pl/testimonials.json';
import plContact from './locales/pl/contact.json';
import plFooter from './locales/pl/footer.json';
import plPrivacy from './locales/pl/privacy.json';
import plCookies from './locales/pl/cookies.json';
import plProjects from './locales/pl/projects.json';
import plNotFound from './locales/pl/notfound.json';

// Import plików tłumaczeń dla języka angielskiego
import enCommon from './locales/en/common.json';
import enNavbar from './locales/en/navbar.json';
import enHero from './locales/en/hero.json';
import enLanguage from './locales/en/language.json';
import enMeta from './locales/en/meta.json';
import enServices from './locales/en/services.json';
import enWhyUs from './locales/en/whyus.json';
import enAbout from './locales/en/about.json';
import enPortfolio from './locales/en/portfolio.json';
import enTestimonials from './locales/en/testimonials.json';
import enContact from './locales/en/contact.json';
import enFooter from './locales/en/footer.json';
import enPrivacy from './locales/en/privacy.json';
import enCookies from './locales/en/cookies.json';
import enProjects from './locales/en/projects.json';
import enNotFound from './locales/en/notfound.json';

// Konfiguracja zasobów
const resources = {
  pl: {
    common: plCommon,
    navbar: plNavbar,
    hero: plHero,
    language: plLanguage,
    meta: plMeta,
    services: plServices,
    whyus: plWhyUs,
    about: plAbout,
    portfolio: plPortfolio,
    testimonials: plTestimonials,
    contact: plContact,
    footer: plFooter,
    privacy: plPrivacy,
    cookies: plCookies,
    projects: plProjects,
    notfound: plNotFound
  },
  en: {
    common: enCommon,
    navbar: enNavbar,
    hero: enHero,
    language: enLanguage,
    meta: enMeta,
    services: enServices,
    whyus: enWhyUs,
    about: enAbout,
    portfolio: enPortfolio,
    testimonials: enTestimonials,
    contact: enContact,
    footer: enFooter,
    privacy: enPrivacy,
    cookies: enCookies,
    projects: enProjects,
    notfound: enNotFound
  }
};

// Konfiguracja detektora języka
const languageDetector = new LanguageDetector();
languageDetector.addDetector({
  name: 'customPathDetector',
  lookup(options) {
    // Sprawdź, czy jesteśmy w przeglądarce
    if (typeof window !== 'undefined') {
      const path = window.location.pathname;
      const langMatch = path.match(/^\/(pl|en)/);
      if (langMatch) {
        return langMatch[1];
      }
    }
    return null;
  },
  cacheUserLanguage(lng) {
    // Nie cachujemy języka z URL
  }
});

i18n
  // Wykrywanie języka przeglądarki
  .use(languageDetector)
  // Integracja z React
  .use(initReactI18next)
  // Inicjalizacja i18next
  .init({
    resources,
    defaultNS: 'common',
    fallbackNS: ['common'],
    fallbackLng: 'pl', // Domyślny język (polski)
    supportedLngs: ['pl', 'en'],
    debug: false, // Wyłączone logi debugowania
    
    detection: {
      order: ['customPathDetector', 'path', 'navigator'],
      lookupFromPathIndex: 0,
      checkWhitelist: true
    },
    
    interpolation: {
      escapeValue: false, // Nie escapujemy wartości HTML
    }
  });

export default i18n;