import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { ArrowLeft, Home, Search } from 'lucide-react';
import Navbar from '../components/layout/Navbar';
import Footer from '../components/layout/Footer';
import { Button } from '../components/ui/Button';
import { usePageTitle } from '../hooks/usePageTitle';
import OpenGraph from '../components/seo/OpenGraph';

const NotFound: React.FC = () => {
  const { t, i18n } = useTranslation(['notfound', 'meta']);
  const location = useLocation();
  const currentLanguage = i18n.language;
  
  // Set page title
  usePageTitle('pageTitle.notFound');
  
  // Scroll to top on load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.2,
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.5,
        ease: [0.22, 1, 0.36, 1]
      }
    }
  };

  // 404 elements with brand-primary gradient effect
  const Four = () => (
    <div className="text-9xl sm:text-[12rem] font-bold text-transparent bg-clip-text bg-gradient-to-r from-brand-primary via-brand-tertiary to-brand-accent">4</div>
  );
  
  const Zero = () => (
    <div className="relative text-9xl sm:text-[12rem] font-bold">
      <div className="relative z-10 text-transparent bg-clip-text bg-gradient-to-r from-brand-accent via-brand-tertiary to-brand-primary">0</div>
      {/* Animated glow effect */}
      <div className="absolute -inset-1 rounded-full blur-xl bg-gradient-to-r from-brand-primary/20 via-brand-tertiary/20 to-brand-accent/20 animate-pulse-slow opacity-70"></div>
    </div>
  );

  return (
    <>
      <OpenGraph 
        title={t('title')} 
        description={t('description')} 
        type="website"
      />
      <Navbar />
      <main className="flex-1 pt-24 pb-16">
        <div className="container px-4 mx-auto">
          <motion.div 
            className="max-w-3xl mx-auto text-center"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            {/* 404 display */}
            <motion.div 
              className="flex justify-center items-center gap-4 mb-8"
              variants={itemVariants}
            >
              <Four />
              <Zero />
              <Four />
            </motion.div>
            
            {/* Error message */}
            <motion.h1 
              className="text-3xl md:text-4xl font-bold text-white mb-6"
              variants={itemVariants}
            >
              {t('title')}
            </motion.h1>
            
            <motion.p 
              className="text-lg text-white/80 mb-8"
              variants={itemVariants}
            >
              {t('description')}
            </motion.p>
            
            {/* URL that was not found */}
            <motion.div 
              className="mb-10 p-4 backdrop-blur-sm bg-white/[0.05] border border-white/10 rounded-lg inline-flex items-center gap-2 text-white/70"
              variants={itemVariants}
            >
              <Search size={18} className="text-brand-primary" />
              <span>{t('searchFor')}:</span>
              <code className="px-2 py-1 bg-white/10 rounded text-brand-tertiary">{location.pathname}</code>
            </motion.div>
            
            {/* Back buttons */}
            <motion.div 
              className="flex flex-col sm:flex-row gap-4 justify-center"
              variants={itemVariants}
            >
              <Button 
                variant="default" 
                icon={<Home size={18} />}
                onClick={() => window.location.href = '/'}
              >
                {t('backHome')}
              </Button>
              
              <Button 
                variant="dark" 
                icon={<ArrowLeft size={18} />}
                onClick={() => window.history.back()}
              >
                {currentLanguage === 'pl' ? 'Wróć do poprzedniej strony' : 'Go back to previous page'}
              </Button>
            </motion.div>
          </motion.div>
          
          {/* Decorative elements - moving wireframes */}
          <div className="absolute inset-0 -z-10 opacity-30 overflow-hidden pointer-events-none">
            {/* Decorative element 1 */}
            <motion.div 
              className="absolute top-1/4 -left-20 w-40 h-40 backdrop-blur-[1px] bg-white/[0.04] border border-white/[0.08] rounded-lg"
              animate={{
                x: [0, 50, 0],
                y: [0, 30, 0],
                rotate: [0, 10, 0],
              }}
              transition={{
                duration: 15,
                repeat: Infinity,
                repeatType: 'reverse',
                ease: 'easeInOut',
              }}
            />
            
            {/* Decorative element 2 */}
            <motion.div 
              className="absolute bottom-1/3 -right-20 w-60 h-40 backdrop-blur-[1px] bg-white/[0.04] border border-white/[0.08] rounded-lg"
              animate={{
                x: [0, -80, 0],
                y: [0, -40, 0],
                rotate: [0, -15, 0],
              }}
              transition={{
                duration: 18,
                repeat: Infinity,
                repeatType: 'reverse',
                ease: 'easeInOut',
                delay: 2,
              }}
            />
            
            {/* Decorative dot pattern */}
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(255,255,255,0.03)_1px,transparent_1px)] bg-[length:30px_30px]" />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default NotFound;