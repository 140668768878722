import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Hook do dynamicznej zmiany tytułu strony w zależności od języka
 * @param titleKey - Klucz tłumaczenia dla tytułu strony
 */
export const usePageTitle = (titleKey: string): void => {
  const { t, i18n } = useTranslation(['meta']);
  
  useEffect(() => {
    // Aktualizuje tytuł strony przy zmianie języka
    const updateTitle = () => {
      document.title = t(titleKey);
    };
    
    // Aktualizacja tytułu przy pierwszym renderowaniu
    updateTitle();
    
    // Nasłuchiwanie na zmiany języka
    i18n.on('languageChanged', updateTitle);
    
    // Sprzątanie przy odmontowaniu komponentu
    return () => {
      i18n.off('languageChanged', updateTitle);
    };
  }, [titleKey, t, i18n]);
};