import React, { useEffect } from 'react';
import CookieBanner from './CookieBanner';
import CookieModal from './CookieModal';
import { useCookieConsent } from '../../contexts/CookieConsentContext';

// Main component that manages cookie consent UI
const CookieConsent: React.FC = () => {
  const { 
    showConsentBanner,
    consentModalOpen,
    setConsentModalOpen
  } = useCookieConsent();
  
  // Close modal when clicking escape
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && consentModalOpen) {
        setConsentModalOpen(false);
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [consentModalOpen, setConsentModalOpen]);
  
  // Handle modal close
  const handleCloseModal = () => {
    setConsentModalOpen(false);
  };
  
  return (
    <>
      {/* Cookie banner */}
      <CookieBanner />
      
      {/* Cookie settings modal */}
      <CookieModal 
        isOpen={consentModalOpen} 
        onClose={handleCloseModal} 
      />
    </>
  );
};

export default CookieConsent;