import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Info, ShieldCheck, PieChart, Target } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Button } from '../ui/Button';
import { useCookieConsent, ConsentOptions } from '../../contexts/CookieConsentContext';
import useScrollToTopNavigation from '../../hooks/useScrollToTopNavigation';

interface CookieModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CookieModal: React.FC<CookieModalProps> = ({ isOpen, onClose }) => {
  const { t, i18n } = useTranslation(['cookies']);
  const { 
    consentOptions, 
    updateConsentOptions, 
    acceptAllCookies,
    acceptNecessaryCookies
  } = useCookieConsent();
  
  // Używamy naszego customowego hooka zamiast standardowego komponentu Link
  const navigateWithScrollReset = useScrollToTopNavigation();
  
  // State to track form values
  const [formValues, setFormValues] = React.useState<ConsentOptions>({...consentOptions});
  
  // Update form values when consent options change
  React.useEffect(() => {
    setFormValues({...consentOptions});
  }, [consentOptions]);
  
  // Handle checkbox changes
  const handleCheckboxChange = (category: keyof ConsentOptions) => {
    if (category === 'necessary') return; // Cannot toggle necessary cookies
    
    setFormValues({
      ...formValues,
      [category]: !formValues[category]
    });
  };
  
  // Handle save preferences
  const handleSavePreferences = () => {
    updateConsentOptions(formValues);
    onClose();
  };

  // Helper function to render the privacy note with link
  const renderPrivacyNote = () => {
    const privacyNote = t('privacyNote');
    const shortLang = i18n.language.slice(0, 2);
    const privacyPolicyLink = shortLang === 'pl' ? '/pl/polityka-prywatnosci' : '/en/privacy-policy';
    
    if (shortLang === 'pl') {
      // For Polish language - split by "Polityce prywatności"
      return privacyNote.split('Polityce prywatności').map((part, i, arr) => {
        if (i === 0 && arr.length > 1) {
          return (
            <React.Fragment key={i}>
              {part}<span 
                onClick={() => navigateWithScrollReset(privacyPolicyLink)} 
                className="text-brand-primary hover:underline cursor-pointer"
              >Polityce prywatności</span>
            </React.Fragment>
          );
        }
        return <React.Fragment key={i}>{part}</React.Fragment>;
      });
    } else {
      // For English language - split by "Privacy Policy"
      return privacyNote.split('Privacy Policy').map((part, i, arr) => {
        if (i === 0 && arr.length > 1) {
          return (
            <React.Fragment key={i}>
              {part}<span 
                onClick={() => navigateWithScrollReset(privacyPolicyLink)} 
                className="text-brand-primary hover:underline cursor-pointer"
              >Privacy Policy</span>
            </React.Fragment>
          );
        }
        return <React.Fragment key={i}>{part}</React.Fragment>;
      });
    }
  };

  // Cookie categories with their details
  const cookieCategories = [
    {
      id: 'necessary' as keyof ConsentOptions,
      icon: <ShieldCheck size={20} className="text-blue-400" />,
      title: t('categories.necessary.title'),
      description: t('categories.necessary.description'),
      disabled: true, // Necessary cookies cannot be disabled
      checked: true // Always checked
    },
    {
      id: 'analytics' as keyof ConsentOptions,
      icon: <PieChart size={20} className="text-purple-400" />,
      title: t('categories.analytics.title'),
      description: t('categories.analytics.description'),
      disabled: false,
      checked: formValues.analytics
    },
    {
      id: 'marketing' as keyof ConsentOptions,
      icon: <Target size={20} className="text-red-400" />,
      title: t('categories.marketing.title'),
      description: t('categories.marketing.description'),
      disabled: false,
      checked: formValues.marketing
    }
  ];

  // Modal animation variants
  const modalVariants = {
    hidden: { 
      opacity: 0,
      y: 50,
      scale: 0.95
    },
    visible: { 
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        damping: 25,
        stiffness: 300
      }
    },
    exit: { 
      opacity: 0,
      y: 50,
      scale: 0.95,
      transition: { 
        duration: 0.2,
        ease: "easeInOut"
      }
    }
  };
  
  // Overlay animation variants
  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { duration: 0.3 }
    },
    exit: { 
      opacity: 0,
      transition: { duration: 0.3 }
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 z-[100] flex items-center justify-center p-4">
          {/* Backdrop */}
          <motion.div 
            className="fixed inset-0 bg-black/70 backdrop-blur-sm"
            variants={overlayVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            onClick={onClose}
          />
          
          {/* Modal */}
          <motion.div
            className="relative bg-surface-light/90 backdrop-blur-xl border border-white/10 rounded-xl shadow-2xl max-w-lg w-full max-h-[90vh] overflow-hidden flex flex-col z-[101]"
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            {/* Header */}
            <div className="p-4 border-b border-white/10 flex items-center justify-between sticky top-0 bg-surface-light/90 backdrop-blur-xl z-10">
              <h2 className="text-lg font-semibold text-white flex items-center">
                <Info size={18} className="mr-2 text-brand-primary" />
                {t('title')}
              </h2>
              <button 
                onClick={onClose}
                className="p-1.5 rounded-full hover:bg-white/10 transition-colors"
              >
                <X size={20} className="text-white/70" />
              </button>
            </div>
            
            {/* Content */}
            <div className="p-4 overflow-y-auto flex-1 modal-scrollbar">
              {/* Introduction */}
              <p className="text-white/80 mb-6">
                {t('description')}
              </p>
              
              {/* Cookie categories */}
              <div className="space-y-4">
                {cookieCategories.map((category) => (
                  <div 
                    key={category.id} 
                    className="p-4 rounded-lg border border-white/10 bg-white/5 hover:bg-white/8 transition-colors"
                  >
                    <div className="flex items-start gap-3">
                      <div className="mt-0.5">{category.icon}</div>
                      <div className="flex-1">
                        <div className="flex items-center justify-between mb-1">
                          <h3 className="font-medium text-white">{category.title}</h3>
                          <label className="relative inline-flex items-center cursor-pointer">
                            <input
                              type="checkbox"
                              className="sr-only peer"
                              checked={category.checked}
                              disabled={category.disabled}
                              onChange={() => handleCheckboxChange(category.id)}
                            />
                            <div className={`relative w-11 h-6 bg-white/10 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all
                              ${category.disabled ? 'opacity-50 cursor-not-allowed' : 'peer-checked:bg-brand-primary hover:bg-white/20'}`}></div>
                          </label>
                        </div>
                        <p className="text-sm text-white/70">{category.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            
            {/* Footer with actions */}
            <div className="p-4 border-t border-white/10 sticky bottom-0 bg-surface-light/90 backdrop-blur-xl z-10">
              <div className="flex flex-col sm:flex-row justify-between gap-3">
                <div className="flex flex-col sm:flex-row gap-2">
                  <Button 
                    variant="default" 
                    size="sm"
                    onClick={() => {
                      acceptAllCookies();
                      onClose();
                    }}
                  >
                    {t('acceptAll')}
                  </Button>
                  <Button 
                    variant="dark" 
                    size="sm"
                    onClick={() => {
                      acceptNecessaryCookies();
                      onClose();
                    }}
                  >
                    {t('rejectAll')}
                  </Button>
                </div>
                
                <Button 
                  variant="navbar" 
                  size="sm"
                  onClick={handleSavePreferences}
                >
                  {t('savePreferences')}
                </Button>
              </div>
              
              <div className="mt-3 text-xs text-white/50 text-center sm:text-right">
                {renderPrivacyNote()}
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default CookieModal;