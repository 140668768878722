import { useNavigate } from 'react-router-dom';

/**
 * Hook zapewniający nawigację z wymuszonym przewinięciem do góry
 * Rozwiązuje problem z utrwaloną pozycją przewijania podczas nawigacji
 */
const useScrollToTopNavigation = () => {
  const navigate = useNavigate();

  const navigateWithScrollReset = (path: string) => {
    // Wyłącz automatyczne przywracanie pozycji przewijania
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }

    // Zresetuj pozycję przewijania przed nawigacją
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    // Wykonaj nawigację
    navigate(path);
    
    // Dodatkowe zabezpieczenie, aby przewinąć ponownie po nawigacji
    setTimeout(() => {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }, 0);
  };

  return navigateWithScrollReset;
};

export default useScrollToTopNavigation;
