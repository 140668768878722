import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { cn } from '../../lib/utils';
import { Globe } from 'lucide-react';

// Mapowanie slugów między językami
const slugMap = {
  pl: {
    'privacy-policy': 'polityka-prywatnosci',
    'project': 'projekt'
  },
  en: {
    'polityka-prywatnosci': 'privacy-policy',
    'projekt': 'project'
  }
};

interface LanguageSwitcherProps {
  className?: string;
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({ className }) => {
  const { i18n, t } = useTranslation(['language']);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  
  const toggleDropdown = () => setIsOpen(!isOpen);
  const closeDropdown = () => setIsOpen(false);
  
  const switchLanguage = (lng: string) => {
    const currentPath = location.pathname;
    
    // Nie zmieniamy ścieżek dla panelu admina
    if (currentPath.startsWith('/admin')) {
      i18n.changeLanguage(lng);
      closeDropdown();
      return;
    }
    
    // Usuń obecny prefix językowy i rozbij ścieżkę na segmenty
    const pathWithoutPrefix = currentPath.replace(/^\/(pl|en)/, '');
    const pathSegments = pathWithoutPrefix.split('/').filter(Boolean);
    
    // Przetłumacz slugi na nowy język
    const translatedSegments = pathSegments.map(segment => {
      // Sprawdź czy segment istnieje w mapie slugów dla docelowego języka
      if (slugMap[lng as keyof typeof slugMap]?.[segment]) {
        return slugMap[lng as keyof typeof slugMap][segment];
      }
      return segment;
    });
    
    // Złącz segmenty z powrotem w ścieżkę
    let newPath = translatedSegments.length > 0 ? `/${translatedSegments.join('/')}` : '/';
    
    // Dodaj nowy prefix językowy
    const targetPath = newPath === '/' ? `/${lng}` : `/${lng}${newPath}`;
    
    // Zmień język i przekieruj
    i18n.changeLanguage(lng);
    navigate(targetPath);
    closeDropdown();
  };
  
  const currentLanguage = i18n.language;
  
  return (
    <div className={cn("relative", className)}>
      <button 
        onClick={toggleDropdown}
        aria-haspopup="true"
        aria-expanded={isOpen}
        className="flex items-center justify-center gap-2 px-2 py-1 rounded-full border border-surface-border hover:border-brand-primary/70 transition-all duration-300 shadow-sm hover:shadow-md group"
      >
        <Globe 
          size={16} 
          className="text-text-white group-hover:text-brand-primary transition-colors duration-300" 
        />
        <div className="w-6 h-6 rounded-full overflow-hidden border border-surface-border">
          <img 
            src={currentLanguage === 'pl' 
              ? "https://flagcdn.com/pl.svg" 
              : "https://flagcdn.com/gb.svg"
            } 
            alt={currentLanguage === 'pl' ? "Flaga Polski" : "Flaga Wielkiej Brytanii"}
            className="w-full h-full object-cover"
          />
        </div>
      </button>
      
      {isOpen && (
        <>
          {/* Overlay to capture clicks outside the dropdown */}
          <div 
            className="fixed inset-0 z-40" 
            onClick={closeDropdown}
            aria-hidden="true"
          />
          
          <div className="absolute right-0 mt-2 z-50 rounded-md shadow-lg bg-surface-light/80 backdrop-blur-md border border-surface-border/30 overflow-hidden">
            <div className="p-2 flex flex-col gap-2">
              <button
                onClick={() => switchLanguage('pl')}
                className={cn(
                  "flex items-center gap-2 px-3 py-2 rounded-md transition-all duration-300 w-full",
                  currentLanguage === 'pl' 
                    ? "bg-brand-primary/10 text-brand-primary" 
                    : "hover:bg-surface-border/50 text-text-white hover:text-brand-primary"
                )}
                aria-label={t('pl')}
                title={t('pl')}
              >
                <div className="w-6 h-6 rounded-full overflow-hidden border border-surface-border flex-shrink-0">
                  <img 
                    src="https://flagcdn.com/pl.svg" 
                    alt="Flaga Polski"
                    className="w-full h-full object-cover"
                  />
                </div>
                <span className="text-sm">{t('pl')}</span>
              </button>
              
              <button
                onClick={() => switchLanguage('en')}
                className={cn(
                  "flex items-center gap-2 px-3 py-2 rounded-md transition-all duration-300 w-full",
                  currentLanguage === 'en' 
                    ? "bg-brand-primary/10 text-brand-primary" 
                    : "hover:bg-surface-border/50 text-text-white hover:text-brand-primary"
                )}
                aria-label={t('en')}
                title={t('en')}
              >
                <div className="w-6 h-6 rounded-full overflow-hidden border border-surface-border flex-shrink-0">
                  <img 
                    src="https://flagcdn.com/gb.svg" 
                    alt="Flaga Wielkiej Brytanii"
                    className="w-full h-full object-cover"
                  />
                </div>
                <span className="text-sm">{t('en')}</span>
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LanguageSwitcher;