import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Button } from '../ui/Button';
import useScrollToTopNavigation from '../../hooks/useScrollToTopNavigation';
import { useCookieConsent } from '../../contexts/CookieConsentContext';

const CookieBanner: React.FC = () => {
  const { t, i18n } = useTranslation(['cookies', 'common']);
  const { 
    showConsentBanner,
    setShowConsentBanner,
    acceptAllCookies,
    acceptNecessaryCookies,
    openCookieSettings
  } = useCookieConsent();
  
  // Używamy naszego customowego hooka zamiast standardowego komponentu Link
  const navigateWithScrollReset = useScrollToTopNavigation();
  
  // Don't render anything if the banner shouldn't be shown
  if (!showConsentBanner) return null;
  
  const shortLang = i18n.language.slice(0, 2);
  
  // Get the correct privacy policy link based on current language
  const privacyPolicyLink = shortLang === 'pl' ? '/pl/polityka-prywatnosci' : '/en/privacy-policy';
  
  // Animation variants
  const bannerVariants = {
    hidden: { 
      opacity: 0, 
      y: 100,
      scale: 0.95
    },
    visible: { 
      opacity: 1, 
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        damping: 25,
        stiffness: 300,
        delay: 0.5
      }
    }
  };

  return (
    <motion.div
      className="fixed bottom-0 left-0 right-0 z-50 p-4 sm:p-6"
      variants={bannerVariants}
      initial="hidden"
      animate="visible"
    >
      <div className="max-w-4xl mx-auto bg-surface-light/90 backdrop-blur-xl border border-white/10 rounded-xl shadow-2xl overflow-hidden">
        <div className="p-4 sm:p-6">
          <h2 className="text-xl font-semibold text-white mb-2">
            {t('banner.title')}
          </h2>
          
          <p className="text-white/80 mb-4 text-sm sm:text-base">
            {t('banner.description')} {' '}
            <span 
              onClick={() => navigateWithScrollReset(privacyPolicyLink)}
              className="text-brand-primary hover:underline cursor-pointer"
            >
              {t('banner.privacyLink')}
            </span>
          </p>
          
          <div className="flex flex-col sm:flex-row gap-3 sm:items-center justify-between">
            <div className="flex flex-col sm:flex-row gap-2">
              <Button 
                variant="default" 
                size="sm"
                onClick={acceptAllCookies}
              >
                {t('acceptAll')}
              </Button>
              <Button 
                variant="dark" 
                size="sm"
                onClick={acceptNecessaryCookies}
              >
                {t('acceptNecessary')}
              </Button>
            </div>
            
            <Button 
              variant="navbar" 
              size="sm"
              onClick={openCookieSettings}
            >
              {t('customize')}
            </Button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default CookieBanner;