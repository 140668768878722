import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '../../contexts/AuthContext';
import { Eye, EyeOff, Lock, Mail, AlertCircle } from 'lucide-react';
import Logo from '../../components/common/Logo';
import { Button } from '../../components/ui/Button';

const AdminLogin: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  
  const { signIn } = useAuth();
  const navigate = useNavigate();
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!email.trim() || !password.trim()) {
      setError('Wprowadź adres email i hasło');
      return;
    }
    
    setIsLoading(true);
    setError(null);
    
    try {
      const { error } = await signIn(email, password);
      
      if (error) {
        if (error.message.includes('Invalid login credentials')) {
          setError('Nieprawidłowy email lub hasło');
        } else {
          setError(error.message);
        }
      } else {
        // Przekieruj do panelu administracyjnego po udanym logowaniu
        navigate('/admin');
      }
    } catch (err) {
      setError('Wystąpił błąd podczas logowania');
      console.error('Błąd logowania:', err);
    } finally {
      setIsLoading(false);
    }
  };
  
  // Animacje
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.1
      }
    }
  };
  
  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.6, ease: [0.22, 1, 0.36, 1] }
    }
  };
  
  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-6 bg-gradient-depth-radial relative overflow-hidden">
      {/* Tło z gradientem */}
      <div className="absolute inset-0 pointer-events-none">
        <div 
          className="absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[70vw] h-[70vh]"
          style={{
            background: `radial-gradient(
              ellipse at center, 
              rgba(255, 90, 31, 0.15) 0%,
              rgba(255, 90, 31, 0.1) 20%,
              rgba(255, 90, 31, 0.05) 40%,
              transparent 80%
            )`,
            filter: 'blur(80px)',
          }}
        />
      </div>
      
      {/* Kontener logowania */}
      <motion.div
        className="w-full max-w-md backdrop-blur-xl bg-surface-dark/80 border border-white/10 rounded-xl p-8 shadow-xl"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div 
          className="text-center mb-8"
          variants={itemVariants}
        >
          <div className="flex justify-center mb-6">
            <Logo size="md" variant="full" />
          </div>
          <h1 className="text-2xl font-bold text-white mb-2">Panel administracyjny</h1>
          <p className="text-white/60">Zaloguj się, aby zarządzać stroną</p>
        </motion.div>
        
        <form onSubmit={handleSubmit}>
          {/* Email */}
          <motion.div 
            className="mb-4"
            variants={itemVariants}
          >
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-white/80">
              Adres e-mail
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-brand-primary/80">
                <Mail size={18} />
              </div>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full py-3 px-10 rounded-lg bg-white/5 border border-white/10 text-white placeholder-white/30 focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                placeholder="Wpisz swój email"
                required
              />
            </div>
          </motion.div>
          
          {/* Hasło */}
          <motion.div 
            className="mb-6"
            variants={itemVariants}
          >
            <label htmlFor="password" className="block mb-2 text-sm font-medium text-white/80">
              Hasło
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-brand-primary/80">
                <Lock size={18} />
              </div>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full py-3 px-10 rounded-lg bg-white/5 border border-white/10 text-white placeholder-white/30 focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                placeholder="Wpisz swoje hasło"
                required
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 flex items-center pr-3 text-white/50 hover:text-white/80 transition-colors"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <EyeOff size={18} /> : <Eye size={18} />}
              </button>
            </div>
          </motion.div>
          
          {/* Komunikat błędu */}
          <AnimatePresence>
            {error && (
              <motion.div
                className="mb-4 px-4 py-3 bg-red-950/30 border border-red-800/50 rounded-lg text-red-200 flex items-center"
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.3 }}
              >
                <AlertCircle size={18} className="mr-2 flex-shrink-0" />
                <span className="text-sm">{error}</span>
              </motion.div>
            )}
          </AnimatePresence>
          
          {/* Przycisk logowania */}
          <motion.div variants={itemVariants}>
            <Button
              type="submit"
              variant="default"
              className="w-full py-3"
              disabled={isLoading}
            >
              {isLoading ? (
                <span className="flex items-center justify-center">
                  <span className="mr-2 w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin"></span>
                  Logowanie...
                </span>
              ) : (
                "Zaloguj się"
              )}
            </Button>
          </motion.div>
        </form>
        
        {/* Stopka */}
        <motion.div 
          className="mt-8 text-center text-xs text-white/40"
          variants={itemVariants}
        >
          &copy; {new Date().getFullYear()} DG Digital Media. Panel administratora.
        </motion.div>
      </motion.div>
    </div>
  );
};

export default AdminLogin;