import React from 'react';
import { motion } from 'framer-motion';
import { Code, Facebook, Instagram, Play, Video, Plane, BrainCircuit, Database, Cpu, ArrowUpRight } from 'lucide-react';

const FloatingIcons: React.FC = () => {
  // Definicje ikon
  const icons = [
    // Kod / symbol programowania
    {
      icon: <Code size={26} strokeWidth={1.5} className="text-white/80" />,
      size: 60,
      left: '8%',
      top: '35%',
      animationDuration: 14,
      animationDelay: 1.5,
      yOffset: 15,
      rotate: -4,
      glowColor: 'rgba(255, 255, 255, 0.1)'
    },
    // Logo Facebook
    {
      icon: <Facebook size={20} strokeWidth={1.5} className="text-white/70" />,
      size: 50,
      right: '18%',
      top: '28%',
      animationDuration: 16,
      animationDelay: 2,
      yOffset: 12,
      rotate: 5,
      glowColor: 'rgba(66, 103, 178, 0.15)'
    },
    // Logo Instagram
    {
      icon: <Instagram size={20} strokeWidth={1.5} className="text-white/70" />,
      size: 48,
      left: '20%',
      bottom: '30%',
      animationDuration: 12,
      animationDelay: 3.5,
      yOffset: 10,
      rotate: 8,
      glowColor: 'rgba(225, 48, 108, 0.15)'
    },
    // Play button / symbol filmowy
    {
      icon: <Play size={20} strokeWidth={1.5} className="text-white/80" />,
      size: 52,
      right: '12%',
      bottom: '35%',
      animationDuration: 18,
      animationDelay: 0.5,
      yOffset: 12,
      rotate: -6,
      glowColor: 'rgba(255, 0, 0, 0.1)'
    },
    // Kamera
    {
      icon: <Video size={22} strokeWidth={1.5} className="text-white/70" />,
      size: 55,
      left: '12%',
      top: '15%',
      animationDuration: 15,
      animationDelay: 2.5,
      yOffset: 14,
      rotate: 4,
      glowColor: 'rgba(255, 125, 58, 0.15)'
    },
    // Samolot zamiast drona (Lucide nie ma ikony drona)
    {
      icon: <Plane size={22} strokeWidth={1.5} className="text-white/70" />,
      size: 58,
      right: '15%',
      top: '50%',
      animationDuration: 20,
      animationDelay: 1,
      yOffset: 16,
      rotate: -3,
      glowColor: 'rgba(150, 150, 150, 0.1)'
    },
    // AI / BrainCircuit
    {
      icon: <BrainCircuit size={22} strokeWidth={1.5} className="text-white/70" />,
      size: 54,
      left: '25%',
      top: '55%',
      animationDuration: 17,
      animationDelay: 2,
      yOffset: 13,
      rotate: 6,
      glowColor: 'rgba(0, 150, 255, 0.1)'
    },
    // Low-code / Database
    {
      icon: <Database size={20} strokeWidth={1.5} className="text-white/70" />,
      size: 48,
      right: '22%',
      bottom: '20%',
      animationDuration: 13,
      animationDelay: 3,
      yOffset: 10,
      rotate: -5,
      glowColor: 'rgba(0, 200, 150, 0.1)'
    },
    // Technologie / Cpu
    {
      icon: <Cpu size={22} strokeWidth={1.5} className="text-white/70" />,
      size: 52,
      left: '18%',
      bottom: '15%',
      animationDuration: 19,
      animationDelay: 0,
      yOffset: 15,
      rotate: 3,
      glowColor: 'rgba(200, 200, 200, 0.1)'
    },
    // ArrowUpRight - symbol linku
    {
      icon: <ArrowUpRight size={18} strokeWidth={1.5} className="text-white/70" />,
      size: 45,
      right: '30%',
      top: '20%',
      animationDuration: 13,
      animationDelay: 2.8,
      yOffset: 9,
      rotate: 0,
      glowColor: 'rgba(255, 255, 255, 0.08)'
    }
  ];

  return (
    <>
      {icons.map((iconData, index) => (
        <motion.div
          key={index}
          className="absolute"
          style={{
            left: iconData.left,
            right: iconData.right,
            top: iconData.top,
            bottom: iconData.bottom,
            zIndex: 1,
            perspective: '800px',
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.7 }}
          transition={{ duration: 1.5, delay: 0.1 + index * 0.15 }}
        >
          <motion.div
            className="relative backdrop-blur-[1px] bg-white/[0.04] border border-white/[0.08] rounded-full shadow-sm overflow-hidden flex items-center justify-center"
            style={{
              width: `${iconData.size}px`,
              height: `${iconData.size}px`,
              transformStyle: 'preserve-3d',
              boxShadow: `0 0 15px ${iconData.glowColor}`
            }}
            animate={{
              y: [0, iconData.yOffset, 0],
              rotateX: [0, 1, 0],
              rotateY: [0, 1.5, 0],
              rotate: [0, iconData.rotate, 0]
            }}
            transition={{
              duration: iconData.animationDuration,
              repeat: Infinity,
              repeatType: 'reverse',
              ease: 'easeInOut',
              delay: iconData.animationDelay,
            }}
            whileInView={{ opacity: 1 }}
          >
            {/* Delikatny gradient wewnątrz ikony */}
            <div 
              className="absolute inset-0 opacity-20" 
              style={{
                background: `radial-gradient(circle at 30% 30%, ${iconData.glowColor}, transparent 70%)`
              }}
            />
            
            {/* Ikona */}
            <div className="relative z-10 flex items-center justify-center">
              {iconData.icon}
            </div>
          </motion.div>
        </motion.div>
      ))}
    </>
  );
};

export default FloatingIcons;