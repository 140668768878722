import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { AlertCircle, ArrowRight, CheckCircle2 } from 'lucide-react';
import { useForm, Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';
import { Link } from 'react-router-dom'; 
import { Button } from './Button';
import { cn } from '../../lib/utils';

// Typ dla danych formularza kontaktowego
export type ContactFormData = {
  name: string;
  email: string;
  subject: string;
  message: string;
  privacy: boolean;
  'form-name': string; // Pole dla Netlify Forms
};

// Props dla komponentu
interface ContactFormProps {
  onSuccess?: (data: ContactFormData) => void;
  onError?: (error: unknown) => void;
  className?: string;
}

// Komponent pola formularza
const FormField = ({ 
  label, 
  name, 
  register, 
  errors, 
  type = 'text', 
  placeholder, 
  rows = 4, 
  multiline = false, 
  required = false,
  hasAttemptedSubmit
}: { 
  label: string;
  name: keyof ContactFormData;
  register: UseFormReturn<ContactFormData>['register'];
  errors: UseFormReturn<ContactFormData>['errors'];
  type?: string;
  placeholder?: string;
  rows?: number;
  multiline?: boolean;
  required?: boolean;
  hasAttemptedSubmit: boolean;
}) => {
  // Generowanie ID dla powiązania label i inputa
  const inputId = `field-${name}`;
  
  return (
    <div className="mb-4 sm:mb-5">
      <label htmlFor={inputId} className="block text-white font-medium text-sm mb-1 sm:mb-2">
        {label}{required && <span className="text-brand-primary ml-1">*</span>}
      </label>
      
      {multiline ? (
        <textarea
          id={inputId}
          name={name}
          rows={rows}
          placeholder={placeholder}
          className={cn(
            "w-full px-4 py-3 rounded-lg backdrop-blur-md bg-white/[0.07] border border-white/10 text-white placeholder-white/40",
            "focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/60 transition-all duration-300",
            hasAttemptedSubmit && errors[name] ? "border-red-500/50 focus:ring-red-500/30 focus:border-red-500/60" : ""
          )}
          {...register(name)}
        />
      ) : (
        <input
          id={inputId}
          type={type}
          name={name}
          placeholder={placeholder}
          className={cn(
            "w-full px-4 py-3 rounded-lg backdrop-blur-md bg-white/[0.07] border border-white/10 text-white placeholder-white/40",
            "focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/60 transition-all duration-300",
            hasAttemptedSubmit && errors[name] ? "border-red-500/50 focus:ring-red-500/30 focus:border-red-500/60" : ""
          )}
          {...register(name)}
        />
      )}
      
      {/* Komunikat błędu ze stałą pozycją - ważne dla stabilności layoutu */}
      <div className="h-4 sm:h-6 mt-1 sm:mt-2">
        {hasAttemptedSubmit && errors[name] && (
          <div className="flex items-center text-red-400 text-sm">
            <AlertCircle size={14} className="mr-1 flex-shrink-0" />
            <span>{errors[name].message}</span>
          </div>
        )}
      </div>
    </div>
  );
};

const ContactForm: React.FC<ContactFormProps> = ({
  onSuccess,
  onError: onErrorProp,
  className
}) => {
  const { t, i18n } = useTranslation(['contact']);
  const currentLanguage = i18n.language;
  
  // Link do polityki prywatności odpowiedni dla aktualnego języka
  const privacyPolicyLink = currentLanguage === 'pl' ? '/pl/polityka-prywatnosci' : '/en/privacy-policy';
  
  // Stan formularza
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  
  // Nazwa formularza dla Netlify
  const formName = "contact";
  
  // Hook react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    trigger
  } = useForm<ContactFormData>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    defaultValues: {
      'form-name': formName // Ustawienie domyślnej wartości dla pola form-name
    }
  });
  
  // Obsługa zmiany języka i rewalidacja
  useEffect(() => {
    if (hasAttemptedSubmit) {
      trigger();
    }
  }, [i18n.language, hasAttemptedSubmit, trigger]);

  // Funkcja kodująca dane formularza jako application/x-www-form-urlencoded
  const encode = (data: any) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  };

  // Obsługa wysłania formularza
  const onSubmit = async (data: ContactFormData) => {
    setIsSubmitting(true);
    
    try {
      // Przygotuj dane formularza
      const formData = {
        'form-name': formName,
        name: data.name,
        email: data.email,
        subject: data.subject,
        message: data.message,
        privacy: data.privacy ? 'true' : 'false'
      };
      
      // Wysyłanie formularza przez fetch API
      const response = await fetch('/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: encode(formData),
      });
      
      if (response.ok) {
        setSubmitStatus('success');
        reset();
        setHasAttemptedSubmit(false);
        
        // Wywołaj callback onSuccess jeśli został przekazany
        if (onSuccess) {
          onSuccess(data);
        }
        
        // Po 5 sekundach resetujemy stan
        setTimeout(() => {
          setSubmitStatus('idle');
        }, 5000);
      } else {
        throw new Error(`Wysyłanie formularza nie powiodło się: ${response.status}`);
      }
    } catch (error) {
      console.error('Error sending form:', error);
      setSubmitStatus('error');
      
      // Wywołaj callback onError jeśli został przekazany
      if (onErrorProp) {
        onErrorProp(error);
      }
      
      // Po 5 sekundach resetujemy stan błędu
      setTimeout(() => {
        setSubmitStatus('idle');
      }, 5000);
    } finally {
      setIsSubmitting(false);
    }
  };
  
  // Obsługa błędów walidacji
  const onError = (errors: any) => {
    console.log('Form validation errors:', errors);
    setHasAttemptedSubmit(true);
  };
  
  // Komponent dla wiadomości statusu
  const StatusMessage = () => {
    if (submitStatus === 'idle') return null;
    
    return (
      <motion.div
        className={cn(
          "mb-6 p-4 rounded-lg backdrop-blur-sm",
          submitStatus === 'success' ? "bg-green-500/20 border border-green-500/30" : "bg-red-500/20 border border-red-500/30"
        )}
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -10 }}
      >
        <div className="flex items-center">
          {submitStatus === 'success' ? (
            <CheckCircle2 size={20} className="text-green-500 mr-3 flex-shrink-0" />
          ) : (
            <AlertCircle size={20} className="text-red-500 mr-3 flex-shrink-0" />
          )}
          <p className="text-white">
            {submitStatus === 'success' ? t('form.success') : t('form.error')}
          </p>
        </div>
      </motion.div>
    );
  };

  return (
    <div className={className}>
      <div className="rounded-2xl backdrop-blur-md bg-white/[0.07] border border-white/10 p-6 lg:p-8">
        {/* Status formularza */}
        <AnimatePresence>
          {submitStatus !== 'idle' && <StatusMessage />}
        </AnimatePresence>
        
        {/* Właściwy formularz */}
        <form 
          name={formName}
          method="POST"
          data-netlify="true" 
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit(onSubmit, onError)} 
          noValidate
        >
          {/* Pole ukryte dla Netlify Forms */}
          <input type="hidden" name="form-name" value={formName} />
          <input type="hidden" name="bot-field" />
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 sm:gap-5">
            {/* Imię */}
            <FormField
              label={t('form.name.label')}
              name="name"
              placeholder={t('form.name.placeholder')}
              required={true}
              register={() => register("name", {
                required: t('form.name.errors.required'),
                minLength: {
                  value: 3,
                  message: t('form.name.errors.minLength')
                }
              })}
              errors={errors}
              hasAttemptedSubmit={hasAttemptedSubmit}
            />
            
            {/* Email */}
            <FormField
              label={t('form.email.label')}
              name="email"
              type="email"
              placeholder={t('form.email.placeholder')}
              required={true}
              register={() => register("email", {
                required: t('form.email.errors.required'),
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: t('form.email.errors.pattern')
                }
              })}
              errors={errors}
              hasAttemptedSubmit={hasAttemptedSubmit}
            />
          </div>
          
          {/* Temat */}
          <FormField
            label={t('form.subject.label')}
            name="subject"
            placeholder={t('form.subject.placeholder')}
            required={true}
            register={() => register("subject", {
              required: t('form.subject.errors.required'),
              minLength: {
                value: 5,
                message: t('form.subject.errors.minLength')
              }
            })}
            errors={errors}
            hasAttemptedSubmit={hasAttemptedSubmit}
          />
          
          {/* Wiadomość */}
          <FormField
            label={t('form.message.label')}
            name="message"
            placeholder={t('form.message.placeholder')}
            required={true}
            multiline={true}
            rows={5}
            register={() => register("message", {
              required: t('form.message.errors.required'),
              minLength: {
                value: 10,
                message: t('form.message.errors.minLength')
              }
            })}
            errors={errors}
            hasAttemptedSubmit={hasAttemptedSubmit}
          />
          
          {/* Akceptacja polityki prywatności */}
          <div className="mb-3 sm:mb-5">
            <div className="flex items-center">
              <div className="flex items-center">
                <Controller
                  name="privacy"
                  control={control}
                  defaultValue={false}
                  rules={{ 
                    required: t('form.privacy.errors.required') 
                  }}
                  render={({ field }) => (
                    <input
                      id="privacy"
                      name="privacy"
                      type="checkbox"
                      className="w-4 h-4"
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                  )}
                />
              </div>
              <label htmlFor="privacy" className="ml-3 text-sm text-white/70">
                {currentLanguage === 'pl' ? (
                  <>
                    Akceptuję <Link to={privacyPolicyLink} className="text-brand-primary hover:text-brand-tertiary transition-colors underline">politykę prywatności i cookies</Link> i wyrażam zgodę na przetwarzanie moich danych osobowych
                  </>
                ) : (
                  <>
                    I accept the <Link to={privacyPolicyLink} className="text-brand-primary hover:text-brand-tertiary transition-colors underline">privacy policy and cookies</Link> and consent to the processing of my personal data
                  </>
                )}
                <span className="text-brand-primary ml-1">*</span>
              </label>
            </div>
            
            {/* Stała wysokość dla komunikatu błędu checkboxa */}
            <div className="h-4 sm:h-6 mt-1 sm:mt-2">
              {hasAttemptedSubmit && errors.privacy && (
                <div className="flex items-center text-red-400 text-sm">
                  <AlertCircle size={14} className="mr-1 flex-shrink-0" />
                  <span>{errors.privacy.message as string}</span>
                </div>
              )}
            </div>
          </div>
          
          {/* Przycisk wysyłania */}
          <div className="mt-5 sm:mt-8">
            <Button
              type="submit"
              variant="default"
              size="lg"
              icon={<ArrowRight size={18} />}
              className="w-full md:w-auto disabled:opacity-70"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <span className="flex items-center text-white">
                  <span className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mr-2"></span>
                  {t('form.submit')}
                </span>
              ) : (
                t('form.submit')
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;