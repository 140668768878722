import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import HomePage from './pages/HomePage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ProjectPage from './pages/ProjectPage';
import NotFound from './pages/NotFound';
import AdminLayout from './admin/layouts/AdminLayout';
import AdminLogin from './admin/pages/AdminLogin';
import AdminDashboard from './admin/pages/AdminDashboard';
import AdminProjects from './admin/pages/AdminProjects';
import AdminProjectEdit from './admin/pages/AdminProjectEdit';
import AdminCategories from './admin/pages/AdminCategories';
import AdminTags from './admin/pages/AdminTags';
import AdminTechnologies from './admin/pages/AdminTechnologies';
import ProtectedRoute from './admin/components/common/ProtectedRoute';
import { CookieConsentProvider } from './contexts/CookieConsentContext';
import CookieConsent from './components/cookie/CookieConsent';
import { AuthProvider } from './contexts/AuthContext';
// Usunięto import bannera informującego o budowie strony
// import UnderConstructionBanner from './components/common/UnderConstructionBanner';
import { HelmetProvider } from 'react-helmet-async';
import OpenGraph from './components/seo/OpenGraph';
import RouteChangeTracker from './components/analytics/RouteChangeTracker';
import ScrollToTop from './components/common/ScrollToTop';

const SUPPORTED_LANGUAGES = ['pl', 'en'] as const;
type SupportedLanguage = typeof SUPPORTED_LANGUAGES[number];

// Komponent do obsługi przekierowania na podstawie języka przeglądarki
const LanguageRedirect: React.FC = () => {
  const { i18n } = useTranslation();
  const location = useLocation();

  // Jeśli jesteśmy w panelu admina, nie robimy przekierowania
  if (location.pathname.startsWith('/admin')) {
    return null;
  }

  // Sprawdź czy ścieżka już zawiera prefix językowy
  const langMatch = location.pathname.match(/^\/(pl|en)(?:\/|$)/);
  const currentLang = i18n.language.slice(0, 2) as SupportedLanguage;

  // Jeśli mamy prefix językowy w URL
  if (langMatch) {
    const pathLang = langMatch[1] as SupportedLanguage;
    
    // Jeśli język w URL różni się od aktualnego języka i18n
    if (currentLang !== pathLang) {
      // Synchronizujemy język i18n z URL
      i18n.changeLanguage(pathLang);
    }
    
    // Nie wykonujemy przekierowania
    return null;
  }

  // Jeśli nie mamy prefiksu językowego, dodajemy go
  const browserLang = navigator.language.slice(0, 2).toLowerCase();
  const targetLang = SUPPORTED_LANGUAGES.includes(browserLang as SupportedLanguage)
    ? browserLang as SupportedLanguage
    : 'en';

  // Budujemy nową ścieżkę z prefiksem językowym
  const basePath = location.pathname === '/' ? '' : location.pathname;
  const targetPath = `/${targetLang}${basePath}${location.search}${location.hash}`;

  // Wykonujemy pojedyncze przekierowanie
  return <Navigate to={targetPath} replace />;
};

// Komponent SEO do zarządzania tagami hreflang
const LanguageSEO: React.FC = () => {
  const location = useLocation();
  const baseUrl = 'https://digital-media.online';

  // Nie dodawaj hreflang dla panelu admina
  if (location.pathname.startsWith('/admin')) {
    return null;
  }

  // Usuń prefix językowy z obecnej ścieżki
  const pathWithoutLang = location.pathname.replace(/^\/(pl|en)/, '');

  return (
    <Helmet>
      <link
        rel="alternate"
        hrefLang="pl"
        href={`${baseUrl}/pl${pathWithoutLang}`}
      />
      <link
        rel="alternate"
        hrefLang="en"
        href={`${baseUrl}/en${pathWithoutLang}`}
      />
      <link
        rel="alternate"
        hrefLang="x-default"
        href={`${baseUrl}/en${pathWithoutLang}`}
      />
    </Helmet>
  );
};

// Zmodyfikowany komponent NotFound, który obsługuje bezpośrednie wejścia na /pl i /en
const NotFoundWithRedirect: React.FC = () => {
  const location = useLocation();
  
  // Sprawdź czy jesteśmy na ścieżkach /pl lub /en bez podścieżek
  if (location.pathname === '/pl' || location.pathname === '/en') {
    return <HomePage />;
  }
  
  return <NotFound />;
};

function App() {
  return (
    <HelmetProvider>
      <AuthProvider>
        <CookieConsentProvider>
          <Router>
            <ScrollToTop />
            <RouteChangeTracker />
            <LanguageSEO />
            <OpenGraph />
            <div className="text-text-primary flex flex-col min-h-screen relative">
              {/* Tło z płynnym gradientem */}
              <div
                className="fixed inset-0 w-full h-full overflow-hidden pointer-events-none"
                style={{
                  zIndex: -1,
                  background:
                    'linear-gradient(90deg, #050505 0%, #080808 8%, #0C0C0C 15%, #121212 25%, #171717 35%, #1A1A1A 50%, #171717 65%, #121212 75%, #0C0C0C 85%, #080808 92%, #050505 100%)',
                  backgroundAttachment: 'fixed',
                }}
              />

              {/* Usunięto baner informujący o budowie strony */}
              {/* <UnderConstructionBanner /> */}
              
              {/* Komponent śledzący zmiany stron dla Google Analytics */}
              <LanguageRedirect />

              <Routes>
                {/* Przekierowanie z root URL */}
                <Route path="/" element={<LanguageRedirect />} />

                {/* Ścieżki dla wersji polskiej */}
                <Route path="/pl" element={<HomePage />} />
                <Route path="/pl/polityka-prywatnosci" element={<PrivacyPolicy />} />
                <Route
                  path="/pl/projekt/:category/:id"
                  element={<ProjectPage />}
                />

                {/* Ścieżki dla wersji angielskiej */}
                <Route path="/en" element={<HomePage />} />
                <Route
                  path="/en/privacy-policy"
                  element={<PrivacyPolicy />}
                />
                <Route
                  path="/en/project/:category/:id"
                  element={<ProjectPage />}
                />

                {/* Stare ścieżki - przekierowanie na wersje językowe */}
                <Route path="/privacy-policy" element={<LanguageRedirect />} />
                <Route path="/polityka-prywatnosci" element={<LanguageRedirect />} />
                <Route path="/project/:category/:id" element={<LanguageRedirect />} />
                <Route path="/projekt/:category/:id" element={<LanguageRedirect />} />
                
                {/* Dodatkowe przekierowania dla nowych formatów URL */}
                <Route path="/pl/project/:category/:id" element={<Navigate to="/pl/projekt/:category/:id" replace />} />
                <Route path="/en/projekt/:category/:id" element={<Navigate to="/en/project/:category/:id" replace />} />

                {/* Panel administracyjny - bez zmian */}
                <Route path="/admin/login" element={<AdminLogin />} />
                <Route
                  path="/admin"
                  element={
                    <ProtectedRoute>
                      <AdminLayout />
                    </ProtectedRoute>
                  }
                >
                  <Route index element={<AdminDashboard />} />
                  <Route path="projects" element={<AdminProjects />} />
                  <Route path="projects/new" element={<AdminProjectEdit />} />
                  <Route path="projects/:id" element={<AdminProjectEdit />} />
                  <Route path="categories" element={<AdminCategories />} />
                  <Route path="tags" element={<AdminTags />} />
                  <Route path="technologies" element={<AdminTechnologies />} />
                </Route>

                {/* Strona 404 */}
                <Route path="*" element={<NotFoundWithRedirect />} />
              </Routes>
              
              <CookieConsent />
            </div>
          </Router>
        </CookieConsentProvider>
      </AuthProvider>
    </HelmetProvider>
  );
}

export default App;