import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { ArrowLeft, CheckCircle2, ArrowRight, Clock, User, Globe, CalendarDays, Trophy, Maximize2 } from 'lucide-react';
import * as LucideIcons from 'lucide-react';
import Navbar from '../components/layout/Navbar';
import Footer from '../components/layout/Footer';
import { usePageTitle } from '../hooks/usePageTitle';
import SectionPreheader from '../components/common/SectionPreheader';
import { Button } from '../components/ui/Button';
import { Carousel, CarouselContent, CarouselItem, CarouselPrevious, CarouselNext, CarouselDots } from '../components/ui/Carousel';
import { supabase } from '../lib/supabase/client';
import OpenGraph from '../components/seo/OpenGraph';
import ReactPlayer from 'react-player';
import ImageGalleryModal from '../components/ui/ImageGalleryModal';
import SimilarProjects from '../components/project/SimilarProjects';
import Autoplay from 'embla-carousel-autoplay';

const ProjectPage: React.FC = () => {
  const { category, id } = useParams<{ category: string; id: string }>();
  const { t, i18n } = useTranslation(['projects', 'common', 'meta']);
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  
  const [project, setProject] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [galleryModalOpen, setGalleryModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [similarProjects, setSimilarProjects] = useState<any[]>([]);
  
  // Set page title based on project name
  usePageTitle(project?.title || 'pageTitle.project');
  
  // Funkcja do aktualizacji URL projektu po zmianie języka
  const updateProjectUrl = () => {
    if (!project) return;
    
    // Mapowanie typów projektów na poszczególne języki
    const projectTypeMap: Record<string, { pl: string; en: string }> = {
      'web': { pl: 'strona-internetowa', en: 'website' },
      'strona-internetowa': { pl: 'strona-internetowa', en: 'website' },
      'website': { pl: 'strona-internetowa', en: 'website' },
      'social': { pl: 'social-media', en: 'social-media' },
      'social-media': { pl: 'social-media', en: 'social-media' },
      'video': { pl: 'wideo', en: 'video' },
      'wideo': { pl: 'wideo', en: 'video' }
    };
    
    // Znajdź właściwy slug dla bieżącego języka
    const projectSlug = currentLanguage === 'pl' ? project.pl_slug || project.slug : project.en_slug || project.slug;
    
    // Określ typ projektu (używając project_type z obiektu projektu lub parametru z URL)
    const currentCategory = category || project.project_type;
    
    // Określ mapowany typ projektu
    const mappedType = projectTypeMap[currentCategory]?.[currentLanguage as 'pl' | 'en'] || currentCategory;
    
    // Zbuduj nową ścieżkę
    const newPath = currentLanguage === 'pl' 
      ? `/pl/projekt/${mappedType}/${projectSlug}` 
      : `/en/project/${mappedType}/${projectSlug}`;
    
    // Aktualizuj URL jeśli jest inny niż obecny
    if (window.location.pathname !== newPath) {
      navigate(newPath, { replace: true });
    }
  };
  
  // Handle language change for URL
  useEffect(() => {
    if (project) {
      updateProjectUrl();
    }
  }, [currentLanguage, project, category, navigate]);
  
  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  // Fetch project data
  useEffect(() => {
    if (!id) return;
    
    const fetchProject = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // Sprawdzamy, czy id wygląda jak UUID
        const isUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(id);
        
        // Budujemy zapytanie na podstawie tego, czy mamy UUID czy slug
        let query = supabase
          .from('projects')
          .select(`
            *,
            project_translations (
              id,
              title,
              description,
              language,
              overview,
              challenge,
              approach,
              results
            ),
            project_features (
              id,
              pl_title,
              en_title,
              pl_description,
              en_description
            ),
            project_technologies (
              id,
              technologies (
                id,
                name,
                icon_name
              )
            ),
            project_gallery (
              id,
              image_url,
              pl_alt,
              en_alt
            ),
            project_testimonials (
              id,
              pl_content,
              en_content,
              author_name,
              company_name,
              logo_url,
              original_language
            ),
            project_tags (
              tag_id,
              tags (
                id,
                pl_name,
                en_name,
                slug,
                pl_slug,
                en_slug
              )
            )
          `);
          
        if (isUUID) {
          query = query.eq('id', id);
        } else {
          // Jeśli nie jest to UUID, traktujemy to jako slug
          query = query.or(`slug.eq.${id},pl_slug.eq.${id},en_slug.eq.${id}`);
        }
        
        const { data, error } = await query.single();
        
        if (error) throw error;
        
        if (!data) {
          setError(currentLanguage === 'pl' ? 'Projekt nie został znaleziony' : 'Project not found');
          setLoading(false);
          return;
        }

        // Format project data
        const projectData = formatProjectData(data);
        setProject(projectData);
        
        // Fetch similar projects based on tags
        if (projectData.project_tags && projectData.project_tags.length > 0) {
          await fetchSimilarProjects(projectData.id, projectData.project_tags.map((pt: any) => pt.tag_id));
        }
        
        setLoading(false);
      } catch (error: any) {
        console.error('Error fetching project:', error);
        setError(error.message || (currentLanguage === 'pl' ? 'Wystąpił błąd podczas pobierania projektu' : 'An error occurred while fetching the project'));
        setLoading(false);
      }
    };
    
    fetchProject();
  }, [id, currentLanguage]);
  
  // Fetch similar projects based on tags
  const fetchSimilarProjects = async (currentProjectId: string, tagIds: string[]) => {
    try {
      if (!tagIds.length) return;
      
      const { data, error } = await supabase
        .from('projects')
        .select(`
          id,
          slug,
          pl_slug,
          en_slug,
          project_type,
          thumbnail_url,
          project_translations (
            id,
            title,
            description,
            language
          ),
          project_tags!inner (
            tag_id,
            tags:tag_id (
              id,
              pl_name,
              en_name,
              slug
            )
          )
        `)
        .neq('id', currentProjectId)
        .in('project_tags.tag_id', tagIds)
        .eq('published', true)
        .order('sort_order', { ascending: true })
        .limit(6);
      
      if (error) throw error;
      
      if (data) {
        // Remove duplicates (a project might match multiple tags)
        const uniqueProjects = Array.from(new Set(data.map((project: any) => project.id)))
          .map(id => {
            const project = data.find((p: any) => p.id === id);
            
            if (!project) return null;
            
            // Add title and description from translations
            const translation = project.project_translations?.find(
              (t: any) => t.language === currentLanguage
            ) || project.project_translations?.find(
              (t: any) => t.language === 'pl' // Fallback do języka polskiego
            );
            
            // Przetwarzamy tagi do wyświetlenia
            const tags = project.project_tags.map((pt: any) => 
              currentLanguage === 'pl' ? pt.tags?.pl_name : pt.tags?.en_name
            ).filter(Boolean);
            
            return {
              ...project,
              title: translation?.title || 'Projekt',
              description: translation?.description || '',
              tags: tags
            };
          })
          .filter(Boolean); // Usuń nulle
        
        setSimilarProjects(uniqueProjects);
      }
    } catch (error) {
      console.error('Error fetching similar projects:', error);
    }
  };
  
  // Format project data
  const formatProjectData = (data: any) => {
    // Znajdź tłumaczenie dla obecnego języka
    const translation = data.project_translations.find(
      (t: any) => t.language === currentLanguage
    ) || data.project_translations.find(
      (t: any) => t.language === 'pl' // Fallback do języka polskiego
    );
    
    // Ustawienie tytułu i opisu z odpowiedniego tłumaczenia
    const projectWithTranslation = {
      ...data,
      title: translation?.title || 'Untitled Project',
      description: translation?.description || '',
      overview: translation?.overview || '',
      challenge: translation?.challenge || '',
      approach: translation?.approach || '',
      results: translation?.results || '',
      stats: data.stats || [],
      relatedProjects: data.relatedProjects || [],
      testimonial: data.testimonial || null,
      url: data.project_url || null,
      completion_time: data.time_frame || null,
      tags: data.project_tags ? data.project_tags.map((pt: any) => 
        currentLanguage === 'pl' ? pt.tags.pl_name : pt.tags.en_name
      ) : []
    };
    
    return projectWithTranslation;
  };
  
  // Use correct language for texts
  const getLocalizedText = (textObj: { pl: string, en: string }) => {
    return currentLanguage === 'pl' ? textObj.pl : textObj.en;
  };
  
  // Format date based on current language
  const formatDate = (dateString: string) => {
    if (!dateString) return '';
    
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    };
    
    return date.toLocaleDateString(
      currentLanguage === 'pl' ? 'pl-PL' : 'en-US', 
      options
    );
  };
  
  // Animation variants
  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: (delay: number = 0) => ({ 
      opacity: 1, 
      y: 0, 
      transition: { 
        delay,
        duration: 0.6, 
        ease: [0.22, 1, 0.36, 1]
      }
    })
  };
  
  // Get the base path for portfolio (depends on language)
  const getPortfolioPath = () => {
    const homePath = `/${currentLanguage === 'pl' ? 'pl' : 'en'}`;
    return `${homePath}?section=portfolio`;
  };

  // Features formatted
  const features = project?.project_features?.map((feature: any) => {
    return {
      title: { 
        pl: feature.pl_title || '', 
        en: feature.en_title || '' 
      },
      description: { 
        pl: feature.pl_description || '', 
        en: feature.en_description || '' 
      }
    };
  }) || [];

  // Gallery formatted
  const gallery = project?.project_gallery?.map((item: any) => item.image_url) || [];
  const galleryAlts = project?.project_gallery?.map((item: any) => 
    currentLanguage === 'pl' ? item.pl_alt || `${project?.title} - obraz` : item.en_alt || `${project?.title} - image`
  ) || [];
  
  // Handle opening gallery modal
  const openGalleryModal = (index: number) => {
    setCurrentImageIndex(index);
    setGalleryModalOpen(true);
  };
  
  // Jeśli projekt się ładuje
  if (loading) {
    return (
      <>
        <main className="min-h-screen flex flex-col relative">
          {/* Navbar */}
          <Navbar />
          
          <div className="flex-1 flex items-center justify-center">
            <div className="text-center">
              <div className="w-10 h-10 border-4 border-t-brand-primary border-white/10 rounded-full animate-spin mx-auto mb-4"></div>
              <p className="text-white/70">
                {currentLanguage === 'pl' ? 'Ładowanie projektu...' : 'Loading project...'}
              </p>
            </div>
          </div>
          
          <Footer />
        </main>
      </>
    );
  }

  // Jeśli wystąpił błąd
  if (error) {
    return (
      <>
        <main className="min-h-screen flex flex-col relative">
          {/* Navbar */}
          <Navbar />
          
          <div className="flex-1 flex items-center justify-center">
            <div className="text-center max-w-md mx-auto px-6">
              <h1 className="text-2xl font-bold text-white mb-4">
                {currentLanguage === 'pl' ? 'Nie znaleziono projektu' : 'Project not found'}
              </h1>
              <p className="text-white/70 mb-6">{error}</p>
              <Link 
                to={currentLanguage === 'pl' ? '/pl' : '/en'}
                className="inline-flex items-center text-brand-primary hover:text-brand-primary-hover transition-colors"
              >
                <ArrowLeft size={16} className="mr-2" />
                {currentLanguage === 'pl' ? 'Powrót do strony głównej' : 'Back to home page'}
              </Link>
            </div>
          </div>
          
          <Footer />
        </main>
      </>
    );
  }

  // Jeśli nie ma projektu
  if (!project) {
    return (
      <>
        <main className="min-h-screen flex flex-col relative">
          {/* Navbar */}
          <Navbar />
          
          <div className="flex-1 flex items-center justify-center">
            <div className="text-center">
              <p className="text-white/70">
                {currentLanguage === 'pl' ? 'Nie znaleziono projektu' : 'Project not found'}
              </p>
            </div>
          </div>
          
          <Footer />
        </main>
      </>
    );
  }
  
  return (
    <>
      {project && (
        <OpenGraph 
          title={project.title} 
          description={project.short_description}
          imagePath={project.thumbnail ? project.thumbnail : undefined}
          imageAlt={project.title}
          type="article"
        />
      )}
      <Navbar />
      <main className="pb-20 overflow-x-hidden">
        {/* Hero section */}
        <section className="relative w-full h-[100vh] flex flex-col">
          {/* Background image with overlay */}
          <div className="absolute inset-0 z-0 w-full h-full">
            <div className="w-full h-full">
              <img 
                src={project?.thumbnail_url || 'https://via.placeholder.com/1200x600?text=No+Image'}
                alt={project?.title}
                className="w-full h-full object-cover object-center"
              />
              <div className="absolute inset-0 bg-gradient-to-b from-black/90 via-black/80 to-[#121212]"></div>
            </div>
          </div>
          
          {/* Hero content */}
          <div className="container mx-auto px-4 sm:px-6 flex-1 flex flex-col justify-center items-center relative z-10">
            <div className="max-w-5xl w-full mx-auto">
              {/* Back button */}
              <motion.div
                variants={fadeInUp}
                initial="hidden"
                animate="visible"
                className="mb-4 sm:mb-8 text-center"
                custom={0}
              >
                <Link 
                  to={getPortfolioPath()}
                  className="inline-flex items-center text-white/70 hover:text-brand-primary transition-colors"
                >
                  <ArrowLeft size={16} className="mr-2" />
                  {currentLanguage === 'pl' ? 'Powrót do portfolio' : 'Back to portfolio'}
                </Link>
              </motion.div>
              
              {/* Project title */}
              <motion.h1
                variants={fadeInUp}
                initial="hidden"
                animate="visible"
                custom={0.2}
                className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-white leading-tight sm:leading-relaxed mb-4 sm:mb-6 text-center"
              >
                {project?.title}
              </motion.h1>
              
              {/* Project meta w hero */}
              <motion.div
                variants={fadeInUp}
                initial="hidden"
                animate="visible"
                custom={0.3}
                className="flex flex-wrap gap-3 sm:gap-4 text-white/80 mb-6 justify-center"
              >
                <div className="flex items-center">
                  <Clock size={18} className="mr-2 text-brand-primary" />
                  <span>{formatDate(project?.created_at)}</span>
                </div>
                {project?.completion_time && (
                  <div className="flex items-center">
                    <CalendarDays size={18} className="mr-2 text-brand-primary" />
                    <span>
                      {t('projects:completionTime', { days: project?.completion_time })}
                    </span>
                  </div>
                )}
                <div className="flex items-center">
                  <User size={18} className="mr-2 text-brand-primary" />
                  <span>{project?.client}</span>
                </div>
              </motion.div>
            </div>
          </div>
        </section>
        
        {/* Tagi projektu - przeniesione z hero pod sekcję hero i nad sekcję treści */}
        {project?.tags && project?.tags.length > 0 && (
          <section className="py-8 relative">
            <div className="container mx-auto px-6">
              <motion.div
                variants={fadeInUp}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                className="max-w-5xl mx-auto flex flex-wrap gap-2 justify-center"
              >
                {project?.tags.map((tag: string, index: number) => (
                  <div 
                    key={index}
                    className="px-3 py-1 rounded-full bg-white/5 hover:bg-white/10 border border-white/10 text-white/80 hover:text-white text-sm transition-all"
                  >
                    #{tag}
                  </div>
                ))}
              </motion.div>
            </div>
          </section>
        )}
        
        {/* Project content - wszystkie informacje w jednej sekcji */}
        <section className="py-16 md:py-20 relative">
          {/* Gradient decoration - right side - dostosowany do strony głównej */}
          <div 
            className="absolute right-0 top-1/3 w-1/2 h-2/3 pointer-events-none"
            style={{
              background: `
                radial-gradient(
                  ellipse at 70% 40%, 
                  rgba(255, 90, 31, 0.35) 0%,
                  rgba(255, 90, 31, 0.25) 10%,
                  rgba(255, 125, 58, 0.2) 30%, 
                  rgba(255, 159, 69, 0.1) 50%,
                  rgba(255, 210, 100, 0.05) 70%,
                  transparent 90%
                )
              `,
              filter: 'blur(40px)',
              transform: 'rotate(15deg)',
              transformOrigin: 'center right'
            }}
          ></div>
          
          <div className="container mx-auto px-6">
            <div className="max-w-5xl mx-auto">
              {/* Project info - przeniesione z osobnej sekcji */}
              <div className="mb-16">
                {/* Pre-header */}
                <motion.div
                  variants={fadeInUp}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  className="mb-6 text-center"
                  custom={0.1}
                >
                  <SectionPreheader 
                    text={currentLanguage === 'pl' ? 'Realizacja' : 'Case Study'} 
                    variant="primary" 
                    motionEnabled={false}
                    align="center"
                  />
                </motion.div>
                
                {/* Short description - teraz wyśrodkowany */}
                <motion.p
                  variants={fadeInUp}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  custom={0.25}
                  className="text-white/90 text-lg mb-12 max-w-3xl mx-auto text-center"
                >
                  {project?.description}
                </motion.p>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {/* Overview column */}
                <div className={`${project?.project_type === 'web' ? 'md:col-span-2' : 'md:col-span-full mx-auto max-w-3xl text-center'}`}>
                  <motion.h2
                    variants={fadeInUp}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    className="text-2xl md:text-3xl font-bold text-white mb-4"
                  >
                    {currentLanguage === 'pl' ? 'Przegląd projektu' : 'Project Overview'}
                  </motion.h2>
                  <motion.p
                    variants={fadeInUp}
                    initial="hidden"
                    animate="visible"
                    custom={0.2}
                    className="text-white/80 text-lg mb-6"
                  >
                    {project?.overview}
                  </motion.p>
                  
                  {/* Tylko jeśli istnieje wyzwanie i jest to projekt web */}
                  {project?.project_type === 'web' && project?.challenge && project?.challenge.length > 0 && (
                    <>
                      <motion.h3
                        variants={fadeInUp}
                        initial="hidden"
                        animate="visible"
                        custom={0.3}
                        className="text-xl font-semibold text-white mt-8 mb-4"
                      >
                        {currentLanguage === 'pl' ? 'Wyzwanie' : 'Challenge'}
                      </motion.h3>
                      
                      <motion.p
                        variants={fadeInUp}
                        initial="hidden"
                        animate="visible"
                        custom={0.3}
                        className="text-white/80 mb-6"
                      >
                        {project?.challenge}
                      </motion.p>
                    </>
                  )}
                  
                  {/* Tylko jeśli istnieje podejście i jest to projekt web */}
                  {project?.project_type === 'web' && project?.approach && project?.approach.length > 0 && (
                    <>
                      <motion.h3
                        variants={fadeInUp}
                        initial="hidden"
                        animate="visible"
                        custom={0.4}
                        className="text-xl font-semibold text-white mt-8 mb-4"
                      >
                        {currentLanguage === 'pl' ? 'Moje podejście' : 'My Approach'}
                      </motion.h3>
                      
                      <motion.p
                        variants={fadeInUp}
                        initial="hidden"
                        animate="visible"
                        custom={0.4}
                        className="text-white/80 mb-6"
                      >
                        {project?.approach}
                      </motion.p>
                    </>
                  )}
                </div>
                
                {/* Stats sidebar - wyświetlane tylko dla projektów typu 'web' */}
                {project?.project_type === 'web' && (
                  <div>
                    <motion.div
                      variants={fadeInUp}
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true }}
                      className="p-6 bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl"
                    >
                      <h3 className="text-xl font-semibold text-white mb-6">
                        {currentLanguage === 'pl' ? 'Kluczowe rezultaty' : 'Key Results'}
                      </h3>
                      
                      <div className="space-y-6">
                        {project?.stats?.map((stat: any, index: number) => (
                          <div key={index} className="flex items-center">
                            <div className="w-16 h-16 rounded-full flex items-center justify-center bg-brand-primary/10 mr-4">
                              <span className="text-xl font-bold text-brand-primary">{stat.value}</span>
                            </div>
                            <span className="text-white/80">{getLocalizedText(stat.label)}</span>
                          </div>
                        ))}
                      </div>
                      
                      {/* Technologie - wyświetlane tylko dla projektów typu 'web' */}
                      {project?.project_type === 'web' && (
                        <div className="mt-8 pt-6 border-t border-white/10">
                          <h4 className="text-lg font-medium text-white mb-4">
                            {currentLanguage === 'pl' ? 'Technologie' : 'Technologies'}
                          </h4>
                          <div className="flex flex-wrap gap-2">
                            {project?.project_technologies?.map((pt: any, index: number) => {
                              // Znajdź ikonę na podstawie nazwy
                              const IconComponent = (pt.technologies.icon_name && LucideIcons[pt.technologies.icon_name as keyof typeof LucideIcons]) || LucideIcons.Code;
                              
                              return (
                                <div 
                                  key={index}
                                  className="px-3 py-1.5 rounded-full bg-white/10 hover:bg-white/15 text-white/80 hover:text-white text-sm flex items-center transition-all cursor-default"
                                >
                                  <IconComponent size={14} className="mr-2 text-brand-primary" />
                                  <span>{pt.technologies.name}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </motion.div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        
        {/* Features - wyświetlane dla projektów typu 'web' i 'social' */}
        {(project?.project_type === 'web' || project?.project_type === 'social') && features && features.length > 0 && (
          <section className="py-20 relative overflow-hidden">
            {/* Gradient decoration - dostosowany do strony głównej */}
            <div 
              className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 w-3/5 h-2/5 pointer-events-none"
              style={{
                background: `
                  radial-gradient(
                    ellipse at center, 
                    rgba(255, 90, 31, 0.35) 0%,
                    rgba(255, 90, 31, 0.25) 20%,
                    rgba(255, 90, 31, 0.15) 40%,
                    rgba(255, 90, 31, 0.05) 60%,
                    transparent 80%
                  )
                `,
                filter: 'blur(40px)',
              }}
            ></div>
            
            <div className="container mx-auto px-6">
              <motion.div
                variants={fadeInUp}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                className="max-w-5xl mx-auto"
              >
                <SectionPreheader 
                  text={currentLanguage === 'pl' ? (project?.project_type === 'social' ? 'Osiągnięcia' : 'Funkcjonalności') : (project?.project_type === 'social' ? 'Achievements' : 'Features')} 
                  variant="tertiary" 
                  className="mb-8" 
                  align="center"
                />
                
                <h2 className="text-2xl md:text-3xl font-bold text-white mb-10 text-center">
                  {currentLanguage === 'pl' 
                    ? (project?.project_type === 'social' ? 'Kluczowe osiągnięcia' : 'Kluczowe funkcje') 
                    : (project?.project_type === 'social' ? 'Key Achievements' : 'Key Features')}
                </h2>
                
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {features.map((feature: any, index: number) => (
                    <motion.div
                      key={index}
                      variants={fadeInUp}
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true }}
                      custom={index * 0.1}
                      className="p-6 bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl hover:bg-white/10 transition-all group hover:shadow-lg hover:shadow-brand-primary/5"
                    >
                      <div className="flex items-start">
                        <div className="mt-1 mr-4">
                          <div className="w-10 h-10 rounded-full flex items-center justify-center bg-brand-primary/20 group-hover:bg-brand-primary/30 transition-all group-hover:scale-110">
                            {project?.project_type === 'social' ? (
                              <Trophy size={16} className="text-brand-primary" />
                            ) : (
                              <CheckCircle2 size={16} className="text-brand-primary" />
                            )}
                          </div>
                        </div>
                        <div>
                          <h3 className="text-lg font-semibold text-white mb-3 group-hover:text-brand-primary transition-colors">
                            {getLocalizedText(feature.title)}
                          </h3>
                          <p className="text-white/70 text-sm">
                            {getLocalizedText(feature.description)}
                          </p>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            </div>
          </section>
        )}
        
        {/* Gallery - wyświetlana dla projektów typu 'web' i 'social' */}
        {(project?.project_type === 'web' || project?.project_type === 'social') && gallery && gallery.length > 0 && (
          <section className="py-20 relative overflow-hidden">
            <div className="container mx-auto px-6">
              <motion.div
                variants={fadeInUp}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                className="max-w-5xl mx-auto"
              >
                <SectionPreheader 
                  text={currentLanguage === 'pl' ? 'Galeria' : 'Gallery'} 
                  variant="primary" 
                  className="mb-8" 
                  align="center"
                />
                
                <h2 className="text-2xl md:text-3xl font-bold text-white mb-10 text-center">
                  {currentLanguage === 'pl' ? 'Wizualna prezentacja projektu' : 'Project Showcase'}
                </h2>
                
                <Carousel
                  opts={{
                    loop: true,
                    align: "center"
                  }}
                  className="w-full"
                  plugins={[
                    Autoplay({
                      delay: 5000,
                      stopOnInteraction: true,
                    }),
                  ]}
                >
                  <CarouselContent>
                    {gallery.map((image: string, index: number) => (
                      <CarouselItem key={index} className="md:basis-2/3 lg:basis-1/2">
                        <div className="p-2">
                          <div 
                            className="overflow-hidden rounded-xl border border-white/10 cursor-pointer group relative"
                            onClick={() => openGalleryModal(index)}
                          >
                            <img 
                              src={image} 
                              alt={currentLanguage === 'pl' ? 
                                project?.project_gallery[index]?.pl_alt || `${project?.title} - ${index + 1}` : 
                                project?.project_gallery[index]?.en_alt || `${project?.title} - ${index + 1}`}
                              className="w-full aspect-video object-contain group-hover:scale-105 transition-transform duration-500"
                            />
                            <div className="absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                              <div className="rounded-full bg-white/20 backdrop-blur-md p-3 transform scale-90 group-hover:scale-100 transition-transform">
                                <Maximize2 size={24} className="text-white" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </CarouselItem>
                    ))}
                  </CarouselContent>
                  <div className="hidden md:block">
                    <CarouselPrevious className="-left-5 lg:-left-12" />
                    <CarouselNext className="-right-5 lg:-right-12" />
                  </div>
                  <div className="mt-4">
                    <CarouselDots />
                  </div>
                </Carousel>
                
                {project?.url && (
                  <div className="mt-10 text-center">
                    <Button 
                      variant="default"
                      size="lg"
                      iconPosition="left"
                      icon={<Globe size={18} />}
                      onClick={() => window.open(project?.url, '_blank', 'noopener,noreferrer')}
                      className="text-white font-medium"
                    >
                      {currentLanguage === 'pl' ? 'Zobacz projekt online' : 'View project online'}
                    </Button>
                  </div>
                )}
              </motion.div>
            </div>
          </section>
        )}
        
        {/* Video Player - wyświetlany tylko dla projektów typu 'video' */}
        {project?.project_type === 'video' && project?.video_url && (
          <section className="py-20 relative overflow-hidden">
            <div className="container mx-auto px-6">
              <motion.div
                variants={fadeInUp}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                className="max-w-5xl mx-auto"
              >
                <SectionPreheader 
                  text={currentLanguage === 'pl' ? 'Materiał wideo' : 'Video Content'} 
                  variant="primary" 
                  className="mb-8" 
                  align="center"
                />
                
                <h2 className="text-2xl md:text-3xl font-bold text-white mb-10 text-center">
                  {currentLanguage === 'pl' ? 'Prezentacja projektu' : 'Project Showcase'}
                </h2>
                
                <div className="rounded-xl overflow-hidden border border-white/10 aspect-video w-full">
                  <ReactPlayer
                    url={project?.video_url}
                    width="100%"
                    height="100%"
                    controls={true}
                    light={project?.thumbnail_url || false}
                    playing={false}
                    config={{
                      youtube: {
                        playerVars: { 
                          modestbranding: 1,
                          rel: 0
                        }
                      }
                    }}
                  />
                </div>
              </motion.div>
            </div>
          </section>
        )}
        
        {/* Results & Testimonials - połączone w jedną sekcję */}
        {(project?.results && project?.results.length > 0) || (project?.project_testimonials && project?.project_testimonials.length > 0 && 
          (project?.project_testimonials[0].pl_content?.trim() || project?.project_testimonials[0].en_content?.trim())) ? (
          <section className="py-20 relative overflow-hidden">
            {/* Gradient decoration - left side - dostosowany do strony głównej */}
            <div 
              className="absolute left-0 top-1/2 -translate-y-1/2 w-1/2 h-1/2 pointer-events-none"
              style={{
                background: `
                  radial-gradient(
                    ellipse at 20% 50%, 
                    rgba(255, 159, 69, 0.3) 0%,
                    rgba(255, 159, 69, 0.2) 15%,
                    rgba(255, 125, 58, 0.15) 30%,
                    rgba(255, 125, 58, 0.05) 50%,
                    transparent 80%
                  )
                `,
                filter: 'blur(45px)',
              }}
            ></div>
            
            <div className="container mx-auto px-6">
              {project?.results && project?.results.length > 0 && (
                <motion.div
                  variants={fadeInUp}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  className="max-w-3xl mx-auto text-center mb-16"
                >
                  <SectionPreheader 
                    text={currentLanguage === 'pl' ? 'Rezultaty' : 'Results'} 
                    variant="accent" 
                    className="mb-8" 
                    align="center"
                  />
                  
                  <h2 className="text-2xl md:text-3xl font-bold text-white mb-6">
                    {currentLanguage === 'pl' ? 'Efekty projektu' : 'Project Results'}
                  </h2>
                  
                  <p className="text-white/80 text-lg">
                    {project?.results}
                  </p>
                </motion.div>
              )}
              
              {/* Testimonial */}
              {project?.project_testimonials && project?.project_testimonials.length > 0 && 
                (project?.project_testimonials[0].pl_content?.trim() || project?.project_testimonials[0].en_content?.trim()) && (
                <motion.div
                  variants={fadeInUp}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  className="max-w-4xl mx-auto"
                >
                  <div className="p-8 md:p-10 bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl relative">
                    {/* Quotation mark */}
                    <div className="absolute top-6 left-6 text-6xl text-brand-primary/20 font-serif">"</div>
                    
                    <div className="ml-8 relative z-10">
                      <p className="text-white/90 text-lg italic mb-6">
                        {currentLanguage === 'pl' ? project?.project_testimonials[0].pl_content : project?.project_testimonials[0].en_content}
                      </p>
                      
                      {/* Informacja o oryginalnym języku opinii */}
                      <p className="text-white/40 italic text-xs mb-4">
                        {currentLanguage === (project?.project_testimonials[0].original_language || 'pl')
                          ? t('testimonials:originalLanguage')
                          : t('testimonials:translatedFrom')
                        }
                      </p>
                      
                      <div className="flex items-center">
                        <div className="mr-4">
                          {project?.project_testimonials[0].logo_url ? (
                            <div className="w-12 h-12 rounded-full overflow-hidden">
                              <img src={project?.project_testimonials[0].logo_url} alt={project?.project_testimonials[0].company_name} className="w-full h-full object-cover" />
                            </div>
                          ) : (
                            <div className="w-12 h-12 bg-gradient-to-br from-brand-primary to-brand-tertiary rounded-full flex items-center justify-center text-white font-bold">
                              {project?.project_testimonials[0].author_name?.split(' ').map((name: string) => name[0]).join('') || 'A'}
                            </div>
                          )}
                        </div>
                        <div>
                          <div className="font-medium text-white">{project?.project_testimonials[0].author_name}</div>
                          <div className="text-white/70 text-sm">{project?.project_testimonials[0].company_name}</div>
                        </div>
                      </div>
                    </div>
                    
                    {/* Bottom quotation mark */}
                    <div className="absolute bottom-6 right-6 text-6xl text-brand-primary/20 font-serif transform rotate-180">"</div>
                  </div>
                </motion.div>
              )}
            </div>
          </section>
        ) : null}
        
        {/* Similar projects based on tags */}
        {similarProjects && similarProjects.length > 0 && (
          <SimilarProjects 
            projects={similarProjects} 
            currentProjectId={project?.id} 
            currentLanguage={currentLanguage}
          />
        )}
        
        {/* CTA - bez gradientu */}
        <section className="py-16 md:py-20 relative overflow-hidden">
          <div className="container mx-auto px-6">
            <motion.div
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="max-w-3xl mx-auto text-center relative z-10"
            >
              <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-white mb-6">
                {currentLanguage === 'pl' ? 'Chcesz zrealizować podobny projekt?' : 'Want to create a similar project?'}
              </h2>
              
              <p className="text-white/80 text-lg mb-8">
                {currentLanguage === 'pl' 
                  ? 'Skontaktuj się ze mną i opowiedz o swoim pomyśle. Wspólnie zaprojektujemy i wdrożę idealne rozwiązanie dla Twojej firmy.' 
                  : 'Get in touch with me and tell me about your idea. Together, we will design and I will implement the perfect solution for your business.'}
              </p>
              
              <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
                <Button 
                  variant="default" 
                  size="lg"
                  icon={<ArrowRight size={18} />}
                  onClick={() => {
                    // Przekierowanie na stronę główną do sekcji kontaktu
                    const homePath = `/${currentLanguage === 'pl' ? 'pl' : 'en'}`;
                    navigate(`${homePath}?section=contact`);
                  }}
                >
                  {currentLanguage === 'pl' ? 'Porozmawiajmy' : 'Let\'s Talk'}
                </Button>
                <Button 
                  variant="dark" 
                  size="lg"
                  onClick={() => {
                    // Przekierowanie na stronę główną do sekcji portfolio
                    const homePath = `/${currentLanguage === 'pl' ? 'pl' : 'en'}`;
                    navigate(`${homePath}?section=portfolio`);
                  }}
                >
                  {currentLanguage === 'pl' ? 'Zobacz więcej projektów' : 'See More Projects'}
                </Button>
              </div>
            </motion.div>
          </div>
        </section>
      </main>
      <Footer />
      
      {/* Image Gallery Modal */}
      <ImageGalleryModal
        isOpen={galleryModalOpen}
        onClose={() => setGalleryModalOpen(false)}
        images={gallery}
        alts={galleryAlts}
        initialIndex={currentImageIndex}
      />
    </>
  );
};

export default ProjectPage;