import React, { useState, useEffect, useRef } from 'react';
import { Menu as MenuIcon, X as CloseIcon, ChevronRight } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../common/Logo';
import LanguageSwitcher from '../ui/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import { Button } from '../ui/Button';
import { useScrollLock } from '../../hooks/useScrollLock';
import { cn } from '../../lib/utils';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const [hoveredSection, setHoveredSection] = useState<string | null>(null);
  const { t, i18n } = useTranslation(['navbar']);
  const navRef = useRef<HTMLDivElement>(null);
  const [highlightInfo, setHighlightInfo] = useState<{left: number; width: number} | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  const isHomePage = location.pathname === `/${currentLanguage}` || location.pathname === `/${currentLanguage}/`;
  
  // Blokada scrollowania gdy menu jest otwarte
  useScrollLock(isMenuOpen);
  
  // Warianty animacji dla menu mobilnego
  const menuContainerVariants = {
    hidden: { 
      opacity: 0,
      transition: { duration: 0.3 }
    },
    visible: { 
      opacity: 1,
      transition: {
        duration: 0.3,
        staggerChildren: 0.1,
        delayChildren: 0.2
      }
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.3 }
    }
  };
  
  const menuItemVariants = {
    hidden: { 
      opacity: 0,
      x: -20
    },
    visible: (i: number) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.1,
        duration: 0.4,
        ease: [0.22, 1, 0.36, 1]
      }
    }),
    exit: {
      opacity: 0,
      x: -20,
      transition: { duration: 0.3 }
    }
  };

  // Funkcja do płynnego przewijania do sekcji
  const scrollToSection = (id: string) => {
    // Zamykamy menu przed jakąkolwiek akcją
    setIsMenuOpen(false);
    
    // Dodajemy małe opóźnienie przed przewinięciem
    setTimeout(() => {
      if (!isHomePage) {
        // Przekierowujemy na stronę główną z parametrem section w URL
        navigate(`/${currentLanguage}?section=${id}`, { replace: true });
        return;
      }
      
      const element = document.getElementById(id);
      if (element) {
        const navbarHeight = 80;
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({ 
          top: elementPosition - navbarHeight,
          behavior: 'smooth'
        });
      }
    }, 100);
  };

  // Efekt dla obserwacji sekcji i ustawiania aktywnej - tylko na stronie głównej
  useEffect(() => {
    if (!isHomePage) return;
    
    // Opcje dla głównych sekcji - precyzyjny obszar detekcji
    const options = {
      root: null,
      rootMargin: '-80px 0px -45% 0px',
      threshold: [0.15, 0.3, 0.5],
    };

    // Funkcja do wybierania najbardziej widocznej sekcji
    const getMostVisibleSection = (entries: IntersectionObserverEntry[]) => {
      const visibleEntries = entries.filter(entry => entry.isIntersecting);
      if (visibleEntries.length === 0) return null;
      visibleEntries.sort((a, b) => b.intersectionRatio - a.intersectionRatio);
      return visibleEntries[0].target.id;
    };

    // Callback dla głównego obserwatora
    const sectionCallback = (entries: IntersectionObserverEntry[]) => {
      const menuSectionIds = ['services', 'why-us', 'about', 'portfolio', 'testimonials', 'contact'];
      
      // Sprawdź, czy którakolwiek z sekcji jest widoczna
      const visibleMenuSection = getMostVisibleSection(
        entries.filter(entry => menuSectionIds.includes(entry.target.id))
      );
      
      // Jeśli żadna sekcja nie jest widoczna, resetuj aktywną sekcję
      if (!visibleMenuSection) {
        setActiveSection(null);
        return;
      }
      
      // Ustaw nową aktywną sekcję
      setActiveSection(visibleMenuSection);
    };

    // Tworzenie obserwatora
    const observer = new IntersectionObserver(sectionCallback, options);

    // Obserwowanie sekcji
    const menuSections = ['services', 'why-us', 'about', 'portfolio', 'testimonials', 'contact'];
    menuSections.forEach(id => {
      const element = document.getElementById(id);
      if (element) observer.observe(element);
    });

    // Sprzątanie
    return () => {
      menuSections.forEach(id => {
        const element = document.getElementById(id);
        if (element) observer.unobserve(element);
      });
    };
  }, [isHomePage]);

  // Efekt sprawdzający parametr sekcji w URL po załadowaniu strony głównej
  useEffect(() => {
    if (isHomePage) {
      const searchParams = new URLSearchParams(location.search);
      const sectionId = searchParams.get('section');
      
      if (sectionId) {
        // Małe opóźnienie, aby upewnić się, że DOM jest w pełni załadowany
        setTimeout(() => {
          const element = document.getElementById(sectionId);
          if (element) {
            const navbarHeight = 80;
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ 
              top: elementPosition - navbarHeight,
              behavior: 'smooth'
            });
            // Czyścimy parametr z URL, używając history replace
            navigate(`/${currentLanguage}`, { replace: true });
          }
        }, 500);
      }
    }
  }, [isHomePage, location.search, navigate, currentLanguage]);

  // Efekt do obliczania pozycji i szerokości aktywnego lub najechaneego elementu
  useEffect(() => {
    if (navRef.current) {
      const currentSection = hoveredSection || activeSection;
      
      if (currentSection) {
        const activeButton = navRef.current.querySelector(`[data-section="${currentSection}"]`) as HTMLElement;
        
        if (activeButton) {
          const rect = activeButton.getBoundingClientRect();
          const navRect = navRef.current.getBoundingClientRect();
          
          setHighlightInfo({
            left: rect.left - navRect.left,
            width: rect.width
          });
        } else {
          setHighlightInfo(null);
        }
      } else {
        setHighlightInfo(null);
      }
    }
  }, [activeSection, hoveredSection]);

  // Funkcja do przewijania na górę strony
  const scrollToTop = () => {
    setIsMenuOpen(false);
    
    if (!isHomePage) {
      // Przekierowujemy na stronę główną bez parametru section, 
      // co spowoduje przewinięcie do góry
      navigate(`/${currentLanguage}`, { replace: true });
      return;
    }
    
    window.scrollTo({ 
      top: 0, 
      behavior: 'smooth' 
    });
  };
    
  const menuLinks = [
    { href: "services", label: t('services') },
    { href: "why-us", label: t('whyUs') },
    { href: "about", label: t('about') },
    { href: "portfolio", label: t('portfolio') },
    { href: "testimonials", label: t('testimonials') }
  ];

  // SVG paths dla animacji hamburgera/krzyżyka
  const topLineVariants = {
    menu: { d: "M 2 4 L 18 4", transition: { duration: 0.5, ease: [0.76, 0, 0.24, 1] } },
    close: { d: "M 3 16 L 17 4", transition: { duration: 0.5, ease: [0.76, 0, 0.24, 1] } }
  };
  
  const middleLineVariants = {
    menu: { d: "M 2 10 L 18 10", opacity: 1, transition: { duration: 0.5, ease: [0.76, 0, 0.24, 1] } },
    close: { d: "M 10 10 L 10 10", opacity: 0, transition: { duration: 0.5, ease: [0.76, 0, 0.24, 1] } }
  };
  
  const bottomLineVariants = {
    menu: { d: "M 2 16 L 18 16", transition: { duration: 0.5, ease: [0.76, 0, 0.24, 1] } },
    close: { d: "M 3 4 L 17 16", transition: { duration: 0.5, ease: [0.76, 0, 0.24, 1] } }
  };

  return (
    <>
      {/* Pełnoekranowe menu mobilne */}
      <AnimatePresence mode="wait">
        {isMenuOpen && (
          <motion.div
            className="fixed inset-0 z-[50] w-full h-full"
            variants={menuContainerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            {/* Tło z efektem glassmorphism */}
            <motion.div 
              className="absolute inset-0 backdrop-blur-xl bg-surface-dark/40 w-full h-full"
              initial={{ opacity: 0, backdropFilter: "blur(0px)" }}
              animate={{ 
                opacity: 1, 
                backdropFilter: "blur(16px)",
                transition: { duration: 0.7, ease: [0.22, 1, 0.36, 1] }
              }}
              exit={{ 
                opacity: 0, 
                backdropFilter: "blur(0px)",
                transition: { duration: 0.5, ease: [0.22, 1, 0.36, 1] }
              }}
            >
              {/* Efekty świetlne */}
              <div className="absolute inset-0 overflow-hidden pointer-events-none">
                <motion.div 
                  className="absolute top-0 left-0 right-0 h-40 opacity-0"
                  initial={{ opacity: 0 }}
                  animate={{ 
                    opacity: 0.1,
                    transition: { duration: 1.2, ease: "easeOut", delay: 0.3 }
                  }}
                  exit={{ 
                    opacity: 0,
                    transition: { duration: 0.4 }
                  }}
                  style={{
                    background: 'linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0) 100%)',
                    filter: 'blur(20px)'
                  }}
                />
                
                <motion.div 
                  className="absolute top-1/3 -left-20 w-80 h-80 opacity-0"
                  initial={{ opacity: 0, scale: 0.7, x: -50 }}
                  animate={{ 
                    opacity: 0.2, 
                    scale: 1, 
                    x: 0,
                    transition: { duration: 1.5, ease: "easeOut", delay: 0.2 }
                  }}
                  exit={{ 
                    opacity: 0, 
                    scale: 0.8, 
                    x: -30,
                    transition: { duration: 0.5 }
                  }}
                  style={{
                    background: 'radial-gradient(circle, rgba(255,90,31,0.4) 0%, rgba(0,0,0,0) 70%)',
                    filter: 'blur(60px)'
                  }}
                />

                <motion.div 
                  className="absolute bottom-1/3 -right-20 w-80 h-80 opacity-0"
                  initial={{ opacity: 0, scale: 0.7, x: 50 }}
                  animate={{ 
                    opacity: 0.1, 
                    scale: 1, 
                    x: 0,
                    transition: { duration: 1.5, ease: "easeOut", delay: 0.4 }
                  }}
                  exit={{ 
                    opacity: 0, 
                    scale: 0.8,
                    x: 30,
                    transition: { duration: 0.5 }
                  }}
                  style={{
                    background: 'radial-gradient(circle, rgba(100,150,255,0.2) 0%, rgba(0,0,0,0) 70%)',
                    filter: 'blur(60px)'
                  }}
                />
              </div>
            </motion.div>
            
            {/* Zawartość menu */}
            <div className="fixed inset-0 flex items-center justify-center p-4 z-[55]">
              <div className="w-full max-w-sm">
                <motion.div
                  className="flex flex-col items-center space-y-8"
                  variants={menuContainerVariants}
                >
                  <nav className="flex flex-col items-center space-y-10 mb-12 w-full">
                    {menuLinks.map((link, index) => (
                      <motion.button
                        key={link.href}
                        type="button"
                        onClick={() => scrollToSection(link.href)}
                        className="text-2xl font-medium text-text-white hover:text-brand-primary transition-all duration-300 relative cursor-pointer w-full text-center"
                        custom={index}
                        variants={menuItemVariants}
                        whileHover={{ scale: 1.05, x: 5 }}
                        whileTap={{ scale: 0.98 }}
                      >
                        {link.label}
                      </motion.button>
                    ))}
                  </nav>
                  
                  {/* Przycisk CTA */}
                  <motion.div
                    className="relative pt-5 border-t border-white/10 flex justify-center w-40"
                    custom={menuLinks.length}
                    variants={menuItemVariants}
                  >
                    <Button 
                      variant="navbar" 
                      icon={<ChevronRight size={16} />}
                      className="px-6 py-2 mt-5 w-full"
                      onClick={() => scrollToSection('contact')}
                    >
                      {t('cta')}
                    </Button>
                  </motion.div>
                </motion.div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Nagłówek strony z nawigacją */}
      <header className="fixed top-0 left-0 right-0 z-[60] px-4 md:px-8 pt-4">
        <nav ref={navRef} className="backdrop-blur-md bg-surface-dark/40 border border-white/10 rounded-full py-3 px-4 md:px-6 flex justify-between items-center max-w-7xl mx-auto relative">
          {/* Świecąca kreska dla aktywnej sekcji */}
          <AnimatePresence>
            {highlightInfo && (
              <motion.div
                layoutId="navHighlight"
                className="absolute top-0 h-[3px] z-10 overflow-visible"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{ 
                  width: highlightInfo.width,
                  left: highlightInfo.left,
                  background: "linear-gradient(90deg, rgba(255,90,31,0) 0%, #FF5A1F 50%, rgba(255,90,31,0) 100%)",
                }}
                transition={{
                  type: "spring",
                  stiffness: 300,
                  damping: 30,
                }}
              >
                <div 
                  className="absolute -top-[4px] inset-x-0 h-[8px] blur-md"
                  style={{ 
                    background: "linear-gradient(90deg, rgba(255,90,31,0) 0%, rgba(255,90,31,0.7) 50%, rgba(255,90,31,0) 100%)",
                  }}
                />
                <div 
                  className="absolute -top-[2px] inset-x-0 h-[4px] blur-sm"
                  style={{ 
                    background: "linear-gradient(90deg, rgba(255,90,31,0) 0%, rgba(255,90,31,0.9) 50%, rgba(255,90,31,0) 100%)",
                  }}
                />
              </motion.div>
            )}
          </AnimatePresence>
        
          {/* Logo */}
          <div className="flex items-center">
            <button onClick={scrollToTop} className="focus:outline-none">
              <Logo size="md" variant="full" className="md:block hidden" />
              <Logo size="md" variant="icon" className="md:hidden block" />
            </button>
          </div>
          
          {/* Menu nawigacyjne - desktop */}
          <div className="hidden md:flex items-center justify-center space-x-6 absolute left-1/2 transform -translate-x-1/2">
            {menuLinks.map((link) => {
              const isActive = activeSection === link.href;
              const isHovered = hoveredSection === link.href;
              const isHighlighted = isActive || isHovered;
              
              return (
                <div 
                  key={link.href} 
                  className="relative"
                  onMouseEnter={() => setHoveredSection(link.href)}
                  onMouseLeave={() => setHoveredSection(null)}
                >
                  <button 
                    onClick={() => scrollToSection(link.href)} 
                    className={cn(
                      "py-3 px-4 relative z-10 transition-all duration-300 rounded-full",
                      isHighlighted 
                        ? "text-brand-primary font-medium bg-white/[0.06]" 
                        : "text-text-white hover:text-brand-primary"
                    )}
                    data-active={isActive}
                    data-section={link.href}
                  >
                    {link.label}
                  </button>
                </div>
              );
            })}
          </div>

          {/* Prawa sekcja - przełącznik języka i CTA */}
          <div className="hidden md:flex items-center">
            <LanguageSwitcher />
            <div className="mx-4 h-6 w-[1px] bg-white/20 rounded-full shadow-sm shadow-white/5"></div>
            <Button 
              variant="navbar"
              size="sm"
              icon={<ChevronRight size={16} />}
              onClick={() => scrollToSection('contact')}
            >
              {t('cta')}
            </Button>
          </div>

          {/* Menu mobilne i przełącznik języka */}
          <div className="flex items-center space-x-3 md:hidden">
            <LanguageSwitcher />
            <button 
              className={cn(
                "w-10 h-10 relative focus:outline-none rounded-full border transition-all duration-300 flex items-center justify-center group",
                isMenuOpen 
                  ? "!border-brand-primary/50 !text-brand-primary"
                  : "!border-white/10 !text-white hover:!text-brand-primary hover:!border-brand-primary/50"
              )}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              aria-label={isMenuOpen ? "Zamknij menu" : "Otwórz menu"}
              aria-expanded={isMenuOpen}
            >
              {/* Animowana ikona menu/zamykania */}
              <div className="flex items-center justify-center w-5 h-5">
                <svg 
                  width="20" 
                  height="20" 
                  viewBox="0 0 20 20" 
                  fill="none" 
                  xmlns="http://www.w3.org/2000/svg" 
                  stroke="currentColor"
                  strokeWidth="2" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                >
                  <motion.path
                    variants={topLineVariants}
                    initial="menu"
                    animate={isMenuOpen ? "close" : "menu"}
                  />
                  <motion.path
                    variants={middleLineVariants}
                    initial="menu"
                    animate={isMenuOpen ? "close" : "menu"}
                  />
                  <motion.path
                    variants={bottomLineVariants}
                    initial="menu"
                    animate={isMenuOpen ? "close" : "menu"}
                  />
                </svg>
              </div>
            </button>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;