import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import Cookies from 'js-cookie';
import { initializeGA, disableGA } from '../services/analytics';

// Rozszerzony typ dla obiektu window
interface CustomWindow extends Window {
  toggleAnalytics?: (enabled: boolean) => void;
  toggleMarketingPixels?: (enabled: boolean) => void;
  [key: string]: any;
}

declare const window: CustomWindow;

// Types for our consent options
export type ConsentOptions = {
  necessary: boolean;
  analytics: boolean;
  marketing: boolean;
};

// Cookie consent context type
export interface CookieConsentContextType {
  consentGiven: boolean;
  consentOptions: ConsentOptions;
  updateConsentOptions: (options: Partial<ConsentOptions>) => void;
  showConsentBanner: boolean;
  setShowConsentBanner: (show: boolean) => void;
  openCookieSettings: () => void;
  acceptAllCookies: () => void;
  acceptNecessaryCookies: () => void;
  rejectAllCookies: () => void;
  consentModalOpen: boolean;
  setConsentModalOpen: (open: boolean) => void;
}

// Default context values
const defaultContextValue: CookieConsentContextType = {
  consentGiven: false,
  consentOptions: {
    necessary: true, // Always true as these are essential
    analytics: false,
    marketing: false,
  },
  updateConsentOptions: () => {},
  showConsentBanner: true,
  setShowConsentBanner: () => {},
  openCookieSettings: () => {},
  acceptAllCookies: () => {},
  acceptNecessaryCookies: () => {},
  rejectAllCookies: () => {},
  consentModalOpen: false,
  setConsentModalOpen: () => {},
};

// Creating context
const CookieConsentContext = createContext<CookieConsentContextType>(defaultContextValue);

// Custom hook to use the cookie consent context
export const useCookieConsent = () => {
  return useContext(CookieConsentContext);
};

// Cookie consent provider component
interface CookieConsentProviderProps {
  children: ReactNode;
}

export const CookieConsentProvider: React.FC<CookieConsentProviderProps> = ({ children }) => {
  // State for tracking consent status
  const [consentGiven, setConsentGiven] = useState<boolean>(false);
  const [consentOptions, setConsentOptions] = useState<ConsentOptions>(defaultContextValue.consentOptions);
  const [showConsentBanner, setShowConsentBanner] = useState<boolean>(true);
  const [consentModalOpen, setConsentModalOpen] = useState<boolean>(false);

  // Load consent preferences from cookies on mount
  useEffect(() => {
    const storedConsent = Cookies.get('cookie-consent');
    
    if (storedConsent) {
      try {
        const parsedConsent = JSON.parse(storedConsent);
        setConsentOptions(parsedConsent);
        setConsentGiven(true);
        setShowConsentBanner(false);
      } catch (error) {
        console.error('Error parsing consent cookie:', error);
        // If there's an error, we'll show the banner
        setShowConsentBanner(true);
      }
    } else {
      // No consent stored yet, show the banner
      setShowConsentBanner(true);
    }
  }, []);

  // Function to update consent options
  const updateConsentOptions = (options: Partial<ConsentOptions>) => {
    const newOptions = { ...consentOptions, ...options };
    setConsentOptions(newOptions);
    setConsentGiven(true);
    setShowConsentBanner(false);
    
    // Save to cookies (expires in 365 days)
    Cookies.set('cookie-consent', JSON.stringify(newOptions), { expires: 365, sameSite: 'strict' });
    
    // Handle consent for different cookie types
    handleCookieConsent(newOptions);
  };

  // Function to accept all cookies
  const acceptAllCookies = () => {
    const allAccepted = {
      necessary: true,
      analytics: true,
      marketing: true,
    };
    
    updateConsentOptions(allAccepted);
  };

  // Function to accept only necessary cookies
  const acceptNecessaryCookies = () => {
    const necessaryOnly = {
      necessary: true,
      analytics: false,
      marketing: false,
    };
    
    updateConsentOptions(necessaryOnly);
  };

  // Function to reject all non-necessary cookies
  const rejectAllCookies = () => {
    acceptNecessaryCookies(); // Same as acceptNecessaryCookies in this case
  };

  // Function to open cookie settings modal
  const openCookieSettings = () => {
    setConsentModalOpen(true);
  };

  // Function to handle consent for different cookie types
  const handleCookieConsent = (options: ConsentOptions) => {
    // Necessary cookies are always allowed and managed by the app itself

    // For analytics cookies (like Google Analytics)
    if (options.analytics) {
      // Włącz skrypty analityczne
      console.log('Analytics cookies enabled');
      initializeGA();
      // Aktywuj skrypt GA za pomocą funkcji zdefiniowanej w index.html
      if (window.toggleAnalytics) {
        window.toggleAnalytics(true);
      }
    } else {
      // Wyłącz skrypty analityczne i usuń pliki cookie
      console.log('Analytics cookies disabled');
      disableGA();
      // Dezaktywuj skrypt GA za pomocą funkcji zdefiniowanej w index.html
      if (window.toggleAnalytics) {
        window.toggleAnalytics(false);
      }
    }

    // For marketing cookies
    if (options.marketing) {
      // Enable marketing scripts
      // This is a placeholder - in a real implementation, you would load your marketing scripts here
      console.log('Marketing cookies enabled');
      if (window.toggleMarketingPixels) {
        window.toggleMarketingPixels(true);
      }
    } else {
      // Disable marketing cookies
      // In a real implementation, you might need to remove existing cookies
      console.log('Marketing cookies disabled');
      if (window.toggleMarketingPixels) {
        window.toggleMarketingPixels(false);
      }
    }
  };

  // Provide the context value to children
  return (
    <CookieConsentContext.Provider
      value={{
        consentGiven,
        consentOptions,
        updateConsentOptions,
        showConsentBanner,
        setShowConsentBanner,
        openCookieSettings,
        acceptAllCookies,
        acceptNecessaryCookies,
        rejectAllCookies,
        consentModalOpen,
        setConsentModalOpen,
      }}
    >
      {children}
    </CookieConsentContext.Provider>
  );
};