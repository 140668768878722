import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Facebook, Instagram, Linkedin as LinkedIn, Mail, MapPin, Phone, Youtube, Cookie } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../common/Logo';
import { cn } from '../../lib/utils';
import { useCookieConsent } from '../../contexts/CookieConsentContext';
import useScrollToTopNavigation from '../../hooks/useScrollToTopNavigation';

interface SocialButtonProps {
  icon: React.ReactNode;
  href: string;
  label: string;
}

const SocialButton: React.FC<SocialButtonProps> = ({ icon, href, label }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="w-10 h-10 rounded-full backdrop-blur-sm bg-white/[0.07] border border-white/10 hover:bg-white/[0.12] transition-all duration-300 flex items-center justify-center group"
      aria-label={label}
    >
      <div className="text-white/70 group-hover:text-brand-primary transition-colors duration-300">
        {icon}
      </div>
    </a>
  );
};

const Footer = () => {
  const { t, i18n } = useTranslation(['footer', 'cookies']);
  const currentYear = new Date().getFullYear();
  const location = useLocation();
  const navigate = useNavigate();
  const isHomePage = location.pathname === '/';
  const { openCookieSettings } = useCookieConsent();
  const navigateWithScrollReset = useScrollToTopNavigation();
  
  // Get short language code
  const shortLang = i18n.language.slice(0, 2);
  
  // Generate privacy policy link based on language
  const privacyPolicyLink = shortLang === 'pl' ? '/pl/polityka-prywatnosci' : '/en/privacy-policy';
  
  // Funkcja do płynnego przewijania do sekcji (identycznie jak w Navbar)
  const scrollToSection = (id: string) => {
    // Jeśli nie jesteśmy na stronie głównej, najpierw przejdź tam z parametrem section
    if (!isHomePage) {
      navigate(`/${shortLang}?section=${id}`, { replace: true });
      return;
    }
    
    // Jeśli jesteśmy na stronie głównej, przewijamy do elementu
    const element = document.getElementById(id);
    if (element) {
      const navbarHeight = 80;
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ 
        top: elementPosition - navbarHeight, 
        behavior: 'smooth' 
      });
    }
  };
  
  // Funkcja do przewijania do góry strony
  const scrollToTop = () => {
    // Jeśli nie jesteśmy na stronie głównej, przechodzimy na stronę główną
    // (bez parametru section, co spowoduje automatyczne przewinięcie na górę)
    if (!isHomePage) {
      navigate(`/${shortLang}`, { replace: true });
      return;
    }
    
    window.scrollTo({ 
      top: 0, 
      behavior: 'smooth' 
    });
  };
  
  // Animacja dla elementów stopki
  const fadeInUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: [0.22, 1, 0.36, 1]
      }
    }
  };

  return (
    <footer className="relative border-t border-white/10 bg-surface-dark/80 backdrop-blur-md text-text-primary mt-10">
      {/* Efekt gradientu na tle stopki */}
      <div className="absolute inset-0 -z-10 pointer-events-none overflow-hidden">
        <div 
          className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[70vw] h-[60vh]"
          style={{
            background: `
              radial-gradient(
                ellipse at 50% 100%, 
                rgba(255, 90, 31, 0.08) 0%,
                rgba(255, 125, 58, 0.05) 40%, 
                rgba(255, 159, 69, 0.03) 70%,
                transparent 100%
              )
            `,
            filter: 'blur(60px)',
          }}
        />
      </div>
      
      <div className="container mx-auto px-4 sm:px-6 md:px-8 lg:px-10 py-12 md:py-16">
        <div className="grid gap-12 md:grid-cols-2 lg:grid-cols-4">
          {/* Logo i opis firmy */}
          <motion.div
            variants={fadeInUpVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className=""
          >
            <div onClick={scrollToTop} className="cursor-pointer">
              <Logo size="lg" variant="full" className="mb-4" />
            </div>
            <p className="text-sm text-white/70 mb-6 max-w-xs">
              {t('company.description')}
            </p>
          </motion.div>
          
          {/* Szybkie linki */}
          <motion.div
            variants={fadeInUpVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <h3 className="text-lg font-semibold text-white mb-4">
              {t('quickLinks.title')}
            </h3>
            <nav className="space-y-3">
              <button 
                onClick={() => scrollToSection('services')} 
                className="block text-sm text-white/70 hover:text-brand-primary transition-colors duration-300 text-left"
              >
                {t('quickLinks.services')}
              </button>
              <button 
                onClick={() => scrollToSection('why-us')} 
                className="block text-sm text-white/70 hover:text-brand-primary transition-colors duration-300 text-left"
              >
                {t('quickLinks.whyUs')}
              </button>
              <button 
                onClick={() => scrollToSection('about')} 
                className="block text-sm text-white/70 hover:text-brand-primary transition-colors duration-300 text-left"
              >
                {t('quickLinks.about')}
              </button>
              <button 
                onClick={() => scrollToSection('portfolio')} 
                className="block text-sm text-white/70 hover:text-brand-primary transition-colors duration-300 text-left"
              >
                {t('quickLinks.portfolio')}
              </button>
              <button 
                onClick={() => scrollToSection('testimonials')} 
                className="block text-sm text-white/70 hover:text-brand-primary transition-colors duration-300 text-left"
              >
                {t('quickLinks.testimonials')}
              </button>
              <button 
                onClick={() => scrollToSection('contact')} 
                className="block text-sm text-white/70 hover:text-brand-primary transition-colors duration-300 text-left"
              >
                {t('quickLinks.contact')}
              </button>
            </nav>
          </motion.div>
          
          {/* Dane kontaktowe i firmowe */}
          <motion.div
            variants={fadeInUpVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <h3 className="text-lg font-semibold text-white mb-4">
              Kontakt i dane
            </h3>
            <address className="space-y-3 not-italic">
              <div className="flex items-start">
                <MapPin size={16} className="text-brand-primary mt-1 mr-2 flex-shrink-0" />
                <p className="text-sm text-white/70">
                  44-240 Żory
                </p>
              </div>
              <div className="flex items-center">
                <Phone size={16} className="text-brand-primary mr-2 flex-shrink-0" />
                <a 
                  href="tel:+48735444835" 
                  className="text-sm text-white/70 hover:text-brand-primary transition-colors"
                >
                  +48 735 444 835
                </a>
              </div>
              <div className="flex items-center">
                <Mail size={16} className="text-brand-primary mr-2 flex-shrink-0" />
                <a 
                  href="mailto:biuro@digital-media.online" 
                  className="text-sm text-white/70 hover:text-brand-primary transition-colors"
                >
                  biuro@digital-media.online
                </a>
              </div>
              <div className="pt-3 mt-3 border-t border-white/10">
                <p className="text-sm text-white/70">
                  NIP: PL6511739666
                </p>
                <p className="text-sm text-white/70 mt-1">
                  REGON: 520065391
                </p>
              </div>
            </address>
          </motion.div>
          
          {/* Media społecznościowe */}
          <motion.div
            variants={fadeInUpVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="relative"
          >
            <h3 className="text-lg font-semibold text-white mb-4">
              {t('social.title')}
            </h3>
            <p className="text-sm text-white/70 mb-6">
              {t('social.description')}
            </p>
            <div className="flex space-x-3">
              <SocialButton
                icon={<Facebook size={18} />}
                href="https://www.facebook.com/dg.digital.media.online"
                label="Facebook"
              />
              <SocialButton
                icon={<Instagram size={18} />}
                href="https://www.instagram.com/dg_digital_media/"
                label="Instagram"
              />
              <SocialButton
                icon={<Youtube size={18} />}
                href="https://www.youtube.com/@dg-digitalmedia9333"
                label="YouTube"
              />
              <SocialButton
                icon={<LinkedIn size={18} />}
                href="https://www.linkedin.com/company/dg-digital-media"
                label="LinkedIn"
              />
            </div>
            
            {/* Dekoracyjny gradient w tle ikon */}
            <div 
              className="absolute -z-10 right-0 bottom-0 w-32 h-32 opacity-20"
              style={{
                background: 'radial-gradient(circle, rgba(255,90,31,0.3) 0%, transparent 70%)',
                filter: 'blur(20px)'
              }}
            />
          </motion.div>
        </div>
        
        {/* Dolna sekcja z copyrightami i linkami */}
        <div className="mt-12 pt-8 border-t border-white/10 flex flex-col md:flex-row items-center justify-between gap-4">
          <p className="text-xs text-white/50">
            © {currentYear} DG Digital Media. {t('copyright')}
          </p>
          <nav className="flex gap-6 text-xs">
            <span 
              onClick={() => navigateWithScrollReset(privacyPolicyLink)}
              className="text-white/50 hover:text-brand-primary transition-colors duration-300 cursor-pointer"
            >
              {t('legal.privacy')}
            </span>
            <button 
              onClick={openCookieSettings}
              className="text-white/50 hover:text-brand-primary transition-colors duration-300 flex items-center"
            >
              <Cookie size={12} className="mr-1" />
              {t('cookies:footerLink')}
            </button>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;