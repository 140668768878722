import React from 'react';
import { cn } from '../../lib/utils';

export interface RadioOption {
  value: string;
  label: string;
  disabled?: boolean;
}

export interface RadioGroupProps {
  name: string;
  options: RadioOption[];
  value: string;
  onChange: (value: string) => void;
  direction?: 'horizontal' | 'vertical';
  className?: string;
  disabled?: boolean;
  required?: boolean;
  error?: string;
  variant?: 'default' | 'admin';
}

export const RadioGroup: React.FC<RadioGroupProps> = ({
  name,
  options,
  value,
  onChange,
  direction = 'horizontal',
  className,
  disabled = false,
  required = false,
  error,
  variant = 'default'
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  if (variant === 'admin') {
    const totalOptions = options.length;
    
    return (
      <div className={cn('flex', className)}>
        {options.map((option, index) => {
          const isFirst = index === 0;
          const isLast = index === totalOptions - 1;
          const isSelected = value === option.value;
          
          return (
            <div
              key={option.value}
              className={cn(
                "px-3 py-1.5 text-sm font-medium cursor-pointer",
                isFirst && "rounded-l-md",
                isLast && "rounded-r-md",
                isSelected
                  ? option.value === 'draft'
                    ? "bg-yellow-400/20 text-yellow-200"
                    : option.value === 'published'
                      ? "bg-green-400/20 text-green-200"
                      : "bg-brand-primary/20 text-brand-primary"
                  : "bg-white/5 text-white/70 hover:bg-white/10 hover:text-white"
              )}
              onClick={() => !disabled && onChange(option.value)}
            >
              <input
                type="radio"
                id={`${name}-${option.value}`}
                name={name}
                value={option.value}
                checked={value === option.value}
                onChange={handleChange}
                disabled={disabled}
                required={required}
                className="sr-only"
              />
              {option.label}
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div 
      className={cn(
        'flex',
        direction === 'horizontal' ? 'flex-row space-x-4' : 'flex-col space-y-2',
        className
      )}
    >
      {options.map((option) => {
        const id = `${name}-${option.value}`;
        const isDisabled = disabled || option.disabled;
        
        return (
          <label
            key={option.value}
            htmlFor={id}
            className={cn(
              'flex items-center space-x-2 cursor-pointer',
              isDisabled && 'opacity-50 cursor-not-allowed',
              'transition-colors duration-200'
            )}
          >
            <input
              type="radio"
              id={id}
              name={name}
              value={option.value}
              checked={value === option.value}
              onChange={handleChange}
              disabled={isDisabled}
              required={required}
              className="w-4 h-4"
            />
            <span className={cn('text-white/80', value === option.value ? 'text-white' : 'text-white/80')}>
              {option.label}
            </span>
          </label>
        );
      })}
      
      {error && (
        <div className="text-red-500 text-sm mt-1">
          {error}
        </div>
      )}
    </div>
  );
}; 