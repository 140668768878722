import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

interface OpenGraphProps {
  title?: string;
  description?: string;
  imagePath?: string;
  imageAlt?: string;
  type?: 'website' | 'article';
}

/**
 * Komponent dodający metadane Open Graph do strony
 * 
 * Uwaga: Oprócz tego komponentu, podstawowe metadane są również zdefiniowane statycznie
 * w pliku index.html, co pomaga z prerendering na Netlify i podglądem w mediach społecznościowych
 */
const OpenGraph: React.FC<OpenGraphProps> = ({
  title,
  description,
  imagePath,
  imageAlt,
  type = 'website',
}) => {
  const { t, i18n } = useTranslation(['meta']);
  const location = useLocation();
  
  // Pobierz domyślne wartości z plików tłumaczeń, jeśli nie podano
  const metaTitle = title || t('pageTitle.home');
  const metaDescription = description || t('meta.description');
  
  // Konstruuj pełny URL strony
  const baseUrl = 'https://digital-media.online';
  const currentPath = location.pathname;
  const canonicalUrl = `${baseUrl}${currentPath}`;
  
  // Logo jako domyślny obraz (bezpośredni URL do logo na serwerze)
  const defaultImage = 'https://digital-media.online/images/logo-card.png';
  
  // Upewnij się, że ścieżka do obrazka jest prawidłowa
  const imageUrl = imagePath ? 
    (imagePath.startsWith('http') ? imagePath : `${baseUrl}${imagePath.startsWith('/') ? '' : '/'}${imagePath}`) 
    : defaultImage;
  
  // Dodaj komentarz do konsoli, aby pomóc w debugowaniu
  useEffect(() => {
    console.log('OpenGraph component rendered with:', {
      url: canonicalUrl,
      title: metaTitle,
      description: metaDescription,
      image: imageUrl,
      lang: i18n.language
    });
  }, [canonicalUrl, metaTitle, metaDescription, imageUrl, i18n.language]);
  
  return (
    <Helmet>
      {/* Open Graph / Facebook */}
      <meta property="og:type" content={type} data-react-helmet="true" />
      <meta property="og:url" content={canonicalUrl} data-react-helmet="true" />
      <meta property="og:title" content={metaTitle} data-react-helmet="true" />
      <meta property="og:description" content={metaDescription} data-react-helmet="true" />
      <meta property="og:image" content={imageUrl} data-react-helmet="true" />
      <meta property="og:image:width" content="1200" data-react-helmet="true" />
      <meta property="og:image:height" content="630" data-react-helmet="true" />
      <meta property="og:locale" content={i18n.language === 'pl' ? 'pl_PL' : 'en_US'} data-react-helmet="true" />
      <meta property="og:site_name" content="DG - Digital Media" data-react-helmet="true" />
      
      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />
      <meta name="twitter:title" content={metaTitle} data-react-helmet="true" />
      <meta name="twitter:description" content={metaDescription} data-react-helmet="true" />
      <meta name="twitter:image" content={imageUrl} data-react-helmet="true" />
      {imageAlt && <meta name="twitter:image:alt" content={imageAlt} data-react-helmet="true" />}
      
      {/* Canonical URL */}
      <link rel="canonical" href={canonicalUrl} data-react-helmet="true" />
      
      {/* Podstawowe meta tagi */}
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} data-react-helmet="true" />
    </Helmet>
  );
};

export default OpenGraph; 