import React, { useState, useEffect } from 'react';
import { InfiniteSlider } from '../ui/InfiniteSlider';
import { supabase } from '../../lib/supabase/client';
import { useTranslation } from 'react-i18next';

// Typy projektu dla showcase'u
interface ShowcaseProject {
  id: string;
  category: string;
  imageUrl: string;
  tags: string[];
}

// Komponent karty projektu dla showcase'u (nieklikalna wersja)
const ShowcaseProjectCard: React.FC<ShowcaseProject> = ({ 
  imageUrl, 
  category,
  tags
}) => {
  return (
    <div className="relative overflow-hidden rounded-xl">
      {/* Obraz projektu */}
      <div className="w-56 h-72 sm:w-64 sm:h-80 overflow-hidden">
        <img 
          src={imageUrl} 
          alt={category} 
          className="w-full h-full object-cover"
        />
      </div>
      
      {/* Nakładka z gradientem */}
      <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/40 to-transparent opacity-70" />
      
      {/* Zawartość tekstowa - na dole */}
      <div className="absolute bottom-0 left-0 right-0 p-4 text-white">
        <p className="font-medium text-xs text-white/90 uppercase tracking-wider mb-2">{category}</p>
        
        {/* Tagi projektu */}
        <div className="flex flex-wrap gap-1">
          {tags.map((tag, index) => (
            <span 
              key={index}
              className="text-[10px] py-0.5 px-1.5 rounded-full bg-white/20 text-white/80"
            >
              #{tag}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

const ProjectShowcase = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [projects, setProjects] = useState<ShowcaseProject[]>([]);
  const [loading, setLoading] = useState(true);
  
  // Funkcja do losowego mieszania tablicy projektów
  const shuffleArray = (array: ShowcaseProject[]) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  // Pobieranie projektów z bazy danych
  useEffect(() => {
    const fetchProjects = async () => {
      setLoading(true);
      
      try {
        // Pobierz projekty z ich tłumaczeniami w odpowiednim języku
        let query = supabase
          .from('projects')
          .select(`
            id,
            slug,
            project_type,
            thumbnail_url,
            thumbnail_alt_pl,
            thumbnail_alt_en,
            project_translations!inner (
              id,
              title,
              language
            ),
            project_tags (
              id,
              tags:tag_id (
                id,
                pl_name,
                en_name,
                slug
              )
            )
          `)
          .eq('project_translations.language', currentLanguage)
          .eq('published', true);
          
        const { data, error } = await query;
        
        if (error) throw error;
        
        if (data) {
          // Przetwarzamy dane do formatu ShowcaseProject
          const showcaseProjects = data.map((project) => {
            // Mapowanie typów projektów na kategorie do wyświetlenia
            const categoryMap: Record<string, { pl: string; en: string }> = {
              'web': { pl: 'Strona WWW', en: 'Website' },
              'social': { pl: 'Social Media', en: 'Social Media' },
              'video': { pl: 'Wideo', en: 'Video' }
            };
            
            // Przetwarzanie tagów projektu
            const tagNames = project.project_tags 
              ? project.project_tags.map((pt: any) => 
                  currentLanguage === 'pl' ? pt.tags.pl_name : pt.tags.en_name
                ) 
              : [];
            
            const category = categoryMap[project.project_type]?.[currentLanguage as 'pl' | 'en'] || project.project_type;
            
            return {
              id: project.id,
              category: category,
              imageUrl: project.thumbnail_url,
              tags: tagNames
            };
          });
          
          // Losowo mieszamy projekty przed zapisaniem do stanu
          const shuffledProjects = shuffleArray([...showcaseProjects]);
          setProjects(shuffledProjects);
        }
      } catch (err) {
        console.error("Błąd pobierania projektów dla showcase:", err);
      } finally {
        setLoading(false);
      }
    };
    
    fetchProjects();
  }, [currentLanguage]);
  
  // Podziel projekty na dwie grupy dla dwóch sliderów
  const projectsGroup1 = projects.slice(0, Math.ceil(projects.length / 2));
  const projectsGroup2 = projects.slice(Math.ceil(projects.length / 2));

  return (
    <section id="project-showcase" className="w-full overflow-hidden py-16 sm:py-20">
      {loading ? (
        // Loading state
        <div className="w-full mx-auto flex items-center justify-center py-10">
          <div className="animate-pulse text-white/50">
            {currentLanguage === 'pl' ? 'Ładowanie projektów...' : 'Loading projects...'}
          </div>
        </div>
      ) : projects.length > 0 ? (
        // Wyświetlanie sliderów gdy są projekty
        <div className="w-full mx-auto space-y-6">
          {/* Pierwszy slider - w prawo */}
          <div className="relative">
            {/* Lewa ciemna nakładka/gradient dla lepszego efektu nieskończoności */}
            <div className="absolute left-0 top-0 bottom-0 w-16 md:w-24 z-10 bg-gradient-to-r from-[#181818] to-transparent pointer-events-none"></div>
            
            {/* Prawa ciemna nakładka/gradient dla lepszego efektu nieskończoności */}
            <div className="absolute right-0 top-0 bottom-0 w-16 md:w-24 z-10 bg-gradient-to-l from-[#181818] to-transparent pointer-events-none"></div>
            
            {/* Slider poruszający się w prawo */}
            <InfiniteSlider 
              gap={16} 
              duration={35} 
              durationOnHover={70}
              className="py-4"
              reverse={false}
            >
              {projectsGroup1.map(project => (
                <ShowcaseProjectCard 
                  key={project.id}
                  id={project.id}
                  imageUrl={project.imageUrl}
                  category={project.category}
                  tags={project.tags}
                />
              ))}
            </InfiniteSlider>
          </div>
          
          {/* Drugi slider - w lewo */}
          {projectsGroup2.length > 0 && (
            <div className="relative">
              {/* Lewa ciemna nakładka/gradient dla lepszego efektu nieskończoności */}
              <div className="absolute left-0 top-0 bottom-0 w-16 md:w-24 z-10 bg-gradient-to-r from-[#181818] to-transparent pointer-events-none"></div>
              
              {/* Prawa ciemna nakładka/gradient dla lepszego efektu nieskończoności */}
              <div className="absolute right-0 top-0 bottom-0 w-16 md:w-24 z-10 bg-gradient-to-l from-[#181818] to-transparent pointer-events-none"></div>
              
              {/* Slider poruszający się w lewo */}
              <InfiniteSlider 
                gap={16} 
                duration={40} 
                durationOnHover={80}
                className="py-4"
                reverse={true}
              >
                {projectsGroup2.map(project => (
                  <ShowcaseProjectCard 
                    key={project.id}
                    id={project.id}
                    imageUrl={project.imageUrl}
                    category={project.category}
                    tags={project.tags}
                  />
                ))}
              </InfiniteSlider>
            </div>
          )}
        </div>
      ) : (
        // Wyświetlanie gdy nie ma projektów
        <div className="w-full mx-auto flex items-center justify-center py-10">
          <div className="text-white/50">
            {currentLanguage === 'pl' ? 'Brak projektów do wyświetlenia' : 'No projects to display'}
          </div>
        </div>
      )}
    </section>
  );
};

export default ProjectShowcase;