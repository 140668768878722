import React from 'react';
import { motion } from 'framer-motion';
import { 
  Sparkles, 
  Layers,
  Users,
  Target,
} from 'lucide-react';
import { useTranslation } from 'react-i18next';
import SectionPreheader from '../common/SectionPreheader';
import { IconContainer } from '../ui/IconContainer';

interface ServiceCardProps {
  title: string;
  description: string;
  icon: React.ReactNode;
  color: string;
  index: number;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ 
  title, 
  description, 
  icon, 
  color,
  index 
}) => {
  // Animacja wejścia dla kart
  const cardVariants = {
    hidden: { 
      opacity: 0,
      y: 40
    },
    visible: (i: number) => ({ 
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        bounce: 0.3,
        duration: 0.8,
        delay: i * 0.2
      }
    })
  };

  // Animacja dla elementów wewnątrz karty
  const itemVariants = {
    hidden: { opacity: 0, x: -10 },
    visible: (i: number) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.3 + i * 0.1,
        duration: 0.5
      }
    })
  };

  return (
    <motion.div
      className="w-full h-full"
      variants={cardVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.2 }}
      custom={index}
    >
      <div
        className="group relative p-6 sm:p-8 rounded-xl overflow-hidden transition-all duration-300 h-full flex flex-col backdrop-blur-md bg-white/[0.05] border border-white/10 hover:shadow-[0_8px_30px_rgba(0,0,0,0.12)] hover:-translate-y-1 hover:bg-white/[0.08]"
      >
        {/* Efekt tła z kropkami - pojawia się po najechaniu */}
        <div
          className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
        >
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(255,255,255,0.03)_1px,transparent_1px)] bg-[length:4px_4px]" />
        </div>

        {/* Delikatny gradient na obramowaniu - enhanced glass effect */}
        <div
          className="absolute inset-0 -z-10 rounded-xl p-px bg-gradient-to-br from-transparent via-white/15 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"
        />

        <div className="relative flex flex-col h-full">
          <div className="flex items-center justify-between mb-4">
            {/* Ikona używająca komponentu IconContainer */}
            <IconContainer color={color}>
              {icon}
            </IconContainer>
          </div>

          <div className="space-y-3 mb-6">
            {/* Tytuł */}
            <h3 className="font-medium text-white text-xl tracking-tight group-hover:text-white/100 transition-colors duration-300">
              {title}
            </h3>
            
            {/* Opis */}
            <p className="text-sm text-white/70 leading-relaxed group-hover:text-white/80 transition-colors duration-300">
              {description}
            </p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

// Główny komponent WhyUs
const WhyUs = () => {
  const { t } = useTranslation(['whyus']);
  
  // Kolory dla kart
  const colors = [
    'rgba(255, 90, 31, 1)',    // Brand primary
    'rgba(255, 125, 58, 1)',   // Brand tertiary
    'rgba(255, 159, 69, 1)',   // Brand accent
    'rgba(232, 72, 13, 1)'     // Brand secondary
  ];

  // Ikony dla kart
  const cardIcons = [
    <Sparkles size={24} className="text-brand-primary" />,
    <Layers size={24} className="text-brand-primary" />,
    <Users size={24} className="text-brand-primary" />,
    <Target size={24} className="text-brand-primary" />
  ];

  // Pobieranie danych z tłumaczeń
  const stats = t('stats', { returnObjects: true }) as Array<{ value: string; label: string }>;
  const technologies = t('technologies', { returnObjects: true }) as Array<{ title: string; description: string }>;

  // Animacja dla sekcji
  const sectionVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <section id="why-us" className="w-full relative py-24 sm:py-28 overflow-hidden">
      {/* Efekt gradientu z lewej strony - komplementarny do gradientu w sekcji usług */}
      <div className="absolute left-0 top-0 bottom-0 pointer-events-none">
        <div 
          className="absolute left-0 top-1/2 transform -translate-y-1/2 w-[60vw] h-[70vh]"
          style={{
            background: `
              radial-gradient(
                ellipse at 0% 50%, 
                rgba(255, 159, 69, 0.5) 0%,
                rgba(255, 159, 69, 0.35) 15%,
                rgba(255, 125, 58, 0.25) 30%, 
                rgba(255, 90, 31, 0.15) 50%,
                rgba(232, 72, 13, 0.08) 70%,
                transparent 90%
              )
            `,
            filter: 'blur(70px)',
          }}
        />
      </div>

      <div className="container mx-auto px-6 sm:px-8 md:px-10 lg:px-12 relative z-10">
        <div className="max-w-3xl mx-auto text-center mb-16">
          {/* Preheader */}
          <SectionPreheader 
            text={t('preheader')} 
            variant="accent" 
            className="mb-8" 
          />
          
          <h2 className="text-3xl md:text-4xl font-bold mb-6 text-white">
            {t('heading')}
          </h2>
          
          <p className="text-gray-300 text-lg mb-12">
            {t('subheading')}
          </p>
          
          {/* Statystyki */}
          <div className="grid grid-cols-3 gap-8 max-w-xl mx-auto mb-16">
            {stats.map((stat, index) => (
              <motion.div
                key={index}
                variants={itemVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                className="text-center"
              >
                <div className="text-3xl md:text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-brand-primary via-brand-tertiary to-brand-accent mb-2">
                  {stat.value}
                </div>
                <div className="text-sm text-white/70">
                  {stat.label}
                </div>
              </motion.div>
            ))}
          </div>
          
          {/* Nowy pojedynczy nagłówek dla połączonych sekcji */}
          <h3 className="text-2xl text-center font-semibold text-white mb-10">
            {t('solutionsAndIndustriesHeading')}
          </h3>
        </div>
        
        {/* Siatka kart */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-5xl mx-auto">
          {technologies.map((tech, index) => (
            <ServiceCard
              key={index}
              title={tech.title}
              description={tech.description}
              icon={cardIcons[index]}
              color={colors[index]}
              index={index}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyUs;