import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { supabase } from '../../lib/supabase/client';
import { cn } from '../../lib/utils';
import { Button } from '../../components/ui/Button';
import { Save, Loader2, AlertTriangle, X, Plus, Trash, Upload, Image as ImageIcon, Code } from 'lucide-react';
import { RadioGroup } from '../../components/ui/RadioGroup';

// Interfejsy dla tagów i technologii
interface Tag {
  id: string;
  pl_name: string;
  en_name: string;
}

interface Technology {
  id: string;
  name: string;
  icon_name: string;
}

// Interfejs dla danych formularza
interface WebProjectFormData {
  // Podstawowe informacje
  title: string;
  title_en: string;
  pl_slug: string;
  en_slug: string;
  client: string;
  timeFrame: string;
  projectUrl?: string;
  thumbnailUrl: string;
  thumbnailAltPl: string;
  thumbnailAltEn: string;
  published: boolean;

  // Tagi i technologie
  tags: string[];
  technologies: string[];

  // Treść
  descriptionPl: string;
  descriptionEn: string;
  overviewPl: string;
  overviewEn: string;
  challengePl: string;
  challengeEn: string;
  approachPl: string;
  approachEn: string;
  resultsPl: string;
  resultsEn: string;

  // Funkcjonalności
  features: Array<{
    titlePl: string;
    titleEn: string;
    descriptionPl: string;
    descriptionEn: string;
  }>;

  // Galeria
  gallery: Array<{
    imageUrl: string;
    altPl: string;
    altEn: string;
  }>;

  // Opinie
  testimonials: Array<{
    companyName: string;
    authorName: string;
    contentPl: string;
    contentEn: string;
    logoUrl?: string;
    originalLanguage: string;
  }>;
}

interface WebProjectFormProps {
  onSubmit: (data: WebProjectFormData) => void;
  initialData?: Partial<WebProjectFormData>;
  isLoading?: boolean;
}

const WebProjectForm: React.FC<WebProjectFormProps> = ({
  onSubmit,
  initialData,
  isLoading = false
}) => {
  const [activeTab, setActiveTab] = useState('basic');
  const [galleryItems, setGalleryItems] = useState<Array<{imageUrl: string; altPl: string; altEn: string}>>(
    initialData?.gallery || [{ imageUrl: '', altPl: '', altEn: '' }]
  );
  const [error, setError] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  const { register, handleSubmit, control, setValue, formState: { errors }, watch, trigger } = useForm<WebProjectFormData>({
    defaultValues: {
      ...initialData,
      tags: initialData?.tags || [],
      technologies: initialData?.technologies || [],
      published: initialData?.published || false,
      features: initialData?.features || [],
      testimonials: initialData?.testimonials || [],
      thumbnailUrl: initialData?.thumbnailUrl || '',
      title_en: initialData?.title_en || '',
    },
    mode: 'onSubmit'
  });

  // Inicjalizacja pustych tablic funkcjonalności jeśli są puste
  useEffect(() => {
    const features = watch('features');
    
    if (!features || features.length === 0) {
      setValue('features', [{ titlePl: '', titleEn: '', descriptionPl: '', descriptionEn: '' }]);
    }
    
    // Inicjalizacja galerii jest teraz poza React Hook Form
    if (galleryItems.length === 0) {
      setGalleryItems([{ imageUrl: '', altPl: '', altEn: '' }]);
    }
    
    // Inicjalizacja testimonials z domyślną wartością originalLanguage
    const testimonials = watch('testimonials');
    if (testimonials && testimonials.length > 0) {
      const updatedTestimonials = testimonials.map(testimonial => ({
        ...testimonial,
        originalLanguage: testimonial.originalLanguage || 'pl' // Dodajemy domyślną wartość, jeśli nie jest ustawiona
      }));
      setValue('testimonials', updatedTestimonials);
    } else if (!testimonials || testimonials.length === 0) {
      // Jeśli nie ma żadnych testimonials, dodajemy pusty z domyślnymi wartościami
      // Użyj wartości client jeśli została przekazana jako nazwa firmy
      const clientName = watch('client') || '';
      setValue('testimonials', [{
        companyName: clientName,
        authorName: '',
        contentPl: '',
        contentEn: '',
        logoUrl: '',
        originalLanguage: 'pl'
      }]);
    }
  }, [setValue, watch, galleryItems.length]);

  // Własny handler do obsługi submit, łączący dane z formularza i stan galerii
  const handleFormSubmit = (data: any) => {
    // Pobierz nazwę firmy klienta z testimonials, jeśli istnieje
    const clientName = data.testimonials && data.testimonials.length > 0 
      ? data.testimonials[0].companyName 
      : '';
    
    // Łączymy dane z formularza i osobny stan galerii
    const completeData = {
      ...data,
      client: clientName, // Ustaw wartość client na podstawie testimonials.companyName
      gallery: galleryItems
    };
    
    // Przekazujemy kompletne dane do nadrzędnego handlera onSubmit
    onSubmit(completeData);
  };

  // Oddzielne handlery dla dodawania elementów
  const addFeature = () => {
    const currentFeatures = watch('features') || [];
    setValue('features', [...currentFeatures, { titlePl: '', titleEn: '', descriptionPl: '', descriptionEn: '' }]);
  };

  const addGalleryItem = () => {
    setGalleryItems([...galleryItems, { imageUrl: '', altPl: '', altEn: '' }]);
  };

  const removeFeature = (index: number) => {
    const currentFeatures = watch('features') || [];
    currentFeatures.splice(index, 1);
    setValue('features', [...currentFeatures]);
  };

  const removeGalleryItem = (index: number) => {
    const newGallery = [...galleryItems];
    newGallery.splice(index, 1);
    setGalleryItems(newGallery);
  };

  // Funkcje do aktualizacji pól funkcjonalności
  const updateFeatureField = (index: number, field: string, value: string) => {
    const currentFeatures = [...(watch('features') || [])];
    if (!currentFeatures[index]) {
      currentFeatures[index] = { titlePl: '', titleEn: '', descriptionPl: '', descriptionEn: '' };
    }
    currentFeatures[index] = { ...currentFeatures[index], [field]: value };
    setValue('features', currentFeatures);
  };

  // Funkcje do aktualizacji pól galerii
  const updateGalleryField = (index: number, field: string, value: string) => {
    const newGallery = [...galleryItems];
    if (!newGallery[index]) {
      newGallery[index] = { imageUrl: '', altPl: '', altEn: '' };
    }
    newGallery[index] = { ...newGallery[index], [field]: value };
    setGalleryItems(newGallery);
  };

  // Funkcja do aktualizacji URL obrazu w galerii
  const updateGalleryImageUrl = (index: number, imageUrl: string) => {
    const newGallery = [...galleryItems];
    if (!newGallery[index]) {
      newGallery[index] = { imageUrl: '', altPl: '', altEn: '' };
    }
    newGallery[index].imageUrl = imageUrl;
    setGalleryItems(newGallery);
  };

  // Stan dla tagów i technologii
  const [tags, setTags] = useState<Tag[]>([]);
  const [technologies, setTechnologies] = useState<Technology[]>([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loadError, setLoadError] = useState<string | null>(null);
  const [isDraft, setIsDraft] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>(initialData?.tags || []);
  const [selectedTechnologies, setSelectedTechnologies] = useState<string[]>(initialData?.technologies || []);

  // Pobierz tagi i technologie
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Pobierz tagi
        const { data: tagsData, error: tagsError } = await supabase
          .from('tags')
          .select('*')
          .order('pl_name');
        
        if (tagsError) throw tagsError;
        
        // Pobierz technologie
        const { data: techData, error: techError } = await supabase
          .from('technologies')
          .select('*')
          .order('sort_order');
        
        if (techError) throw techError;
        
        setTags(tagsData || []);
        setTechnologies(techData || []);
      } catch (error: any) {
        console.error('Błąd pobierania danych:', error);
        setLoadError('Wystąpił błąd podczas pobierania danych. Spróbuj ponownie.');
      } finally {
        setLoadingData(false);
      }
    };
    
    fetchData();
  }, []);

  // Lista zakładek
  const tabs = [
    { id: 'basic', label: 'Podstawowe informacje' },
    { id: 'content', label: 'Treść' },
    { id: 'features', label: 'Funkcjonalności' },
    { id: 'gallery', label: 'Galeria' },
    { id: 'technologies', label: 'Technologie' },
    { id: 'testimonials', label: 'Klient' }
  ];

  // Renderowanie aktywnej zakładki
  const renderActiveTab = () => {
    switch (activeTab) {
      case 'basic':
        return (
          <div className="space-y-6">
            {/* Tytuł PL */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Tytuł projektu (PL)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <input
                type="text"
                {...register('title', { required: 'Tytuł w języku polskim jest wymagany' })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                placeholder="Nazwa projektu po polsku"
              />
              {errors.title && (
                <p className="mt-2 text-sm text-red-400">{errors.title.message}</p>
              )}
            </div>

            {/* Tytuł EN */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Tytuł projektu (EN)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <input
                type="text"
                {...register('title_en', { required: 'Tytuł w języku angielskim jest wymagany' })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                placeholder="Nazwa projektu po angielsku"
              />
              {errors.title_en && (
                <p className="mt-2 text-sm text-red-400">{errors.title_en.message}</p>
              )}
            </div>

            {/* Slug PL */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Slug (URL PL)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <input
                type="text"
                {...register('pl_slug', { 
                  required: 'Polski slug jest wymagany',
                  pattern: {
                    value: /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
                    message: 'Slug może zawierać tylko małe litery, cyfry i myślniki'
                  }
                })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                placeholder="nazwa-projektu-pl"
              />
              {errors.pl_slug && (
                <p className="mt-2 text-sm text-red-400">{errors.pl_slug.message}</p>
              )}
              <p className="mt-1 text-xs text-white/50">
                Ten URL będzie używany w polskiej wersji strony
              </p>
            </div>
            
            {/* Slug EN */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Slug (URL EN)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <input
                type="text"
                {...register('en_slug', { 
                  required: 'Angielski slug jest wymagany',
                  pattern: {
                    value: /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
                    message: 'Slug może zawierać tylko małe litery, cyfry i myślniki'
                  }
                })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                placeholder="project-name-en"
              />
              {errors.en_slug && (
                <p className="mt-2 text-sm text-red-400">{errors.en_slug.message}</p>
              )}
              <p className="mt-1 text-xs text-white/50">
                Ten URL będzie używany w angielskiej wersji strony
              </p>
            </div>

            {/* Czas realizacji (w dniach) */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Czas realizacji (w dniach)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <input
                type="number"
                min="1"
                {...register('timeFrame', { 
                  required: 'Czas realizacji jest wymagany',
                  valueAsNumber: true,
                  validate: {
                    positive: (value) => value > 0 || 'Wartość musi być większa od zera'
                  } 
                })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                placeholder="np. 30"
              />
              <p className="mt-1 text-xs text-white/50">
                Podaj liczbę dni potrzebnych na realizację projektu
              </p>
              {errors.timeFrame && (
                <p className="mt-2 text-sm text-red-400">{errors.timeFrame.message}</p>
              )}
            </div>

            {/* URL projektu */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                URL projektu (opcjonalnie)
              </label>
              <input
                type="url"
                {...register('projectUrl')}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                placeholder="https://example.com"
              />
              <p className="mt-1 text-xs text-white/50">
                Link do strony internetowej projektu (jeśli jest dostępna)
              </p>
              {errors.projectUrl && (
                <p className="mt-2 text-sm text-red-400">{errors.projectUrl.message}</p>
              )}
            </div>

            {/* Miniatura */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Miniatura projektu
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <div className="space-y-4">
                {watch('thumbnailUrl') && (
                  <div className="relative w-full aspect-video rounded-lg overflow-hidden border border-white/10">
                    <img 
                      src={watch('thumbnailUrl')} 
                      alt="Podgląd miniatury"
                      className="w-full h-full object-cover"
                    />
                    <button
                      type="button"
                      onClick={() => setValue('thumbnailUrl', '')}
                      className="absolute top-2 right-2 p-1.5 rounded-md bg-black/50 text-white/70 hover:bg-red-500/50 hover:text-white transition-colors"
                    >
                      <Trash size={16} />
                    </button>
                  </div>
                )}
                
                <div className="relative">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={async (e) => {
                      const file = e.target.files?.[0];

                      if (!file) return;

                      if (file.size > 2 * 1024 * 1024) {
                        alert('Plik jest za duży! Maksymalny rozmiar: 2MB');
                        return;
                      }

                      try {
                        console.log("Przesyłanie pliku:", file.name);

                        const fileExt = file.name.split('.').pop();
                        const fileName = `${Math.random().toString(36).substring(2)}.${fileExt}`;
                        const filePath = `thumbnails/${fileName}`;

                        const { data, error } = await supabase.storage
                          .from('projects')
                          .upload(filePath, file);

                        if (error) {
                          console.error('Błąd przesyłania pliku:', error);
                          alert(`Błąd: ${error.message || 'Nieznany błąd'}`);
                          return;
                        }

                        console.log("Plik przesłany:", data);

                        const { data: urlData, error: urlError } = await supabase.storage
                          .from('projects')
                          .getPublicUrl(filePath);

                        if (urlError || !urlData.publicUrl) {
                          console.error("Błąd pobierania URL pliku:", urlError);
                          alert("Nie udało się pobrać URL pliku.");
                          return;
                        }

                        console.log("Publiczny URL pliku:", urlData.publicUrl);

                        // Sprawdzamy, czy `setValue` istnieje, zanim go użyjemy
                        if (typeof setValue === "function") {
                          console.log("✅ setValue JEST dostępne!");
                          setValue('thumbnailUrl', urlData.publicUrl);
                        } else {
                          console.error("❌ setValue NIE ISTNIEJE!");
                        }

                      } catch (err) {
                        console.error("Nieoczekiwany błąd:", err);
                        alert(`Nieoczekiwany błąd: ${err.message}`);
                      }
                    }}

                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                  />
                  <div className="w-full px-4 py-8 rounded-lg bg-white/5 border border-white/10 border-dashed flex flex-col items-center justify-center gap-2 text-white/70 hover:bg-white/8 hover:border-brand-primary/30 transition-all cursor-pointer">
                    <div className="p-3 rounded-full bg-white/5">
                      {watch('thumbnailUrl') ? (
                        <ImageIcon size={24} />
                      ) : (
                        <Upload size={24} />
                      )}
                    </div>
                    <div className="text-sm text-center">
                      {watch('thumbnailUrl') 
                        ? 'Kliknij aby zmienić miniaturę' 
                        : 'Kliknij aby dodać miniaturę lub upuść plik tutaj'}
                    </div>
                    <div className="text-xs text-white/50">
                      PNG, JPG lub WEBP (max. 2MB)
                    </div>
                  </div>
                </div>
              </div>
              {errors.thumbnailUrl && (
                <p className="mt-2 text-sm text-red-400">{errors.thumbnailUrl.message}</p>
              )}
            </div>

            {/* Tekst alternatywny miniatury (PL) */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Tekst alternatywny miniatury (PL)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <input
                type="text"
                {...register('thumbnailAltPl', { required: 'Tekst alternatywny (PL) jest wymagany' })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                placeholder="Opis miniatury po polsku"
              />
              {errors.thumbnailAltPl && (
                <p className="mt-2 text-sm text-red-400">{errors.thumbnailAltPl.message}</p>
              )}
            </div>

            {/* Tekst alternatywny miniatury (EN) */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Tekst alternatywny miniatury (EN)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <input
                type="text"
                {...register('thumbnailAltEn', { required: 'Tekst alternatywny (EN) jest wymagany' })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                placeholder="Opis miniatury po angielsku"
              />
              {errors.thumbnailAltEn && (
                <p className="mt-2 text-sm text-red-400">{errors.thumbnailAltEn.message}</p>
              )}
            </div>

            {/* Tagi */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Tagi projektu
              </label>
              {loadingData ? (
                <div className="flex items-center justify-center py-4">
                  <Loader2 size={20} className="animate-spin text-brand-primary" />
                </div>
              ) : loadError ? (
                <div className="p-4 bg-red-950/30 border border-red-800/50 rounded-lg text-red-200">
                  <AlertTriangle size={18} className="mb-2" />
                  <p>{loadError}</p>
                </div>
              ) : (
                <Controller
                  name="tags"
                  control={control}
                  render={({ field }) => (
                    <div className="flex flex-wrap gap-2">
                      {tags.map((tag) => (
                        <button
                          key={tag.id}
                          type="button"
                          onClick={() => {
                            const isSelected = field.value.includes(tag.id);
                            const newValue = isSelected
                              ? field.value.filter(id => id !== tag.id)
                              : [...field.value, tag.id];
                            field.onChange(
                              newValue
                            );
                            setSelectedTags(newValue);
                          }}
                          className={cn(
                            "px-3 py-1.5 rounded-full text-sm transition-all duration-300",
                            field.value.includes(tag.id)
                              ? "bg-brand-primary/20 text-brand-primary border border-brand-primary/30"
                              : "bg-white/5 text-white/70 border border-white/10 hover:bg-white/10"
                          )}
                        >
                          {tag.pl_name}
                        </button>
                      ))}
                    </div>
                  )}
                />
              )}
            </div>
          </div>
        );

      case 'content':
        return (
          <div className="space-y-6">
            {/* Opis PL */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Opis (PL)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <textarea
                {...register('descriptionPl', { required: 'Opis w języku polskim jest wymagany' })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                rows={3}
                placeholder="Krótki opis projektu po polsku"
              />
              {errors.descriptionPl && (
                <p className="mt-2 text-sm text-red-400">{errors.descriptionPl.message}</p>
              )}
            </div>

            {/* Opis EN */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Opis (EN)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <textarea
                {...register('descriptionEn', { required: 'Opis w języku angielskim jest wymagany' })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                rows={3}
                placeholder="Short project description in English"
              />
              {errors.descriptionEn && (
                <p className="mt-2 text-sm text-red-400">{errors.descriptionEn.message}</p>
              )}
            </div>

            {/* Przegląd PL */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Przegląd projektu (PL)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <textarea
                {...register('overviewPl', { required: 'Przegląd w języku polskim jest wymagany' })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                rows={4}
                placeholder="Ogólny przegląd projektu po polsku"
              />
              {errors.overviewPl && (
                <p className="mt-2 text-sm text-red-400">{errors.overviewPl.message}</p>
              )}
            </div>

            {/* Przegląd EN */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Przegląd projektu (EN)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <textarea
                {...register('overviewEn', { required: 'Przegląd w języku angielskim jest wymagany' })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                rows={4}
                placeholder="General project overview in English"
              />
              {errors.overviewEn && (
                <p className="mt-2 text-sm text-red-400">{errors.overviewEn.message}</p>
              )}
            </div>

            {/* Wyzwanie PL */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Wyzwanie (PL)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <textarea
                {...register('challengePl', { required: 'Wyzwanie w języku polskim jest wymagane' })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                rows={4}
                placeholder="Opis wyzwania projektu po polsku"
              />
              {errors.challengePl && (
                <p className="mt-2 text-sm text-red-400">{errors.challengePl.message}</p>
              )}
            </div>

            {/* Wyzwanie EN */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Wyzwanie (EN)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <textarea
                {...register('challengeEn', { required: 'Wyzwanie w języku angielskim jest wymagane' })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                rows={4}
                placeholder="Project challenge description in English"
              />
              {errors.challengeEn && (
                <p className="mt-2 text-sm text-red-400">{errors.challengeEn.message}</p>
              )}
            </div>

            {/* Podejście PL */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Podejście (PL)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <textarea
                {...register('approachPl', { required: 'Podejście w języku polskim jest wymagane' })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                rows={4}
                placeholder="Opis podejścia do projektu po polsku"
              />
              {errors.approachPl && (
                <p className="mt-2 text-sm text-red-400">{errors.approachPl.message}</p>
              )}
            </div>

            {/* Podejście EN */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Podejście (EN)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <textarea
                {...register('approachEn', { required: 'Podejście w języku angielskim jest wymagane' })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                rows={4}
                placeholder="Project approach description in English"
              />
              {errors.approachEn && (
                <p className="mt-2 text-sm text-red-400">{errors.approachEn.message}</p>
              )}
            </div>

            {/* Rezultaty PL */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Rezultaty (PL)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <textarea
                {...register('resultsPl', { required: 'Rezultaty w języku polskim są wymagane' })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                rows={4}
                placeholder="Opis rezultatów projektu po polsku"
              />
              {errors.resultsPl && (
                <p className="mt-2 text-sm text-red-400">{errors.resultsPl.message}</p>
              )}
            </div>

            {/* Rezultaty EN */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Rezultaty (EN)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <textarea
                {...register('resultsEn', { required: 'Rezultaty w języku angielskim są wymagane' })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                rows={4}
                placeholder="Project results description in English"
              />
              {errors.resultsEn && (
                <p className="mt-2 text-sm text-red-400">{errors.resultsEn.message}</p>
              )}
            </div>
          </div>
        );

      case 'features':
        return (
          <div className="space-y-6">
            <Controller
              name="features"
              control={control}
              render={({ field }) => (
                <div>
                  <div className="flex justify-between items-center mb-4">
                    <label className="text-sm font-medium text-white/80">
                      Funkcjonalności projektu
                    </label>
                    <Button
                      type="button"
                      variant="dark"
                      size="sm"
                      icon={<Plus size={16} />}
                      onClick={addFeature}
                    >
                      Dodaj funkcjonalność
                    </Button>
                  </div>
                  
                  <div className="space-y-6">
                    {(field.value || []).map((feature, index) => (
                      <div 
                        key={index}
                        className="p-4 bg-white/5 border border-white/10 rounded-lg relative"
                      >
                        <button
                          type="button"
                          onClick={() => removeFeature(index)}
                          className="absolute top-2 right-2 p-1.5 rounded-md bg-white/5 text-white/70 hover:bg-red-500/20 hover:text-red-400 transition-colors"
                        >
                          <Trash size={16} />
                        </button>
                        
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                          {/* Tytuł PL */}
                          <div>
                            <label className="block mb-2 text-sm font-medium text-white/80">
                              Tytuł (PL)
                              <span className="text-brand-primary ml-1">*</span>
                            </label>
                            <input
                              type="text"
                              value={feature?.titlePl || ''}
                              onChange={(e) => updateFeatureField(index, 'titlePl', e.target.value)}
                              className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                              placeholder="Tytuł funkcjonalności po polsku"
                            />
                          </div>
                          
                          {/* Tytuł EN */}
                          <div>
                            <label className="block mb-2 text-sm font-medium text-white/80">
                              Tytuł (EN)
                              <span className="text-brand-primary ml-1">*</span>
                            </label>
                            <input
                              type="text"
                              value={feature?.titleEn || ''}
                              onChange={(e) => updateFeatureField(index, 'titleEn', e.target.value)}
                              className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                              placeholder="Feature title in English"
                            />
                          </div>
                        </div>
                        
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          {/* Opis PL */}
                          <div>
                            <label className="block mb-2 text-sm font-medium text-white/80">
                              Opis (PL)
                              <span className="text-brand-primary ml-1">*</span>
                            </label>
                            <textarea
                              value={feature?.descriptionPl || ''}
                              onChange={(e) => updateFeatureField(index, 'descriptionPl', e.target.value)}
                              className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                              rows={3}
                              placeholder="Opis funkcjonalności po polsku"
                            />
                          </div>
                          
                          {/* Opis EN */}
                          <div>
                            <label className="block mb-2 text-sm font-medium text-white/80">
                              Opis (EN)
                              <span className="text-brand-primary ml-1">*</span>
                            </label>
                            <textarea
                              value={feature?.descriptionEn || ''}
                              onChange={(e) => updateFeatureField(index, 'descriptionEn', e.target.value)}
                              className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                              rows={3}
                              placeholder="Feature description in English"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                    
                    {(!field.value || field.value.length === 0) && (
                      <div className="p-8 rounded-lg border border-dashed border-white/20 bg-white/5 text-center">
                        <Code size={36} className="text-white/20 mx-auto mb-3" />
                        <p className="text-white/60 mb-1">Brak funkcjonalności</p>
                        <p className="text-sm text-white/40">Kliknij "Dodaj funkcjonalność" aby rozpocząć</p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            />
          </div>
        );

      case 'gallery':
        return (
          <div className="space-y-6">
            <div>
              <div className="flex justify-between items-center mb-4">
                <label className="text-sm font-medium text-white/80">
                  Galeria projektu
                </label>
                <Button
                  type="button"
                  variant="dark"
                  size="sm"
                  icon={<Plus size={16} />}
                  onClick={addGalleryItem}
                >
                  Dodaj zdjęcie
                </Button>
              </div>
              
              <div className="space-y-6">
                {galleryItems.map((item, index) => (
                  <div 
                    key={index}
                    className="p-4 bg-white/5 border border-white/10 rounded-lg relative"
                  >
                    <button
                      type="button"
                      onClick={() => removeGalleryItem(index)}
                      className="absolute top-2 right-2 p-1.5 rounded-md bg-white/5 text-white/70 hover:bg-red-500/20 hover:text-red-400 transition-colors"
                    >
                      <Trash size={16} />
                    </button>
                    
                    <div className="mb-4">
                      <label className="block mb-2 text-sm font-medium text-white/80">
                        Zdjęcie (przesłane z komputera)
                        <span className="text-brand-primary ml-1">*</span>
                      </label>
                      
                      {/* Wyświetlanie podglądu obrazu */}
                      {item?.imageUrl && (
                        <div className="mb-2 relative">
                          <div className="relative w-full h-48 bg-white/5 rounded-lg overflow-hidden">
                            <img 
                              src={item.imageUrl} 
                              alt={item.altPl || "Podgląd obrazu"} 
                              className="w-full h-full object-contain" 
                            />
                          </div>
                        </div>
                      )}
                      
                      {/* Upload zdjęcia z komputera */}
                      <div className="flex items-center space-x-3">
                        <label className="cursor-pointer flex-1">
                          <div className="w-full px-4 py-3 rounded-lg bg-white/5 border border-white/10 text-white hover:bg-white/10 transition-colors flex items-center justify-center">
                            <Upload size={18} className="mr-2 text-white/70" />
                            <span>Wybierz plik</span>
                          </div>
                          <input
                            type="file"
                            accept="image/*"
                            className="hidden"
                            onChange={async (e) => {
                              const file = e.target.files?.[0];
                              if (file) {
                                if (file.size > 3 * 1024 * 1024) {
                                  setError(
                                    'Maksymalny rozmiar pliku to 3MB. Proszę wybrać mniejszy plik lub zoptymalizować ten.'
                                  );
                                  return;
                                }
                                
                                // Generujemy unikalną nazwę pliku
                                const fileExt = file.name.split('.').pop();
                                const fileName = `${Date.now()}.${fileExt}`;
                                const filePath = `gallery/${fileName}`;
                                
                                // Przesyłamy plik do storage
                                try {
                                  setIsUploading(true);
                                  const { error: uploadError } = await supabase.storage
                                    .from('projects')
                                    .upload(filePath, file);
                                
                                  if (uploadError) {
                                    throw uploadError;
                                  }
                                
                                  // Pobieramy publiczny URL do pliku
                                  const { data } = supabase.storage
                                    .from('projects')
                                    .getPublicUrl(filePath);
                                
                                  // Aktualizujemy stan
                                  updateGalleryImageUrl(index, data.publicUrl);
                                  setError(null);
                                } catch (error: any) {
                                  setError(`Błąd podczas przesyłania pliku: ${error.message}`);
                                } finally {
                                  setIsUploading(false);
                                }
                              }
                            }}
                          />
                        </label>
                      </div>
                    </div>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      {/* Alt PL */}
                      <div>
                        <label className="block mb-2 text-sm font-medium text-white/80">
                          Tekst alternatywny (PL)
                          <span className="text-brand-primary ml-1">*</span>
                        </label>
                        <input
                          type="text"
                          value={item?.altPl || ''}
                          onChange={(e) => updateGalleryField(index, 'altPl', e.target.value)}
                          className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                          placeholder="Opisowy tekst alternatywny po polsku"
                        />
                      </div>
                      
                      {/* Alt EN */}
                      <div>
                        <label className="block mb-2 text-sm font-medium text-white/80">
                          Tekst alternatywny (EN)
                          <span className="text-brand-primary ml-1">*</span>
                        </label>
                        <input
                          type="text"
                          value={item?.altEn || ''}
                          onChange={(e) => updateGalleryField(index, 'altEn', e.target.value)}
                          className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                          placeholder="Descriptive alt text in English"
                        />
                      </div>
                    </div>
                  </div>
                ))}
                
                {galleryItems.length === 0 && (
                  <div className="p-8 rounded-lg border border-dashed border-white/20 bg-white/5 text-center">
                    <ImageIcon size={36} className="text-white/20 mx-auto mb-3" />
                    <p className="text-white/60 mb-1">Galeria jest pusta</p>
                    <p className="text-sm text-white/40">Kliknij "Dodaj zdjęcie" aby dodać obrazy do galerii</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        );

      case 'testimonials':
        return (
          <div className="space-y-6">
            <Controller
              name="testimonials"
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <div>
                  <div className="flex justify-between items-center mb-4">
                    <label className="text-sm font-medium text-white/80">
                      Dane klienta i opinia
                    </label>
                  </div>
                  
                  <div className="space-y-6">
                    {field.value.slice(0, 1).map((testimonial, index) => (
                      <div 
                        key={index}
                        className="p-4 bg-white/5 border border-white/10 rounded-lg relative"
                      >
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                          {/* Nazwa firmy */}
                          <div>
                            <label className="block mb-2 text-sm font-medium text-white/80">
                              Nazwa firmy klienta
                              <span className="text-brand-primary ml-1">*</span>
                            </label>
                            <input
                              type="text"
                              value={testimonial.companyName}
                              onChange={(e) => {
                                const newTestimonials = [...field.value];
                                newTestimonials[index].companyName = e.target.value;
                                field.onChange(newTestimonials);
                              }}
                              className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                              placeholder="Nazwa firmy klienta"
                            />
                          </div>
                        </div>

                        <div className="mt-6 mb-3">
                          <label className="block mb-2 text-lg font-semibold text-white/80">
                            Dane opinii (opcjonalne)
                          </label>
                        </div>
                        
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                          {/* Autor */}
                          <div>
                            <label className="block mb-2 text-sm font-medium text-white/80">
                              Autor opinii
                            </label>
                            <input
                              type="text"
                              value={testimonial.authorName}
                              onChange={(e) => {
                                const newTestimonials = [...field.value];
                                newTestimonials[index].authorName = e.target.value;
                                field.onChange(newTestimonials);
                              }}
                              className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                              placeholder="Imię i nazwisko autora"
                            />
                          </div>
                          
                          {/* Logo URL */}
                          <div>
                            <label className="block mb-2 text-sm font-medium text-white/80">
                              URL logo firmy
                            </label>
                            <input
                              type="url"
                              value={testimonial.logoUrl}
                              onChange={(e) => {
                                const newTestimonials = [...field.value];
                                newTestimonials[index].logoUrl = e.target.value;
                                field.onChange(newTestimonials);
                              }}
                              className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                              placeholder="https://example.com/logo.png"
                            />
                          </div>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                          {/* Treść PL */}
                          <div>
                            <label className="block mb-2 text-sm font-medium text-white/80">
                              Treść opinii (PL)
                            </label>
                            <textarea
                              value={testimonial.contentPl}
                              onChange={(e) => {
                                const newTestimonials = [...field.value];
                                newTestimonials[index].contentPl = e.target.value;
                                field.onChange(newTestimonials);
                              }}
                              className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                              rows={4}
                              placeholder="Treść opinii po polsku"
                            />
                          </div>
                          
                          {/* Treść EN */}
                          <div>
                            <label className="block mb-2 text-sm font-medium text-white/80">
                              Treść opinii (EN)
                            </label>
                            <textarea
                              value={testimonial.contentEn}
                              onChange={(e) => {
                                const newTestimonials = [...field.value];
                                newTestimonials[index].contentEn = e.target.value;
                                field.onChange(newTestimonials);
                              }}
                              className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                              rows={4}
                              placeholder="Testimonial content in English"
                            />
                          </div>
                        </div>
                        
                        {/* Oryginalny język opinii */}
                        <div className="mt-4">
                          <label className="block mb-2 text-sm font-medium text-white/80">
                            Oryginalny język opinii
                          </label>
                          <RadioGroup
                            name={`originalLanguage-${index}`}
                            options={[
                              { value: 'pl', label: 'Polski' },
                              { value: 'en', label: 'Angielski' }
                            ]}
                            value={testimonial.originalLanguage || 'pl'}
                            onChange={(value) => {
                              const newTestimonials = [...field.value];
                              newTestimonials[index].originalLanguage = value;
                              field.onChange(newTestimonials);
                            }}
                            direction="horizontal"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            />
          </div>
        );

      case 'technologies':
        return (
          <div className="space-y-6">
            {loadingData ? (
              <div className="flex items-center justify-center py-12">
                <Loader2 size={24} className="animate-spin text-brand-primary" />
              </div>
            ) : loadError ? (
              <div className="p-4 bg-red-950/30 border border-red-800/50 rounded-lg text-red-200">
                <AlertTriangle size={18} className="mb-2" />
                <p>{loadError}</p>
              </div>
            ) : (
              <div>
                <label className="block mb-4 text-sm font-medium text-white/80">
                  Technologie użyte w projekcie
                </label>
                <Controller
                  name="technologies"
                  control={control}
                  render={({ field }) => (
                    <div className="flex flex-wrap gap-2">
                      {technologies.map((tech) => (
                        <button
                          key={tech.id}
                          type="button"
                          onClick={() => {
                            const isSelected = field.value.includes(tech.id);
                            const newValue = isSelected
                              ? field.value.filter(id => id !== tech.id)
                              : [...field.value, tech.id];
                            field.onChange(
                              newValue
                            );
                            setSelectedTechnologies(newValue);
                          }}
                          className={cn(
                            "px-3 py-1.5 rounded-full text-sm transition-all duration-300 flex items-center gap-2",
                            field.value.includes(tech.id)
                              ? "bg-brand-primary/20 text-brand-primary border border-brand-primary/30"
                              : "bg-white/5 text-white/70 border border-white/10 hover:bg-white/10"
                          )}
                        >
                          {tech.name}
                        </button>
                      ))}
                    </div>
                  )}
                />
              </div>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="h-full flex flex-col">
      {/* Nawigacja zakładek */}
      <div className="flex gap-4 mb-6 border-b border-white/10 overflow-x-auto pb-px custom-scrollbar">
        {tabs.map(tab => (
          <button
            key={tab.id}
            type="button"
            onClick={() => setActiveTab(tab.id)}
            className={cn(
              "px-4 py-2 text-sm font-medium transition-colors relative whitespace-nowrap",
              activeTab === tab.id 
                ? "text-brand-primary" 
                : "text-white/70 hover:text-white"
            )}
          >
            {tab.label}
            {activeTab === tab.id && (
              <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-brand-primary" />
            )}
          </button>
        ))}
      </div>

      {/* Zawartość aktywnej zakładki */}
      <div className="flex-1 overflow-y-auto mb-8">
        {renderActiveTab()}
      </div>
      
      {/* Przyciski akcji */}
      <div className="border-t border-white/10 py-4 flex justify-end gap-4 mt-auto">
        <Button
          type="button"
          variant="dark"
          disabled={isLoading}
          icon={isLoading ? <Loader2 className="animate-spin" /> : <Save />}
          onClick={async () => {
            setIsDraft(true);
            // Pomiń walidację i wyślij dane
            const data = watch();
            handleFormSubmit({ ...data, published: false });
          }}
        >
          {isLoading ? 'Zapisywanie...' : 'Zapisz jako szkic'}
        </Button>
        <Button
          type="button"
          variant="default"
          disabled={isLoading} 
          icon={isLoading ? <Loader2 className="animate-spin" /> : <Save />}
          onClick={async () => {
            setIsDraft(false);
            // Wykonaj pełną walidację przed publikacją
            const isValid = await trigger();
            if (isValid) {
              const data = watch();
              handleFormSubmit({ ...data, published: true });
            }
          }}
        >
          {isLoading ? 'Publikowanie...' : 'Opublikuj projekt'}
        </Button>
      </div>
    </form>
  );
};

export default WebProjectForm;