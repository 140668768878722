import ReactGA from 'react-ga4';

// Klucz śledzenia Google Analytics
const TRACKING_ID = 'G-9FHC77B927';

// Flaga wskazująca, czy inicjalizacja już nastąpiła
let initialized = false;

// Funkcja typu Window z dodatkową właściwością dla TypeScript
interface CustomWindow extends Window {
  toggleAnalytics?: (enabled: boolean) => void;
  gtag?: (...args: any[]) => void;
  [key: string]: any; // Pozwala na dodanie dowolnych kluczy do obiektu window
}

declare const window: CustomWindow;

/**
 * Inicjalizacja Google Analytics.
 * Uruchamiany tylko gdy użytkownik wyraził zgodę na cookies analityczne.
 */
export const initializeGA = (trackingId: string = TRACKING_ID): void => {
  if (initialized || !trackingId || trackingId === 'G-XXXXXXXXXX') {
    return;
  }
  
  // Inicjalizacja GA4 przez react-ga4
  ReactGA.initialize(trackingId, {
    gaOptions: {
      send_page_view: false,
    },
  });
  
  initialized = true;
  console.log('Google Analytics zainicjalizowane przez react-ga4');
};

/**
 * Wyślij informację o wyświetleniu strony do Google Analytics.
 */
export const sendPageview = (
  path: string = window.location.pathname,
  search: string = window.location.search,
  title: string = document.title
): void => {
  if (!initialized) {
    console.warn('Google Analytics nie zostało zainicjalizowane');
    return;
  }
  
  // Wysyłanie przez react-ga4
  ReactGA.send({
    hitType: 'pageview',
    page: path + search,
    title: title,
  });
  
  // Wysyłanie również przez natywne gtag jeśli dostępne
  if (window.gtag) {
    window.gtag('event', 'page_view', {
      page_path: path + search,
      page_title: title,
      page_location: window.location.href
    });
    console.log('Pageview wysłany również przez natywne gtag');
  }
  
  console.log('Pageview wysłany:', path + search);
};

/**
 * Wyślij zdarzenie do Google Analytics.
 */
export const sendEvent = (
  category: string,
  action: string,
  label?: string,
  value?: number
): void => {
  if (!initialized) {
    console.warn('Google Analytics nie zostało zainicjalizowane');
    return;
  }
  
  ReactGA.event({
    category,
    action,
    label,
    value,
  });
  
  // Wysyłanie również przez natywne gtag jeśli dostępne
  if (window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value
    });
  }
};

/**
 * Ustaw właściwości użytkownika w Google Analytics.
 */
export const setUserProperties = (properties: Record<string, any>): void => {
  if (!initialized) {
    console.warn('Google Analytics nie zostało zainicjalizowane');
    return;
  }
  
  ReactGA.gtag('set', 'user_properties', properties);
  
  // Ustawienie również przez natywne gtag jeśli dostępne
  if (window.gtag) {
    window.gtag('set', 'user_properties', properties);
  }
};

/**
 * Wyłącz Google Analytics i usuń pliki cookie.
 * Używane gdy użytkownik wycofał zgodę na cookies analityczne.
 */
export const disableGA = (): void => {
  if (!initialized) {
    return;
  }
  
  // Ustaw flagę opt-out dla GA
  window['ga-disable-' + TRACKING_ID] = true;
  
  initialized = false;
  console.log('Google Analytics wyłączone');
};
