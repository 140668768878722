import React from 'react';
import { motion } from 'framer-motion';
import { Globe, Video, MessageSquare } from 'lucide-react';
import { cn } from '../../../lib/utils';

interface ProjectType {
  id: string;
  name: string;
  description: string;
  icon: React.ReactNode;
  formType: string;
}

const projectTypes: ProjectType[] = [
  {
    id: 'web',
    name: 'Strona internetowa',
    description: 'Strony firmowe, landing page, sklepy online i aplikacje webowe',
    icon: <Globe size={24} className="text-brand-primary" />,
    formType: 'web'
  },
  {
    id: 'video',
    name: 'Produkcja wideo',
    description: 'Filmy promocyjne, animacje, relacje z wydarzeń',
    icon: <Video size={24} className="text-brand-primary" />,
    formType: 'video'
  },
  {
    id: 'social',
    name: 'Social Media',
    description: 'Kampanie reklamowe, prowadzenie profili, content marketing',
    icon: <MessageSquare size={24} className="text-brand-primary" />,
    formType: 'social'
  }
];

interface ProjectTypeSelectorProps {
  onSelect: (type: string) => void;
  selectedType?: string | null;
}

const ProjectTypeSelector: React.FC<ProjectTypeSelectorProps> = ({ onSelect, selectedType }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {projectTypes.map((type) => (
        <motion.button
          key={type.id}
          onClick={() => onSelect(type.formType)}
          className={cn(
            "p-6 rounded-xl text-left transition-all duration-300",
            "backdrop-blur-sm border",
            selectedType === type.formType
              ? "bg-brand-primary/10 border-brand-primary/30"
              : "bg-white/5 border-white/10 hover:bg-white/8 hover:border-white/20"
          )}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <div className="flex items-start gap-4">
            <div className="p-3 rounded-lg bg-white/10">
              {type.icon}
            </div>
            <div>
              <h3 className={cn(
                "text-lg font-medium mb-2",
                selectedType === type.formType ? "text-brand-primary" : "text-white"
              )}>
                {type.name}
              </h3>
              <p className="text-sm text-white/70">
                {type.description}
              </p>
            </div>
          </div>
        </motion.button>
      ))}
    </div>
  );
};

export default ProjectTypeSelector; 