import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Carousel, CarouselContent, CarouselItem, CarouselPrevious, CarouselNext, CarouselDots } from '../ui/Carousel';
import SectionPreheader from '../common/SectionPreheader';
import { ArrowRight } from 'lucide-react';
import Autoplay from 'embla-carousel-autoplay';

interface Project {
  id: string;
  slug: string;
  pl_slug?: string;
  en_slug?: string;
  title: string;
  description?: string;
  project_type: string;
  thumbnail_url: string;
  tags?: string[];
}

interface SimilarProjectsProps {
  projects: Project[];
  currentProjectId: string;
  currentLanguage: string;
}

const SimilarProjects: React.FC<SimilarProjectsProps> = ({ 
  projects, 
  currentProjectId,
  currentLanguage 
}) => {
  // Filter out current project
  const filteredProjects = projects.filter(project => project.id !== currentProjectId);
  const [api, setApi] = useState<any>(null);
  const autoplayRef = useRef<any>(null);
  
  // Don't render if there are no similar projects
  if (filteredProjects.length === 0) {
    return null;
  }
  
  // Animation variants
  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: (delay: number = 0) => ({ 
      opacity: 1, 
      y: 0, 
      transition: { 
        delay,
        duration: 0.6, 
        ease: [0.22, 1, 0.36, 1]
      }
    })
  };
  
  // Initialize autoplay
  useEffect(() => {
    if (filteredProjects.length > 0) {
      autoplayRef.current = Autoplay({ delay: 5000, stopOnInteraction: true });
    }
  }, [filteredProjects]);
  
  // Handle mouse enter/leave to pause/resume autoplay
  const onMouseEnter = React.useCallback(() => {
    if (api?.plugins()?.autoplay) {
      api.plugins().autoplay.stop();
    }
  }, [api]);

  const onMouseLeave = React.useCallback(() => {
    if (api?.plugins()?.autoplay) {
      try {
        api.plugins().autoplay.play();
      } catch (err) {
        console.log('Autoplay error:', err);
      }
    }
  }, [api]);
  
  // Get plugins for carousel
  const getPlugins = () => {
    if (autoplayRef.current && filteredProjects.length > 0) {
      return [autoplayRef.current];
    }
    return [];
  };
  
  // Get project link based on project type and language
  const getProjectLink = (project: Project) => {
    const slug = currentLanguage === 'pl' ? project.pl_slug || project.slug : project.en_slug || project.slug;
    const basePath = currentLanguage === 'pl' ? '/pl/projekt/' : '/en/project/';
    
    // Mapowanie typów projektów
    let categoryPath = project.project_type;
    if (categoryPath === 'web') {
      categoryPath = currentLanguage === 'pl' ? 'strona-internetowa' : 'website';
    } else if (categoryPath === 'video') {
      categoryPath = currentLanguage === 'pl' ? 'wideo' : 'video';
    } else if (categoryPath === 'social') {
      categoryPath = 'social-media';
    }
    
    return `${basePath}${categoryPath}/${slug}`;
  };

  return (
    <section className="py-20 relative overflow-hidden">
      <div className="container mx-auto px-6">
        <motion.div
          variants={fadeInUp}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="max-w-5xl mx-auto"
        >
          <SectionPreheader 
            text={currentLanguage === 'pl' ? 'Zobacz więcej' : 'See more'} 
            variant="secondary" 
            className="mb-8" 
            align="center"
          />
          
          <h2 className="text-2xl md:text-3xl font-bold text-white mb-10 text-center">
            {currentLanguage === 'pl' ? 'Podobne projekty' : 'Similar Projects'}
          </h2>
          
          <div 
            className="max-w-6xl mx-auto"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <Carousel
              opts={{
                loop: false,
                align: "start",
                skipSnaps: false,
                startIndex: 0,
                containScroll: "trimSnaps"
              }}
              plugins={getPlugins()}
              setApi={setApi}
              className="w-full"
            >
              <CarouselContent className="py-4">
                {filteredProjects.map((project, index) => (
                  <CarouselItem 
                    key={project.id} 
                    className="md:basis-1/2 lg:basis-1/3 pt-4 h-auto"
                  >
                    <div className="h-full px-2">
                      <Link 
                        to={getProjectLink(project)}
                        className="block h-full group"
                      >
                        <motion.div
                          className="bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl overflow-hidden h-full transition-all duration-300 group-hover:border-brand-primary/40 group-hover:shadow-glow-sm"
                          whileHover={{ y: -5 }}
                          transition={{ duration: 0.3 }}
                        >
                          {/* Obrazek projektu */}
                          <div className="aspect-video w-full overflow-hidden relative">
                            <img
                              src={project.thumbnail_url}
                              alt={project.title}
                              className="w-full h-full object-cover transition-transform duration-700 ease-in-out group-hover:scale-105"
                            />
                            
                            {/* Gradient overlay na zdjęciu */}
                            <div 
                              className="absolute inset-0 z-10 opacity-40 transition-opacity duration-300 group-hover:opacity-30"
                              style={{ 
                                background: 'linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.7) 100%)' 
                              }}
                            />
                            
                            {/* Etykiety tagów na zdjęciu */}
                            {project.tags && project.tags.length > 0 && (
                              <div className="absolute top-3 left-3 z-20 flex flex-wrap gap-2">
                                {project.tags.map((tag, i) => (
                                  <span 
                                    key={i} 
                                    className="text-[10px] font-medium py-1 px-2 rounded-full backdrop-blur-md bg-white/10 border border-white/20 text-white"
                                  >
                                    {tag}
                                  </span>
                                ))}
                              </div>
                            )}
                          </div>
                          
                          {/* Zawartość karty */}
                          <div className="p-5">
                            <h3 className="text-lg font-semibold text-white mb-2 group-hover:text-brand-primary transition-colors">
                              {project.title}
                            </h3>
                            <p className="text-sm text-white/70 mb-4 line-clamp-2">
                              {project.description || ''}
                            </p>
                            
                            {/* Przycisk */}
                            <div className="flex items-center justify-end">
                              <span className="text-xs font-medium text-brand-primary flex items-center gap-1 transition-all duration-300 group-hover:gap-2">
                                {currentLanguage === 'pl' ? 'Zobacz projekt' : 'View project'}
                                <ArrowRight size={12} className="transition-transform duration-300 group-hover:translate-x-1" />
                              </span>
                            </div>
                          </div>
                        </motion.div>
                      </Link>
                    </div>
                  </CarouselItem>
                ))}
              </CarouselContent>
              <div className="hidden md:block">
                <CarouselPrevious className="-left-5 lg:-left-12" />
                <CarouselNext className="-right-5 lg:-right-12" />
              </div>
              <div className="mt-8">
                <CarouselDots />
              </div>
            </Carousel>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default SimilarProjects;
