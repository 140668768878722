import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { sendPageview } from '../../services/analytics';
import { useCookieConsent } from '../../contexts/CookieConsentContext';

/**
 * Komponent śledzący zmiany ścieżki i wysyłający informacje do Google Analytics
 * gdy użytkownik wyraził zgodę na cookies analityczne
 */
const RouteChangeTracker: React.FC = () => {
  const location = useLocation();
  const { consentOptions } = useCookieConsent();
  
  useEffect(() => {
    // Wysyłaj informacje o zmianie strony tylko gdy użytkownik wyraził zgodę na cookies analityczne
    if (consentOptions.analytics) {
      // Pozyskaj ścieżkę, parametry wyszukiwania i tytuł strony
      const path = location.pathname;
      const search = location.search;
      const title = document.title;
      
      // Wyślij informację o wyświetleniu strony do Google Analytics
      sendPageview(path, search, title);
      
      console.log('Śledzenie Analytics włączone dla:', path + search);
    } else {
      console.log('Śledzenie Analytics wyłączone - brak zgody użytkownika');
    }
  }, [location, consentOptions.analytics]);

  // Komponent nie renderuje żadnego UI
  return null;
};

export default RouteChangeTracker;
