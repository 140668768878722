import { ChevronRight } from 'lucide-react';
import AnimatedHeadline from '../common/AnimatedHeadline';
import { Button } from '../ui/Button';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import UiWireframes from './ui/UiWireframes';
import FloatingIcons from './ui/FloatingIcons';
import FloatingImages from './ui/FloatingImages';

const Hero = () => {
  const { t } = useTranslation(['hero']);
  
  // Pobieramy frazy do animacji z plików tłumaczeń
  const animatedPhrases = t('phrases', { returnObjects: true }) as string[];

  // Funkcja do przewijania do sekcji
  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      // Dodaj offset, aby uwzględnić wysokość navbaru
      const navbarHeight = 80; // przybliżona wysokość navbaru z marginesami
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      
      // Przewiń do elementu z płynną animacją
      window.scrollTo({ 
        top: elementPosition - navbarHeight, 
        behavior: 'smooth' 
      });
    }
  };

  return (
    <section className="min-h-screen w-full flex items-center justify-center relative overflow-hidden py-16 pt-28 md:pt-32">
      {/* Efekt pocisku/komety z gradientem - TYLKO JEDEN element */}
      <div className="absolute top-0 left-0 right-0 bottom-0 pointer-events-none w-full overflow-visible z-0">
        {/* Główny efekt pocisku gradientowego - skośny, od lewego górnego rogu */}
        <motion.div 
          className="absolute -top-[5vh] left-0 w-[70vw] h-[70vh]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5, ease: "easeOut" }}
          style={{
            background: `
              radial-gradient(
                ellipse at 15% 0%, 
                rgba(255, 90, 31, 0.55) 0%,
                rgba(255, 90, 31, 0.45) 10%,
                rgba(255, 125, 58, 0.3) 30%, 
                rgba(255, 159, 69, 0.15) 50%,
                rgba(255, 210, 100, 0.08) 70%,
                transparent 90%
              )
            `,
            filter: 'blur(40px)',
            transform: 'rotate(-22deg) translateX(-5vw)',
            transformOrigin: 'top left'
          }}
        />
      </div>
      
      {/* Warstwa z unoszącymi się wireframe'ami UI */}
      <div className="absolute inset-0 w-full h-full overflow-hidden pointer-events-none z-[1]">
        <UiWireframes />
        <FloatingIcons />
      </div>
      
      {/* Warstwa z unoszącymi się zdjęciami */}
      <div className="absolute inset-0 w-full h-full overflow-hidden pointer-events-none z-[0]">
        <FloatingImages />
      </div>

      {/* Treść */}
      <div className="container mx-auto px-6 sm:px-8 md:px-10 lg:px-12 w-full z-10 relative">
        <div className="flex flex-col items-center justify-center text-center w-full mx-auto h-full">
          <div className="space-y-6 sm:space-y-8 z-10 w-full">
            <AnimatedHeadline 
              prefix={t('prefix')}
              phrases={animatedPhrases}
              interval={4000}
            />
            <p className="text-text-primary text-base sm:text-lg md:text-xl max-w-2xl mx-auto mt-6 sm:mt-8 md:mt-10">
              {t('description')}
            </p>
            
            <div className="flex flex-col sm:flex-row justify-center items-center gap-y-3 gap-x-6 pt-2 sm:pt-8 mt-4">
              <Button 
                variant="default" 
                icon={<ChevronRight size={18} />}
                onClick={() => scrollToSection('contact')}
              >
                {t('cta.talk')}
              </Button>
              <Button 
                variant="dark" 
                icon={<ChevronRight size={16} className="opacity-70" />}
                iconPosition="right"
                onClick={() => scrollToSection('portfolio')}
              >
                {t('cta.portfolio')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;