import React from 'react';
import { motion } from 'framer-motion';
import { Monitor, Megaphone, Video, Check, ChevronRight } from 'lucide-react';
import * as LucideIcons from 'lucide-react';
import { useTranslation } from 'react-i18next';
import SectionPreheader from '../common/SectionPreheader';
import { TabsComponent, TabItem } from '../ui/TabsComponent';
import { IconContainer } from '../ui/IconContainer';

// Interfejs dla funkcji usługowej
interface ServiceFeature {
  title: string;
  description: string;
  icon: string;
}

// Rozszerzony komponent karty dla zawartości taba
const ServiceContent = ({ 
  title, 
  description, 
  items,
  features,
  benefits,
  icon 
}: { 
  title: string;
  description: string;
  items: string[];
  features: ServiceFeature[];
  benefits: string[];
  icon: React.ReactNode;
}) => {
  const { t } = useTranslation(['services']);

  // Funkcja do dynamicznego pobierania ikon z Lucide
  const getIconComponent = (iconName: string) => {
    const IconComponent = LucideIcons[iconName as keyof typeof LucideIcons] || LucideIcons.Circle;
    return <IconComponent size={20} className="text-brand-primary" />;
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col space-y-8 p-8"
    >
      {/* Główny opis usługi */}
      <div className="mb-6">
        <p className="text-white/80 leading-relaxed">{description}</p>
      </div>

      {/* Lista usług */}
      <div className="bg-white/[0.03] backdrop-blur-sm border border-white/10 rounded-xl p-6">
        <h4 className="text-lg font-medium text-white mb-4">{t('section_labels.services_range')}</h4>
        <ul className="grid grid-cols-1 md:grid-cols-2 gap-3">
          {items.map((item, index) => (
            <li key={index} className="flex items-start">
              <span className="mr-2 mt-1 text-brand-primary">
                <Check size={16} />
              </span>
              <span className="text-white/80">{item}</span>
            </li>
          ))}
        </ul>
      </div>

      {/* Karty funkcji */}
      <div>
        <h4 className="text-lg font-medium text-white mb-4">{t('section_labels.methodology')}</h4>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {features.map((feature, idx) => (
            <div 
              key={idx}
              className="group relative p-4 rounded-xl overflow-hidden transition-all duration-300 backdrop-blur-md bg-white/[0.05] border border-white/10 hover:shadow-[0_8px_30px_rgba(0,0,0,0.12)] hover:-translate-y-1 hover:bg-white/[0.08]"
            >
              {/* Efekt tła z kropkami - pojawia się po najechaniu */}
              <div
                className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              >
                <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(255,255,255,0.03)_1px,transparent_1px)] bg-[length:4px_4px]" />
              </div>

              {/* Delikatny gradient na obramowaniu - enhanced glass effect */}
              <div
                className="absolute inset-0 -z-10 rounded-xl p-px bg-gradient-to-br from-transparent via-white/15 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              />

              <div className="relative z-10">
                <div className="flex items-center mb-3">
                  <div className="w-10 h-10 flex items-center justify-center rounded-full bg-brand-primary/10 text-brand-primary mr-3 group-hover:bg-brand-primary/20 transition-all duration-300 group-hover:scale-110">
                    {getIconComponent(feature.icon)}
                  </div>
                  <h5 className="font-medium text-white group-hover:text-white/100 transition-colors duration-300">{feature.title}</h5>
                </div>
                <p className="text-sm text-white/70 group-hover:text-white/90 transition-colors duration-300">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Korzyści */}
      <div className="bg-gradient-to-br from-brand-primary/5 to-brand-secondary/5 backdrop-blur-sm border border-white/10 rounded-xl p-6">
        <h4 className="text-lg font-medium text-white mb-4">{t('section_labels.business_value')}</h4>
        <ul className="space-y-2">
          {benefits.map((benefit, index) => (
            <li key={index} className="flex items-start">
              <span className="mr-2 mt-1 flex-shrink-0 text-brand-tertiary">
                <ChevronRight size={16} />
              </span>
              <span className="text-white/80">{benefit}</span>
            </li>
          ))}
        </ul>
      </div>
    </motion.div>
  );
};

// Główny komponent sekcji Services
const Services = () => {
  const { t } = useTranslation(['services']);
  
  // Dane usług z wykorzystaniem tłumaczeń
  const services = [
    {
      title: t('web.title'),
      description: t('web.description'),
      icon: <Monitor size={24} className="text-brand-primary" />,
      items: t('web.items', { returnObjects: true }) as string[],
      features: t('web.features', { returnObjects: true }) as ServiceFeature[],
      benefits: t('web.benefits', { returnObjects: true }) as string[],
    },
    {
      title: t('marketing.title'),
      description: t('marketing.description'),
      icon: <Megaphone size={24} className="text-brand-primary" />,
      items: t('marketing.items', { returnObjects: true }) as string[],
      features: t('marketing.features', { returnObjects: true }) as ServiceFeature[],
      benefits: t('marketing.benefits', { returnObjects: true }) as string[],
    },
    {
      title: t('video.title'),
      description: t('video.description'),
      icon: <Video size={24} className="text-brand-primary" />,
      items: t('video.items', { returnObjects: true }) as string[],
      features: t('video.features', { returnObjects: true }) as ServiceFeature[],
      benefits: t('video.benefits', { returnObjects: true }) as string[],
    }
  ];
  
  // Komponent kart usługowych dla widoku tabowego
  const tabs: TabItem[] = services.map(service => ({
    title: service.title,
    icon: service.icon,
    content: (
      <ServiceContent
        title={service.title}
        description={service.description}
        items={service.items}
        features={service.features}
        benefits={service.benefits}
        icon={service.icon}
      />
    )
  }));

  // Animacja dla sekcji
  const sectionVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <section id="services" className="w-full relative py-24 sm:py-28 overflow-hidden">
      {/* Główny efekt gradientowy wychodzący z prawej strony */}
      <div className="absolute right-0 top-0 bottom-0 pointer-events-none">
        <div 
          className="absolute right-0 top-1/2 transform -translate-y-1/2 w-[50vw] h-[80vh]"
          style={{
            background: `
              radial-gradient(
                ellipse at 100% 50%, 
                rgba(255, 90, 31, 0.55) 0%,
                rgba(255, 90, 31, 0.45) 10%,
                rgba(255, 125, 58, 0.3) 30%, 
                rgba(255, 159, 69, 0.15) 50%,
                rgba(255, 210, 100, 0.08) 70%,
                transparent 90%
              )
            `,
            filter: 'blur(60px)',
          }}
        />
      </div>

      <div className="container mx-auto px-4 sm:px-6 md:px-8 lg:px-10 relative z-10">
        <motion.div
          className="max-w-3xl mx-auto text-center mb-10 sm:mb-14"
          variants={sectionVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.1 }}
        >
          {/* Preheader */}
          <SectionPreheader 
            text={t('preheader')} 
            variant="primary" 
            className="mb-6 sm:mb-8" 
          />
          
          <motion.h2 
            className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 sm:mb-6 text-white"
            variants={itemVariants}
          >
            {t('heading')}
          </motion.h2>
          
          <motion.p 
            className="text-gray-300 text-base sm:text-lg"
            variants={itemVariants}
          >
            {t('subheading')}
          </motion.p>
        </motion.div>
        
        <motion.div
          variants={sectionVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="w-full mx-auto max-w-full sm:max-w-5xl"
        >
          {/* Komponent zakładek (tabs) */}
          <TabsComponent 
            tabs={tabs}
            theme="brand"
            contentClassName="backdrop-blur-md bg-[#181818]/95 border border-white/10 border-t-0 rounded-b-xl"
          />
        </motion.div>
      </div>
    </section>
  );
};

export default Services;