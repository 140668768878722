import React from 'react';
import { cn } from '../../lib/utils';
import { VariantProps, cva } from "class-variance-authority";

const buttonVariants = cva(
  "relative group border text-center rounded-full transition-all duration-500 uppercase tracking-wider font-normal",
  {
    variants: {
      variant: {
        // Wariant główny - przycisk z efektem świecenia (jasny, z kolorami marki)
        default: "bg-gradient-to-r from-brand-secondary via-brand-primary to-brand-tertiary border-transparent shadow-glow hover:shadow-glow-intense hover:scale-105",
        
        // Drugi wariant - ciemny, bez kolorów marki
        dark: "bg-gradient-to-b from-[#323232] to-[#252525] border border-[#3A3A3A] shadow-[0_3px_10px_rgba(0,0,0,0.3)] hover:shadow-[0_5px_15px_rgba(0,0,0,0.45)] hover:from-[#383838] hover:to-[#2A2A2A] hover:border-[#4A4A4A] hover:translate-y-[-2px] hover:scale-[1.02]",
        
        // Trzeci wariant - jasny (biały) dla navbaru
        navbar: "bg-white border-transparent shadow-[0_3px_10px_rgba(0,0,0,0.15)] hover:shadow-[0_5px_15px_rgba(0,0,0,0.25)] hover:bg-white hover:scale-[1.02] hover:translate-y-[-1px]",
      },
      size: {
        sm: "px-4 py-1.5 text-xs",
        default: "px-6 py-2.5 text-sm",
        lg: "px-8 py-3 text-base",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
  VariantProps<typeof buttonVariants> { 
    icon?: React.ReactNode;
    iconPosition?: 'left' | 'right';
  }

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, size, variant, children, icon, iconPosition = 'right', ...props }, ref) => {
    return (
      <button
        className={cn(buttonVariants({ variant, size }), className)}
        ref={ref}
        {...props}
      >
        {/* Zawartość przycisku */}
        <span className={cn(
          "flex items-center justify-center gap-2 relative z-10",
          // Podstawowo biały tekst dla wszystkich przycisków
          "text-white", 
          // Czarny tekst tylko dla wariantu navbar
          variant === "navbar" && "text-black"
        )}>
          {icon && iconPosition === 'left' && icon}
          {children}
          {icon && iconPosition === 'right' && icon}
        </span>
        
        {/* Dodatkowy efekt gradientu dla głównego przycisku */}
        {variant === 'default' && (
          <span className="absolute inset-0 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-500 bg-gradient-to-r from-brand-tertiary via-brand-primary to-brand-secondary" />
        )}
        
        {/* Subtelny efekt połysku dla ciemnego przycisku */}
        {variant === 'dark' && (
          <span className="absolute inset-0 rounded-full opacity-0 group-hover:opacity-15 transition-opacity duration-500 bg-gradient-to-t from-transparent via-white/5 to-transparent" />
        )}
        
        {/* Subtelny efekt połysku dla białego przycisku */}
        {variant === 'navbar' && (
          <span className="absolute inset-0 rounded-full opacity-0 group-hover:opacity-30 transition-opacity duration-500 bg-gradient-to-t from-transparent via-gray-100/70 to-transparent" />
        )}
      </button>
    );
  }
);

Button.displayName = 'Button';

export { Button, buttonVariants };