import React, { useState, useEffect, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import SectionPreheader from '../common/SectionPreheader';
import { cn } from '../../lib/utils';
import { 
  Carousel, 
  CarouselContent, 
  CarouselItem, 
  CarouselPrevious, 
  CarouselNext,
  CarouselDots
} from '../ui/Carousel';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import { supabase } from '../../lib/supabase/client';

// Typy dla projektów
type ProjectTag = {
  id: string;
  pl_name: string;
  en_name: string;
  slug: string;
};

type ProjectTranslation = {
  id: string;
  project_id: string;
  language: string;
  title: string;
  description: string;
  overview: string;
  challenge: string;
  approach: string;
  results: string;
};

type Project = {
  id: string;
  slug: string;
  pl_slug?: string;
  en_slug?: string;
  project_type: string;
  client: string;
  time_frame: string;
  thumbnail_url: string;
  thumbnail_alt_pl: string;
  thumbnail_alt_en: string;
  created_at: string;
  updated_at: string;
  published: boolean;
  title?: string;
  description?: string;
  project_translations?: ProjectTranslation[];
  project_tags?: { id: string; tags: ProjectTag }[];
  tags?: string[]; // Nazwy tagów po przetworzeniu
};

type ProjectCategory = 'all' | 'social' | 'web' | 'video';

// Komponent karty projektu
const ProjectCard = ({ 
  project,
  index,
  category
}: { 
  project: Project;
  index: number;
  category: ProjectCategory;
}) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  
  // Determine the correct path based on language and category
  const getProjectPath = () => {
    const projectType = project.project_type || category;
    
    // Używamy odpowiedniego sluga w zależności od języka
    let slug = project.slug;
    
    // Jeśli projekt ma oddzielne slugi dla każdego języka
    if (currentLanguage === 'pl' && project.pl_slug) {
      slug = project.pl_slug;
    } else if (currentLanguage === 'en' && project.en_slug) {
      slug = project.en_slug;
    }
    
    // Mapowanie typów projektów na poszczególne języki
    const projectTypeMap: Record<string, { pl: string; en: string }> = {
      'web': { pl: 'strona-internetowa', en: 'website' },
      'social': { pl: 'social-media', en: 'social-media' },
      'video': { pl: 'wideo', en: 'video' }
    };
    
    // Określamy właściwy typ projektu dla URL
    const mappedType = projectTypeMap[projectType]?.[currentLanguage as 'pl' | 'en'] || projectType;
    
    // Dodajemy odpowiedni prefiks językowy oraz ścieżkę projektu
    if (currentLanguage === 'pl') {
      return `/pl/projekt/${mappedType}/${slug}`;
    } else {
      return `/en/project/${mappedType}/${slug}`;
    }
  };

  return (
    <Link 
      to={getProjectPath()}
      className="block h-full group"
    >
      <motion.div
        className="bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl overflow-hidden h-full transition-all duration-300 group-hover:border-brand-primary/40 group-hover:shadow-glow-sm"
        whileHover={{ y: -5 }}
        transition={{ duration: 0.3 }}
      >
        {/* Obrazek projektu */}
        <div className="aspect-video w-full overflow-hidden relative">
          <img
            src={project.thumbnail_url}
            alt={currentLanguage === 'pl' ? project.thumbnail_alt_pl : project.thumbnail_alt_en}
            className="w-full h-full object-cover transition-transform duration-700 ease-in-out group-hover:scale-105"
          />
          
          {/* Gradient overlay na zdjęciu */}
          <div 
            className="absolute inset-0 z-10 opacity-40 transition-opacity duration-300 group-hover:opacity-30"
            style={{ 
              background: 'linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.7) 100%)' 
            }}
          />
          
          {/* Etykiety tagów na zdjęciu */}
          <div className="absolute top-3 left-3 z-20 flex flex-wrap gap-2">
            {project.tags && project.tags.map((tag, i) => (
              <span 
                key={i} 
                className="text-[10px] font-medium py-1 px-2 rounded-full backdrop-blur-md bg-white/10 border border-white/20 text-white"
              >
                {tag}
              </span>
            ))}
          </div>
        </div>
        
        {/* Zawartość karty */}
        <div className="p-5">
          <h3 className="text-lg font-semibold text-white mb-2 group-hover:text-brand-primary transition-colors">
            {project.title}
          </h3>
          <p className="text-sm text-white/70 mb-4 line-clamp-2">
            {project.description}
          </p>
          
          {/* Przycisk */}
          <div className="flex items-center justify-end">
            <span className="text-xs font-medium text-brand-primary flex items-center gap-1 transition-all duration-300 group-hover:gap-2">
              {currentLanguage === 'pl' ? 'Zobacz projekt' : 'View project'}
              <ArrowRight size={12} className="transition-transform duration-300 group-hover:translate-x-1" />
            </span>
          </div>
        </div>
      </motion.div>
    </Link>
  );
};

// Przycisk filtru
const FilterButton = ({ 
  children, 
  active, 
  onClick
}: { 
  children: React.ReactNode; 
  active: boolean; 
  onClick: () => void;
}) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        "py-2 px-4 rounded-full text-sm font-medium transition-all duration-300 focus:outline-none border",
        active 
          ? "bg-white/10 border-white/20 text-white" 
          : "bg-transparent border-white/5 text-white/60 hover:text-white hover:bg-white/5"
      )}
    >
      <span className="relative z-10">
        {children}
      </span>
    </button>
  );
};

// Główny komponent Portfolio
const Portfolio = () => {
  const { t, i18n } = useTranslation(['portfolio']);
  const [api, setApi] = React.useState<any>(null);
  const [activeFilter, setActiveFilter] = useState<ProjectCategory>('all');
  const autoplayRef = React.useRef<any>(null);
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const currentLanguage = i18n.language;
  
  // Pobieranie projektów z bazy danych Supabase
  useEffect(() => {
    fetchProjects();
  }, [currentLanguage]);
  
  const fetchProjects = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // Pobierz projekty z ich tłumaczeniami w odpowiednim języku
      let query = supabase
        .from('projects')
        .select(`
          *,
          project_translations!inner (
            id,
            title,
            description,
            language
          ),
          project_tags (
            id,
            tags:tag_id (
              id,
              pl_name,
              en_name,
              slug
            )
          )
        `)
        .eq('project_translations.language', currentLanguage)
        .eq('published', true);
      
      const { data, error } = await query;
      
      if (error) throw error;
      
      // Przetwarzamy dane, dodając tytuł i opis z tłumaczeń
      const processedData = data.map((project: any) => {
        const translation = project.project_translations.find((t: any) => t.language === currentLanguage);
        const tagNames = project.project_tags.map((pt: any) => 
          currentLanguage === 'pl' ? pt.tags.pl_name : pt.tags.en_name
        );
        
        return {
          ...project,
          title: translation ? translation.title : 'Brak tytułu',
          description: translation ? translation.description : '',
          tags: tagNames,
          project_translations: undefined // Usuwamy zagnieżdżone dane z obiektu
        };
      });
      
      // Dla celów debugowania - wyświetlamy daty utworzenia projektów
      console.log('Projekty przed sortowaniem:');
      processedData.forEach(project => {
        console.log(`${project.title}: created_at=${project.created_at}, updated_at=${project.updated_at}, typ=${project.project_type}`);
      });
      
      setProjects(processedData);
    } catch (error: any) {
      console.error('Błąd pobierania projektów:', error);
      setError('Wystąpił błąd podczas pobierania projektów.');
    } finally {
      setLoading(false);
    }
  };
  
  // Filtrowane projekty w zależności od wybranej kategorii
  const filteredProjects = useMemo(() => {
    // Najpierw kopiujemy tablicę projektów
    let result = [...projects];
    
    console.log(`Przetwarzanie filtru: ${activeFilter}`);
    
    // Jeśli filtr nie jest "wszystkie", filtrujemy według typu projektu
    if (activeFilter !== 'all') {
      result = result.filter(project => project.project_type === activeFilter);
    }
    
    // Zawsze sortujemy według daty utworzenia (od najnowszych)
    result.sort((a, b) => {
      // Wykorzystujemy updated_at jako bardziej wiarygodny wskaźnik "nowości"
      const dateA = new Date(a.updated_at).getTime();
      const dateB = new Date(b.updated_at).getTime();
      
      // Jeśli daty są identyczne, używamy created_at jako dodatkowego kryterium
      if (dateA === dateB) {
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
      }
      
      return dateB - dateA; // Sortowanie malejące (od najnowszych)
    });
    
    // Dla celów debugowania - wyświetlamy filtrowane i posortowane projekty
    console.log(`Projekty po filtracji i sortowaniu dla filtru: ${activeFilter}`);
    result.forEach((project, index) => {
      console.log(`${index}. ${project.title}: updated_at=${project.updated_at}, typ=${project.project_type}`);
    });
    
    return result;
  }, [activeFilter, projects]);
  
  // Inicjalizacja autoplay po załadowaniu danych
  useEffect(() => {
    if (!loading && !error && filteredProjects.length > 0) {
      autoplayRef.current = Autoplay({ delay: 5000, stopOnInteraction: true });
    }
  }, [loading, error, filteredProjects]);
  
  // Kategorie projektów
  const projectCategories = [
    { id: 'all', label: t('filters.all') },
    { id: 'social', label: t('filters.social') },
    { id: 'web', label: t('filters.web') },
    { id: 'video', label: t('filters.video') }
  ];
  
  // Obsługa zatrzymania i wznowienia autoplay przy najeżdżaniu myszką
  const onMouseEnter = React.useCallback(() => {
    if (api?.plugins()?.autoplay) {
      api.plugins().autoplay.stop();
    }
  }, [api]);

  const onMouseLeave = React.useCallback(() => {
    if (api?.plugins()?.autoplay) {
      // Dodajemy dodatkowe sprawdzenie dla bezpieczeństwa
      try {
        api.plugins().autoplay.play();
      } catch (err) {
        console.log('Autoplay error:', err);
      }
    }
  }, [api]);
  
  // Wywołaj scrollTo(0) również przy zmianie projektów
  useEffect(() => {
    if (api) {
      api.scrollTo(0);
      console.log("Przesunięto karuzelę do pierwszego elementu");
    }
  }, [filteredProjects, api]);

  // Ustawienie wartości plugins dla karuzeli
  const getPlugins = () => {
    if (autoplayRef.current && !loading && !error && filteredProjects.length > 0) {
      return [autoplayRef.current];
    }
    return []; // Pusta tablica, gdy nie ma projektów lub trwa ładowanie
  };
  
  return (
    <section id="portfolio" className="w-full relative py-24 sm:py-28 overflow-hidden">
      {/* Efekt gradientu po lewej stronie */}
      <div className="absolute left-0 top-0 bottom-0 pointer-events-none">
        <div 
          className="absolute left-0 top-1/2 transform -translate-y-1/2 w-[60vw] h-[70vh]"
          style={{
            background: `
              radial-gradient(
                ellipse at 0% 50%, 
                rgba(255, 90, 31, 0.55) 0%,
                rgba(255, 90, 31, 0.45) 10%,
                rgba(255, 125, 58, 0.3) 30%, 
                rgba(255, 159, 69, 0.15) 50%,
                rgba(255, 210, 100, 0.08) 70%,
                transparent 90%
              )
            `,
            filter: 'blur(70px)',
          }}
        />
      </div>
      
      <div className="container mx-auto px-6 sm:px-8 md:px-10 lg:px-12 relative z-10">
        <div className="max-w-3xl mx-auto text-center mb-16">
          {/* Preheader */}
          <SectionPreheader 
            text={t('preheader')} 
            variant="tertiary" 
            className="mb-8" 
          />
          
          <h2 className="text-3xl md:text-4xl font-bold mb-6 text-white">
            {t('heading')}
          </h2>
          
          <p className="text-gray-300 text-lg mb-10">
            {t('subheading')}
          </p>
          
          {/* Przyciski filtrów */}
          <div className="flex flex-wrap justify-center gap-3 mb-12">
            {projectCategories.map((category) => (
              <FilterButton
                key={category.id}
                active={activeFilter === category.id as ProjectCategory}
                onClick={() => setActiveFilter(category.id as ProjectCategory)}
              >
                {category.label}
              </FilterButton>
            ))}
          </div>
        </div>
        
        {/* Karuzela projektów */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="max-w-6xl mx-auto px-4"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <AnimatePresence mode="wait">
            {loading ? (
              <div className="flex justify-center items-center py-20">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-brand-primary"></div>
              </div>
            ) : error ? (
              <div className="bg-white/5 backdrop-blur-sm border border-red-500/10 text-center py-10 px-4 rounded-lg">
                <p className="text-red-400">{error}</p>
                <button 
                  onClick={fetchProjects}
                  className="mt-4 px-4 py-2 bg-white/10 hover:bg-white/20 text-white rounded-md transition-colors"
                >
                  Spróbuj ponownie
                </button>
              </div>
            ) : filteredProjects.length === 0 ? (
              <div className="bg-white/5 backdrop-blur-sm border border-white/10 text-center py-10 px-4 rounded-lg">
                <p className="text-white/70">{t('noProjects')}</p>
              </div>
            ) : (
              <motion.div
                key={activeFilter}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.4 }}
              >
                <Carousel
                  key={`carousel-${activeFilter}-${filteredProjects.length}`}
                  opts={{
                    loop: false, // Wyłączamy opcję loop, która może powodować problemy z kolejnością
                    align: "start", // Zmieniamy align na "start" zamiast "center"
                    skipSnaps: false,
                    startIndex: 0,
                    containScroll: "trimSnaps"
                  }}
                  plugins={getPlugins()}
                  setApi={setApi}
                  className="w-full"
                >
                  <CarouselContent className="py-4">
                    {filteredProjects.map((project, index) => (
                      <CarouselItem 
                        key={`${project.id}-${index}`} 
                        className="md:basis-1/2 lg:basis-1/3 pt-4 h-auto"
                      >
                        <div className="h-full px-2">
                          <ProjectCard
                            project={project}
                            index={index}
                            category={activeFilter}
                          />
                        </div>
                      </CarouselItem>
                    ))}
                  </CarouselContent>
                  
                  {/* Nawigacja karuzeli */}
                  <div className="hidden md:block">
                    <CarouselPrevious className="-left-5 lg:-left-12" />
                    <CarouselNext className="-right-5 lg:-right-12" />
                  </div>
                  
                  {/* Kropki nawigacyjne */}
                  <div className="mt-8">
                    <CarouselDots />
                  </div>
                </Carousel>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </div>
    </section>
  );
};

export default Portfolio;