import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, ChevronLeft, ChevronRight, Play, Pause } from 'lucide-react';

interface ImageGalleryModalProps {
  isOpen: boolean;
  onClose: () => void;
  images: string[];
  alts: string[];
  initialIndex: number;
}

const ImageGalleryModal: React.FC<ImageGalleryModalProps> = ({ 
  isOpen, 
  onClose, 
  images, 
  alts, 
  initialIndex = 0 
}) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [isLoading, setIsLoading] = useState(true);
  const [autoplay, setAutoplay] = useState(false);
  const autoplayIntervalRef = useRef<NodeJS.Timeout | null>(null);
  
  // Reset loading state when image changes
  useEffect(() => {
    setIsLoading(true);
  }, [currentIndex]);

  // Autoplay functionality
  useEffect(() => {
    if (autoplay && isOpen) {
      autoplayIntervalRef.current = setInterval(() => {
        navigateNext();
      }, 3000);
    }
    
    return () => {
      if (autoplayIntervalRef.current) {
        clearInterval(autoplayIntervalRef.current);
        autoplayIntervalRef.current = null;
      }
    };
  }, [autoplay, isOpen, currentIndex]);

  // Prevent scrolling when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!isOpen) return;
      
      if (e.key === 'Escape') {
        onClose();
      } else if (e.key === 'ArrowLeft') {
        navigatePrevious();
      } else if (e.key === 'ArrowRight') {
        navigateNext();
      } else if (e.key === ' ') {
        // Space toggles autoplay
        setAutoplay(prev => !prev);
        e.preventDefault();
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, currentIndex, images.length]);

  // Navigate to next and previous images
  const navigateNext = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };
  
  const navigatePrevious = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const toggleAutoplay = () => {
    setAutoplay(prev => !prev);
  };

  // Animation variants
  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.2 } },
    exit: { opacity: 0, transition: { duration: 0.2 } }
  };
  
  const modalVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: { 
      opacity: 1, 
      scale: 1, 
      transition: { 
        duration: 0.3,
        ease: [0.22, 1, 0.36, 1]
      } 
    },
    exit: { 
      opacity: 0, 
      scale: 0.95, 
      transition: { 
        duration: 0.2
      } 
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed inset-0 z-[9999] flex items-center justify-center bg-black/80 backdrop-blur-sm"
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={overlayVariants}
          onClick={onClose} // Close when clicking the backdrop
        >
          {/* Main modal container - stop propagation to prevent close when clicking inside */}
          <motion.div
            className="relative w-full max-w-6xl mx-auto rounded-lg p-2 md:p-4 max-h-[90vh]"
            variants={modalVariants}
            onClick={(e) => e.stopPropagation()}
          >
            {/* Close button */}
            <button
              className="absolute top-4 right-4 md:top-6 md:right-6 z-50 text-white bg-black/50 hover:bg-black/70 p-2 rounded-full transition-colors"
              onClick={onClose}
            >
              <X size={20} />
            </button>
            
            {/* Image container */}
            <div className="relative flex items-center justify-center w-full h-full overflow-hidden rounded-lg">
              {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center bg-black/30">
                  <div className="w-8 h-8 border-4 border-white/10 border-t-brand-primary rounded-full animate-spin"></div>
                </div>
              )}
              
              <img
                src={images[currentIndex]}
                alt={alts[currentIndex] || `Zdjęcie ${currentIndex + 1}`}
                className="max-h-[80vh] max-w-full object-contain"
                onLoad={() => setIsLoading(false)}
              />
              
              {/* Navigation controls for desktop */}
              <div className="absolute inset-0 flex items-center justify-between px-4">
                <button 
                  className="w-10 h-10 md:w-12 md:h-12 bg-black/50 hover:bg-black/70 rounded-full flex items-center justify-center text-white transition-colors transform hover:scale-105"
                  onClick={(e) => { e.stopPropagation(); navigatePrevious(); }}
                >
                  <ChevronLeft size={24} />
                </button>
                <button 
                  className="w-10 h-10 md:w-12 md:h-12 bg-black/50 hover:bg-black/70 rounded-full flex items-center justify-center text-white transition-colors transform hover:scale-105"
                  onClick={(e) => { e.stopPropagation(); navigateNext(); }}
                >
                  <ChevronRight size={24} />
                </button>
              </div>
              
              {/* Image counter & autoplay button */}
              <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex gap-3 items-center">
                <button
                  onClick={(e) => { e.stopPropagation(); toggleAutoplay(); }}
                  className={`px-3 py-1.5 rounded-full flex items-center gap-2 transition-colors ${
                    autoplay 
                      ? 'bg-brand-primary text-white' 
                      : 'bg-black/60 text-white hover:bg-black/70'
                  }`}
                >
                  {autoplay ? <Pause size={14} /> : <Play size={14} />}
                  <span className="text-sm">
                    {autoplay ? 'Zatrzymaj' : 'Odtwarzaj'}
                  </span>
                </button>
                <div className="px-3 py-1.5 rounded-full bg-black/60 text-white text-sm">
                  {currentIndex + 1} / {images.length}
                </div>
              </div>
            </div>
            
            {/* Image thumbnails */}
            <div className="flex items-center justify-center mt-4 max-w-full overflow-x-auto">
              <div className="flex gap-2 p-2">
                {images.map((image, index) => (
                  <button
                    key={index}
                    className={`w-16 h-16 rounded-lg overflow-hidden border-2 transition-all flex-shrink-0 ${
                      index === currentIndex 
                        ? 'border-brand-primary scale-110 shadow-lg shadow-brand-primary/20' 
                        : 'border-transparent opacity-70 hover:opacity-100'
                    }`}
                    onClick={() => setCurrentIndex(index)}
                  >
                    <img
                      src={image}
                      alt={alts[index] || `Miniatura ${index + 1}`}
                      className="w-full h-full object-cover"
                    />
                  </button>
                ))}
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ImageGalleryModal;
