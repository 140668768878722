import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

// Import zdjęć z folderu assets
import homeHero01 from '../../../assets/image/home-hero-01.webp';
import homeHero02 from '../../../assets/image/home-hero-02.webp';
import homeHero03 from '../../../assets/image/home-hero-03.webp';
import homeHero04 from '../../../assets/image/home-hero-04.webp';

// Ścieżki do dodanych zdjęć
const heroImages = [
  homeHero01,
  homeHero02,
  homeHero03,
  homeHero04,
];

const FloatingImages = () => {
  // Stan do śledzenia, czy wyświetlamy na urządzeniu mobilnym
  const [isMobile, setIsMobile] = useState(false);
  
  // Efekt sprawdzający rozmiar ekranu
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    // Sprawdź przy inicjalizacji
    checkIfMobile();
    
    // Dodaj nasłuchiwacz na zmianę rozmiaru okna
    window.addEventListener('resize', checkIfMobile);
    
    // Usuń nasłuchiwacz przy odmontowaniu komponentu
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  // Konfiguracja dla unoszących się zdjęć
  const floatingImages = [
    // Zdjęcie 1
    {
      width: '200px',
      height: '140px',
      right: '12%',
      top: '18%',
      rotate: 8,
      animationDuration: 18,
      animationDelay: 0,
      yOffset: 15,
      imageIndex: 0,
      objectPosition: 'center',
    },
    // Zdjęcie 2
    {
      width: '180px',
      height: '240px',
      left: '8%',
      bottom: '20%',
      rotate: -6,
      animationDuration: 20,
      animationDelay: 2,
      yOffset: 12,
      imageIndex: 1,
      objectPosition: 'center',
    },
    // Zdjęcie 3
    {
      width: '160px',
      height: '220px',
      right: '20%',
      bottom: '25%',
      rotate: 10,
      animationDuration: 16,
      animationDelay: 4,
      yOffset: 10,
      imageIndex: 2,
      objectPosition: 'center',
    },
    // Zdjęcie 4
    {
      width: '180px',
      height: '130px',
      left: '22%',
      top: '22%',
      rotate: -12,
      animationDuration: 22,
      animationDelay: 1,
      yOffset: 18,
      imageIndex: 3,
      objectPosition: 'top',
    },
  ];
  
  // Ustal nieprzezroczystość w zależności od rozmiaru ekranu
  const baseOpacity = isMobile ? 0.65 : 0.9;

  return (
    <>
      {floatingImages.map((image, index) => (
        <motion.div
          key={index}
          className="absolute"
          style={{
            left: image.left,
            right: image.right,
            top: image.top,
            bottom: image.bottom,
            zIndex: 1,
            perspective: '1000px',
            // Ukryj wszystkie zdjęcia poza pierwszym na urządzeniach mobilnych
            display: isMobile && index !== 0 ? 'none' : 'block',
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: baseOpacity }}
          transition={{ duration: 2, delay: index * 0.3 }}
        >
          <motion.div
            className="relative overflow-hidden rounded-xl shadow-lg"
            style={{
              width: image.width,
              height: image.height,
              transformStyle: 'preserve-3d',
            }}
            animate={{
              y: [0, image.yOffset, 0],
              rotateX: [0, 2, 0],
              rotateY: [0, 2, 0],
            }}
            transition={{
              duration: image.animationDuration,
              repeat: Infinity,
              repeatType: 'reverse',
              ease: 'easeInOut',
              delay: image.animationDelay,
            }}
          >
            {/* Zdjęcie bez tła i z mocnym zaokrągleniem */}
            <div className="absolute inset-0 w-full h-full">
              <img 
                src={heroImages[image.imageIndex % heroImages.length]} 
                alt="Portfolio sample" 
                className="w-full h-full object-cover rounded-xl"
                style={{ objectPosition: image.objectPosition }}
              />
              {/* Nakład dla lepszej integracji - nieco mocniejszy dla czytelności */}
              <div className="absolute inset-0 bg-black/45 backdrop-blur-[1px] rounded-xl"></div>
            </div>
          </motion.div>
        </motion.div>
      ))}
    </>
  );
};

export default FloatingImages;
