import React from 'react';
import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { motion } from 'framer-motion';
import { supabase } from '../../lib/supabase/client';
import { cn } from '../../lib/utils';
import { Button } from '../../components/ui/Button';
import { Save, Loader2, AlertTriangle, X, Plus, Trash, Upload, Image as ImageIcon, Trophy, Users } from 'lucide-react';
import { RadioGroup } from '../../components/ui/RadioGroup';

// Interfejsy dla tagów
interface Tag {
  id: string;
  pl_name: string;
  en_name: string;
}

// Interfejs dla danych formularza
interface SocialProjectFormData {
  // Podstawowe informacje
  title: string;
  title_en: string;
  pl_slug: string;
  en_slug: string;
  thumbnailUrl: string;
  thumbnailAltPl: string;
  thumbnailAltEn: string;
  published: boolean;

  // Tagi
  tags: string[];

  // Treść
  descriptionPl: string;
  descriptionEn: string;
  overviewPl: string;
  overviewEn: string;
  resultsPl: string;
  resultsEn: string;

  // Funkcjonalności (wcześniej osiągnięcia)
  features: Array<{
    titlePl: string;
    titleEn: string;
    descriptionPl: string;
    descriptionEn: string;
  }>;

  // Galeria
  gallery: Array<{
    imageUrl: string;
    altPl: string;
    altEn: string;
  }>;

  // Klient/Opinie
  testimonials: Array<{
    companyName: string;
    authorName: string;
    contentPl: string;
    contentEn: string;
    logoUrl?: string;
    originalLanguage: string;
  }>;
}

interface SocialProjectFormProps {
  onSubmit: (data: SocialProjectFormData) => void;
  initialData?: Partial<SocialProjectFormData>;
  isLoading?: boolean;
}

const SocialProjectForm: React.FC<SocialProjectFormProps> = ({
  onSubmit,
  initialData,
  isLoading = false
}) => {
  const [activeTab, setActiveTab] = useState('basic');
  const [error, setError] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isDraft, setIsDraft] = useState(!initialData?.published);
  const [tags, setTags] = useState<Tag[]>([]);

  const { register, handleSubmit, control, setValue, formState: { errors }, watch, trigger } = useForm<SocialProjectFormData>({
    defaultValues: {
      ...initialData,
      tags: initialData?.tags || [],
      published: initialData?.published || false,
      thumbnailUrl: initialData?.thumbnailUrl || '',
      title_en: initialData?.title_en || '',
      features: initialData?.features || [{ titlePl: '', titleEn: '', descriptionPl: '', descriptionEn: '' }],
      gallery: initialData?.gallery || [{ imageUrl: '', altPl: '', altEn: '' }],
      testimonials: initialData?.testimonials || [{ companyName: '', authorName: '', contentPl: '', contentEn: '', originalLanguage: 'pl' }],
    },
    mode: 'onSubmit'
  });

  // Pobieranie tagów z bazy danych
  useEffect(() => {
    const fetchTags = async () => {
      const { data, error } = await supabase
        .from('tags')
        .select('*')
        .order('pl_name');
      
      if (error) {
        console.error('Błąd pobierania tagów:', error);
        return;
      }
      
      setTags(data || []);
    };
    
    fetchTags();
  }, []);

  // Obsługa przesyłania plików do Supabase Storage
  const uploadFile = async (file: File, bucket: string = 'projects/uploads') => {
    setIsUploading(true);
    setError(null);
    
    try {
      // Generuj unikalną nazwę pliku
      const fileExt = file.name.split('.').pop();
      const timestamp = Date.now();
      const randomNumber = Math.floor(Math.random() * 10000);
      const fileName = `${timestamp}-${randomNumber}.${fileExt}`;
      
      // Prześlij plik do Supabase Storage
      const { data, error } = await supabase.storage
        .from(bucket)
        .upload(fileName, file, {
          cacheControl: '3600',
          upsert: false
        });
      
      if (error) throw error;
      
      // Pobierz publiczny URL pliku
      const { data: { publicUrl } } = supabase.storage
        .from(bucket)
        .getPublicUrl(fileName);
      
      return publicUrl;
    } catch (error: any) {
      console.error('Błąd przesyłania pliku:', error);
      setError(`Błąd przesyłania pliku: ${error.message}`);
      return null;
    } finally {
      setIsUploading(false);
    }
  };
  
  // Obsługa przesyłania miniaturki
  const handleThumbnailUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    
    const url = await uploadFile(file);
    if (url) {
      setValue('thumbnailUrl', url);
      trigger('thumbnailUrl');
    }
  };

  // Dodawanie nowej funkcjonalności
  const addFeature = () => {
    const features = watch('features') || [];
    setValue('features', [
      ...features,
      { titlePl: '', titleEn: '', descriptionPl: '', descriptionEn: '' }
    ]);
  };

  // Usuwanie funkcjonalności
  const removeFeature = (index: number) => {
    const features = watch('features') || [];
    setValue('features', features.filter((_, i) => i !== index));
  };

  // Aktualizacja pól funkcjonalności
  const updateFeatureField = (index: number, field: string, value: string) => {
    const features = watch('features') || [];
    const updatedFeatures = [...features];
    updatedFeatures[index] = {
      ...updatedFeatures[index],
      [field]: value
    };
    setValue('features', updatedFeatures);
  };

  // Wysyłanie formularza
  const handleFormSubmit = async (data: SocialProjectFormData) => {
    onSubmit(data);
  };

  // Lista zakładek
  const tabs = [
    { id: 'basic', label: 'Podstawowe informacje' },
    { id: 'content', label: 'Treść' },
    { id: 'features', label: 'Osiągnięcia' },
    { id: 'gallery', label: 'Galeria' },
    { id: 'testimonials', label: 'Klient' }
  ];

  return (
    <div>
      {error && (
        <div className="bg-red-500/10 border border-red-500/30 rounded-lg p-4 mb-6 flex items-center gap-3">
          <AlertTriangle size={20} className="text-red-500" />
          <p className="text-red-200 text-sm">{error}</p>
          <button 
            onClick={() => setError(null)}
            className="ml-auto text-white/70 hover:text-white"
          >
            <X size={16} />
          </button>
        </div>
      )}
    
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        {/* Nawigacja */}
        <div className="mb-8 border-b border-white/10 pb-1">
          <div className="flex flex-wrap -mb-px">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                type="button"
                onClick={() => setActiveTab(tab.id)}
                className={cn(
                  "px-5 py-2.5 font-medium text-sm transition-colors relative",
                  activeTab === tab.id 
                    ? "text-brand-primary" 
                    : "text-white/60 hover:text-white"
                )}
              >
                {tab.label}
                {activeTab === tab.id && (
                  <motion.div 
                    layoutId="activeTab"
                    className="absolute bottom-0 left-0 right-0 h-0.5 bg-brand-primary"
                  />
                )}
              </button>
            ))}
          </div>
        </div>
        
        {/* Tab: Podstawowe informacje */}
        {activeTab === 'basic' && (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-white mb-4">Podstawowe informacje</h2>
            
            {/* Tytuł PL */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Tytuł projektu (PL)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <input
                type="text"
                {...register('title', { required: 'Tytuł w języku polskim jest wymagany' })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                placeholder="Nazwa projektu po polsku"
              />
              {errors.title && (
                <p className="mt-2 text-sm text-red-400">{errors.title.message}</p>
              )}
            </div>
            
            {/* Tytuł EN */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Tytuł projektu (EN)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <input
                type="text"
                {...register('title_en', { required: 'Tytuł w języku angielskim jest wymagany' })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                placeholder="Nazwa projektu po angielsku"
              />
              {errors.title_en && (
                <p className="mt-2 text-sm text-red-400">{errors.title_en.message}</p>
              )}
            </div>
            
            {/* Slug PL */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Slug (URL PL)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <input
                type="text"
                {...register('pl_slug', { 
                  required: 'Polski slug jest wymagany',
                  pattern: {
                    value: /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
                    message: 'Slug może zawierać tylko małe litery, cyfry i myślniki'
                  }
                })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                placeholder="tytul-projektu-pl"
              />
              {errors.pl_slug && (
                <p className="mt-2 text-sm text-red-400">{errors.pl_slug.message}</p>
              )}
              <p className="mt-1 text-xs text-white/50">
                Ten URL będzie używany w polskiej wersji strony
              </p>
            </div>
            
            {/* Slug EN */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Slug (URL EN)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <input
                type="text"
                {...register('en_slug', { 
                  required: 'Angielski slug jest wymagany',
                  pattern: {
                    value: /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
                    message: 'Slug może zawierać tylko małe litery, cyfry i myślniki'
                  }
                })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                placeholder="project-title-en"
              />
              {errors.en_slug && (
                <p className="mt-2 text-sm text-red-400">{errors.en_slug.message}</p>
              )}
              <p className="mt-1 text-xs text-white/50">
                Ten URL będzie używany w angielskiej wersji strony
              </p>
            </div>
            
            {/* Miniatura */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Miniatura projektu
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <div className="space-y-4">
                {watch('thumbnailUrl') && (
                  <div className="relative w-full aspect-video rounded-lg overflow-hidden border border-white/10">
                    <img 
                      src={watch('thumbnailUrl')} 
                      alt="Podgląd miniatury"
                      className="w-full h-full object-cover"
                    />
                    <button
                      type="button"
                      onClick={() => setValue('thumbnailUrl', '')}
                      className="absolute top-2 right-2 p-1.5 rounded-md bg-black/50 text-white/70 hover:bg-red-500/50 hover:text-white transition-colors"
                    >
                      <Trash size={16} />
                    </button>
                  </div>
                )}
                
                <div className="relative">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleThumbnailUpload}
                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                  />
                  <div className="w-full px-4 py-8 rounded-lg bg-white/5 border border-white/10 border-dashed flex flex-col items-center justify-center gap-2 text-white/70 hover:bg-white/8 hover:border-brand-primary/30 transition-all cursor-pointer">
                    <div className="p-3 rounded-full bg-white/5">
                      {watch('thumbnailUrl') ? (
                        <ImageIcon size={24} />
                      ) : (
                        <Upload size={24} />
                      )}
                    </div>
                    <div className="text-sm text-center">
                      {watch('thumbnailUrl') 
                        ? 'Kliknij aby zmienić miniaturę' 
                        : 'Kliknij aby dodać miniaturę lub upuść plik tutaj'}
                    </div>
                    <div className="text-xs text-white/50">
                      PNG, JPG lub WEBP (max. 2MB)
                    </div>
                  </div>
                </div>
              </div>
              {errors.thumbnailUrl && (
                <p className="mt-2 text-sm text-red-400">{errors.thumbnailUrl.message}</p>
              )}
            </div>

            {/* Tekst alternatywny miniatury (PL) */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Tekst alternatywny miniatury (PL)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <input
                type="text"
                {...register('thumbnailAltPl', { required: 'Tekst alternatywny (PL) jest wymagany' })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                placeholder="Opis miniatury po polsku"
              />
              {errors.thumbnailAltPl && (
                <p className="mt-2 text-sm text-red-400">{errors.thumbnailAltPl.message}</p>
              )}
            </div>

            {/* Tekst alternatywny miniatury (EN) */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Tekst alternatywny miniatury (EN)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <input
                type="text"
                {...register('thumbnailAltEn', { required: 'Tekst alternatywny (EN) jest wymagany' })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                placeholder="Opis miniatury po angielsku"
              />
              {errors.thumbnailAltEn && (
                <p className="mt-2 text-sm text-red-400">{errors.thumbnailAltEn.message}</p>
              )}
            </div>

            {/* Tagi */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Tagi projektu
              </label>
              <Controller
                name="tags"
                control={control}
                render={({ field }) => (
                  <div className="flex flex-wrap gap-2 mb-6">
                    {tags.map((tag) => (
                      <button
                        key={tag.id}
                        type="button"
                        onClick={() => {
                          const isSelected = field.value.includes(tag.id);
                          const newValue = isSelected
                            ? field.value.filter(id => id !== tag.id)
                            : [...field.value, tag.id];
                          field.onChange(newValue);
                        }}
                        className={cn(
                          "px-3 py-1.5 rounded-full text-sm transition-all duration-300",
                          field.value.includes(tag.id)
                            ? "bg-brand-primary/20 text-brand-primary border border-brand-primary/30"
                            : "bg-white/5 text-white/70 border border-white/10 hover:bg-white/10"
                        )}
                      >
                        {tag.pl_name}
                      </button>
                    ))}
                  </div>
                )}
              />
            </div>
          </div>
        )}
        
        {/* Tab: Treść */}
        {activeTab === 'content' && (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-white mb-4">Treść projektu</h2>
            
            {/* Opis projektu (PL) */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Opis (PL)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <textarea
                {...register('descriptionPl', { 
                  required: "Opis projektu jest wymagany"
                })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                rows={3}
                placeholder="Krótki opis projektu wyświetlany na liście projektów (2-3 zdania)"
              />
              {errors.descriptionPl && (
                <p className="mt-2 text-sm text-red-400">{errors.descriptionPl.message}</p>
              )}
            </div>
            
            {/* Opis projektu (EN) */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Opis (EN)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <textarea
                {...register('descriptionEn', { 
                  required: "Opis projektu po angielsku jest wymagany"
                })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                rows={3}
                placeholder="A short project description displayed in the project list (2-3 sentences)"
              />
              {errors.descriptionEn && (
                <p className="mt-2 text-sm text-red-400">{errors.descriptionEn.message}</p>
              )}
            </div>
            
            {/* Przegląd projektu (PL) */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Przegląd projektu (PL)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <textarea
                {...register('overviewPl', { required: "Przegląd projektu jest wymagany" })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                rows={4}
                placeholder="Szczegółowy opis projektu i jego założeń"
              />
              {errors.overviewPl && (
                <p className="mt-2 text-sm text-red-400">{errors.overviewPl.message}</p>
              )}
            </div>
            
            {/* Przegląd projektu (EN) */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Przegląd projektu (EN)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <textarea
                {...register('overviewEn', { required: "Przegląd projektu po angielsku jest wymagany" })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                rows={4}
                placeholder="Detailed description of the project and its objectives"
              />
              {errors.overviewEn && (
                <p className="mt-2 text-sm text-red-400">{errors.overviewEn.message}</p>
              )}
            </div>
            
            {/* Rezultaty (PL) */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Rezultaty (PL)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <textarea
                {...register('resultsPl', { required: "Rezultaty w języku polskim są wymagane" })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                rows={4}
                placeholder="Opis rezultatów projektu po polsku"
              />
              {errors.resultsPl && (
                <p className="mt-2 text-sm text-red-400">{errors.resultsPl.message}</p>
              )}
            </div>
            
            {/* Rezultaty (EN) */}
            <div>
              <label className="block mb-2 text-sm font-medium text-white/80">
                Rezultaty (EN)
                <span className="text-brand-primary ml-1">*</span>
              </label>
              <textarea
                {...register('resultsEn', { required: "Rezultaty w języku angielskim są wymagane" })}
                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                rows={4}
                placeholder="Project results description in English"
              />
              {errors.resultsEn && (
                <p className="mt-2 text-sm text-red-400">{errors.resultsEn.message}</p>
              )}
            </div>
          </div>
        )}
        
        {/* Tab: Funkcjonalności */}
        {activeTab === 'features' && (
          <div className="space-y-6">
            <Controller
              name="features"
              control={control}
              render={({ field }) => (
                <div>
                  <div className="flex justify-between items-center mb-4">
                    <label className="text-sm font-medium text-white/80">
                      Osiągnięcia projektu
                    </label>
                    <Button
                      type="button"
                      variant="dark"
                      size="sm"
                      icon={<Plus size={16} />}
                      onClick={addFeature}
                    >
                      Dodaj osiągnięcie
                    </Button>
                  </div>
                  
                  <div className="space-y-6">
                    {(field.value || []).map((feature, index) => (
                      <div 
                        key={index}
                        className="p-4 bg-white/5 border border-white/10 rounded-lg relative"
                      >
                        <button
                          type="button"
                          onClick={() => removeFeature(index)}
                          className="absolute top-2 right-2 p-1.5 rounded-md bg-white/5 text-white/70 hover:bg-red-500/20 hover:text-red-400 transition-colors"
                        >
                          <Trash size={16} />
                        </button>
                        
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                          {/* Tytuł PL */}
                          <div>
                            <label className="block mb-2 text-sm font-medium text-white/80">
                              Tytuł (PL)
                              <span className="text-brand-primary ml-1">*</span>
                            </label>
                            <input
                              type="text"
                              value={feature?.titlePl || ''}
                              onChange={(e) => updateFeatureField(index, 'titlePl', e.target.value)}
                              className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                              placeholder="Tytuł osiągnięcia po polsku"
                            />
                          </div>
                          
                          {/* Tytuł EN */}
                          <div>
                            <label className="block mb-2 text-sm font-medium text-white/80">
                              Tytuł (EN)
                              <span className="text-brand-primary ml-1">*</span>
                            </label>
                            <input
                              type="text"
                              value={feature?.titleEn || ''}
                              onChange={(e) => updateFeatureField(index, 'titleEn', e.target.value)}
                              className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                              placeholder="Achievement title in English"
                            />
                          </div>
                        </div>
                        
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          {/* Opis PL */}
                          <div>
                            <label className="block mb-2 text-sm font-medium text-white/80">
                              Opis (PL)
                              <span className="text-brand-primary ml-1">*</span>
                            </label>
                            <textarea
                              value={feature?.descriptionPl || ''}
                              onChange={(e) => updateFeatureField(index, 'descriptionPl', e.target.value)}
                              className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                              rows={3}
                              placeholder="Opis osiągnięcia po polsku"
                            />
                          </div>
                          
                          {/* Opis EN */}
                          <div>
                            <label className="block mb-2 text-sm font-medium text-white/80">
                              Opis (EN)
                              <span className="text-brand-primary ml-1">*</span>
                            </label>
                            <textarea
                              value={feature?.descriptionEn || ''}
                              onChange={(e) => updateFeatureField(index, 'descriptionEn', e.target.value)}
                              className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                              rows={3}
                              placeholder="Achievement description in English"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                    
                    {(!field.value || field.value.length === 0) && (
                      <div className="p-8 rounded-lg border border-dashed border-white/20 bg-white/5 text-center">
                        <Trophy size={36} className="text-white/20 mx-auto mb-3" />
                        <p className="text-white/60 mb-1">Brak osiągnięć</p>
                        <p className="text-sm text-white/40">Kliknij "Dodaj osiągnięcie" aby rozpocząć</p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            />
          </div>
        )}
        
        {/* Tab: Galeria */}
        {activeTab === 'gallery' && (
          <div className="space-y-6">
            <div>
              <div className="flex justify-between items-center mb-4">
                <label className="text-sm font-medium text-white/80">
                  Galeria projektu
                </label>
                <Button
                  type="button"
                  variant="dark"
                  size="sm"
                  onClick={() => {
                    const gallery = watch('gallery') || [];
                    setValue('gallery', [...gallery, { imageUrl: '', altPl: '', altEn: '' }]);
                  }}
                  className="flex items-center gap-1"
                >
                  <Plus size={16} />
                  <span>Dodaj zdjęcie</span>
                </Button>
              </div>
              
              <div className="space-y-6">
                {watch('gallery')?.map((item, index) => (
                  <div 
                    key={index}
                    className="p-4 bg-white/5 border border-white/10 rounded-lg relative"
                  >
                    <button
                      type="button"
                      onClick={() => {
                        const gallery = [...watch('gallery')];
                        gallery.splice(index, 1);
                        setValue('gallery', gallery);
                      }}
                      className="absolute top-2 right-2 p-1.5 rounded-md bg-white/5 text-white/70 hover:bg-red-500/20 hover:text-red-400 transition-colors"
                    >
                      <Trash size={16} />
                    </button>
                    
                    <div className="mb-4">
                      <label className="block mb-2 text-sm font-medium text-white/80">
                        Zdjęcie (przesłane z komputera)
                        <span className="text-brand-primary ml-1">*</span>
                      </label>
                      
                      {/* Wyświetlanie podglądu obrazu */}
                      {item?.imageUrl && (
                        <div className="mb-2 relative">
                          <div className="relative w-full h-48 bg-white/5 rounded-lg overflow-hidden">
                            <img 
                              src={item.imageUrl} 
                              alt={item.altPl || "Podgląd obrazu"} 
                              className="w-full h-full object-contain" 
                            />
                          </div>
                        </div>
                      )}
                      
                      {/* Upload zdjęcia z komputera */}
                      <div className="flex items-center space-x-3">
                        <label className="cursor-pointer flex-1">
                          <div className="w-full px-4 py-3 rounded-lg bg-white/5 border border-white/10 text-white hover:bg-white/10 transition-colors flex items-center justify-center">
                            <Upload size={18} className="mr-2 text-white/70" />
                            <span>Wybierz plik</span>
                          </div>
                          <input
                            type="file"
                            accept="image/*"
                            className="hidden"
                            onChange={async (e) => {
                              const file = e.target.files?.[0];
                              if (file) {
                                if (file.size > 3 * 1024 * 1024) {
                                  setError(
                                    'Maksymalny rozmiar pliku to 3MB. Proszę wybrać mniejszy plik lub zoptymalizować ten.'
                                  );
                                  return;
                                }
                                
                                // Generujemy unikalną nazwę pliku
                                const fileExt = file.name.split('.').pop();
                                const fileName = `${Date.now()}.${fileExt}`;
                                const filePath = `gallery/${fileName}`;
                                
                                // Przesyłamy plik do storage
                                try {
                                  setIsUploading(true);
                                  const { error: uploadError } = await supabase.storage
                                    .from('projects')
                                    .upload(filePath, file);
                                
                                  if (uploadError) {
                                    throw uploadError;
                                  }
                                
                                  // Pobieramy publiczny URL do pliku
                                  const { data } = supabase.storage
                                    .from('projects')
                                    .getPublicUrl(filePath);
                                
                                  // Aktualizujemy stan
                                  const gallery = [...watch('gallery')];
                                  gallery[index].imageUrl = data.publicUrl;
                                  setValue('gallery', gallery);
                                  setError(null);
                                } catch (error: any) {
                                  setError(`Błąd podczas przesyłania pliku: ${error.message}`);
                                } finally {
                                  setIsUploading(false);
                                }
                              }
                            }}
                          />
                        </label>
                      </div>
                    </div>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      {/* Alt PL */}
                      <div>
                        <label className="block mb-2 text-sm font-medium text-white/80">
                          Tekst alternatywny (PL)
                          <span className="text-brand-primary ml-1">*</span>
                        </label>
                        <input
                          type="text"
                          value={item?.altPl || ''}
                          onChange={(e) => {
                            const gallery = [...watch('gallery')];
                            gallery[index].altPl = e.target.value;
                            setValue('gallery', gallery);
                          }}
                          className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                          placeholder="Opisowy tekst alternatywny po polsku"
                        />
                      </div>
                      
                      {/* Alt EN */}
                      <div>
                        <label className="block mb-2 text-sm font-medium text-white/80">
                          Tekst alternatywny (EN)
                          <span className="text-brand-primary ml-1">*</span>
                        </label>
                        <input
                          type="text"
                          value={item?.altEn || ''}
                          onChange={(e) => {
                            const gallery = [...watch('gallery')];
                            gallery[index].altEn = e.target.value;
                            setValue('gallery', gallery);
                          }}
                          className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                          placeholder="Descriptive alt text in English"
                        />
                      </div>
                    </div>
                  </div>
                ))}
                
                {(!watch('gallery') || watch('gallery').length === 0) && (
                  <div className="p-8 rounded-lg border border-dashed border-white/20 bg-white/5 text-center">
                    <ImageIcon size={36} className="text-white/20 mx-auto mb-3" />
                    <p className="text-white/60 mb-1">Galeria jest pusta</p>
                    <p className="text-sm text-white/40">Kliknij "Dodaj zdjęcie" aby dodać obrazy do galerii</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        
        {/* Tab: Klient */}
        {activeTab === 'testimonials' && (
          <div className="space-y-6">
            <Controller
              name="testimonials"
              control={control}
              defaultValue={[{ companyName: '', authorName: '', contentPl: '', contentEn: '', originalLanguage: 'pl' }]}
              render={({ field }) => {
                // Upewniamy się, że zawsze mamy co najmniej jeden element
                if (!field.value || field.value.length === 0) {
                  field.onChange([{ companyName: '', authorName: '', contentPl: '', contentEn: '', originalLanguage: 'pl' }]);
                }
                
                return (
                  <div>
                    <div className="flex justify-between items-center mb-4">
                      <label className="text-sm font-medium text-white/80">
                        Dane klienta i opinia
                      </label>
                    </div>
                    
                    <div className="space-y-6">
                      {field.value.slice(0, 1).map((testimonial, index) => (
                        <div 
                          key={index}
                          className="p-4 bg-white/5 border border-white/10 rounded-lg relative"
                        >
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                            {/* Nazwa firmy */}
                            <div>
                              <label className="block mb-2 text-sm font-medium text-white/80">
                                Nazwa firmy klienta
                                <span className="text-brand-primary ml-1">*</span>
                              </label>
                              <input
                                type="text"
                                value={testimonial.companyName}
                                onChange={(e) => {
                                  const newTestimonials = [...field.value];
                                  newTestimonials[index].companyName = e.target.value;
                                  field.onChange(newTestimonials);
                                }}
                                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                                placeholder="Nazwa firmy klienta"
                              />
                            </div>
                          </div>

                          <div className="mt-6 mb-3">
                            <label className="block mb-2 text-lg font-semibold text-white/80">
                              Dane opinii (opcjonalne)
                            </label>
                          </div>
                          
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                            {/* Autor */}
                            <div>
                              <label className="block mb-2 text-sm font-medium text-white/80">
                                Autor opinii
                              </label>
                              <input
                                type="text"
                                value={testimonial.authorName}
                                onChange={(e) => {
                                  const newTestimonials = [...field.value];
                                  newTestimonials[index].authorName = e.target.value;
                                  field.onChange(newTestimonials);
                                }}
                                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                                placeholder="Imię i nazwisko autora opinii"
                              />
                            </div>
                          </div>
                          
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {/* Treść opinii (PL) */}
                            <div>
                              <label className="block mb-2 text-sm font-medium text-white/80">
                                Treść opinii (PL)
                              </label>
                              <textarea
                                value={testimonial.contentPl}
                                onChange={(e) => {
                                  const newTestimonials = [...field.value];
                                  newTestimonials[index].contentPl = e.target.value;
                                  field.onChange(newTestimonials);
                                }}
                                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                                rows={3}
                                placeholder="Treść opinii po polsku"
                              />
                            </div>
                            
                            {/* Treść opinii (EN) */}
                            <div>
                              <label className="block mb-2 text-sm font-medium text-white/80">
                                Treść opinii (EN)
                              </label>
                              <textarea
                                value={testimonial.contentEn}
                                onChange={(e) => {
                                  const newTestimonials = [...field.value];
                                  newTestimonials[index].contentEn = e.target.value;
                                  field.onChange(newTestimonials);
                                }}
                                className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                                rows={3}
                                placeholder="Opinion content in English"
                              />
                            </div>
                          </div>
                          
                          {/* Oryginalny język opinii */}
                          <div className="mt-4">
                            <label className="block mb-2 text-sm font-medium text-white/80">
                              Oryginalny język opinii
                            </label>
                            <RadioGroup
                              name={`originalLanguage-${index}`}
                              options={[
                                { value: 'pl', label: 'Polski' },
                                { value: 'en', label: 'Angielski' }
                              ]}
                              value={testimonial.originalLanguage || 'pl'}
                              onChange={(value) => {
                                const newTestimonials = [...field.value];
                                newTestimonials[index].originalLanguage = value;
                                field.onChange(newTestimonials);
                              }}
                              direction="horizontal"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              }}
            />
          </div>
        )}
        
        {/* Przyciski akcji */}
        <div className="border-t border-white/10 py-4 flex justify-end gap-4 mt-auto">
          <Button
            type="button"
            variant="dark"
            disabled={isLoading}
            icon={isLoading ? <Loader2 className="animate-spin" /> : <Save />}
            onClick={async () => {
              setIsDraft(true);
              // Pomiń walidację i wyślij dane
              const data = watch();
              handleFormSubmit({ ...data, published: false });
            }}
          >
            {isLoading ? 'Zapisywanie...' : 'Zapisz jako szkic'}
          </Button>
          <Button
            type="button"
            variant="default"
            disabled={isLoading} 
            icon={isLoading ? <Loader2 className="animate-spin" /> : <Save />}
            onClick={async () => {
              setIsDraft(false);
              // Wykonaj pełną walidację przed publikacją
              const isValid = await trigger();
              if (isValid) {
                const data = watch();
                handleFormSubmit({ ...data, published: true });
              }
            }}
          >
            {isLoading ? 'Publikowanie...' : 'Opublikuj projekt'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SocialProjectForm;
