import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { 
  Plus, 
  Search, 
  Edit, 
  Trash, 
  Loader2, 
  X,
  AlertTriangle,
  CheckCircle2,
  Save,
  Tag
} from 'lucide-react';
import { supabase } from '../../lib/supabase/client';
import { Button } from '../../components/ui/Button';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

// Interfejs dla tagu
interface TagInterface {
  id: string;
  pl_name: string;
  en_name: string;
  slug: string;
  pl_slug: string;
  en_slug: string;
}

// Interfejs dla formularza tagu
interface TagForm {
  id?: string;
  pl_name: string;
  en_name: string;
  pl_slug: string;
  en_slug: string;
}

const AdminTags: React.FC = () => {
  const [tags, setTags] = useState<TagInterface[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [tagToDelete, setTagToDelete] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  
  // React Hook Form
  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm<TagForm>({
    defaultValues: {
      pl_name: '',
      en_name: '',
      pl_slug: '',
      en_slug: ''
    }
  });
  
  // Pobranie tagów
  useEffect(() => {
    fetchTags();
  }, []);
  
  const fetchTags = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const { data, error } = await supabase
        .from('tags')
        .select('*')
        .order('pl_name');
      
      if (error) throw error;
      
      setTags(data || []);
    } catch (error: any) {
      console.error('Błąd pobierania tagów:', error);
      setError('Wystąpił błąd podczas pobierania tagów. Spróbuj ponownie.');
    } finally {
      setLoading(false);
    }
  };
  
  // Filtrowanie tagów
  const filteredTags = tags.filter(tag =>
    tag.pl_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    tag.en_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    tag.pl_slug.toLowerCase().includes(searchTerm.toLowerCase()) ||
    tag.en_slug.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  // Funkcja do edycji tagu
  const handleEditTag = (tag: TagInterface) => {
    setValue('id', tag.id);
    setValue('pl_name', tag.pl_name);
    setValue('en_name', tag.en_name);
    setValue('pl_slug', tag.pl_slug);
    setValue('en_slug', tag.en_slug);
    setIsEditing(true);
    setIsFormOpen(true);
  };
  
  // Funkcja do usuwania tagu
  const handleDeleteTag = async () => {
    if (!tagToDelete) return;
    
    setIsDeleting(true);
    
    try {
      // Sprawdźmy, czy tag jest używany w projektach
      const { data: projectsData, error: projectsError } = await supabase
        .from('project_tags')
        .select('project_id')
        .eq('tag_id', tagToDelete);
      
      if (projectsError) throw projectsError;
      
      if (projectsData && projectsData.length > 0) {
        throw new Error(`Nie można usunąć tego tagu, ponieważ jest używany w ${projectsData.length} projektach.`);
      }
      
      // Usuwanie tagu
      const { error } = await supabase
        .from('tags')
        .delete()
        .eq('id', tagToDelete);
      
      if (error) throw error;
      
      // Aktualizacja listy tagów
      setTags(tags.filter(tag => tag.id !== tagToDelete));
      
    } catch (error: any) {
      console.error('Błąd usuwania tagu:', error);
      setError(error.message || 'Wystąpił błąd podczas usuwania tagu. Spróbuj ponownie.');
    } finally {
      setIsDeleting(false);
      setTagToDelete(null);
    }
  };
  
  // Obsługa formularza
  const onSubmit = async (data: TagForm) => {
    setIsSubmitting(true);
    setError(null);
    
    try {
      if (isEditing) {
        // Aktualizacja istniejącego tagu
        const { error } = await supabase
          .from('tags')
          .update({
            pl_name: data.pl_name,
            en_name: data.en_name,
            pl_slug: data.pl_slug,
            en_slug: data.en_slug
          })
          .eq('id', data.id);
        
        if (error) throw error;
        
        // Aktualizacja listy tagów
        setTags(tags.map(tag => 
          tag.id === data.id 
            ? { ...tag, pl_name: data.pl_name, en_name: data.en_name, pl_slug: data.pl_slug, en_slug: data.en_slug } 
            : tag
        ));
      } else {
        // Tworzenie nowego tagu
        const newTag = {
          id: uuidv4(),
          pl_name: data.pl_name,
          en_name: data.en_name,
          slug: data.pl_slug, // Używamy pl_slug jako głównego slug dla kompatybilności
          pl_slug: data.pl_slug,
          en_slug: data.en_slug
        };
        
        const { error } = await supabase
          .from('tags')
          .insert([newTag]);
        
        if (error) throw error;
        
        // Aktualizacja listy tagów
        setTags([...tags, newTag]);
      }
      
      // Resetowanie formularza i stanu
      reset();
      setSubmitSuccess(true);
      setTimeout(() => {
        setSubmitSuccess(false);
        setIsFormOpen(false);
        setIsEditing(false);
      }, 1500);
      
    } catch (error: any) {
      console.error('Błąd zapisywania tagu:', error);
      setError('Wystąpił błąd podczas zapisywania tagu. Spróbuj ponownie.');
    } finally {
      setIsSubmitting(false);
    }
  };
  
  // Generowanie slug'a z nazwy
  const generateSlug = (name: string) => {
    return name
      .toLowerCase()
      .replace(/[^\w\s\-ąćęłńóśźż]/g, '')
      .replace(/[\s_]+/g, '-')
      .replace(/ą/g, 'a')
      .replace(/ć/g, 'c')
      .replace(/ę/g, 'e')
      .replace(/ł/g, 'l')
      .replace(/ń/g, 'n')
      .replace(/ó/g, 'o')
      .replace(/ś/g, 's')
      .replace(/ź|ż/g, 'z')
      .replace(/--+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');
  };
  
  // Animacja
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };
  
  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.6, ease: [0.22, 1, 0.36, 1] }
    }
  };
  
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-8"
        variants={itemVariants}
      >
        <div>
          <h1 className="text-3xl font-bold text-white mb-2">Tagi</h1>
          <p className="text-white/70">Zarządzaj tagami projektów</p>
        </div>
        
        <Button 
          variant="default" 
          icon={<Plus size={16} />}
          onClick={() => {
            reset();
            setIsEditing(false);
            setIsFormOpen(true);
          }}
        >
          Nowy tag
        </Button>
      </motion.div>
      
      {/* Wyszukiwarka */}
      <motion.div 
        className="mb-6"
        variants={itemVariants}
      >
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-white/50">
            <Search size={18} />
          </div>
          <input
            type="text"
            placeholder="Szukaj tagów..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full py-2.5 pl-10 pr-4 rounded-lg bg-white/5 border border-white/10 text-white placeholder-white/40 focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
          />
          {searchTerm && (
            <button
              onClick={() => setSearchTerm('')}
              className="absolute inset-y-0 right-0 flex items-center pr-3 text-white/50 hover:text-white/80 transition-colors"
            >
              <X size={16} />
            </button>
          )}
        </div>
      </motion.div>
      
      {/* Komunikat błędu */}
      {error && (
        <motion.div
          className="mb-6 p-4 bg-red-950/30 border border-red-800/50 rounded-lg text-red-200 flex items-center"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
        >
          <AlertTriangle size={18} className="mr-2 flex-shrink-0" />
          <span>{error}</span>
        </motion.div>
      )}
      
      {/* Formularz dodawania/edycji tagu */}
      {isFormOpen && (
        <motion.div
          className="mb-6 p-6 bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
        >
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-white">
              {isEditing ? 'Edytuj tag' : 'Nowy tag'}
            </h2>
            <button
              onClick={() => {
                setIsFormOpen(false);
                setIsEditing(false);
                reset();
              }}
              className="p-1.5 rounded-md bg-white/5 text-white/70 hover:bg-white/10 hover:text-white transition-colors"
            >
              <X size={18} />
            </button>
          </div>
          
          {/* Komunikat sukcesu */}
          {submitSuccess && (
            <div className="mb-4 p-3 bg-green-950/30 border border-green-800/50 rounded-lg text-green-200 flex items-center">
              <CheckCircle2 size={18} className="mr-2 flex-shrink-0" />
              <span>{isEditing ? 'Tag został zaktualizowany!' : 'Tag został dodany!'}</span>
            </div>
          )}
          
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              {/* Nazwa tagu PL */}
              <div>
                <label className="block mb-2 text-sm font-medium text-white/80">
                  Nazwa tagu (PL)
                </label>
                <input
                  type="text"
                  {...register('pl_name', { 
                    required: 'Nazwa tagu w języku polskim jest wymagana',
                    onChange: (e) => {
                      if (!isEditing || !errors.pl_slug) {
                        // Automatycznie generuj slug z nazwy polskiej
                        setValue('pl_slug', generateSlug(e.target.value));
                      }
                    }
                  })}
                  className={`w-full py-2.5 px-4 rounded-lg ${
                    errors.pl_name 
                      ? 'bg-red-900/20 border-red-800/50 focus:ring-red-500/30 focus:border-red-600/50' 
                      : 'bg-white/5 border-white/10 focus:ring-brand-primary/30 focus:border-brand-primary/50'
                  } text-white focus:outline-none focus:ring-2 transition-all`}
                  placeholder="Nazwa tagu po polsku"
                />
                {errors.pl_name && (
                  <p className="mt-2 text-sm text-red-400">{errors.pl_name.message}</p>
                )}
              </div>
              
              {/* Nazwa tagu EN */}
              <div>
                <label className="block mb-2 text-sm font-medium text-white/80">
                  Nazwa tagu (EN)
                </label>
                <input
                  type="text"
                  {...register('en_name', { 
                    required: 'Nazwa tagu w języku angielskim jest wymagana',
                    onChange: (e) => {
                      if (!isEditing || !errors.en_slug) {
                        // Automatycznie generuj slug z nazwy angielskiej
                        setValue('en_slug', generateSlug(e.target.value));
                      }
                    }
                  })}
                  className={`w-full py-2.5 px-4 rounded-lg ${
                    errors.en_name 
                      ? 'bg-red-900/20 border-red-800/50 focus:ring-red-500/30 focus:border-red-600/50' 
                      : 'bg-white/5 border-white/10 focus:ring-brand-primary/30 focus:border-brand-primary/50'
                  } text-white focus:outline-none focus:ring-2 transition-all`}
                  placeholder="Nazwa tagu po angielsku"
                />
                {errors.en_name && (
                  <p className="mt-2 text-sm text-red-400">{errors.en_name.message}</p>
                )}
              </div>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              {/* Slug PL */}
              <div>
                <label className="block mb-2 text-sm font-medium text-white/80">
                  Slug (URL tagu PL)
                </label>
                <input
                  type="text"
                  {...register('pl_slug', { 
                    required: 'Slug w języku polskim jest wymagany',
                    pattern: {
                      value: /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
                      message: 'Slug może zawierać tylko małe litery, cyfry i myślniki'
                    }
                  })}
                  className={`w-full py-2.5 px-4 rounded-lg ${
                    errors.pl_slug 
                      ? 'bg-red-900/20 border-red-800/50 focus:ring-red-500/30 focus:border-red-600/50' 
                      : 'bg-white/5 border-white/10 focus:ring-brand-primary/30 focus:border-brand-primary/50'
                  } text-white focus:outline-none focus:ring-2 transition-all`}
                  placeholder="nazwa-tagu-pl"
                />
                {errors.pl_slug ? (
                  <p className="mt-2 text-sm text-red-400">{errors.pl_slug.message}</p>
                ) : (
                  <p className="mt-2 text-xs text-white/50">
                    Unikalny identyfikator używany w adresie URL tagu dla wersji polskiej
                  </p>
                )}
              </div>
              
              {/* Slug EN */}
              <div>
                <label className="block mb-2 text-sm font-medium text-white/80">
                  Slug (URL tagu EN)
                </label>
                <input
                  type="text"
                  {...register('en_slug', { 
                    required: 'Slug w języku angielskim jest wymagany',
                    pattern: {
                      value: /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
                      message: 'Slug może zawierać tylko małe litery, cyfry i myślniki'
                    }
                  })}
                  className={`w-full py-2.5 px-4 rounded-lg ${
                    errors.en_slug 
                      ? 'bg-red-900/20 border-red-800/50 focus:ring-red-500/30 focus:border-red-600/50' 
                      : 'bg-white/5 border-white/10 focus:ring-brand-primary/30 focus:border-brand-primary/50'
                  } text-white focus:outline-none focus:ring-2 transition-all`}
                  placeholder="tag-name-en"
                />
                {errors.en_slug ? (
                  <p className="mt-2 text-sm text-red-400">{errors.en_slug.message}</p>
                ) : (
                  <p className="mt-2 text-xs text-white/50">
                    Unikalny identyfikator używany w adresie URL tagu dla wersji angielskiej
                  </p>
                )}
              </div>
            </div>
            
            {/* Przyciski akcji */}
            <div className="flex justify-end">
              <Button 
                type="submit" 
                variant="default" 
                icon={isSubmitting ? <Loader2 size={16} className="animate-spin" /> : <Save size={16} />}
                disabled={isSubmitting}
              >
                {isSubmitting 
                  ? (isEditing ? 'Aktualizowanie...' : 'Dodawanie...') 
                  : (isEditing ? 'Zapisz zmiany' : 'Dodaj tag')
                }
              </Button>
            </div>
          </form>
        </motion.div>
      )}
      
      {/* Lista tagów */}
      <motion.div 
        variants={itemVariants}
        className="bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl overflow-hidden"
      >
        {loading ? (
          <div className="flex items-center justify-center py-12">
            <Loader2 size={24} className="text-brand-primary animate-spin mr-2" />
            <span className="text-white/70">Ładowanie tagów...</span>
          </div>
        ) : filteredTags.length === 0 ? (
          <div className="text-center py-12">
            <Tag size={48} className="text-white/20 mx-auto mb-4" />
            <div className="text-white/40 mb-2">Nie znaleziono tagów</div>
            <p className="text-white/60 text-sm max-w-md mx-auto">
              {searchTerm 
                ? 'Spróbuj zmienić kryteria wyszukiwania.' 
                : 'Dodaj swój pierwszy tag, klikając przycisk "Nowy tag".'}
            </p>
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="border-b border-white/10">
                  <th className="px-6 py-3 text-left text-xs font-medium text-white/40 uppercase tracking-wider">
                    Nazwa (PL)
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white/40 uppercase tracking-wider">
                    Nazwa (EN)
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white/40 uppercase tracking-wider">
                    Slug (PL)
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white/40 uppercase tracking-wider">
                    Slug (EN)
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-white/40 uppercase tracking-wider">
                    Akcje
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-white/5">
                {filteredTags.map((tag) => (
                  <tr key={tag.id} className="hover:bg-white/5 transition-colors">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="text-white">{tag.pl_name}</span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="text-white">{tag.en_name}</span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <code className="text-brand-tertiary bg-brand-primary/10 px-2 py-1 rounded text-sm">
                        {tag.pl_slug}
                      </code>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <code className="text-brand-tertiary bg-brand-primary/10 px-2 py-1 rounded text-sm">
                        {tag.en_slug}
                      </code>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center">
                      <div className="flex items-center justify-center gap-2">
                        <button 
                          onClick={() => handleEditTag(tag)}
                          className="p-1.5 rounded-md bg-white/5 text-white/70 hover:bg-blue-500/20 hover:text-blue-400 transition-colors"
                        >
                          <Edit size={16} />
                        </button>
                        <button 
                          onClick={() => setTagToDelete(tag.id)}
                          className="p-1.5 rounded-md bg-white/5 text-white/70 hover:bg-red-500/20 hover:text-red-400 transition-colors"
                        >
                          <Trash size={16} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </motion.div>
      
      {/* Dialog potwierdzenia usunięcia */}
      {tagToDelete && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm">
          <div className="bg-surface-dark backdrop-blur-xl w-full max-w-md border border-white/10 rounded-xl p-6 shadow-2xl">
            <h3 className="text-xl font-semibold text-white mb-2">Potwierdzenie usunięcia</h3>
            <p className="text-white/70 mb-6">Czy na pewno chcesz usunąć ten tag? Ta operacja jest nieodwracalna.</p>
            
            <div className="flex justify-end gap-3">
              <button
                onClick={() => setTagToDelete(null)}
                className="px-4 py-2 rounded-lg bg-white/5 text-white hover:bg-white/10 transition-colors"
                disabled={isDeleting}
              >
                Anuluj
              </button>
              <button
                onClick={handleDeleteTag}
                className="px-4 py-2 rounded-lg bg-red-600/80 text-white hover:bg-red-600 transition-colors flex items-center"
                disabled={isDeleting}
              >
                {isDeleting ? (
                  <>
                    <Loader2 size={16} className="animate-spin mr-2" />
                    Usuwanie...
                  </>
                ) : (
                  <>
                    <Trash size={16} className="mr-2" />
                    Usuń tag
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default AdminTags;