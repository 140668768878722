import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { 
  Plus, 
  Search, 
  Filter,
  Edit, 
  Trash, 
  Eye, 
  Calendar, 
  Loader2, 
  X,
  AlertTriangle,
  Check,
  ChevronDown
} from 'lucide-react';
import { supabase } from '../../lib/supabase/client';
import { Button } from '../../components/ui/Button';

// Interfejs dla projektu
interface Project {
  id: string;
  slug: string;
  project_type: string;
  client: string;
  time_frame: string;
  thumbnail_url: string;
  created_at: string;
  updated_at: string;
  published: boolean;
  title?: string; // Z połączenia z tłumaczeniami
}

const AdminProjects: React.FC = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState<string | null>(null);
  const [filterPublished, setFilterPublished] = useState<boolean | null>(null);
  const [showFilters, setShowFilters] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  
  // Pobranie projektów
  useEffect(() => {
    fetchProjects();
  }, []);
  
  const fetchProjects = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // Pobierz projekty z ich tłumaczeniami w języku polskim
      let query = supabase
        .from('projects')
        .select(`
          *,
          project_translations!inner (
            title,
            language
          )
        `)
        .eq('project_translations.language', 'pl')
        .order('created_at', { ascending: false });
      
      const { data, error } = await query;
      
      if (error) throw error;
      
      // Przetwarzamy dane, dodając tytuł z tłumaczeń
      const processedData = data.map(project => {
        const translation = project.project_translations.find((t: any) => t.language === 'pl');
        return {
          ...project,
          title: translation ? translation.title : 'Brak tytułu',
          project_translations: undefined // Usuwamy zagnieżdżone dane
        };
      });
      
      setProjects(processedData);
    } catch (error: any) {
      console.error('Błąd pobierania projektów:', error);
      setError('Wystąpił błąd podczas pobierania projektów. Spróbuj ponownie.');
    } finally {
      setLoading(false);
    }
  };
  
  // Filtrowanie projektów
  const filteredProjects = projects.filter(project => {
    const matchesSearch = project.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          project.client.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          project.slug.toLowerCase().includes(searchTerm.toLowerCase());
                          
    const matchesType = filterType ? project.project_type === filterType : true;
    const matchesPublished = filterPublished !== null ? project.published === filterPublished : true;
    
    return matchesSearch && matchesType && matchesPublished;
  });
  
  // Funkcja do formatowania daty
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('pl-PL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };
  
  // Funkcja do usuwania projektu
  const handleDeleteProject = async () => {
    if (!projectToDelete) return;
    
    setIsDeleting(true);
    
    try {
      // Usuwanie powiązanych tłumaczeń
      const { error: translationsError } = await supabase
        .from('project_translations')
        .delete()
        .eq('project_id', projectToDelete);
      
      if (translationsError) throw translationsError;
      
      // Usuwanie powiązanych statystyk
      const { error: statsError } = await supabase
        .from('project_statistics')
        .delete()
        .eq('project_id', projectToDelete);
      
      if (statsError) throw statsError;
      
      // Usuwanie powiązanych funkcji
      const { error: featuresError } = await supabase
        .from('project_features')
        .delete()
        .eq('project_id', projectToDelete);
      
      if (featuresError) throw featuresError;
      
      // Usuwanie powiązanych galerii
      const { error: galleryError } = await supabase
        .from('project_gallery')
        .delete()
        .eq('project_id', projectToDelete);
      
      if (galleryError) throw galleryError;
      
      // Usuwanie powiązanych opinii
      const { error: testimonialsError } = await supabase
        .from('project_testimonials')
        .delete()
        .eq('project_id', projectToDelete);
      
      if (testimonialsError) throw testimonialsError;
      
      // Usuwanie powiązań z kategoriami
      const { error: categoriesError } = await supabase
        .from('project_categories')
        .delete()
        .eq('project_id', projectToDelete);
      
      if (categoriesError) throw categoriesError;
      
      // Usuwanie powiązań z tagami
      const { error: tagsError } = await supabase
        .from('project_tags')
        .delete()
        .eq('project_id', projectToDelete);
      
      if (tagsError) throw tagsError;
      
      // Usuwanie powiązań z technologiami
      const { error: technologiesError } = await supabase
        .from('project_technologies')
        .delete()
        .eq('project_id', projectToDelete);
      
      if (technologiesError) throw technologiesError;
      
      // Usuwanie projektu
      const { error } = await supabase
        .from('projects')
        .delete()
        .eq('id', projectToDelete);
      
      if (error) throw error;
      
      // Aktualizacja listy projektów
      setProjects(projects.filter(project => project.id !== projectToDelete));
      
    } catch (error: any) {
      console.error('Błąd usuwania projektu:', error);
      setError('Wystąpił błąd podczas usuwania projektu. Spróbuj ponownie.');
    } finally {
      setIsDeleting(false);
      setProjectToDelete(null);
    }
  };
  
  // Animacja
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };
  
  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.6, ease: [0.22, 1, 0.36, 1] }
    }
  };
  
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-8"
        variants={itemVariants}
      >
        <div>
          <h1 className="text-3xl font-bold text-white mb-2">Projekty</h1>
          <p className="text-white/70">Zarządzaj projektami wyświetlanymi na stronie</p>
        </div>
        
        <Link to="/admin/projects/new">
          <Button 
            variant="default" 
            icon={<Plus size={16} />}
          >
            Nowy projekt
          </Button>
        </Link>
      </motion.div>
      
      {/* Wyszukiwarka i filtry */}
      <motion.div 
        className="mb-6"
        variants={itemVariants}
      >
        <div className="flex flex-col md:flex-row gap-4">
          {/* Pole wyszukiwania */}
          <div className="relative flex-1">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-white/50">
              <Search size={18} />
            </div>
            <input
              type="text"
              placeholder="Szukaj projektów..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full py-2.5 pl-10 pr-4 rounded-lg bg-white/5 border border-white/10 text-white placeholder-white/40 focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
            />
            {searchTerm && (
              <button
                onClick={() => setSearchTerm('')}
                className="absolute inset-y-0 right-0 flex items-center pr-3 text-white/50 hover:text-white/80 transition-colors"
              >
                <X size={16} />
              </button>
            )}
          </div>
          
          {/* Przycisk filtrów */}
          <div className="md:w-auto">
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="w-full md:w-auto flex items-center justify-center gap-2 py-2.5 px-4 rounded-lg bg-white/5 border border-white/10 text-white hover:bg-white/8 transition-colors focus:outline-none focus:ring-2 focus:ring-brand-primary/30"
            >
              <Filter size={18} />
              <span>Filtry</span>
              <ChevronDown 
                size={16} 
                className={`transition-transform duration-300 ${showFilters ? 'rotate-180' : 'rotate-0'}`} 
              />
            </button>
          </div>
        </div>
        
        {/* Panel filtrów */}
        {showFilters && (
          <div className="mt-4 p-4 bg-white/5 backdrop-blur-sm border border-white/10 rounded-lg">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {/* Filtr typu projektu */}
              <div>
                <label className="block mb-2 text-sm font-medium text-white/80">
                  Typ projektu
                </label>
                <select
                  value={filterType || ''}
                  onChange={(e) => setFilterType(e.target.value || null)}
                  className="w-full py-2.5 px-4 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                >
                  <option value="">Wszystkie typy</option>
                  <option value="web">Strony internetowe</option>
                  <option value="social">Social Media</option>
                  <option value="video">Produkcja wideo</option>
                </select>
              </div>
              
              {/* Filtr opublikowanych */}
              <div>
                <label className="block mb-2 text-sm font-medium text-white/80">
                  Status
                </label>
                <select
                  value={filterPublished === null ? '' : filterPublished ? 'true' : 'false'}
                  onChange={(e) => {
                    if (e.target.value === '') {
                      setFilterPublished(null);
                    } else {
                      setFilterPublished(e.target.value === 'true');
                    }
                  }}
                  className="w-full py-2.5 px-4 rounded-lg bg-white/5 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
                >
                  <option value="">Wszystkie statusy</option>
                  <option value="true">Opublikowane</option>
                  <option value="false">Nieopublikowane</option>
                </select>
              </div>
              
              {/* Przyciski resetowania/zastosowania */}
              <div className="flex items-end gap-2">
                <button
                  onClick={() => {
                    setFilterType(null);
                    setFilterPublished(null);
                  }}
                  className="py-2.5 px-4 rounded-lg bg-white/5 border border-white/10 text-white hover:bg-white/8 transition-colors focus:outline-none focus:ring-2 focus:ring-brand-primary/30 flex-1"
                >
                  Resetuj
                </button>
              </div>
            </div>
          </div>
        )}
      </motion.div>
      
      {/* Komunikat błędu */}
      {error && (
        <motion.div
          className="mb-6 p-4 bg-red-950/30 border border-red-800/50 rounded-lg text-red-200 flex items-center"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
        >
          <AlertTriangle size={18} className="mr-2 flex-shrink-0" />
          <span>{error}</span>
        </motion.div>
      )}
      
      {/* Tabela projektów */}
      <motion.div 
        variants={itemVariants}
        className="bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl overflow-hidden"
      >
        {loading ? (
          <div className="flex items-center justify-center py-12">
            <Loader2 size={24} className="text-brand-primary animate-spin mr-2" />
            <span className="text-white/70">Ładowanie projektów...</span>
          </div>
        ) : filteredProjects.length === 0 ? (
          <div className="text-center py-12">
            <div className="text-white/40 mb-2">Nie znaleziono projektów</div>
            <p className="text-white/60 text-sm max-w-md mx-auto">
              {searchTerm || filterType || filterPublished !== null 
                ? 'Spróbuj zmienić kryteria wyszukiwania lub filtrowania.' 
                : 'Dodaj swój pierwszy projekt, klikając przycisk "Nowy projekt".'}
            </p>
          </div>
        ) : (
          <div className="overflow-x-auto w-full custom-scrollbar">
            <table className="w-full table-fixed" style={{ minWidth: '850px' }}>
              <thead className="bg-white/5">
                <tr>
                  <th className="p-2 text-left text-xs font-medium text-white/40 uppercase w-[40px]">
                    <span className="sr-only">Miniatura</span>
                  </th>
                  <th className="p-2 text-left text-xs font-medium text-white/40 uppercase w-[200px] lg:w-[300px]">
                    Projekt
                  </th>
                  <th className="hidden md:table-cell p-2 text-left text-xs font-medium text-white/40 uppercase w-[120px] lg:w-[150px]">
                    Klient
                  </th>
                  <th className="p-2 text-left text-xs font-medium text-white/40 uppercase w-[70px]">
                    Typ
                  </th>
                  <th className="p-2 text-left text-xs font-medium text-white/40 uppercase w-[70px]">
                    Status
                  </th>
                  <th className="hidden lg:table-cell p-2 text-left text-xs font-medium text-white/40 uppercase w-[120px]">
                    Data
                  </th>
                  <th className="p-2 text-center text-xs font-medium text-white/40 uppercase w-[100px]">
                    Akcje
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredProjects.map((project) => (
                  <tr key={project.id} className="border-t border-white/5 hover:bg-white/5">
                    <td className="p-2">
                      <div className="w-8 h-8 rounded overflow-hidden">
                        <img 
                          src={project.thumbnail_url} 
                          alt={project.title} 
                          className="w-full h-full object-cover"
                        />
                      </div>
                    </td>
                    <td className="p-2">
                      <div className="text-sm font-medium text-white max-w-[200px] lg:max-w-[300px] truncate" title={project.title}>{project.title}</div>
                      <div className="text-xs text-white/40 max-w-[200px] lg:max-w-[300px] truncate" title={project.slug}>{project.slug}</div>
                    </td>
                    <td className="hidden md:table-cell p-2">
                      <div className="text-xs text-white/70 max-w-[120px] lg:max-w-[150px] truncate" title={project.client}>{project.client}</div>
                    </td>
                    <td className="p-2">
                      <div className="text-xs">
                        {project.project_type === 'web' && (
                          <span className="text-blue-400">WWW</span>
                        )}
                        {project.project_type === 'social' && (
                          <span className="text-purple-400">Social</span>
                        )}
                        {project.project_type === 'video' && (
                          <span className="text-amber-400">Wideo</span>
                        )}
                      </div>
                    </td>
                    <td className="p-2">
                      {project.published ? (
                        <span className="inline-flex items-center gap-1 text-xs text-green-400">
                          <Check size={10} />
                          <span className="hidden sm:inline">Aktywny</span>
                        </span>
                      ) : (
                        <span className="inline-flex items-center gap-1 text-xs text-gray-400">
                          <X size={10} />
                          <span className="hidden sm:inline">Szkic</span>
                        </span>
                      )}
                    </td>
                    <td className="hidden lg:table-cell p-2">
                      <div className="text-xs text-white/60 whitespace-nowrap">
                        {formatDate(project.updated_at)}
                      </div>
                    </td>
                    <td className="p-2 text-center">
                      <div className="flex items-center justify-center gap-1">
                        <Link 
                          to={`/admin/projects/${project.id}`}
                          className="p-1 rounded-md bg-white/5 text-white/70 hover:bg-white/10 hover:text-white transition-colors"
                          title="Edytuj"
                        >
                          <Edit size={14} />
                        </Link>
                        <a 
                          href={`/pl/projekt/${project.project_type}/${project.id}`} 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="p-1 rounded-md bg-white/5 text-white/70 hover:bg-blue-500/20 hover:text-blue-400 transition-colors"
                          title="Podgląd"
                        >
                          <Eye size={14} />
                        </a>
                        <button 
                          className="p-1 rounded-md bg-white/5 text-white/70 hover:bg-red-500/20 hover:text-red-400 transition-colors"
                          title="Usuń"
                          onClick={() => setProjectToDelete(project.id)}
                        >
                          <Trash size={14} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </motion.div>
      
      {/* Dialog potwierdzenia usunięcia */}
      {projectToDelete && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm">
          <div className="bg-surface-dark backdrop-blur-xl w-full max-w-md border border-white/10 rounded-xl p-6 shadow-2xl">
            <h3 className="text-xl font-semibold text-white mb-2">Potwierdzenie usunięcia</h3>
            <p className="text-white/70 mb-6">Czy na pewno chcesz usunąć ten projekt? Ta operacja jest nieodwracalna.</p>
            
            <div className="flex justify-end gap-3">
              <button
                onClick={() => setProjectToDelete(null)}
                className="px-4 py-2 rounded-lg bg-white/5 text-white hover:bg-white/10 transition-colors"
                disabled={isDeleting}
              >
                Anuluj
              </button>
              <button
                onClick={handleDeleteProject}
                className="px-4 py-2 rounded-lg bg-red-600/80 text-white hover:bg-red-600 transition-colors flex items-center"
                disabled={isDeleting}
              >
                {isDeleting ? (
                  <>
                    <Loader2 size={16} className="animate-spin mr-2" />
                    Usuwanie...
                  </>
                ) : (
                  <>
                    <Trash size={16} className="mr-2" />
                    Usuń projekt
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default AdminProjects;