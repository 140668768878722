import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

// Tworzymy klienta Supabase, używając zmiennych środowiskowych
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL || '';
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY || '';

// Upewnij się, że masz ustawione zmienne środowiskowe
if (!supabaseUrl || !supabaseAnonKey) {
  console.error('Brak zmiennych środowiskowych Supabase. Upewnij się, że masz plik .env z odpowiednimi wartościami.');
}

// Tworzymy i eksportujemy klienta Supabase
export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey);