import React, { useRef, useCallback, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Quote } from 'lucide-react';
import SectionPreheader from '../common/SectionPreheader';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
  CarouselDots
} from '../ui/Carousel';
import { cn } from '../../lib/utils';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import { supabase } from '../../lib/supabase/client';

// Interfejs dla opinii
interface Testimonial {
  id: string;
  project_id: string;
  company_name: string;
  author_name: string;
  pl_content: string;
  en_content: string;
  logo_url: string | null;
  original_language?: string; // Dodane pole określające oryginalny język
}

// Komponent pojedynczego testimonial'a
interface TestimonialCardProps {
  quote: string;
  author: string;
  company: string;
  index: number;
  logoUrl: string | null;
  isOriginalLanguage: boolean; // Czy opinia jest w oryginalnym języku
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({
  quote,
  author,
  company,
  index,
  logoUrl,
  isOriginalLanguage
}) => {
  const { t } = useTranslation(['testimonials']);
  
  // Kolory dla avatarów - zróżnicowane dla lepszego efektu wizualnego
  const avatarGradients = [
    'bg-gradient-to-br from-brand-primary to-brand-tertiary',
    'bg-gradient-to-br from-brand-tertiary to-brand-accent',
    'bg-gradient-to-br from-brand-secondary to-brand-primary',
    'bg-gradient-to-br from-brand-accent to-brand-tertiary'
  ];

  return (
    <div className="h-full flex">
      <div 
        className="group w-full h-full p-6 rounded-xl backdrop-blur-sm bg-white/[0.05] border border-white/10 hover:bg-white/[0.08] hover:shadow-[0_8px_30px_rgba(0,0,0,0.12)] hover:-translate-y-1 transition-all duration-300 flex flex-col"
        style={{
          zIndex: 5,
          minHeight: "320px",
          transform: "translate3d(0, 0, 0)",
          backfaceVisibility: "hidden"
        }}
      >
        {/* Efekt tła z kropkami - pojawia się po najechaniu */}
        <div
          className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-xl overflow-hidden"
        >
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(255,255,255,0.03)_1px,transparent_1px)] bg-[length:4px_4px]" />
        </div>

        {/* Delikatny gradient na obramowaniu */}
        <div
          className="absolute inset-0 -z-10 rounded-xl p-px bg-gradient-to-br from-transparent via-white/15 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"
        />

        <div className="flex flex-col h-full relative z-10">
          {/* Ikona cytatu */}
          <div className="mb-4">
            <div className="w-10 h-10 rounded-full flex items-center justify-center bg-brand-primary/10">
              <Quote size={18} className="text-brand-primary" />
            </div>
          </div>
          
          {/* Cytat */}
          <blockquote className="text-white/80 italic mb-6 flex-grow">
            "{quote}"
          </blockquote>
          
          {/* Autor - na dole karty */}
          <div className="mt-auto flex flex-col">
            <div className="flex items-center mb-2">
              {logoUrl ? (
                <div className="w-24 h-12 flex items-center justify-center mr-4">
                  <img 
                    src={logoUrl} 
                    alt={`${company} logo`} 
                    className="max-h-full max-w-full object-contain"
                  />
                </div>
              ) : (
                <div className={cn(
                  "w-12 h-12 rounded-full flex items-center justify-center mr-4",
                  avatarGradients[index % avatarGradients.length]
                )}>
                  <span className="text-white font-semibold">
                    {author.split(' ').map(name => name[0]).join('')}
                  </span>
                </div>
              )}
              
              <div>
                <div className="font-medium text-white">{author}</div>
                <div className="text-sm text-white/60">{company}</div>
              </div>
            </div>
            
            {/* Informacja o oryginalnym języku */}
            <div className="text-xs text-white/40 italic">
              {isOriginalLanguage ? t('originalLanguage') : t('translatedFrom')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Główny komponent Testimonials
const Testimonials = () => {
  const { t, i18n } = useTranslation(['testimonials']);
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const currentLanguage = i18n.language.slice(0, 2);
  const [api, setApi] = useState<any>(null);
  const autoplayRef = useRef<any>(null);
  
  // Obsługa zatrzymania i wznowienia autoplay przy najeżdżaniu myszką
  const onMouseEnter = useCallback(() => {
    if (api?.plugins?.()?.autoplay) {
      api.plugins().autoplay.stop();
    }
  }, [api]);

  const onMouseLeave = useCallback(() => {
    if (api?.plugins?.()?.autoplay) {
      try {
        api.plugins().autoplay.play();
      } catch (err) {
        console.log('Autoplay error:', err);
      }
    }
  }, [api]);
  
  // Pobieranie opinii z tabeli project_testimonials
  useEffect(() => {
    const fetchTestimonials = async () => {
      setIsLoading(true);
      
      try {
        // Krok 1: Najpierw pobieramy ID wszystkich opublikowanych projektów
        const { data: publishedProjects, error: projectsError } = await supabase
          .from('projects')
          .select('id')
          .eq('published', true);
        
        if (projectsError) {
          console.error('Error fetching published projects:', projectsError);
          setIsLoading(false);
          return;
        }
        
        // Jeśli nie ma opublikowanych projektów, kończymy
        if (!publishedProjects || publishedProjects.length === 0) {
          setTestimonials([]);
          setIsLoading(false);
          return;
        }
        
        // Tworzymy tablicę z ID opublikowanych projektów
        const publishedProjectIds = publishedProjects.map(project => project.id);
        
        // Krok 2: Pobieramy opinie, które należą tylko do opublikowanych projektów
        const { data: testimonialData, error: testimonialError } = await supabase
          .from('project_testimonials')
          .select('*')
          .in('project_id', publishedProjectIds)
          .order('id', { ascending: false });
        
        if (testimonialError) {
          console.error('Error fetching testimonials:', testimonialError);
          setIsLoading(false);
          return;
        }
        
        // Filtrujemy opinie, aby usunąć te bez treści
        const filteredTestimonials = testimonialData?.filter(testimonial => 
          (testimonial.pl_content && testimonial.pl_content.trim() !== '') || 
          (testimonial.en_content && testimonial.en_content.trim() !== '')
        ) || [];
        
        // Ustawiamy opinie
        setTestimonials(filteredTestimonials);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching testimonials:', error);
        setIsLoading(false);
      }
    };
    
    fetchTestimonials();
  }, []);
  
  // Inicjalizacja autoplay po załadowaniu danych
  useEffect(() => {
    if (!isLoading && testimonials.length > 0) {
      autoplayRef.current = Autoplay({ delay: 5000, stopOnInteraction: true });
    }
  }, [isLoading, testimonials]);
  
  // Wybieranie odpowiednich treści w zależności od języka
  const getContentByLanguage = (testimonial: Testimonial) => {
    if (currentLanguage === 'pl') {
      return testimonial.pl_content || testimonial.en_content;
    } else {
      return testimonial.en_content || testimonial.pl_content;
    }
  };
  
  // Sprawdzanie, czy wyświetlana treść jest w oryginalnym języku
  const isOriginalLanguage = (testimonial: Testimonial) => {
    if (!testimonial.original_language) {
      // Jeśli nie ma informacji o oryginalnym języku, używamy starej logiki
      if (currentLanguage === 'pl') {
        return testimonial.pl_content && testimonial.pl_content.trim() !== '';
      } else {
        return testimonial.en_content && testimonial.en_content.trim() !== '';
      }
    }
    
    // Korzystamy z nowego pola original_language
    return currentLanguage === testimonial.original_language;
  };
  
  // Jeśli nie ma żadnych opinii, pokazujemy komunikat
  if (!isLoading && testimonials.length === 0) {
    return (
      <section id="testimonials" className="w-full relative py-24 sm:py-28 overflow-hidden">
        <div className="container mx-auto px-6 sm:px-8 md:px-10 lg:px-12 relative z-10">
          <div className="max-w-3xl mx-auto text-center mb-16">
            <SectionPreheader 
              text={t('preheader')} 
              variant="primary" 
              className="mb-8" 
            />
            
            <h2 className="text-3xl md:text-4xl font-bold mb-6 text-white">
              {t('heading')}
            </h2>
            
            <p className="text-gray-300 text-lg mb-12">
              {t('subheading')}
            </p>
          </div>
          
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="bg-white/5 backdrop-blur-sm border border-dashed border-brand-primary/40 rounded-xl p-10 text-center max-w-4xl mx-auto"
          >
            <p className="text-white/70">
              {t('noTestimonials')}
            </p>
          </motion.div>
        </div>
      </section>
    );
  }
  
  return (
    <section id="testimonials" className="w-full relative py-24 sm:py-28 overflow-hidden">
      {/* Gradient tła - dopasowany do mocy gradientu z sekcji Portfolio */}
      <div className="absolute inset-0 pointer-events-none">
        <div 
          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[70vw] h-[70vh]"
          style={{
            background: `
              radial-gradient(
                ellipse at center, 
                rgba(255, 90, 31, 0.55) 0%,
                rgba(255, 90, 31, 0.45) 10%,
                rgba(255, 125, 58, 0.3) 30%, 
                rgba(255, 159, 69, 0.15) 50%,
                rgba(255, 210, 100, 0.08) 70%,
                transparent 90%
              )
            `,
            filter: 'blur(70px)',
          }}
        />
      </div>
      
      <div className="container mx-auto px-6 sm:px-8 md:px-10 lg:px-12 relative z-10">
        <div className="max-w-3xl mx-auto text-center mb-16">
          {/* Preheader */}
          <SectionPreheader 
            text={t('preheader')} 
            variant="primary" 
            className="mb-8" 
          />
          
          <h2 className="text-3xl md:text-4xl font-bold mb-6 text-white">
            {t('heading')}
          </h2>
          
          <p className="text-gray-300 text-lg mb-12">
            {t('subheading')}
          </p>
        </div>
        
        {/* Karuzela z opiniami */}
        {isLoading ? (
          <div className="flex justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-brand-primary"></div>
          </div>
        ) : testimonials.length === 0 ? (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="bg-white/5 backdrop-blur-sm border border-dashed border-brand-primary/40 rounded-xl p-10 text-center max-w-4xl mx-auto"
          >
            <p className="text-white/70">
              {t('noTestimonials')}
            </p>
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="max-w-5xl mx-auto"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <div className="relative w-full px-4 md:px-10">
              <Carousel
                key={`testimonials-carousel-${testimonials.length}`}
                opts={{
                  loop: false,
                  align: "start",
                  skipSnaps: false,
                  startIndex: 0,
                  containScroll: "trimSnaps"
                }}
                plugins={testimonials.length > 0 ? [autoplayRef.current].filter(Boolean) : []}
                setApi={setApi}
                className="w-full"
              >
                <CarouselContent className="py-4">
                  {testimonials.map((testimonial, index) => (
                    <CarouselItem key={index} className="md:basis-1/2 lg:basis-1/3 pt-4 h-auto">
                      <div className="h-full px-2">
                        <TestimonialCard 
                          quote={getContentByLanguage(testimonial)}
                          author={testimonial.author_name}
                          company={testimonial.company_name}
                          index={index}
                          logoUrl={testimonial.logo_url}
                          isOriginalLanguage={isOriginalLanguage(testimonial)}
                        />
                      </div>
                    </CarouselItem>
                  ))}
                </CarouselContent>
                
                <div className="hidden md:block">
                  <CarouselPrevious className="absolute top-1/2 -translate-y-1/2 -left-5 lg:-left-12 w-10 h-10 rounded-full bg-white/5 backdrop-blur-sm hover:bg-white/10 border border-white/10 flex items-center justify-center text-white" />
                  <CarouselNext className="absolute top-1/2 -translate-y-1/2 -right-5 lg:-right-12 w-10 h-10 rounded-full bg-white/5 backdrop-blur-sm hover:bg-white/10 border border-white/10 flex items-center justify-center text-white" />
                </div>
                
                <div className="mt-8">
                  <CarouselDots className="flex justify-center gap-2" />
                </div>
              </Carousel>
            </div>
          </motion.div>
        )}
      </div>
    </section>
  );
};

export default Testimonials;