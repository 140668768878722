import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../components/layout/Navbar';
import Footer from '../components/layout/Footer';
import { motion } from 'framer-motion';
import { ArrowLeft } from 'lucide-react';
import { Helmet } from 'react-helmet-async';

const SUPPORTED_LANGUAGES = ['pl', 'en'] as const;
type SupportedLang = typeof SUPPORTED_LANGUAGES[number];

const policySlugs: Record<SupportedLang, string> = {
  pl: 'polityka-prywatnosci',
  en: 'privacy-policy',
};

const PrivacyPolicy: React.FC = () => {
  const { t, i18n } = useTranslation(['privacy', 'meta']);
  const navigate = useNavigate();
  
  const shortLang = i18n.language.slice(0, 2).toLowerCase() as SupportedLang;
  const validLang = SUPPORTED_LANGUAGES.includes(shortLang) ? shortLang : 'en';

  const correctPath = `/${validLang}/${policySlugs[validLang]}`;
  const currentPath = window.location.pathname;

  // Przekierowanie na poprawną ścieżkę jeśli potrzebne
  React.useEffect(() => {
    if (currentPath !== correctPath) {
      navigate(correctPath, { replace: true });
    }
  }, [currentPath, correctPath, navigate, i18n.language]);

  // Specjalny efekt do wymuszenia scrollowania na górę strony
  useEffect(() => {
    // Natychmiastowe scrollowanie do góry
    window.scrollTo(0, 0);
    
    // Zabezpieczenie przed przywracaniem pozycji scrollowania
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }
    
    // Ustawienie pozycji body na górę
    document.body.scrollTop = 0;
    // Ustawienie pozycji documentElement na górę (dla większości przeglądarek)
    document.documentElement.scrollTop = 0;
    
    // Wielokrotne wymuszenie scrollowania w różnych momentach
    const timeoutIds: NodeJS.Timeout[] = [];
    for (let delay of [0, 50, 100, 200, 500, 1000]) {
      timeoutIds.push(
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto'
          });
          // Dodatkowe zabezpieczenie dla różnych przeglądarek
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }, delay)
      );
    }
    
    return () => {
      timeoutIds.forEach(id => clearTimeout(id));
      // Przywrócenie normalnego zachowania po opuszczeniu strony
      if ('scrollRestoration' in history) {
        history.scrollRestoration = 'auto';
      }
    };
  }, []); // Pusta tablica zależności - uruchamia się tylko przy montowaniu komponentu

  return (
    <>
      <Helmet>
        <title>{shortLang === 'pl' ? 'Polityka prywatności | DG - Digital Media' : 'Privacy Policy | DG - Digital Media'}</title>
        <meta name="description" content={t('meta.privacyDescription')} />
        <meta property="og:title" content={shortLang === 'pl' ? 'Polityka prywatności | DG - Digital Media' : 'Privacy Policy | DG - Digital Media'} />
        <meta property="og:description" content={t('meta.privacyDescription')} />
        <meta property="og:type" content="website" />
      </Helmet>
      <Navbar />
      <div className="container mx-auto px-4 sm:px-6 py-16 sm:py-24">
        <div className="max-w-4xl mx-auto">
          <div className="mt-16 md:mt-8 mb-8 sm:mb-12">
            <Link
              to={`/${validLang}`}
              className="inline-flex items-center gap-2 text-white/70 hover:text-brand-primary transition-colors duration-300"
            >
              <ArrowLeft size={16} />
              <span>{t('backToHome')}</span>
            </Link>
          </div>
          
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="mb-12"
          >
            <h1 className="text-3xl sm:text-4xl font-bold mb-6 text-white">
              {t('title')}
            </h1>
            
            <div className="bg-white/[0.05] backdrop-blur-sm border border-white/10 rounded-xl p-6 sm:p-8 md:p-10 custom-scrollbar">
              <p className="text-white/80 mb-8 text-lg">
                {t('introduction')}
              </p>
              
              {/* Informacje ogólne */}
              <section className="mb-10">
                <h2 className="text-2xl font-semibold text-white mb-4">
                  {t('generalInfo.title')}
                </h2>
                <p className="text-white/80 mb-4">
                  {t('generalInfo.description')}
                </p>
                
                <ol className="list-decimal pl-6 space-y-3 text-white/80">
                  {/* Punkty 1-4 */}
                  {t('generalInfo.points', { returnObjects: true }).slice(0, 4).map((point, index) => (
                    <li key={index} className="pl-2">
                      {point}
                    </li>
                  ))}
                  
                  {/* Punkt 5 z podpunktami */}
                  <li className="pl-2">
                    {t('generalInfo.points.4')}
                    <ul className="list-disc pl-6 mt-2 space-y-1">
                      {t('generalInfo.usagePurposes', { returnObjects: true }).map((purpose, idx, arr) => (
                        <li key={idx} className="text-white/75">
                          {purpose}
                        </li>
                      ))}
                    </ul>
                  </li>
                  
                  {/* Punkt 6 z podpunktami */}
                  <li className="pl-2">
                    {t('generalInfo.points.5')}
                    <ul className="list-disc pl-6 mt-2 space-y-1">
                      {t('generalInfo.dataCollectionMethods', { returnObjects: true }).map((method, idx, arr) => (
                        <li key={idx} className="text-white/75">
                          {method}
                        </li>
                      ))}
                    </ul>
                  </li>
                </ol>
              </section>
              
              {/* Informacje w formularzach */}
              <section className="mb-10">
                <h2 className="text-2xl font-semibold text-white mb-4">
                  {t('formInfo.title')}
                </h2>
                <p className="text-white/80 mb-4">
                  {t('formInfo.description')}
                </p>
                
                <ol className="list-decimal pl-6 space-y-3 text-white/80">
                  {t('formInfo.points', { returnObjects: true }).map((point, index) => (
                    <li key={index} className="pl-2">
                      {point}
                    </li>
                  ))}
                </ol>
              </section>
              
              {/* Logi Administratora */}
              <section className="mb-10">
                <h2 className="text-2xl font-semibold text-white mb-4">
                  {t('adminLogs.title')}
                </h2>
                <p className="text-white/80 mb-4">
                  {t('adminLogs.description')}
                </p>
                
                <ol className="list-decimal pl-6 space-y-3 text-white/80">
                  {t('adminLogs.points', { returnObjects: true }).map((point, index) => (
                    <li key={index} className="pl-2">
                      {point}
                    </li>
                  ))}
                </ol>
              </section>
              
              {/* Informacja o plikach cookies */}
              <section className="mb-10">
                <h2 className="text-2xl font-semibold text-white mb-4">
                  {t('cookies.title')}
                </h2>
                <p className="text-white/80 mb-4">
                  {t('cookies.description')}
                </p>
                
                <ol className="list-decimal pl-6 space-y-3 text-white/80">
                  {t('cookies.points', { returnObjects: true }).map((point, index) => (
                    <li key={index} className="pl-2">
                      {point}
                    </li>
                  ))}
                </ol>
              </section>
              
              {/* Wybrane metody ochrony danych */}
              <section className="mb-10">
                <h2 className="text-2xl font-semibold text-white mb-4">
                  {t('dataProtectionMethods.title')}
                </h2>
                <p className="text-white/80 mb-4">
                  {t('dataProtectionMethods.description')}
                </p>
                
                <ol className="list-decimal pl-6 space-y-3 text-white/80">
                  {t('dataProtectionMethods.points', { returnObjects: true }).map((point, index) => (
                    <li key={index} className="pl-2">
                      {point}
                    </li>
                  ))}
                </ol>
              </section>
              
              {/* Istotne techniki marketingowe */}
              <section className="mb-10">
                <h2 className="text-2xl font-semibold text-white mb-4">
                  {t('marketingTechniques.title')}
                </h2>
                <p className="text-white/80 mb-4">
                  {t('marketingTechniques.description')}
                </p>
                
                <ol className="list-decimal pl-6 space-y-3 text-white/80">
                  {t('marketingTechniques.points', { returnObjects: true }).map((point, index) => (
                    <li key={index} className="pl-2">
                      {point}
                    </li>
                  ))}
                </ol>
              </section>
              
              {/* Twoje prawa i dodatkowe informacje */}
              <section className="mb-10">
                <h2 className="text-2xl font-semibold text-white mb-4">
                  {t('userRights.title')}
                </h2>
                <p className="text-white/80 mb-4">
                  {t('userRights.description')}
                </p>
                
                <ol className="list-decimal pl-6 space-y-3 text-white/80">
                  {/* Punkt 1 z podpunktami "grupy odbiorców" */}
                  <li className="pl-2">
                    {t('userRights.points.0')}
                    <ul className="list-disc pl-6 mt-2 space-y-1">
                      {t('userRights.recipientGroups', { returnObjects: true }).map((group, idx) => (
                        <li key={idx} className="text-white/75">
                          {group}
                        </li>
                      ))}
                    </ul>
                  </li>
                  
                  {/* Punkt 2 */}
                  <li className="pl-2">
                    {t('userRights.points.1')}
                  </li>
                  
                  {/* Punkt 3 z podpunktami "prawa do żądania" */}
                  <li className="pl-2">
                    {t('userRights.points.2')}
                    <ul className="list-disc pl-6 mt-2 space-y-1">
                      {t('userRights.rightsToRequest', { returnObjects: true }).map((right, idx) => (
                        <li key={idx} className="text-white/75">
                          {right}
                        </li>
                      ))}
                    </ul>
                  </li>
                  
                  {/* Punkty 4-8 */}
                  {t('userRights.points', { returnObjects: true }).slice(3).map((point, index) => (
                    <li key={index + 3} className="pl-2">
                      {point}
                    </li>
                  ))}
                </ol>
              </section>
              
              {/* Hosting */}
              <section className="mb-10">
                <h2 className="text-2xl font-semibold text-white mb-4">
                  {t('hosting.title')}
                </h2>
                <p className="text-white/80 mb-4">
                  {t('hosting.description')}
                </p>
                
                <ol className="list-decimal pl-6 space-y-3 text-white/80">
                  {/* Punkt 1 */}
                  <li className="pl-2">
                    {t('hosting.points.0')}
                  </li>
                  
                  {/* Punkt 2 z podpunktami logów */}
                  <li className="pl-2">
                    {t('hosting.points.1')}
                    <ul className="list-disc pl-6 mt-2 space-y-1">
                      {t('hosting.loggedData', { returnObjects: true }).map((data, idx) => (
                        <li key={idx} className="text-white/75">
                          {data}
                        </li>
                      ))}
                    </ul>
                  </li>
                </ol>
              </section>
              
              {/* Zarządzanie plikami cookies */}
              <section className="mb-10">
                <h2 className="text-2xl font-semibold text-white mb-4">
                  {t('cookieManagement.title')}
                </h2>
                <p className="text-white/80 mb-4">
                  {t('cookieManagement.description')}
                </p>
                
                <ol className="list-decimal pl-6 space-y-3 text-white/80">
                  {/* Punkt 1 */}
                  <li className="pl-2">
                    {t('cookieManagement.points.0')}
                  </li>
                  
                  {/* Punkt 2 z instrukcjami dla przeglądarki */}
                  <li className="pl-2">
                    {t('cookieManagement.points.1')}
                    <ul className="list-disc pl-6 mt-2 space-y-1">
                      {t('cookieManagement.browserInstructions', { returnObjects: true }).map((instruction, idx) => (
                        <li key={idx} className="text-white/75">
                          {instruction}
                        </li>
                      ))}
                    </ul>
                  </li>
                  
                  {/* Punkt 3 - urządzenia mobilne */}
                  <li className="pl-2">
                    Urządzenia mobilne:
                    <ul className="list-disc pl-6 mt-2 space-y-1">
                      {t('cookieManagement.mobileInstructions', { returnObjects: true }).map((instruction, idx) => (
                        <li key={idx} className="text-white/75">
                          {instruction}
                        </li>
                      ))}
                    </ul>
                  </li>
                </ol>
              </section>
            </div>
          </motion.div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;