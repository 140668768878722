import Navbar from '../components/layout/Navbar';
import Hero from '../components/home/Hero';
import Services from '../components/home/Services';
import WhyUs from '../components/home/WhyUs';
import AboutUs from '../components/home/AboutUs';
import Footer from '../components/layout/Footer';
import { useTranslation } from 'react-i18next';
import OpenGraph from '../components/seo/OpenGraph';
import { usePageTitle } from '../hooks/usePageTitle';

// Oryginalne komponenty
import ProjectShowcase from '../components/home/ProjectShowcase';
import Portfolio from '../components/home/Portfolio';
import Testimonials from '../components/home/Testimonials';
import ContactSection from '../components/home/ContactSection';

function HomePage() {
  const { t } = useTranslation(['meta']);
  usePageTitle('pageTitle.home');
  
  return (
    <>
      <OpenGraph 
        title={t('pageTitle.home')}
        description={t('meta.description')}
        type="website"
      />
      <Navbar />
      <Hero />
      <ProjectShowcase />
      <Services />
      <WhyUs />
      <AboutUs />
      <Portfolio />
      <Testimonials />
      <ContactSection />
      <Footer />
    </>
  );
}

export default HomePage;