import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Mail, Phone, MapPin, Clock, ExternalLink } from 'lucide-react';
import ContactForm from '../ui/ContactForm';
import SectionPreheader from '../common/SectionPreheader';

// Komponent sekcji kontaktowej
const ContactSection = () => {
  const { t } = useTranslation(['contact']);

  // Kontener z danymi kontaktowymi
  const ContactInfo = () => {
    const contactItems = [
      {
        icon: <Mail size={20} className="text-brand-primary" />,
        label: t('contact.email.label'),
        value: t('contact.email.value'),
        href: `mailto:${t('contact.email.value')}`,
        isClickable: true
      },
      {
        icon: <Phone size={20} className="text-brand-primary" />,
        label: t('contact.phone.label'),
        value: t('contact.phone.value'),
        href: `tel:${t('contact.phone.value').replace(/\s+/g, '')}`,
        isClickable: true
      },
      {
        icon: <MapPin size={20} className="text-brand-primary" />,
        label: t('contact.address.label'),
        value: t('contact.address.value'),
        href: `https://www.google.com/maps/place/Żory`,
        isClickable: true,
        isExternal: true
      },
      {
        icon: <Clock size={20} className="text-brand-primary" />,
        label: t('contact.hours.label'),
        value: t('contact.hours.value'),
        isClickable: false
      },
    ];

    return (
      <div className="rounded-2xl backdrop-blur-md bg-white/[0.07] border border-white/10 p-6 lg:p-8 h-full">
        <h3 className="text-xl md:text-2xl font-semibold text-white mb-6">
          {t('contact.title')}
        </h3>
        
        <div className="space-y-6">
          {contactItems.map((item, index) => (
            <div key={index} className="flex items-start">
              {/* Ikona */}
              <div className="w-10 h-10 rounded-full flex items-center justify-center bg-white/[0.07] mr-4 flex-shrink-0">
                {item.icon}
              </div>
              
              {/* Treść */}
              <div>
                <div className="text-white/60 text-sm mb-1">{item.label}</div>
                {item.isClickable ? (
                  <a 
                    href={item.href} 
                    className="text-white font-medium hover:text-brand-primary transition-colors flex items-center group"
                    target={item.isExternal ? "_blank" : undefined}
                    rel={item.isExternal ? "noopener noreferrer" : undefined}
                  >
                    {item.value}
                    {item.isExternal && (
                      <ExternalLink 
                        size={14} 
                        className="ml-1 opacity-0 group-hover:opacity-100 transition-opacity" 
                      />
                    )}
                  </a>
                ) : (
                  <div className="text-white font-medium">{item.value}</div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  // Animacje dla elementów
  const fadeInUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: [0.22, 1, 0.36, 1]
      }
    }
  };

  // Obsługa sukcesu wysłania formularza
  const handleFormSuccess = (data: any) => {
    console.log('Form submitted successfully:', data);
    // Tutaj można dodać dodatkową logikę po sukcesie formularza
  };

  // Obsługa błędu wysłania formularza
  const handleFormError = (error: any) => {
    console.error('Form submission error:', error);
    // Tutaj można dodać dodatkową logikę po błędzie formularza
  };

  return (
    <section id="contact" className="w-full relative py-24 sm:py-28 overflow-hidden">
      {/* Gradient tła - przesunięty bardziej w lewo i do góry, z taką samą mocą jak w sekcji Testimonials */}
      <div className="absolute inset-0 pointer-events-none">
        <div 
          className="absolute top-1/4 left-1/4 -translate-x-1/3 w-[65vw] h-[70vh]"
          style={{
            background: `
              radial-gradient(
                ellipse at 30% 30%, 
                rgba(255, 90, 31, 0.55) 0%,
                rgba(255, 90, 31, 0.45) 10%,
                rgba(255, 125, 58, 0.3) 30%, 
                rgba(255, 159, 69, 0.15) 50%,
                rgba(255, 210, 100, 0.08) 70%,
                transparent 90%
              )
            `,
            filter: 'blur(70px)',
          }}
        />
      </div>
      
      <div className="container mx-auto px-6 sm:px-8 md:px-10 lg:px-12 relative z-10">
        <div className="max-w-3xl mx-auto text-center mb-16">
          {/* Preheader */}
          <SectionPreheader 
            text={t('preheader')} 
            variant="tertiary" 
            className="mb-8" 
          />
          
          <h2 className="text-3xl md:text-4xl font-bold mb-6 text-white">
            {t('heading')}
          </h2>
          
          <p className="text-gray-300 text-lg">
            {t('subheading')}
          </p>
        </div>
        
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 lg:gap-12">
          {/* Lewa kolumna - dane kontaktowe */}
          <motion.div
            className="lg:col-span-1"
            variants={fadeInUpVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <ContactInfo />
          </motion.div>
          
          {/* Prawa kolumna - formularz */}
          <motion.div
            className="lg:col-span-2"
            variants={fadeInUpVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <ContactForm 
              onSuccess={handleFormSuccess}
              onError={handleFormError}
            />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;