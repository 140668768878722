import React from 'react';
import { motion } from 'framer-motion';
import { cn } from '../../lib/utils';

interface SectionPreheaderProps {
  text: string;
  align?: 'left' | 'center' | 'right';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'accent';
  className?: string;
  motionEnabled?: boolean;
}

const SectionPreheader: React.FC<SectionPreheaderProps> = ({
  text,
  align = 'center',
  variant = 'primary',
  className = '',
  motionEnabled = true
}) => {
  // Klasy dla różnych wyrównań
  const alignClasses = {
    left: 'text-left',
    center: 'text-center',
    right: 'text-right'
  };
  
  // Kolory dla różnych wariantów
  const variantColors = {
    primary: '#FF5A1F',
    secondary: '#E8480D',
    tertiary: '#FF7D3A',
    accent: '#FF9F45'
  };

  // Szybkość animacji
  const speed = "6s";
  
  // Animacja wejścia
  const containerAnimation = {
    hidden: { opacity: 0, y: -10 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };
  
  // Renderowanie komponentu
  const preheaderContent = (
    <div className={cn(
      'flex justify-center',
      className
    )}>
      <div 
        className="relative inline-block py-[1px] overflow-hidden rounded-full"
      >
        {/* Dolna animowana linia */}
        <div
          className="absolute w-[300%] h-[50%] bottom-[-11px] right-[-250%] rounded-full animate-star-movement-bottom z-0 opacity-70"
          style={{
            background: `radial-gradient(circle, ${variantColors[variant]}, transparent 10%)`,
            animationDuration: speed,
          }}
        />
        
        {/* Górna animowana linia */}
        <div
          className="absolute w-[300%] h-[50%] top-[-10px] left-[-250%] rounded-full animate-star-movement-top z-0 opacity-70"
          style={{
            background: `radial-gradient(circle, ${variantColors[variant]}, transparent 10%)`,
            animationDuration: speed,
          }}
        />
        
        {/* Główny kontener z treścią */}
        <div 
          className={cn(
            'relative z-1 border text-foreground text-center text-base py-2 px-6 rounded-full',
            'backdrop-blur-md bg-surface-dark/60 border-white/10',
            alignClasses[align]
          )}
        >
          {/* Tekst preheadera */}
          <div 
            className="uppercase tracking-wider text-xs font-light text-white/80 drop-shadow-sm"
          >
            {text}
          </div>
        </div>
      </div>
    </div>
  );
  
  // Jeśli animacja jest włączona, opakuj w komponenty motion
  if (motionEnabled) {
    return (
      <motion.div
        variants={containerAnimation}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.8 }}
      >
        {preheaderContent}
      </motion.div>
    );
  }
  
  // Jeśli animacja jest wyłączona, zwracamy sam content
  return preheaderContent;
};

export default SectionPreheader;