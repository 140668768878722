import React, { useState, useEffect, useMemo } from 'react';
import { motion } from 'framer-motion';
import { Plus, Search, Edit, Trash, Loader2, X, AlertTriangle, CheckCircle2, Save, Code, Link as LinkIcon, ChevronUp, ChevronDown, Laptop, ShoppingBag, Accessibility, BarChart2, Cookie, LineChart, PieChart, DollarSign, CreditCard } from 'lucide-react';
import { supabase } from '../../lib/supabase/client';
import { Button } from '../../components/ui/Button';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import * as LucideIcons from 'lucide-react';

// Interfejs dla technologii
interface Technology {
  id: string;
  name: string;
  icon_name: string;
  sort_order: number;
}

// Interfejs dla formularza technologii
interface TechnologyForm {
  id?: string;
  name: string;
  icon_name: string;
}

// Lista ikon Lucide do wyboru - rozszerzona i pogrupowana
const iconGroups = [
  {
    category: "Języki & Frameworki",
    icons: [
      'Code',
      'FileCode',
      'Component',
      'Package',
      'Blocks',
      'BrainCircuit',
      'FileJson'
    ]
  },
  {
    category: "UI & Design",
    icons: [
      'Palette',
      'PenTool',
      'Layers',
      'Layout',
      'LayoutGrid',
      'FileText',
      'Accessibility'
    ]
  },
  {
    category: "Platformy & Urządzenia",
    icons: [
      'Globe',
      'Laptop',
      'Monitor',
      'Smartphone',
      'Box',
      'Workflow',
      'Cookie'
    ]
  },
  {
    category: "Backend & DevOps",
    icons: [
      'Database',
      'Server',
      'Cloud',
      'GitBranch',
      'Terminal',
      'Shield',
      'Settings',
      'Cog'
    ]
  },
  {
    category: "E-commerce & Analityka",
    icons: [
      'ShoppingBag',
      'Search',
      'BarChart2',
      'LineChart',
      'PieChart',
      'DollarSign',
      'CreditCard'
    ]
  }
];

// Dodatkowe ikony - pozwala na dostęp do każdej ikony z Lucide
const allIcons = Object.keys(LucideIcons)
  .filter(key => 
    typeof LucideIcons[key] === 'function' && 
    key !== 'createLucideIcon' && 
    !key.includes('Default')
  )
  .sort();

const AdminTechnologies: React.FC = () => {
  const [technologies, setTechnologies] = useState<Technology[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [technologyToDelete, setTechnologyToDelete] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [iconSearchTerm, setIconSearchTerm] = useState('');
  
  // React Hook Form
  const { register, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm<TechnologyForm>({
    defaultValues: {
      name: '',
      icon_name: 'Code'
    }
  });

  // Pobranie technologii
  useEffect(() => {
    fetchTechnologies();
  }, []);

  const fetchTechnologies = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const { data, error } = await supabase
        .from('technologies')
        .select('*')
        .order('sort_order');
      
      if (error) throw error;
      
      setTechnologies(data || []);
    } catch (error: any) {
      console.error('Błąd pobierania technologii:', error);
      setError('Wystąpił błąd podczas pobierania technologii. Spróbuj ponownie.');
    } finally {
      setLoading(false);
    }
  };

  // Filtrowanie technologii
  const filteredTechnologies = technologies.filter(technology =>
    technology.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Funkcja do edycji technologii
  const handleEditTechnology = (technology: Technology) => {
    setValue('id', technology.id);
    setValue('name', technology.name);
    setValue('icon_name', technology.icon_name);
    setIsEditing(true);
    setIsFormOpen(true);
  };

  // Funkcja do usuwania technologii
  const handleDeleteTechnology = async () => {
    if (!technologyToDelete) return;
    
    setIsDeleting(true);
    
    try {
      // Sprawdźmy, czy technologia jest używana w projektach
      const { data: projectsData, error: projectsError } = await supabase
        .from('project_technologies')
        .select('project_id')
        .eq('technology_id', technologyToDelete);
      
      if (projectsError) throw projectsError;
      
      if (projectsData && projectsData.length > 0) {
        throw new Error(`Nie można usunąć tej technologii, ponieważ jest używana w ${projectsData.length} projektach.`);
      }
      
      // Usuwanie technologii
      const { error } = await supabase
        .from('technologies')
        .delete()
        .eq('id', technologyToDelete);
      
      if (error) throw error;
      
      // Aktualizacja listy technologii
      setTechnologies(technologies.filter(technology => technology.id !== technologyToDelete));
      
    } catch (error: any) {
      console.error('Błąd usuwania technologii:', error);
      setError(error.message || 'Wystąpił błąd podczas usuwania technologii. Spróbuj ponownie.');
    } finally {
      setIsDeleting(false);
      setTechnologyToDelete(null);
    }
  };

  // Obsługa formularza
  const onSubmit = async (data: TechnologyForm) => {
    setIsSubmitting(true);
    setError(null);
    
    try {
      if (isEditing) {
        // Aktualizacja istniejącej technologii
        const { error } = await supabase
          .from('technologies')
          .update({
            name: data.name,
            icon_name: data.icon_name
          })
          .eq('id', data.id);
        
        if (error) throw error;
        
        // Aktualizacja listy technologii
        setTechnologies(technologies.map(technology => 
          technology.id === data.id 
            ? { ...technology, name: data.name, icon_name: data.icon_name } 
            : technology
        ));
      } else {
        // Znajdź najwyższy sort_order
        const maxSortOrder = technologies.length > 0
          ? Math.max(...technologies.map(t => t.sort_order))
          : 0;
        
        // Tworzenie nowej technologii
        const newTechnology = {
          id: uuidv4(),
          name: data.name,
          icon_name: data.icon_name,
          sort_order: maxSortOrder + 1
        };
        
        const { error } = await supabase
          .from('technologies')
          .insert([newTechnology]);
        
        if (error) throw error;
        
        // Aktualizacja listy technologii
        setTechnologies([...technologies, newTechnology]);
      }
      
      // Resetowanie formularza i stanu
      reset();
      setSubmitSuccess(true);
      setTimeout(() => {
        setSubmitSuccess(false);
        setIsFormOpen(false);
        setIsEditing(false);
      }, 1500);
      
    } catch (error: any) {
      console.error('Błąd zapisywania technologii:', error);
      setError('Wystąpił błąd podczas zapisywania technologii. Spróbuj ponownie.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Zmiana kolejności technologii
  const handleMoveUp = async (id: string) => {
    const index = technologies.findIndex(t => t.id === id);
    if (index <= 0) return;
    
    try {
      const currentTech = technologies[index];
      const prevTech = technologies[index - 1];
      
      const { error: error1 } = await supabase
        .from('technologies')
        .update({ sort_order: prevTech.sort_order })
        .eq('id', currentTech.id);
      
      if (error1) throw error1;
      
      const { error: error2 } = await supabase
        .from('technologies')
        .update({ sort_order: currentTech.sort_order })
        .eq('id', prevTech.id);
      
      if (error2) throw error2;
      
      const updatedTechnologies = [...technologies];
      updatedTechnologies[index] = { ...currentTech, sort_order: prevTech.sort_order };
      updatedTechnologies[index - 1] = { ...prevTech, sort_order: currentTech.sort_order };
      
      updatedTechnologies.sort((a, b) => a.sort_order - b.sort_order);
      
      setTechnologies(updatedTechnologies);
      
    } catch (error: any) {
      console.error('Błąd zmiany kolejności:', error);
      setError('Wystąpił błąd podczas zmiany kolejności. Spróbuj ponownie.');
    }
  };

  const handleMoveDown = async (id: string) => {
    const index = technologies.findIndex(t => t.id === id);
    if (index >= technologies.length - 1) return;
    
    try {
      const currentTech = technologies[index];
      const nextTech = technologies[index + 1];
      
      const { error: error1 } = await supabase
        .from('technologies')
        .update({ sort_order: nextTech.sort_order })
        .eq('id', currentTech.id);
      
      if (error1) throw error1;
      
      const { error: error2 } = await supabase
        .from('technologies')
        .update({ sort_order: currentTech.sort_order })
        .eq('id', nextTech.id);
      
      if (error2) throw error2;
      
      const updatedTechnologies = [...technologies];
      updatedTechnologies[index] = { ...currentTech, sort_order: nextTech.sort_order };
      updatedTechnologies[index + 1] = { ...nextTech, sort_order: currentTech.sort_order };
      
      updatedTechnologies.sort((a, b) => a.sort_order - b.sort_order);
      
      setTechnologies(updatedTechnologies);
      
    } catch (error: any) {
      console.error('Błąd zmiany kolejności:', error);
      setError('Wystąpił błąd podczas zmiany kolejności. Spróbuj ponownie.');
    }
  };

  // Wartość wyboru ikony
  const selectedIcon = watch('icon_name');

  // Filtrowanie ikon na podstawie wyszukiwania
  const filteredIcons = useMemo(() => {
    if (!iconSearchTerm) {
      return iconGroups;
    }
    
    const searchTermLower = iconSearchTerm.toLowerCase();
    
    // Filtruj ikony w każdej kategorii
    return iconGroups.map(group => ({
      category: group.category,
      icons: group.icons.filter(iconName => 
        iconName.toLowerCase().includes(searchTermLower)
      )
    })).filter(group => group.icons.length > 0);
    
  }, [iconSearchTerm]);

  // Animacja
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.6, ease: [0.22, 1, 0.36, 1] }
    }
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-8"
        variants={itemVariants}
      >
        <div>
          <h1 className="text-3xl font-bold text-white mb-2">Technologie</h1>
          <p className="text-white/70">Zarządzaj technologiami używanymi w projektach</p>
        </div>
        
        <Button 
          variant="default" 
          icon={<Plus size={16} />}
          onClick={() => {
            reset();
            setIsEditing(false);
            setIsFormOpen(true);
          }}
        >
          Nowa technologia
        </Button>
      </motion.div>
      
      {/* Wyszukiwarka */}
      <motion.div 
        className="mb-6"
        variants={itemVariants}
      >
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-white/50">
            <Search size={18} />
          </div>
          <input
            type="text"
            placeholder="Szukaj technologii..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full py-2.5 pl-10 pr-4 rounded-lg bg-white/5 border border-white/10 text-white placeholder-white/40 focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all"
          />
          {searchTerm && (
            <button
              onClick={() => setSearchTerm('')}
              className="absolute inset-y-0 right-0 flex items-center pr-3 text-white/50 hover:text-white/80 transition-colors"
            >
              <X size={16} />
            </button>
          )}
        </div>
      </motion.div>
      
      {/* Komunikat błędu */}
      {error && (
        <motion.div
          className="mb-6 p-4 bg-red-950/30 border border-red-800/50 rounded-lg text-red-200 flex items-center"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
        >
          <AlertTriangle size={18} className="mr-2 flex-shrink-0" />
          <span>{error}</span>
        </motion.div>
      )}
      
      {/* Formularz dodawania/edycji technologii */}
      {isFormOpen && (
        <motion.div
          className="mb-6 p-6 bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
        >
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-white">
              {isEditing ? 'Edytuj technologię' : 'Nowa technologia'}
            </h2>
            <button
              onClick={() => {
                setIsFormOpen(false);
                setIsEditing(false);
                reset();
              }}
              className="p-1.5 rounded-md bg-white/5 text-white/70 hover:bg-white/10 hover:text-white transition-colors"
            >
              <X size={18} />
            </button>
          </div>
          
          {/* Komunikat sukcesu */}
          {submitSuccess && (
            <div className="mb-4 p-3 bg-green-950/30 border border-green-800/50 rounded-lg text-green-200 flex items-center">
              <CheckCircle2 size={18} className="mr-2 flex-shrink-0" />
              <span>{isEditing ? 'Technologia została zaktualizowana!' : 'Technologia została dodana!'}</span>
            </div>
          )}
          
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* Nazwa technologii */}
            <div className="mb-6">
              <label className="block mb-2 text-sm font-medium text-white/80">
                Nazwa technologii
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-white/50">
                  <LinkIcon size={18} />
                </div>
                <input
                  type="text"
                  {...register('name', { required: 'Nazwa technologii jest wymagana' })}
                  className={`w-full py-2.5 pl-10 pr-4 rounded-lg ${
                    errors.name 
                      ? 'bg-red-900/20 border-red-800/50 focus:ring-red-500/30 focus:border-red-600/50' 
                      : 'bg-white/5 border-white/10 focus:ring-brand-primary/30 focus:border-brand-primary/50'
                  } text-white focus:outline-none focus:ring-2 transition-all`}
                  placeholder="np. React, Next.js, WordPress"
                />
              </div>
              {errors.name && (
                <p className="mt-2 text-sm text-red-400">{errors.name.message}</p>
              )}
            </div>
            
            {/* Wyszukiwarka ikon */}
            <div className="mb-6">
              <div className="flex justify-between items-center mb-2">
                <label className="text-sm font-medium text-white/80">
                  Wyszukaj ikonę
                </label>
              </div>
              <div className="relative mb-4">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-white/50">
                  <Search size={16} />
                </div>
                <input
                  type="text"
                  placeholder="Wyszukaj ikonę..."
                  value={iconSearchTerm}
                  onChange={(e) => setIconSearchTerm(e.target.value)}
                  className="w-full py-2 pl-10 pr-4 rounded-lg bg-white/5 border border-white/10 text-white placeholder-white/40 focus:outline-none focus:ring-2 focus:ring-brand-primary/30 focus:border-brand-primary/50 transition-all text-sm"
                />
                {iconSearchTerm && (
                  <button
                    type="button"
                    onClick={() => setIconSearchTerm('')}
                    className="absolute inset-y-0 right-0 flex items-center pr-3 text-white/50 hover:text-white/80 transition-colors"
                  >
                    <X size={14} />
                  </button>
                )}
              </div>
              
              {/* Wybór ikony - z przewijaniem */}
              <div className="p-4 bg-white/5 border border-white/10 rounded-lg max-h-60 overflow-y-auto custom-scrollbar">
                {filteredIcons.length === 0 ? (
                  <p className="text-white/60 text-center">Nie znaleziono ikon</p>
                ) : (
                  <div className="space-y-6">
                    {filteredIcons.map((group, groupIndex) => (
                      <div key={groupIndex}>
                        {/* Nagłówek grupy */}
                        <h4 className="text-sm font-medium text-white/80 mb-3">{group.category}</h4>
                        
                        {/* Siatka ikon */}
                        <div className="grid grid-cols-4 sm:grid-cols-6 md:grid-cols-8 gap-2">
                          {group.icons.map((iconName) => {
                            const IconComponent = (LucideIcons as any)[iconName] || LucideIcons.Code;
                            const isSelected = selectedIcon === iconName;
                            
                            return (
                              <button
                                key={iconName}
                                type="button"
                                onClick={() => setValue('icon_name', iconName)}
                                className={`p-2 rounded-lg flex flex-col items-center justify-center gap-1 hover:bg-white/10 transition-colors ${
                                  isSelected ? 'bg-brand-primary/20 text-brand-primary border border-brand-primary/30' : 'text-white/70 border border-transparent'
                                }`}
                              >
                                <IconComponent size={18} />
                                <span className="text-xs truncate w-full text-center">{iconName}</span>
                              </button>
                            );
                          })}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            
            {/* Podgląd wybranej ikony */}
            <div className="mb-6 p-4 bg-white/5 border border-white/10 rounded-lg">
              <div className="flex items-center gap-4">
                <div className="p-3 rounded-lg bg-white/10 text-white">
                  {React.createElement(
                    (LucideIcons as any)[selectedIcon] || LucideIcons.Code,
                    { size: 24 }
                  )}
                </div>
                <div>
                  <p className="text-white font-medium">{selectedIcon}</p>
                  <p className="text-white/60 text-sm">Tak będzie wyglądać ikona</p>
                </div>
              </div>
            </div>
            
            {/* Przyciski akcji */}
            <div className="flex justify-end">
              <Button 
                type="submit" 
                variant="default" 
                icon={isSubmitting ? <Loader2 size={16} className="animate-spin" /> : <Save size={16} />}
                disabled={isSubmitting}
              >
                {isSubmitting 
                  ? (isEditing ? 'Aktualizowanie...' : 'Dodawanie...') 
                  : (isEditing ? 'Zapisz zmiany' : 'Dodaj technologię')
                }
              </Button>
            </div>
          </form>
        </motion.div>
      )}
      
      {/* Lista technologii */}
      <motion.div 
        variants={itemVariants}
        className="bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl overflow-hidden"
      >
        {loading ? (
          <div className="flex items-center justify-center py-12">
            <Loader2 size={24} className="text-brand-primary animate-spin mr-2" />
            <span className="text-white/70">Ładowanie technologii...</span>
          </div>
        ) : filteredTechnologies.length === 0 ? (
          <div className="text-center py-12">
            <Code size={48} className="text-white/20 mx-auto mb-4" />
            <div className="text-white/40 mb-2">Nie znaleziono technologii</div>
            <p className="text-white/60 text-sm max-w-md mx-auto">
              {searchTerm 
                ? 'Spróbuj zmienić kryteria wyszukiwania.' 
                : 'Dodaj swoją pierwszą technologię, klikając przycisk "Nowa technologia".'}
            </p>
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="border-b border-white/10">
                  <th className="px-6 py-3 text-left text-xs font-medium text-white/40 uppercase tracking-wider">
                    Kolejność
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white/40 uppercase tracking-wider">
                    Ikona
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white/40 uppercase tracking-wider">
                    Nazwa
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-white/40 uppercase tracking-wider">
                    Akcje
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-white/5">
                {filteredTechnologies.map((technology, index) => (
                  <tr key={technology.id} className="hover:bg-white/5 transition-colors">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center justify-center space-x-1">
                        <button
                          onClick={() => handleMoveUp(technology.id)}
                          disabled={index === 0}
                          className={`p-1 rounded-full ${
                            index === 0 
                              ? 'text-white/20 cursor-not-allowed' 
                              : 'text-white/60 hover:text-white hover:bg-white/10'
                          } transition-colors`}
                        >
                          <ChevronUp size={16} />
                        </button>
                        <button
                          onClick={() => handleMoveDown(technology.id)}
                          disabled={index === filteredTechnologies.length - 1}
                          className={`p-1 rounded-full ${
                            index === filteredTechnologies.length - 1 
                              ? 'text-white/20 cursor-not-allowed' 
                              : 'text-white/60 hover:text-white hover:bg-white/10'
                          } transition-colors`}
                        >
                          <ChevronDown size={16} />
                        </button>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="p-2 rounded-lg bg-white/10 inline-flex items-center justify-center">
                        {React.createElement(
                          (LucideIcons as any)[technology.icon_name] || LucideIcons.Code,
                          { size: 18, className: 'text-white' }
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="text-white">{technology.name}</span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center">
                      <div className="flex items-center justify-center gap-2">
                        <button 
                          onClick={() => handleEditTechnology(technology)}
                          className="p-1.5 rounded-md bg-white/5 text-white/70 hover:bg-blue-500/20 hover:text-blue-400 transition-colors"
                        >
                          <Edit size={16} />
                        </button>
                        <button 
                          onClick={() => setTechnologyToDelete(technology.id)}
                          className="p-1.5 rounded-md bg-white/5 text-white/70 hover:bg-red-500/20 hover:text-red-400 transition-colors"
                        >
                          <Trash size={16} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </motion.div>
      
      {/* Dialog potwierdzenia usunięcia */}
      {technologyToDelete && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm">
          <div className="bg-surface-dark backdrop-blur-xl w-full max-w-md border border-white/10 rounded-xl p-6 shadow-2xl">
            <h3 className="text-xl font-semibold text-white mb-2">Potwierdzenie usunięcia</h3>
            <p className="text-white/70 mb-6">Czy na pewno chcesz usunąć tę technologię? Ta operacja jest nieodwracalna.</p>
            
            <div className="flex justify-end gap-3">
              <button
                onClick={() => setTechnologyToDelete(null)}
                className="px-4 py-2 rounded-lg bg-white/5 text-white hover:bg-white/10 transition-colors"
                disabled={isDeleting}
              >
                Anuluj
              </button>
              <button
                onClick={handleDeleteTechnology}
                className="px-4 py-2 rounded-lg bg-red-600/80 text-white hover:bg-red-600 transition-colors flex items -center"
                disabled={isDeleting}
              >
                {isDeleting ? (
                  <>
                    <Loader2 size={16} className="animate-spin mr-2" />
                    Usuwanie...
                  </>
                ) : (
                  <>
                    <Trash size={16} className="mr-2" />
                    Usuń technologię
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default AdminTechnologies;