import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import './i18n'; // Importujemy konfigurację i18n

// Przechwytywanie i filtrowanie tylko komunikatów konsoli związanych z React DevTools
const originalConsoleLog = console.log;
console.log = function(...args) {
  // Jeśli komunikat dotyczy React DevTools, nie wyświetlaj go
  if (args && args.length > 0 && typeof args[0] === 'string' && 
      args[0].includes('Download the React DevTools')) {
    return;
  }
  originalConsoleLog.apply(console, args);
};

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>
);