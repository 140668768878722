import React, { useState, useRef, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { GooeyFilter } from "./GooeyFilter";
import { useScreenSize } from "../../hooks/useScreenSize";
import { cn } from "../../lib/utils";

export interface TabItem {
  title: string;
  icon?: React.ReactNode;
  content: React.ReactNode;
}

interface TabsComponentProps {
  tabs: TabItem[];
  defaultTab?: number;
  className?: string;
  contentClassName?: string;
  animated?: boolean;
  gooeyEffect?: boolean;
  theme?: 'light' | 'dark' | 'brand';
}

export const TabsComponent: React.FC<TabsComponentProps> = ({
  tabs,
  defaultTab = 0,
  className = "",
  contentClassName = "",
  animated = true,
  gooeyEffect = true,
  theme = 'brand'
}) => {
  const [activeTab, setActiveTab] = useState(defaultTab);
  const screenSize = useScreenSize();
  const isMobile = screenSize.lessThan("md");
  const tabsContainerRef = useRef<HTMLDivElement>(null);

  // Kolory w zależności od motywu
  const themeStyles = {
    light: {
      tab: "bg-white/95",
      tabActive: "text-brand-primary",
      tabInactive: "text-text-secondary",
      content: "bg-white/95",
      contentText: "text-gray-700"
    },
    dark: {
      tab: "bg-[#1A1A1A]",
      tabActive: "text-white",
      tabInactive: "text-white/60",
      content: "bg-[#1A1A1A]",
      contentText: "text-white/80"
    },
    brand: {
      tab: "bg-[#181818]",
      tabActive: "text-brand-primary font-semibold tracking-wide",
      tabInactive: "text-white/70",
      content: "bg-[#181818]",
      contentText: "text-white/80"
    }
  };

  const styles = themeStyles[theme];

  // Efekt przewijania do aktywnej zakładki w widoku mobilnym
  useEffect(() => {
    if (tabsContainerRef.current && isMobile) {
      const container = tabsContainerRef.current;
      const activeTabElement = container.children[activeTab] as HTMLElement;
      
      if (activeTabElement) {
        // Oblicz pozycję, aby zakładka była wycentrowana
        const containerWidth = container.offsetWidth;
        const tabWidth = activeTabElement.offsetWidth;
        const tabLeft = activeTabElement.offsetLeft;
        
        // Płynne przewijanie do aktywnej zakładki z dodatkowym marginesem bezpieczeństwa
        container.scrollTo({
          left: Math.max(0, tabLeft - (containerWidth - tabWidth) / 2),
          behavior: 'smooth'
        });
      }
    }
  }, [activeTab, isMobile]);

  return (
    <div className={cn("relative w-full", className)}>
      {/* Filtr gooey - widoczny tylko gdy efekt jest włączony */}
      {gooeyEffect && (
        <GooeyFilter
          id="services-gooey-filter"
          strength={isMobile ? 6 : 12}
        />
      )}

      <div className="w-full relative">
        {/* Warstwa z efektem gooey zawierająca taby i treść */}
        <div
          className="relative w-full"
          style={{ filter: gooeyEffect ? "url(#services-gooey-filter)" : "none" }}
        >
          {/* Kontener nagłówków zakładek ze scrollem na mobile */}
          <div className="overflow-x-auto scrollbar-none">
            <div 
              className="flex min-w-min w-full" 
              style={{ minWidth: isMobile ? `${tabs.length * 110}px` : 'auto' }}
            >
              {tabs.map((_, index) => (
                <div key={index} className="relative flex-1 h-14 sm:h-20 md:h-24">
                  {activeTab === index && (
                    <motion.div
                      layoutId="active-tab"
                      className={cn(
                        "absolute inset-0", 
                        styles.tab,
                        "rounded-t-lg", // Zaokrąglenie tylko górnych rogów
                        "shadow-[0_-3px_15px_rgba(255,90,31,0.2)]" // Delikatna poświata dla aktywnego taba
                      )}
                      transition={{
                        type: "spring",
                        bounce: 0.05,
                        duration: 0.4,
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          
          {/* Panel treści - z tą samą klasą bg co aktywny tab dla płynnego przejścia */}
          <div className={cn(
            "w-full min-h-[350px] sm:min-h-[400px]", 
            styles.tab, 
            styles.contentText,
            "rounded-b-lg border-t-0", // Zaokrąglenie tylko dolnych rogów, brak górnej ramki
            contentClassName
          )}>
            <AnimatePresence mode="wait">
              <motion.div
                key={activeTab}
                initial={animated ? {
                  opacity: 0,
                  y: 20,
                  filter: "blur(8px)",
                } : undefined}
                animate={animated ? {
                  opacity: 1,
                  y: 0,
                  filter: "blur(0px)",
                } : undefined}
                exit={animated ? {
                  opacity: 0,
                  y: -20,
                  filter: "blur(8px)",
                } : undefined}
                transition={{
                  duration: 0.3,
                  ease: "easeOut",
                }}
                className="p-4 sm:p-6 md:p-8 lg:p-10 h-full"
              >
                {tabs[activeTab].content}
              </motion.div>
            </AnimatePresence>
          </div>
        </div>

        {/* Interaktywne nagłówki zakładek - nakładka bez filtra ze scrollem */}
        <div className="absolute top-0 left-0 right-0 overflow-x-auto scrollbar-none">
          <div 
            ref={tabsContainerRef}
            className="flex min-w-min" 
            style={{ minWidth: isMobile ? `${tabs.length * 110}px` : 'auto' }}
          >
            {tabs.map((tab, index) => (
              <button
                key={index}
                onClick={() => setActiveTab(index)}
                className="flex-1 h-14 sm:h-20 md:h-24"
                style={{ minWidth: isMobile ? '110px' : 'auto' }}
              >
                <div
                  className={cn(
                    "w-full h-full flex flex-col items-center justify-center transition-colors duration-300",
                    "text-xs sm:text-sm md:text-base px-2 py-1 sm:py-2",
                    activeTab === index 
                      ? cn(styles.tabActive, "font-medium relative") 
                      : cn(styles.tabInactive, "font-normal")
                  )}
                >
                  {/* Mobile layout - icon above text with more spacing */}
                  <div className="sm:hidden flex flex-col items-center justify-center gap-1.5 pt-1 relative">
                    {tab.icon && <span className="text-base">{tab.icon}</span>}
                    <span className="text-center text-[11px] leading-tight max-w-[90px] mx-auto">{tab.title}</span>
                    
                    {/* Aktywny wskaźnik dla mobile */}
                    {activeTab === index && (
                      <div className="absolute bottom-[-8px] w-10 h-1 bg-brand-primary rounded-full" />
                    )}
                  </div>
                  
                  {/* Desktop layout - icon beside text */}
                  <div className="hidden sm:flex items-center justify-center gap-2 relative">
                    {tab.icon && <span className="text-base sm:text-lg">{tab.icon}</span>}
                    <span className="truncate">{tab.title}</span>
                    
                    {/* Aktywny wskaźnik dla desktop */}
                    {activeTab === index && (
                      <div className="absolute -bottom-4 left-1/2 transform -translate-x-1/2 w-12 h-1 bg-brand-primary rounded-full" />
                    )}
                  </div>
                  
                  {/* Dodatkowe podświetlenie aktywnej zakładki */}
                  {activeTab === index && (
                    <div className="absolute inset-0 border-2 border-b-0 border-brand-primary/30 rounded-t-lg opacity-60" />
                  )}
                </div>
              </button>
            ))}
          </div>
        </div>
        
        {/* Stylizowane wskaźniki przewijania dla mobile */}
        {isMobile && (
          <>
            <div className="absolute top-0 left-0 w-12 h-14 bg-gradient-to-r from-[#181818] to-transparent pointer-events-none z-10" />
            <div className="absolute top-0 right-0 w-12 h-14 bg-gradient-to-l from-[#181818] to-transparent pointer-events-none z-10" />
            
            {/* Dodatkowe wskaźniki przewijania dla mobile */}
            <div className="absolute -bottom-5 left-0 right-0 flex justify-center pointer-events-none z-10">
              <div className="flex space-x-1.5">
                {tabs.map((_, index) => (
                  <div
                    key={index}
                    className={`w-2 h-2 rounded-full transition-all ${
                      activeTab === index
                        ? "bg-brand-primary w-4"
                        : "bg-white/30"
                    }`}
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};